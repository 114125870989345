export const ExpiredLoadsIcon = () => {
    return (
        <svg width="30" height="25" viewBox="0 0 318 323" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M309.748 322.995H62.2522C59.3921 322.995 56.7416 321.681 55.235 319.525C53.7284 317.368 53.5915 314.683 54.8724 312.412L178.62 93.0418C180.021 90.5722 183.008 89.0012 185.996 89C188.986 88.9988 191.977 90.5698 193.38 93.0418L317.128 312.412C318.409 314.683 318.272 317.368 316.765 319.525C315.258 321.681 312.608 322.995 309.748 322.995V322.995ZM75.6021 308.37H296.398L186 112.666L75.6015 308.37H75.6021Z" fill="black" />
            <path d="M177.748 176.748H194.247V249.872H177.748V176.748Z" fill="black" />
            <path d="M177.748 264.498H194.247V279.123H177.748V264.498Z" fill="black" />
            <path d="M84.4987 0C37.8347 0 0 37.8347 0 84.4987C0 131.163 37.8347 169 84.4987 169H101.399C114 169 113 152.099 101.399 152.099H84.4987C47.166 152.099 16.9008 121.831 16.9008 84.4987C16.9008 47.166 47.166 16.9008 84.4987 16.9008C121.831 16.9008 152.099 47.166 152.099 84.4987V101.399C155 109 165.5 110 169 101.399V84.4987C169 37.8347 131.163 0 84.4987 0Z" fill="black" />
            <path d="M96.2212 40V93L69.7212 119.5" stroke="black" strokeWidth="9" strokeLinecap="round" />
            <circle cx="94.4836" cy="92.131" r="11.2951" fill="black" />
        </svg>

    )
}
// ;vertical-align: middle;overflow: hidden;"
