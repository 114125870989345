import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from "react-router";
import axios from 'axios'
import { environmentVariables } from "../../environment"

const Callback = () => {
    const history = useHistory();
    const { user, logout, isAuthenticated, isLoading } = useAuth0();

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    function handleRedirect() {
        // Api call to check if the user exists in DB
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            window.localStorage.userCompanyName = "SystemAdmin";
            window.localStorage.removeItem('LoadIdEmail');
            history.push({ pathname: '/admin' });
        }
        else {
            axios(
                {
                    url: environmentVariables.getuserdetails,
                    method: 'get'
                }
            ).then(response => {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "UserNotPresent") {
                    const findUser = newResponse.find(
                        ({ email_address }) => email_address === user.email
                    )
                    if (findUser === undefined || findUser === null) {
                        window.localStorage.removeItem('LoadIdEmail');
                        history.push({ pathname: '/useragreement' });
                    }
                    else {
                        window.localStorage.setItem('ifUserExist', true)
                        if (findUser.login_status === "AgreementAgreed") {
                            window.localStorage.removeItem('LoadIdEmail');
                            history.push({ pathname: '/createprofile' });
                        }
                        else if (findUser.login_status === "ProfileCreated") {
                            window.localStorage.removeItem('LoadIdEmail');
                            history.push({ pathname: '/underverification' });
                        }
                        else if (findUser.login_status === "ProfileSuspended") {
                            history.push({ pathname: '/usersuspended' });
                        }
                        else if (findUser.company_status === "ProfileDeactivated") {
                            history.push({ pathname: '/companysuspended' });
                        }
                        else if (findUser.login_status === "ProfileRejected") {
                            history.push({ pathname: '/rejecteduser' });
                        }
                        else if (findUser.login_status === "ProfileDeleted") {
                            logout({
                                returnTo: window.location.origin,
                            });
                        }
                        else if (findUser.login_status === "ProfileAccepted") {
                            if (findUser.user_role) {
                                window.localStorage.setItem('user_role', findUser.user_role)
                            }
                            if (findUser.company_role) {
                                window.localStorage.setItem('company_role', findUser.company_role);
                            }
                            if (findUser.company_mc_number) {
                                window.localStorage.setItem('company_mc_number', findUser.company_mc_number);
                            }
                            if (findUser.login_status) {
                                window.localStorage.setItem('login_status', findUser.login_status);
                            }

                            if (findUser.companyName) {
                                window.localStorage.userCompanyName = findUser.companyName;
                            }
                            if (findUser.first_name && findUser.last_name) {
                                window.localStorage.userName = pascalCase(findUser.first_name) + " " + pascalCase(findUser.last_name);
                            }

                            if (window.localStorage.getItem('LoadIdEmail')) {
                                history.push({
                                    pathname: '/loadboard',
                                    state: { loadId: window.localStorage.getItem('LoadIdEmail') }
                                });

                            }
                            else {
                                history.push({ pathname: '/loadboard' });
                            }
                            // Commented as still there is work to be done
                            // if (findUser.user_role === "Carrier") {
                            //     history.push({ pathname: '/carrier' });
                            // }
                            // else if (findUser.user_role === "Broker") {
                            //     history.push({ pathname: '/broker' });
                            // }
                            // else if (findUser.user_role === "Broker&Carrier") {
                            //     history.push({ pathname: '/carrier' });
                            // }
                            // else if (findUser.user_role === "CompanyAdmin") {
                            //     history.push({ pathname: '/carrier' });
                            // }
                        }
                        else {
                            logout({
                                returnTo: window.location.origin,
                            });
                        }
                    }
                }
                else {
                    window.localStorage.removeItem('LoadIdEmail');
                    history.push({ pathname: '/useragreement' });
                }
            })
                .catch(error => {
                    console.log("callback error", error)
                })
        }
    }
    return (
        <div>
            {isLoading && <h2>Loading..</h2>}
            {isAuthenticated && <React.Fragment> {handleRedirect()} </React.Fragment>}
        </div>
    )
}

export default Callback;