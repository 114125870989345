import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    CssBaseline,
    InputLabel,
    Typography,
    Grid,
    MenuItem,
    TextField,
    Select,
    TextareaAutosize,
    IconButton,
    Snackbar,
    Tooltip
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Row, Col, Image, Form } from "react-bootstrap";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import { useAuth0 } from "@auth0/auth0-react";
import { uploadFile } from "react-s3";
import * as AWS from "aws-sdk";
import Swal from 'sweetalert2';
import FormControlMUI from "@material-ui/core/FormControl";
import MaskedInput from "react-text-mask";
import useWebSocket, { } from 'react-use-websocket';

// Imports to have all the Icons
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Import statements for CSS and environment
import { environmentVariables } from "../../../environment"
import MUIDrawer from "../../Drawer"
import "./index.css";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: "none",
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    bottomTool: {
        marginBottom: 91,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: "auto"
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const S3_BUCKET = "loadeo-company-logo-images";
const DIRECTORY = "company_logos";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAT6WHWU3YF7CIHQP7";
const SECRET_ACCESS_KEY = "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";

const s3 = new AWS.S3({
    secretAccessKey: SECRET_ACCESS_KEY, accessKeyId: ACCESS_KEY, region: REGION
});

const config = {
    bucketName: S3_BUCKET,
    dirName: DIRECTORY,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

function PhoneNumberInput(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}

PhoneNumberInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
};


const CompanyProfile = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth0();

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState("");

    const [loggedInUserRole, setLoggedInUserRole] = useState("");
    const [emailList, setEmailList] = useState("");

    // Company Info Variables
    const [companyName, setCompanyName] = useState("");
    const [companyMCNumber, setCompanyMCNumber] = useState(location.mcNumber);
    const [companyID, setCompanyID] = useState("");
    const [companyRole, setCompanyRole] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [companyState, setCompanyState] = useState("");
    const [companyEmailAddress, setCompanyEmailAddress] = useState("");
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyStatus, setCompanyStatus] = useState("");
    const [companyDisabledCompanies, setCompanyDisabledCompanies] = useState([]);

    const [selectedCompanyLogo, setSelectedCompanyLogo] = useState(null);
    const [selectedCompanyLogoName, setSelectedCompanyLogoName] = useState("");
    const [previewCompanyLogo, setPreviewCompanyLogo] = useState("");
    const [editCompanyDetails, setEditCompanyDetails] = useState(false);
    const [deleteCompanyLogo, setDeleteCompanyLogo] = useState(false);
    const [oldMCNumber, setOldMCNumber] = useState(location.mcNumber);
    const [mcNumberValid, setMCNumberValid] = useState(true);
    const [tempCompanyLogo, setTempCompanyLogo] = useState("");

    // Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            shouldReconnect: (closeEvent) => true,
        });

    useEffect(() => {
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            if (!location.mcNumber) {
                history.push({ pathname: "/systemadmincompanies" })
            }
        }
        getUserMcNumber();
    }, [location.mcNumber ? location.mcNumber : companyMCNumber, history, user.email, location.mcNumber]);

    // Function to handle send Socket IO messages
    async function sendMessageForSocketIO(message, recieverId) {
        await axios({
            url: environmentVariables.websocketfetchallusers,
            method: 'get'
        })
            .then(response => {
                let newResponse = JSON.parse(response.data.body)
                let connectionIds = []
                newResponse.forEach(element => {
                    if (recieverId === null || recieverId === "") {
                        connectionIds.push(element.connection_id)
                    }
                    else {
                        if (recieverId === element.user_id) {
                            connectionIds.push(element.connection_id)
                        }
                    }

                });
                sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
            })
            .catch(error => { })
    }

    // Function To handle snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    //Function to get User Mc Number
    async function getUserMcNumber() {
        if (!location.mcNumber) {
            await axios({
                url: environmentVariables.getuserdetails,
                method: 'get',
            })
                .then((response) => {
                    let newResponse = JSON.parse(response.data.body)
                    const findUser = newResponse.find(
                        ({ email_address }) => email_address === user.email
                    );
                    if (findUser.company_mc_number) {
                        setCompanyMCNumber(findUser.company_mc_number);
                    }
                    if (findUser.user_role) {
                        setLoggedInUserRole(findUser.user_role);
                    }
                    if (findUser.user_role === "Carrier" || findUser.user_role === "Broker" || findUser.user_role === "Broker&Carrier") {
                        history.push({ pathname: "/forbidden" })
                    }
                }).finally(() => {
                    getCompanyInfo();
                })
                .catch(error => {
                    history.push({ pathname: '/systemadmincompanies' });
                });
        }
        else {
            setCompanyMCNumber(location.mcNumber);
            getCompanyInfo();
        }
    }

    //Function to Update Company Profile  
    async function updateCompanyProfile(logo) {
        let response = await getUserDetailsResponse(companyMCNumber)
        if (response.data === "UserNotPresent" || companyMCNumber === oldMCNumber) {
            const listValues = {
                company_id: companyID,
                website: companyWebsite,
                mc_number: companyMCNumber,
                companyName: companyName.toUpperCase(),
                company_role: companyRole === "Broker & Carrier" ? "Broker&Carrier" : companyRole,
                companyDescription: companyDescription,
                address: companyAddress,
                city: companyCity,
                state: companyState,
                email_address: companyEmailAddress,
                phone_number: companyPhoneNumber,
                logo: logo,
                old_mc_number: oldMCNumber,
                status: companyStatus,
                disabled_companies: companyDisabledCompanies
            };
            sendMessageForSocketIO("companyDetailsChanged", emailList);
            axios({
                url: environmentVariables.updatecompanyprofile,
                method: 'patch',
                data: listValues,
            })
                .then((success) => {
                    location.mcNumber = companyMCNumber;
                }).finally(() => {
                    getCompanyInfo();
                    setOldMCNumber(companyMCNumber);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else
            if (companyMCNumber !== "UserNotPresent" && companyMCNumber !== oldMCNumber && response !== companyMCNumber) {
                Swal.fire({
                    text: "MC number is already exists in the db",
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
                setCompanyMCNumber(oldMCNumber)
                setEditCompanyDetails(true)
            }
    }

    const handleCompanyLogo = (e) => {
        setPreviewCompanyLogo(URL.createObjectURL(e.target.files[0]));
        var file = e.target.files[0];
        var temp = file.slice(0, file.size, 'image/png');
        setSelectedCompanyLogo(new File([temp], `${companyID}.png`, { type: 'image/png' }));
        setSelectedCompanyLogoName(file.name);
    }

    //Function to handle Save Changes
    const handleSaveChanges = () => {
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            if (mcNumberValid) {
                setEditCompanyDetails(false);
                if (deleteCompanyLogo) {
                    deleteFile()
                    if (selectedCompanyLogo) {
                        handleUpload(selectedCompanyLogo);
                    }
                }
                else if (!deleteCompanyLogo && selectedCompanyLogo) {
                    handleUpload(selectedCompanyLogo);
                }
                else if (!deleteCompanyLogo && !selectedCompanyLogo) {
                    updateCompanyProfile(tempCompanyLogo);
                }
            }
            else {
                Swal.fire({
                    text: "MC Number should have length between 6-10 digits",
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    }

    const handleDeleteButton = () => {
        setTempCompanyLogo("");
        setDeleteCompanyLogo(true);
        setPreviewCompanyLogo("");
    }

    const handleDeleteSelectedButton = () => {
        setSelectedCompanyLogo(null);
        setSelectedCompanyLogoName("");
        setPreviewCompanyLogo(tempCompanyLogo);
    }

    //Function to handle Cancel Changes
    const handleCancelChanges = () => {
        setEditCompanyDetails(false);
        getCompanyInfo();
        setCompanyMCNumber(location.mcNumber);
        setPreviewCompanyLogo(companyLogo);
    }

    const deleteFile = async () => {
        var fileName = "company_logos/" + companyID + ".png"
        await s3.deleteObject({
            Key: fileName,
            Bucket: S3_BUCKET
        }).promise().then(() => {
            setDeleteCompanyLogo(false);
            updateCompanyProfile(tempCompanyLogo);
        });

    }

    const handleUpload = (file) => {
        if (companyLogo) {
            deleteFile().then(() => {
                uploadFile(file, config)
                    .then((data) => {
                        setCompanyLogo(data.location);
                        setTempCompanyLogo(data.location);
                        updateCompanyProfile(data.location);
                        setSelectedCompanyLogo(null);
                        setPreviewCompanyLogo(data.location);
                    })
                    .catch((err) => console.log(err))
            });
        }
        else {
            uploadFile(file, config)
                .then((data) => {
                    setTempCompanyLogo(data.location);
                    setCompanyLogo(data.location);
                    updateCompanyProfile(data.location);
                    setSelectedCompanyLogo(null);
                    setPreviewCompanyLogo(data.location);
                })
                .catch((err) => console.log(err))
        }
    }

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    //function to get email Address of all user with same MC Number
    async function getUserDetailsResponse(company_mc_number) {

        let response = await axios({
            url: environmentVariables.getapprovedcompanyuserdetails + "?company_mc_number=" + company_mc_number,
            method: "get",

            if(response) {
                // let newResponse = response.data.body
                let id = []
                response.data.forEach(data => {
                    if (response.data !== "UserNotPresent") {
                        id.push(data.email_address)
                    }
                })
                setEmailList(id)
            }
        })
        return response
    }

    //Function to get company Information
    async function getCompanyInfo() {
        axios({
            url: environmentVariables.getcompanyinformation + "?mc_number=" + companyMCNumber,
            method: 'get'
        })
            .then((response) => {
                // let newResponse = JSON.parse(response.data.body)
                // Setting Company ID
                if (response.data[0].company_id) {
                    setCompanyID(response.data[0].company_id);
                }

                if (response.data[0].disabled_companies) {
                    setCompanyDisabledCompanies(response.data[0].disabled_companies);
                }

                // Setting Company Name
                if (response.data[0].companyName) {
                    setCompanyName(response.data[0].companyName);
                }
                // Setting Company Role
                if (response.data[0].company_role === "Broker&Carrier") {
                    setCompanyRole("Broker & Carrier");
                }
                else if (response.data[0].company_role) {
                    setCompanyRole(response.data[0].company_role);
                }

                // Setting Company Description
                if (response.data[0].companyDescription) {
                    setCompanyDescription(response.data[0].companyDescription);
                }

                // Setting Company Address
                if (response.data[0].address) {
                    setCompanyAddress(response.data[0].address);
                }

                // Setting Company City
                if (response.data[0].city) {
                    setCompanyCity(response.data[0].city);
                }

                // Setting Company State
                if (response.data[0].state) {
                    setCompanyState(response.data[0].state);
                }

                // Setting Company Email
                if (response.data[0].email_address) {
                    setCompanyEmailAddress(response.data[0].email_address);
                }

                // Setting Company Phone Number
                if (response.data[0].phone_number) {
                    setCompanyPhoneNumber(response.data[0].phone_number);
                }

                // Setting Company Website
                if (response.data[0].website) {
                    setCompanyWebsite(response.data[0].website);
                }

                // Setting Company Logo
                if (response.data[0].logo) {
                    setCompanyLogo(response.data[0].logo);
                    setTempCompanyLogo(response.data[0].logo);
                    setPreviewCompanyLogo(response.data[0].logo);
                }

                // Setting Company Status
                if (response.data[0].status) {
                    setCompanyStatus(response.data[0].status);
                }
            }).finally(() => {
                setSelectedCompanyLogo(null);
            }).catch((err) => {
                console.log(err);
            })
    }
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <Typography component={'div'}>
                    <Grid>
                        <div style={{ height: 20 }}></div>
                        <Row>
                            <Col sm={1} ></Col>
                            <Col sm={1} className="mt-1">
                                {user.email === process.env.REACT_APP_ADMIN_USER &&
                                    <Tooltip title="Companies">
                                        <IconButton onClick={() => history.push({ pathname: '/systemadmincompanies' })}>
                                            <ArrowBackIcon style={{ color: "#142440" }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Col>
                            <Col>
                                <b style={{ fontSize: 25, fontWeight: "bold", color: "black" }}>Company Details</b>
                            </Col>
                        </Row>
                        <Row>
                            <>
                                <Col sm={1}></Col>
                                <Col xs={12} sm={5} md={4} className="m-auto">
                                    {editCompanyDetails ?
                                        <>
                                            {previewCompanyLogo ?
                                                <Row>
                                                    <Col sm={9}>
                                                        <Image
                                                            style={{ width: "100%", height: "100%", maxWidth: 400, maxHeight: 400 }}
                                                            src={previewCompanyLogo}
                                                            fluid
                                                            rounded
                                                        />
                                                    </Col>
                                                    <Col sm={1}>
                                                        {selectedCompanyLogo ?
                                                            <Tooltip title="Cancel uploading" >
                                                                <IconButton onClick={handleDeleteSelectedButton}>
                                                                    <CloseIcon style={{ color: "#142440" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title="Remove current logo" >
                                                                <IconButton onClick={handleDeleteButton}>
                                                                    <DeleteIcon style={{ color: "#142440" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </Col>

                                                </Row>
                                                : null}
                                            {user.email === process.env.REACT_APP_ADMIN_USER &&
                                                <Row className="mt-3">
                                                    <Col sm={7}>
                                                        <Form>
                                                            <Form.File id="company-logo" custom>
                                                                <Form.File.Input isValid={selectedCompanyLogo ? true : false} onChange={handleCompanyLogo} />
                                                                <Form.File.Label data-browse="Choose File" >
                                                                    {selectedCompanyLogo ? selectedCompanyLogoName : "Company Logo"}
                                                                </Form.File.Label>
                                                                <Form.Control.Feedback type="valid">Success!</Form.Control.Feedback>
                                                            </Form.File>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                        :
                                        <Row>
                                            <Col sm={9}>
                                                <Image
                                                    style={{ width: "100%", height: "100%", maxWidth: 400, maxHeight: 400 }}
                                                    src={tempCompanyLogo ? tempCompanyLogo : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAQlBMVEX///+hoaGenp6ampr39/fHx8fOzs7j4+P8/Pyvr6/d3d3FxcX29va6urqYmJjs7OzU1NSlpaW1tbWtra3n5+e/v78TS0zBAAACkUlEQVR4nO3b63KCMBCGYUwUUVEO6v3fagWVY4LYZMbZnff51xaZ5jON7CZNEgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABQb5tvI8qzX4/nH84XG5Upfj2ir2V2E5fZ/XpIX9saMnhkYLIkiyRJjdgMoiEDMmiQgfwM8rSu77ew2wnPoLTmwdZBs0J2BuXrYckcQm4nOoP+WcmWAbcTnUHZPy9eA24nOoN7n0HI54ToDM5k8PjluwyqgNuJzqDoaugPg8gWZ4noDAYLwuIg75fLeeHHsjNIzrZJwWwW+0DNsmEWPjiEZ5AcD8ZUu8VZ8HyQMifvBdIz+PS33i8adu+7Qn4Gn1Tdupl7rlCfQb9seosK7RkcBy1o30iVZ5CPOtDW3WhQnsF13IV3v0p3BqfJRoSpXVepzmA/24+yqeMyzRm4tqOs44lSUwa3yfgOri25av5CPRnklR33VlPnrqSZV09qMsiqSWV082xOz1uPajJ49pTM/f115k6guWa6JGjJ4N1lt8fXN2rv/vysjFaSQdFXBc/KKF04ptFPliclGVR9Bu27XCyeVOkmy5OODAZN9rYyyip/AIPJ8qIig+PoXbf7YdPdncFoSdCQQT4ZceV+MhiFMBy0hgyu0yGvOLI17KwpyGBaHK5jtt0N5GcwLw7XZdB31sRn8O+ziqYro8Vn4CwOV+k6a9Iz+PwRsKC7h+gMfMXhKu/OmuwM/MXhKq8yWnYG/uJw5Uxoy2jRGZTBZ/jboxuSM1guDtdNhKazJjiDbNMe0AxzKUVnkO+jEJxBxNtJzWCTxlNLzSB8KehJ/H+mJGYAjaDjzj9SnHZRuXZiAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAECXP1XDHv7U4SNFAAAAAElFTkSuQmCC"}
                                                    fluid
                                                    rounded
                                                />
                                            </Col>

                                        </Row>

                                    }
                                </Col>
                                <Col
                                    sm={5}
                                    fluid
                                >
                                    <Row className={editCompanyDetails ? "p-2" : "p-1"}>
                                        {!editCompanyDetails ?
                                            <b adjustsFontSizeToFit style={{ fontSize: 30, color: "black", width: "80%" }}>{companyName}</b>
                                            :
                                            <TextField
                                                style={{ fontSize: 35, color: "black", width: "80%" }}
                                                label="Company Name"
                                                value={companyName.toUpperCase()}
                                                onChange={(value) => {
                                                    setCompanyName(value.target.value);
                                                }}
                                            />
                                        }
                                        {user.email === process.env.REACT_APP_ADMIN_USER ?
                                            editCompanyDetails ?
                                                <>
                                                    <Tooltip title="Save changes" className="Save">
                                                        <IconButton onClick={handleSaveChanges}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Cancel editing">
                                                        <IconButton onClick={handleCancelChanges}>
                                                            <CloseIcon style={{ color: "#142440" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                :
                                                <Tooltip title="Edit company profile">
                                                    <IconButton style={{ maxWidth: 50, maxHeight: 50 }} onClick={() => setEditCompanyDetails(true)}>
                                                        <EditIcon style={{ color: "#142440" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            : null}
                                    </Row>
                                    <Row
                                        /*style={{marginBottom: 10}}*/
                                        className="p-2"
                                    >
                                        {!editCompanyDetails ?
                                            <p style={{ fontSize: 16, fontWeight: 400, color: "black" }}>MC Number: {companyMCNumber}</p>
                                            :
                                            <TextField
                                                style={{ fontSize: 16, color: "black", fontWeight: 400 }}
                                                size="small"
                                                label="MC Number"
                                                variant="outlined"
                                                value={companyMCNumber}
                                                type="tel"
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                onChange={(value) => {
                                                    const onlyNums = value.target.value.replace(/[^0-9]/g, '');
                                                    if (onlyNums.length > 10 || onlyNums.length < 6) {
                                                        setMCNumberValid(false);
                                                        setCompanyMCNumber(onlyNums);
                                                    } else if (onlyNums.length <= 10 || onlyNums.length >= 6) {
                                                        setMCNumberValid(true);
                                                        setCompanyMCNumber(onlyNums);
                                                    }
                                                }}
                                            />
                                        }
                                    </Row>
                                    <Row
                                        /*style={{marginBottom: 10}}*/
                                        className="p-2"
                                    >
                                        {!editCompanyDetails ?
                                            <p style={{ fontSize: 22, fontWeight: 450, color: "black" }}>{companyRole}</p>
                                            :
                                            <FormControlMUI>
                                                <InputLabel id="role">Role</InputLabel>
                                                <Select
                                                    style={{ fontSize: 25, fontWeight: 450, color: "black" }}
                                                    labelId="role"
                                                    value={companyRole === "Broker & Carrier" ? "Broker&Carrier" : companyRole}
                                                    onChange={(value) => setCompanyRole(value.target.value)}
                                                >
                                                    <MenuItem value={"Broker"}>Broker</MenuItem>
                                                    <MenuItem value={"Carrier"}>Carrier</MenuItem>
                                                    <MenuItem value={"Broker&Carrier"}>Broker & Carrier</MenuItem>
                                                </Select>
                                            </FormControlMUI>
                                        }
                                    </Row>
                                    <Row className="pl-2">
                                        {!editCompanyDetails ?
                                            <p style={{ fontSize: 16, width: "95%" }}>{companyDescription}</p>
                                            :
                                            <TextareaAutosize
                                                label="Company Description"
                                                value={companyDescription}
                                                rowsMin={2} placeholder="Describe your company..."
                                                style={{ fontSize: 16, width: "80%", padding: 5 }}
                                                onChange={(value) => setCompanyDescription(value.target.value)}
                                            />
                                        }
                                    </Row>
                                    <Row
                                        /*style={{ marginTop: 30 }}*/
                                        className="pl-2"
                                    >
                                        <p style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>Contact Information</p>
                                    </Row>
                                    <Row className="pl-2">
                                        {!editCompanyDetails ?
                                            <p style={{ fontSize: 14 }}>Location: {pascalCase(companyAddress)}, {pascalCase(companyCity)}, {pascalCase(companyState)}</p>
                                            :
                                            <>
                                                <TextField
                                                    style={{ fontSize: 14, paddingBottom: "5%" }}
                                                    size="small"
                                                    label="Address"
                                                    variant="outlined"
                                                    value={pascalCase(companyAddress)}
                                                    onChange={(value) => setCompanyAddress(value.target.value)}
                                                />
                                                <TextField
                                                    className="ml-2"
                                                    style={{ fontSize: 14, paddingBottom: "5%" }}
                                                    size="small"
                                                    label="City"
                                                    variant="outlined"
                                                    value={pascalCase(companyCity)}
                                                    onChange={(value) => setCompanyCity(value.target.value)}
                                                />
                                                <TextField
                                                    className="ml-2"
                                                    style={{ fontSize: 14 }}
                                                    size="small"
                                                    label="State"
                                                    variant="outlined"
                                                    value={pascalCase(companyState)}
                                                    onChange={(value) => setCompanyState(value.target.value)}
                                                />
                                            </>
                                        }
                                    </Row>
                                    <Row className={`pl-2 ${editCompanyDetails ? "mt-3" : ""}`}>
                                        {!editCompanyDetails ?
                                            <p style={{ fontSize: 14 }}>Email: {companyEmailAddress}</p>
                                            :
                                            <TextField
                                                style={{ fontSize: 14, width: "60%" }}
                                                size="small"
                                                placeholder="email@example.com"
                                                label="Email Address"
                                                variant="outlined"
                                                value={companyEmailAddress}
                                                onChange={(value) => setCompanyEmailAddress(value.target.value)}
                                            />
                                        }
                                    </Row>
                                    <Row className={`pl-2 ${editCompanyDetails ? "mt-3" : ""}`}>
                                        {!editCompanyDetails ?
                                            <p style={{ fontSize: 14 }}>Phone Number: {companyPhoneNumber}</p>
                                            :
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                label="Phone Number"
                                                value={companyPhoneNumber}
                                                onChange={(value) => {
                                                    setCompanyPhoneNumber(value.target.value);
                                                }}
                                                InputProps={{
                                                    inputComponent: PhoneNumberInput,
                                                }}
                                            />
                                        }
                                    </Row>
                                    <Row className={`pl-2 ${editCompanyDetails ? "mt-3" : ""}`}>
                                        {!editCompanyDetails ?
                                            <p style={{ fontSize: 14 }}>Website: <a style={{ textDecorationLine: "underline" }} target="_blank" href={`http://${companyWebsite}`} >{companyWebsite}</a></p>
                                            :
                                            <TextField
                                                style={{ fontSize: 14, width: "60%" }}
                                                size="small"
                                                placeholder="www.example.com"
                                                label="Website"
                                                variant="outlined"
                                                value={companyWebsite}
                                                onChange={(value) => setCompanyWebsite(value.target.value)}
                                            />
                                        }
                                    </Row>
                                    <div className={classes.bottomTool}></div>
                                </Col>
                                <Col sm={1}></Col>
                            </>
                        </Row>
                    </Grid>
                </Typography>
            </main>
        </div>
    )
}

export default CompanyProfile;