export const MyProfileIccon = () => {
	return (
		<svg width="35" height="35" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="20" height="20" fill="white" />
			<path d="M16.4964 16.4341V14.7679C16.4964 13.8841 16.1453 13.0365 15.5203 12.4116C14.8954 11.7866 14.0478 11.4355 13.164 11.4355H7.33236C6.44856 11.4355 5.60096 11.7866 4.97603 12.4116C4.35109 13.0365 4 13.8841 4 14.7679V16.4341" stroke="#333333" strokeWidth="0.7" stroke-strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.2503 9.66472C12.0907 9.66472 13.5827 8.17277 13.5827 6.33236C13.5827 4.49195 12.0907 3 10.2503 3C8.40992 3 6.91797 4.49195 6.91797 6.33236C6.91797 8.17277 8.40992 9.66472 10.2503 9.66472Z" stroke="#333333" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
		</svg>

	)
}
