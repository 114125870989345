import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import "../../Page401AndPage404/index.css"
import { SignIn } from "../Button/Button"
const styles = {
    header: {
        backgroundImage: `url(${"https://loadeo-company-logo-images.s3.amazonaws.com/404.png"})`,
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vh',
    },
    header1: {
        backgroundImage: `url(${"https://loadeo-company-logo-images.s3.amazonaws.com/401.png"})`,
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vh',
        marginLeft: "5%"
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
}
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const Error401Page = () => {
    const history = useHistory();
    return (
        <section id="head" className="d-flex align-items-center">
            <div className="container">
                <div className="row ">
                    <div className="col-md-6">
                        <div className="head_content">
                            <h2>YOU ARE NOT AUTHORIZED TO VIEW THIS PAGE</h2>
                            <p>This page is not pubically available  To access please login first.</p>
                            <div className="head_btn">
                                <button className="button1" onClick={() => history.push({ pathname: "/" })}>
                                    Return Home
                                </button>
                                <SignIn />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="head_img">
                            <div style={styles.header1}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error401Page;