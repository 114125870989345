import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
    Tabs,
    Tab,
    Snackbar,
    CssBaseline,
    List,
    ListItem,
    Divider,
    IconButton,
    Typography,
    Grid,
    Paper,
    Box,
    Button,
    InputBase,
} from "@material-ui/core";
import { useHistory } from "react-router";
import Edit from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import cities from "cities";
// import zipcodes from "zipcodes";
import Swal from 'sweetalert2';
import moment from "moment";
import { createMuiTheme, MuiThemeProvider, } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import useWebSocket, { } from 'react-use-websocket';
import addNotification from 'react-push-notification';
import { toNumber } from "lodash";
import Countdown from "react-countdown";
import { animateScroll } from "react-scroll";

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

// Imports to have all the Icons
import CloseIcon from "@material-ui/icons/Close";
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';
import { ExpandLessIcon } from "../../../SvgIcons/ExpandLess";
import { ExpandMoreIcon } from "../../../SvgIcons/ExpandMore"
import { handleFetchLoadForLoadboard } from "../../../APICalls";

// Import statements for Chat Component
import Header from "../CommentsComponent/Header/Header";
import SearchBar from "../CommentsComponent/SearchBar/SearchBar";
import HeaderChat from "../CommentsComponent/HeaderChat/HeaderChat";
import Chat from "../CommentsComponent/Chat/Chat";

// Import statements for CSS and environment
import "react-datepicker/dist/react-datepicker.css";
import { environmentVariables } from "../../../environment"
import SearchForm from "../../SearchForm"
import MUIDrawer from "../../Drawer";
import { handleLocalStorageValue } from "../../../LocalStorageValue"
import { AcceptCounterOffer, ExpiredCounterOffer } from "../Button/Button"
import LoadDetailsPannel from "../../LoadDetailsPannel";

//import statement to handle Sorting
import sortDate from '../../Sorting/sortDate'
import sortNum from '../../Sorting/sortNum'
// import map
import Map from '../../Pages/Map/index';
import { withScriptjs } from "react-google-maps";

//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];
let allPostedLoades = [];
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginTop: 10,
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: "none",
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: "auto",
        textTransform: "none",
        fontSize: 15,
    },
    leftAlign: {
        marginLeft: "25px",
        textTransform: "none",
        fontSize: 16,
    }
}));

// Function to handle MuiAlert
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// function to get top tab pannels
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

//--------------------------------------------*********************-----------------------------------------
const AllLoads = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth0();
    const SearchFormRef = useRef();

    //constats to handle post load form
    const PostLoadFormRef = useRef();

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState("");

    // Constant to handle expand and colapse the top pannel
    const [expandColapseTopPannel, setExpandColapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);

    // $$$$$$$$$$$$$$$$$$$$ Constants to handle  top box $$$$$$$$$$$$$$$$$$$$$$$$$$$
    // Constant to handle LoadNumber
    const [loadNumberDetail, setLoadNumberDetail] = useState("");

    // Constant to handle bottom grid open and close
    const [mainGridPercent, setMainGridPercent] = useState("100%");

    // Constants for search component
    const [loadNumberLength, setLoadNumberLength] = useState(0)

    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState("");
    const [searchPickUpFromState, setSearchPickUpFromState] = useState("");
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState("");
    const [searchPickUpMiles, setSearchPickUpMiles] = useState("")

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState("");
    const [searchDeliverToState, setSearchDeliverToState] = useState("");
    const [searchDeliverToZip, setSearchDeliverToZip] = useState("");
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState("")

    // Constant to handle main MuiDatatable
    const [rowData, setRowData] = useState([]);
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [isPaneOpen, setIsPaneOpen] = useState(false);

    //constant to handle mui data table loader
    const [loading, setLoading] = useState(false);

    const MapLoader = withScriptjs(Map);
    const [mapDistance, setMapDistance] = useState("");

    //constants to handle bit table loader
    const [loadingActiveBids, setLoadingActiveBids] = useState(false)
    const [loadingClosedBids, setLoadingClosedBids] = useState(false)

    //constants to handle post load form
    const [selectedRow, setSelectedRow] = useState([]);

    //constant to handle Disable Button
    const [disableSendButton, setDisableSendButton] = useState(true)

    const [loadUpdatedStatus, setLoadUpdatedStatus] = useState(false)

    const options = {
        filter: false,
        search: false,
        responsive: "vertical",
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onChangePage: () => (HanglePageChange()),
        onTableChange: (rowData, rowindex) => (setMuiDatatableColour(rowindex)),
        onRowClick: (rowData, rowState) =>
            handleRowClickConfirmation(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpen) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClick(row, dataIndex)
                }
            }
            const findResult = rowData.find(
                ({ load_id }) => load_id === row[0]
            );
            if (findResult !== undefined) {
                if (findResult.read_users) {
                    const findStatus = findResult.read_users.find(
                        ({ name }) => name === user.email
                    )
                    if (findStatus === undefined) {
                        return {
                            style: { backgroundColor: '#dceede', fontStyle: 'italic' }
                        }
                    }
                }
            }
            let filteredArray = chatDetails.filter(({ load_id }) => load_id === row[0])
            let rowStatus = 0
            filteredArray.forEach(element => {
                if (element.read_status) {
                    const findChatStatus = element.read_status.find(
                        ({ name }) => name !== user.email
                    )
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValue.load_id !== row[0]) {
                        rowStatus = rowStatus + 1
                    }
                }
            })
            if (rowStatus > 0) {
                return {
                    style: { backgroundColor: '#DAF7A6' }
                }
            }

        },
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    'No loads are posted'
            },
        }
    };

    const columnDefs = [
        { label: "PostId", name: "load_id", options: { display: false } },
        {
            label: "Pick-up From",
            name: "pickup_dest",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true
                                }
                            }
                            if (found === true) {
                                return false
                            }
                            else {
                                return true
                            }
                        }
                    }
                },
            },
        },
        {
            label: "Pick-up Date",
            name: "pickupfrom_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                },
            },
        },
        {
            label: "Deliver To",
            name: "delivery_dest",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true
                                }
                            }
                            if (found === true) {
                                return false
                            }
                            else {
                                return true
                            }
                        }
                    }
                },
            },
        },
        {
            label: "Miles (Time)",
            name: "cities_distance",
            options: {
                sort: true,
                display: displaySpecificColums, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },

        {
            label: "Delivery Date",
            name: "pickupto_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Truck Type",
            name: "truck_type",
            options: {
                customBodyRender: (value) => {
                    if (value !== "") {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== "") {
                                if (valEnteredNumber.includes(",")) {
                                    let splittingValue = valEnteredNumber.split(",");
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: "Weight",
            name: "max_weight",
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: "Number Of Pieces",
            name: "total_item_count",
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    if (value !== "") {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== "NaN" && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: "TimeStamp",
            name: "time_stamp",
            options: {
                display: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = "false";
                        }
                    }
                }
            }
        },
        {
            label: "LoadExpirationTime",
            name: "load_expiration_time",
            options:
            {
                display: false,
                viewColumns: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = "false";
                        }
                    }
                }
            }
        },
        {
            label: "Load Expires In",
            name: "LoadExpiresIn",
            options: {
                display: displaySpecificColums,
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[9];
                    var localTime = moment.utc(TimeStamp).local().format("YYYY-MM-DD HH:mm:ss");
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(LoadExpirationInMiliseconds(rowIndex.rowData[10]))}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: "Posting Company",
            name: "posted_by_company",
            options: {
                display: displaySpecificColums
            }
        },
        {
            label: "Load ID",
            name: "load_number",
            options: {
                // display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
    ];
    // Variable to handle column defs of load table
    const [columnDefsLoadTable, setColumnDefsLoadTable] = useState(columnDefs);

    // Constants for showing Loaddetails in the secondary pane
    const [loadPostedByUser, setLoadPostedByUser] = useState("");
    const [loadPostedBy, setLoadPostedBy] = useState("");
    const [loadLoadType, setLoadLoadType] = useState("");
    const [loadLoadNotes, setLoadLoadNotes] = useState("");

    const [loadPickUpdate, setLoadPickUpdate] = useState("");
    const [loadPickUpWindow, setLoadPickUpWindow] = useState("");
    const [loadPickUpDestination, setLoadPickUpDestination] = useState("");

    const [loadDeliverByDate, setLoadDeliverByDate] = useState("");
    const [loadDeliverWindow, setLoadDeliverWindow] = useState("");
    const [loadDeliverDestination, setLoadDeliverDestination] = useState("");

    const [loadMaxWeight, setLoadMaxWeight] = useState("");
    const [loadNoOfPieces, setLoadNoOfPieces] = useState("");

    const [loadStackable, setLoadStackable] = useState(false);
    const [loadHazmat, setLoadHazmat] = useState(false);
    const [loadDockHigh, setLoadDockHigh] = useState(false);
    const [loadTempControl, setLoadTempControl] = useState(false);
    const [loadFreightDimension, setLoadFreightDimension] = useState("");
    const [loadBidAmount, setLoadBidAmount] = useState("");
    const [loadBidAmountDisable, setLoadBidAmountDisable] = useState(false);
    const [loadStatus, setLoadStatus] = useState("");
    const [loadCityDistance, setLoadCityDistance] = useState("");
    const [loadPostedTime, setloadPostedTime] = useState("");
    const [loadExpiresIn, setLoadExpiresIn] = useState("");
    const [isUpdateLoadExpirationValueChanged, setIsUpdateLoadExpirationValueChanged] = useState(false)

    // Constant to handle selected row (List on single row selected)
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);

    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState(window.localStorage.getItem('user_role'));

    // Constant to handle logged in company role
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState(window.localStorage.getItem('company_role'));

    // Constant to handle second muidatatble 
    const [rowDataActiveBidTable, setRowDataActiveBidTable] = useState([]);

    // Constant to handle third muidatatble 
    const [rowDataClosedBidTable, setRowDataClosedBidTable] = useState([]);

    /*************************************To handle Bid functionality******************************************************** */
    // Bid datatable Row and column defination
    const columnDefsBidTable = [
        {
            label: "Posted By",
            name: "posted_by_company",
            options: { display: false },
        },
        { label: "PostId", name: "load_id", options: { display: false } },
        { label: "BidId", name: "bid_id", options: { display: false } },
        { label: "Carrier Company", name: "carrier_company" },
        {
            label: "Bid Amount", name: "bid_amount", options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[13] === "newoffer") && rowIndex.rowData[14] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[4]}</p>}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[15]} </p>}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "Accept" && <p className="afterBidDisplay"> $ {rowIndex.rowData[15]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: "Status", name: "status", options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[5]);
                    return (
                        bidStatus
                    )
                }
            }
        },
        { label: "Carrier Name", name: "carrier_name", options: { display: false } },
        { label: "bid_time_stamp", name: "bid_time_stamp", options: { display: false } },
        { label: "bid_expiration_time", name: "bid_expiration_time", options: { display: false } },
        {
            label: "Bid Notes", name: "bid_notes", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === "" || rowuserdata.rowData[9] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== "" || rowuserdata.rowData[9] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    )
                }
            }
        },
        {
            label: "Miles Out", name: "miles_out", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === "" || rowuserdata.rowData[10] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== "" || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    )
                }
            }
        },
        {
            label: "Truck Dimension", name: "truck_dimension", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === "LXWXH" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[11] !== "LXWXH" && rowuserdata.rowData[11]}
                    </>
                    )
                }
            }
        },

        {
            label: "Bid Expiration",
            name: "BidExpiration",
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[7];
                    var localTime = moment.utc(TimeStamp).local().format("YYYY-MM-DD HH:mm:ss");
                    let BidExpireTime = parseInt(rowIndex.rowData[8].replace(/\D/g, ''));
                    let time = "";
                    if (BidExpireTime === 5) {
                        time = "00:0" + BidExpireTime;
                    }
                    else {
                        time = "00:" + BidExpireTime;
                    }
                    let timeParts = time.split(":");
                    let timeInMiliseconds = ((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(timeInMiliseconds)}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: "Counter Offer",
            name: "counteroffer_sent",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[13];
                    let counterofferstatus = rowIndex.rowData[14];
                    let counteroffer = rowIndex.rowData[15];

                    return (<>
                        {(counteroffersent === "newoffer") && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ backgroundColor: '#0d2346', fontSize: 12 }} disabled={false}>  Counter Offer</Button>}
                        {(counteroffersent === "carrier" || counteroffersent === "broker") && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ backgroundColor: '#0d2346' }} disabled={false}> ${counteroffer} <Edit className="coediticon" disabled={true} />
                        </Button>}
                        {(counteroffersent === "carrier" || counteroffersent === "broker") && counterofferstatus === "Accept" && <AcceptCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                },
            },
        },
        {
            label: "Counter Offer Status",
            name: "counteroffer_status",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[14];
                    return (<>
                        {counterofferstatus === "Accept" && <div>{counterofferstatus} </div>}
                    </>
                    )
                }
            },

        },
        {
            label: "Counter Offer",
            name: "counter_offer",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false
            },
        },
    ];

    const optionsBidTable = {
        filter: false,
        responsive: "vertical",
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingActiveBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    // Bid datatable Row and column defination
    const columnDefsClosedBidTable = [
        {
            label: "Posted By",
            name: "posted_by_company",
            options: { display: false },
        },
        { label: "PostId", name: "load_id", options: { display: false } },
        { label: "BidId", name: "bid_id", options: { display: false } },
        { label: "Carrier Company", name: "carrier_company" },
        {
            label: "Bid Amount", name: "bid_amount", options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[13] === "newoffer") && rowIndex.rowData[14] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[4]}</p>}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[15]} </p>}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "Accept" && <p className="afterBidDisplay"> $ {rowIndex.rowData[15]}
                        </p>}
                    </>
                    );
                }
            }
        },
        {
            label: "Status", name: "status", options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[5]);
                    return (
                        bidStatus
                    )
                }
            }
        },
        { label: "Carrier Name", name: "carrier_name", options: { display: false } },
        { label: "bid_time_stamp", name: "bid_time_stamp", options: { display: false } },
        { label: "bid_expiration_time", name: "bid_expiration_time", options: { display: false } },
        {
            label: "Bid Notes", name: "bid_notes", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === "" || rowuserdata.rowData[9] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== "" || rowuserdata.rowData[9] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    )
                }
            }
        },
        {
            label: "Miles Out", name: "miles_out", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === "" || rowuserdata.rowData[10] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== "" || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    )
                }
            }
        },
        {
            label: "Truck Dimension", name: "truck_dimension", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === "LXWXH" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[11] !== "LXWXH" && rowuserdata.rowData[11]}
                    </>
                    )
                }
            }
        },

        {
            label: "Bid Expiration",
            name: "BidExpiration",
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.rowData[5] === "Closed") {
                        return (
                            <Countdown autoStart={true} date={Date.now() + 0}>
                            </Countdown>
                        )
                    }
                },
            }
        },
        {
            label: "Counter Offer",
            name: "counteroffer_sent",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[13];
                    let counterofferstatus = rowIndex.rowData[14];
                    let counteroffer = rowIndex.rowData[15];

                    return (<>
                        {(counteroffersent === "newoffer") && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ fontSize: 12 }} disabled={true}>
                            Counter Offer </Button>}
                        {(counteroffersent === "carrier" || counteroffersent === "broker") && counterofferstatus === "New" && <p className="afterBidDisplay"> ${counteroffer} </p>}
                        {(counteroffersent === "carrier" || counteroffersent === "broker") && counterofferstatus === "Accept" && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                },
            },
        },
        {
            label: "Counter Offer Status",
            name: "counteroffer_status",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[14];
                    return (<>
                        {counterofferstatus === "Accept" && <div>{counterofferstatus} </div>}
                    </>
                    )
                }
            },

        },
        {
            label: "Counter Offer",
            name: "counter_offer",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
            }
        }
    ];

    const optionsClosedBidTable = {
        filter: false,
        responsive: "vertical",
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingClosedBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    /*************************************Constant to handle Chat ********************************** */
    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // Constants to handle all Chat
    const [allChat, setAllChat] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState("");
    const [chatFirstCarrierName, setChatFirstCarrierName] = useState("");
    const [chatFirstCompanyName, setChatFirstCompanyName] = useState("");
    const [chatCarrierMCNumber, setChatCarrierMCNumber] = useState("")
    const [chatAllMessages, setChatAllMessages] = useState([]);
    const [chatDetails, setChatDetails] = useState([]);

    // Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });


    // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
    //---------------------------- Place to handle all the functions ----------------------------
    // Function that is triggered on page load
    useEffect(() => {
        checkUserRole();
        fetchMainMuiDatatbleValues();
        ExpireAndFetchAllBids();
        try {
            setInterval(async () => {
                RemoveExpiredLoads();
                ExpireAndFetchAllBids();
            }, 120000);
        } catch (e) {
            console.log(e);
        }
    }, []);


    // Function to recive all the socket io messages
    async function recieveMessageForSocketIo(message) {
        let recievedMessage = message.data.replace(/\"/g, "");
        // Recieveing a message from carrier for new load
        if (recievedMessage.includes("newLoadHasBeenPlaced")) {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'There is a new Load',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
        }

        if (recievedMessage === "companyDetailsChanged") {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'Your Company Details Is Changed',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            handleLocalStorageValue(user.email)
        }

        // Recieve message for a new chat
        if (recievedMessage.includes("ThereIsNewMessageFromCarrier")) {
            scrollToBottom()
            addNotification({
                title: 'Notification',
                subtitle: 'There is a Message',
                message: 'There is a new Message',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    fetchAllComments(
                        {
                            "load_id": recievedMessage.split("||")[1].split(":")[1],
                            "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                        }
                    );
                    try {
                        let response = await axios({
                            url:
                                environmentVariables.getcarrierloadboard +
                                "?carrier_name=" +
                                user.email,
                            method: "get",
                        })
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        }
                    }
                    catch (error) { }
                }
            }
        }
        // Recive a message on new bid
        if (recievedMessage.includes("ThereIsNewBid") || recievedMessage.includes("BidHasBeenRemoved")) {
            let messageText = "";
            if (recievedMessage.includes("ThereIsNewBid")) {
                messageText = 'There is a new Bid';
            }
            if (recievedMessage.includes("BidHasBeenRemoved")) {
                messageText = 'A Bid has been Removed';
            }
            addNotification({
                title: 'Notification',
                subtitle: messageText,
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    loadAndRealoadSecondGrid(recievedMessage.split("||")[1].split(":")[1]);
                }
            }
            else {
                fetchMainMuiDatatbleValues();
            }
        }
        // Recieve message for update bid
        if (recievedMessage.includes("BrokerLoadHasBeenWatchList") || recievedMessage.includes("BrokerLoadHasBeenWatchListRemoved")) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    try {
                        let response = await axios({
                            url:
                                environmentVariables.getcarrierloadboard +
                                "?carrier_name=" +
                                user.email,
                            method: "get",
                        })
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        }
                    }
                    catch (error) { }
                }
            }
        }

        if (recievedMessage.includes("LoadBeenRead")) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO === false || isPaneOpenForSocketIO === true) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    try {
                        let response = await axios({
                            url:
                                environmentVariables.getcarrierloadboard +
                                "?carrier_name=" +
                                user.email,
                            method: "get",
                        })
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        }
                    }
                    catch (error) { }
                }
            }
        }

        // Receive a message on counter offer
        if (recievedMessage.includes("ThereIsACounterOffer")) {
            Swal.fire({
                text: 'Counter Offer Submitted',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc',
            }
            )
            fetchMainMuiDatatbleValues();
            axios({
                url:
                    environmentVariables.getcarrierloadboard +
                    "?carrier_name=" +
                    user.email,
                method: "get",
            })
                .then((response) => {
                    const findResult = response.data.find(
                        ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                    );
                    populateSecondaryPaneValues(findResult);
                    const FilterColumns = [...columnDefsLoadTable];
                    for (let i = 1; i < FilterColumns.length; i++) {
                        if (FilterColumns[i].options.display !== undefined) {
                            FilterColumns[i].options.display = false;
                        }
                    }
                    setColumnDefsLoadTable(FilterColumns)
                    setMainGridPercent("35%");
                    setIsPaneOpen(true);
                    setDisplaySpecificColums(false);
                    isPaneOpenForSocketIO = true;
                    selectedRowDataValueForSocketIO = findResult;
                    setSelectedRowDataValue(findResult);
                    loadAndRealoadSecondGrid(recievedMessage.split("||")[1].split(":")[1]);

                })
                .catch((error) => { });
        }
        // Receive a message on counter offer
        if (recievedMessage.includes("CounterOfferAccepted")) {
            Swal.fire({
                text: 'Counter Offer Accepted',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc',
            }
            )
            fetchMainMuiDatatbleValues();
            axios({
                url:
                    environmentVariables.getcarrierloadboard +
                    "?carrier_name=" +
                    user.email,
                method: "get",
            })
                .then((response) => {
                    const findResult = response.data.find(
                        ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                    );

                    populateSecondaryPaneValues(findResult);
                    const FilterColumns = [...columnDefsLoadTable];
                    for (let i = 1; i < FilterColumns.length; i++) {
                        if (FilterColumns[i].options.display !== undefined) {
                            FilterColumns[i].options.display = false;
                        }
                    }
                    setColumnDefsLoadTable(FilterColumns)
                    setMainGridPercent("35%");
                    setIsPaneOpen(true);
                    setDisplaySpecificColums(false);
                    isPaneOpenForSocketIO = true;
                    selectedRowDataValueForSocketIO = findResult;
                    setSelectedRowDataValue(findResult);
                    loadAndRealoadSecondGrid(recievedMessage.split("||")[1].split(":")[1]);

                })
                .catch((error) => { });
        }
        if (recievedMessage.includes("chatHasBeenRead")) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    try {
                        let response = await axios({
                            url:
                                environmentVariables.getcarrierloadboard +
                                "?carrier_name=" +
                                user.email,
                            method: "get",
                        })
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        }
                    }
                    catch (error) { }
                }
            }
        }

        // receiving message for new notification
        if (recievedMessage.includes("ThereIsNewNotifiction")) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                try {
                    let response = await axios({
                        url:
                            environmentVariables.getcarrierloadboard +
                            "?carrier_name=" +
                            user.email,
                        method: "get",
                    })
                    if (response) {
                        const findResult = response.data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
                catch (error) { }
            }
        }

        // Recieveing a message from carrier for new message
        if (recievedMessage.includes("ThereIsMessageFromBroker")) {
            scrollToBottom()
            addNotification({
                title: 'Notification',
                subtitle: 'There is a Message',
                message: 'There is a new Message',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });

            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    fetchAllComments({
                        "load_id": recievedMessage.split("||")[1].split(":")[1],
                        "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                    }
                    );
                    try {
                        let response = await axios({
                            url:
                                environmentVariables.getcarrierloadboard +
                                "?carrier_name=" +
                                user.email,
                            method: "get",
                        })
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        }
                    }
                    catch (error) { }
                }
            }
        }

        if (recievedMessage.includes("BidHasBeenAccepted") || recievedMessage.includes("LoadHasBeenAwarded")) {
            addNotification({
                title: 'Notification',
                subtitle: "There is a Message",
                message: "There is a Message",
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    Swal.fire({
                        text: "Bid Accepted and Load Awarded",
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                    handleGettingConfirmationForClosingSecondaryPane();
                }
            }
        }

        if (recievedMessage.includes("BrokerLoadHasBeenUpdated")) {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'Load has been Updated',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    axios({
                        url:
                            environmentVariables.getcarrierloadboard +
                            "?carrier_name=" +
                            user.email,
                        method: "get",
                    })
                        .then((response) => {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            populateSecondaryPaneValues(findResult);
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        })
                        .catch((error) => { });
                }
            }
        }

        if (recievedMessage.includes("BrokerLoadHasBeenDeleted") || recievedMessage.includes("BrokerLoadHasBeenExpired")) {
            let messageText = "";
            if (recievedMessage.includes("BrokerLoadHasBeenDeleted")) {
                messageText = 'Load has been deleted';
            }
            if (recievedMessage.includes("BrokerLoadHasBeenExpired")) {
                messageText = 'Load has been Expired';
            }
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    Swal.fire({
                        text: messageText,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                    handleGettingConfirmationForClosingSecondaryPane();
                }
            }
        }

        // Recive a message on new bid
        if (recievedMessage.includes("ABidHasExpired")) {
            addNotification({
                title: 'Notification',
                subtitle: 'Your Bid has been Expired',
                message: 'Your Bid has been Expired',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });

            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    loadAndRealoadSecondGrid(recievedMessage.split("||")[1].split(":")[1]);
                    fetchMainMuiDatatbleValues();
                    try {
                        let LoadboardLoads = await handleFetchLoadForLoadboard(user.email)
                        if (LoadboardLoads) {
                            const findResult = LoadboardLoads.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            populateSecondaryPaneValues(findResult);
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        }
                    }
                    catch (error) {
                        console.log(error)
                    }
                }
            }
            else {
                fetchMainMuiDatatbleValues();
            }
        }

    }

    //Function to scroll bottom
    function scrollToBottom() {
        animateScroll.scrollToBottom({});
    }
    /**********************************Check User Role******************************************************* */
    //Function to check user Role
    function checkUserRole() {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: "/forbidden" });
        }
    }

    const handleLeavingEditLoadForm = () => {
        if (window.confirm("Changes you made may not be saved")) {
            setLoadUpdatedStatus(true)
            return true;
        }
        else {
            setShowing(true)
            return setLoadUpdatedStatus(false)
        }
    }

    // function to get confirmation for row click
    const handleRowClickConfirmation = (selectedRowData, rowMeta) => {
        if (isUpdateLoadExpirationValueChanged === true) {
            let leavingResponse = handleLeavingEditLoadForm()
            if (leavingResponse === true) {
                handleRowClick(selectedRowData, rowMeta)
            }
        }
        else {
            handleRowClick(selectedRowData, rowMeta)
        }
    }

    //function to assign values on row click in userdetails table
    const handleRowClick = (selectedRowData, rowMeta) => {
        const findChatResult = chatDetails.filter(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        updateChatReadStatus(findChatResult);
        setLoadUpdatedStatus(false)
        getBidData(selectedRowData, rowMeta)
        setSelectedRow(selectedRowData);
    }

    // Function to fetch all the data main MuiDatatble
    async function fetchMainMuiDatatbleValues() {
        try {
            let LoadboardLoads = await handleFetchLoadForLoadboard(user.email)
            if (LoadboardLoads) {
                LoadboardLoads.sort((a, b) =>
                    b.time_stamp.localeCompare(a.time_stamp)
                );
                if (LoadboardLoads.length !== 0) {
                    let loadIds = []
                    setLoading(true);
                    LoadboardLoads.forEach(element => {
                        if (element.load_number) {
                            loadIds.push(element.load_number)
                        }
                    })
                    setLoadNumberLength(Math.max(...loadIds).toString().length)
                }
                else {
                    setLoadNumberLength(8)
                    setLoading(true);
                }
                let arrayToRemove = LoadboardLoads.filter(function (singleVal) {
                    return singleVal['load_delete_status'] !== true && singleVal['load_expired_status'] !== true && singleVal['status'] !== "Closed" && singleVal['status'] !== "Awarded"
                });

                const tempData = arrayToRemove.map(item => {
                    // item.abovemarketrate = item.abovemarketrate ? "Yes" : ""
                    return item
                })
                setRowData(tempData);
                allPostedLoades = tempData;

                if (location.state) {
                    if (location.state.loadId && window.localStorage.getItem('LoadIdEmail')) {
                        // OPen secondary pane
                        const findLoad = tempData.find(
                            ({ load_id }) => load_id === location.state.loadId
                        );
                        if (findLoad) {
                            removeBackroundColour();
                            populateSecondaryPaneValues(findLoad);
                            setDisplaySpecificColums(false);
                            const FilterColumns = [...columnDefsLoadTable];
                            for (let i = 1; i < FilterColumns.length; i++) {
                                if (FilterColumns[i].options.display !== undefined) {
                                    FilterColumns[i].options.display = false;
                                }
                            }
                            setColumnDefsLoadTable(FilterColumns)
                            setMainGridPercent("35%");
                            setIsPaneOpen(true);
                            isPaneOpenForSocketIO = true;
                            selectedRowDataValueForSocketIO = findLoad;
                            setSelectedRowDataValue(findLoad);
                            loadAndRealoadSecondGrid(location.state.loadId);
                            window.localStorage.removeItem('LoadIdEmail');
                        }

                    }
                }
                return tempData;
            }
        }
        catch (error) {
            console.log("fetch load error", error)
        }
    }

    // Function to handle remove removing expired loads
    async function RemoveExpiredLoads() {
        const allNewBids = await ExpireAndFetchAllBids();
        allPostedLoades.forEach(element => {
            if (element.load_expiration_time) {
                const localVarExpirationTime = element.load_expiration_time.split(":");
                let hrtomin = (Number(localVarExpirationTime[0]) * 60 + Number(localVarExpirationTime[1]));
                let futureTime = "";
                if (hrtomin === 0) {
                    let stillUtc = moment.utc(element.time_stamp).toDate();
                    let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                    futureTime = moment(local).add(hrtomin, "minutes");
                }
                else if (hrtomin > 0) {
                    let stillUtc = moment.utc(element.time_stamp).toDate();
                    let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                    futureTime = moment(local).add(hrtomin, "minutes");
                }
                let diference = futureTime.diff(moment(), "minutes");
                if (diference <= 0) {
                    RemoveBidsWhenLoadExpires(allNewBids, element.load_id);
                    let singleElement = JSON.parse(JSON.stringify(element));
                    let listToUpdateExpiration = { ...singleElement, 'load_Expired_Status': true };
                    listToUpdateExpiration.watch_load = [];
                    listToUpdateExpiration.read_users = [];
                    axios({
                        url: environmentVariables.editloaddetail,
                        method: "patch",
                        data: cleanListForUpdatingWatchLoad(listToUpdateExpiration),
                    })
                        .then((response) => {
                            fetchMainMuiDatatbleValues();
                        })
                        .catch((error) => { });
                }
            }
        });
    }
    /*************************************FTo Handle Bid Function********************************************************** */
    async function RemoveBidsWhenLoadExpires(allNewBids, loadId) {
        if (allNewBids.length !== 0) {
            allNewBids.forEach(element => {
                if (element.load_id === loadId) {
                    if (element.status !== "accepted" && element.status !== "Closed") {
                        DeleteExpiredBids(element)
                    }
                }
            });
        }
    }

    // Function to handle get all bids
    async function ExpireAndFetchAllBids() {
        try {
            let response = await axios({
                url: environmentVariables.getbid,
                method: "get"
            });
            if (response.data !== "NoBidsAreAvailable") {
                ExpireBidsAfterTimeOut(response.data)
                return response.data;
            }
            else {
                return [];
            }
        }
        catch (error) { }
    }

    // Function to check expiration difference
    const CheckBidExpirationDifference = (expirationTime, bidTimeStamp) => {
        const localVarExpirationTime = parseInt(expirationTime.replace(/\D/g, ''));
        var stillUtc = moment.utc(bidTimeStamp).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        let futureTime = moment(local).add(localVarExpirationTime, "minutes");
        let diference = futureTime.diff(moment(), "minutes");
        return diference;
    }

    async function DeleteExpiredBids(bidData) {
        let listValues = bidData
        listValues.status = "Closed"
        try {
            let response = await axios({
                url: environmentVariables.deletebid,
                method: "patch",
                data: listValues
            })
            if (response) {
                loadAndRealoadSecondGrid(bidData.load_id);
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    // Function to handle bid expiration
    const ExpireBidsAfterTimeOut = (allBidInformation) => {
        allBidInformation.forEach(sindleBids => {
            if ((sindleBids.status !== "accepted") && (sindleBids.status !== "Closed")) {
                if (sindleBids.bid_expiration_time) {
                    if (CheckBidExpirationDifference(sindleBids.bid_expiration_time, sindleBids.bid_time_stamp) <= 0) {
                        DeleteExpiredBids(sindleBids)
                    }
                }
            }
        });
    }


    // Function to get bid status for bid tables
    const getStatusForBidTables = (status) => {
        let newStatus
        if (status === "new") {
            newStatus = "New"
        }
        else if (status === "accepted") {
            newStatus = "Won"
        }
        else {
            newStatus = "Closed"
        }
        return newStatus;
    }

    async function getBidData(selectedRowData, selectedRowState) {
        removeBackroundColour();
        const findResult = rowData.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        if (!window.localStorage.getItem('LoadId')) {
            let row = document.getElementById(`MUIDataTableBodyRow-${selectedRowState.dataIndex}`);
            row.setAttribute("style", "background: #9FD2F3");
        }

        populateSecondaryPaneValues(findResult);
        setDisplaySpecificColums(false);
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = false;
            }
        }
        setColumnDefsLoadTable(FilterColumns)
        setMainGridPercent("35%");
        setIsPaneOpen(true);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = findResult;
        setSelectedRowDataValue(findResult);
        loadAndRealoadSecondGrid(selectedRowData[0]);
        let fetchAllCommentResponse = await fetchAllComments(findResult);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowData[0]
                );
                updateChatReadStatus(findChatResult);
            }
        }
        handleLoadReadStatus(findResult)
        return findResult;
    }

    // Function to load and reload bid details
    async function loadAndRealoadSecondGrid(loadId) {
        try {
            let response = await axios({
                url: environmentVariables.getbid + "?load_id=" + loadId,
                method: "get",
            });
            let data = JSON.parse(response.data.body)
            if (data !== "NoBidsAreAvailable") {
                data.sort((a, b) =>
                    b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                );
                let findBids = []
                let closedBids = []
                let carrierList = []
                if (data.length !== 0) {
                    setLoadingActiveBids(true);
                    setLoadingClosedBids(true);
                }
                else {
                    setLoadingActiveBids(false);
                    setLoadingClosedBids(false);
                }
                data.forEach(element => {
                    if (element.carrier_name) {
                        if (element.status === "new") {
                            findBids.push(element);
                            carrierList.push(element.carrier_name)
                        }
                        else {
                            closedBids.push(element);
                        }
                    }
                });
                if (findBids.length === 0) {
                    setLoadingActiveBids(true);
                }
                if (closedBids.length === 0) {
                    setLoadingClosedBids(true);
                }
                setRowDataActiveBidTable(findBids);
                /*setCarrierName(carrierList)*/
                data.forEach(element => {
                    if (element.status === "Closed") {
                        setRowDataClosedBidTable(closedBids);
                    }
                });
            }
            else {
                setRowDataActiveBidTable([]);
                setRowDataClosedBidTable([])
                setLoadingClosedBids(true);
                setLoadingActiveBids(true);
            }
        }
        catch (error) {
            setRowDataActiveBidTable([]);
            setRowDataClosedBidTable([]);
        }
    }


    /********************************************Function to handle Tab Pane , Expand and Close Search Form**************************************** */
    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };


    //$$$$$$$$$$$$$$$$$$$$$$$$$$ Functions to handle top box $$$$$$$$$$$$$$$$$$$$$$$$$$$$
    const getArea = (addressArray) => {
        let area = "";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            "sublocality_level_1" === addressArray[i].types[j] ||
                            "locality" === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    const getState = (addressArray) => {
        let state = "";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    "administrative_area_level_1" === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    // ----------------------------------- Functions to handle all Muidatatble functionality -------------------------------------------
    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: "none",
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        "&:nth-child(odd)": {
                            backgroundColor: "#ECECEC",
                        },
                        "&:hover": { backgroundColor: "#aab5be!important" },
                        "&:focus": { backgroundColor: "#000!important" },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to handle remove backround color from selection
    const removeBackroundColour = () => {
        for (let i = 0; i < rowData.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute("style");
                if (styleObject !== null) {
                    if (styleObject.toString().includes("9FD2F3")) {
                        domObject.removeAttribute("style", "background: #9FD2F3");
                    }
                }
            }

        }
    }

    // Function to handle Muidatatable Page change
    function HanglePageChange() {
        handleGettingConfirmationForClosingSecondaryPane();
    }

    function setMuiDatatableColour(allRowsData) {
        if (allRowsData.data.length > 0) {
            allRowsData.data.forEach((data) => {
                if (data.data[data.data.length - 3] === "Yes") {
                    // const vaal = document.getElementById(`MUIDataTableBodyRow-${data.index}`);
                    // if (vaal !== null) {
                    //     vaal.setAttribute("style", "background: #81ff9e");
                    // }
                }
            })
        }
    }

    async function handleLoadReadStatus(selectedRowData) {
        let listValues = JSON.parse(JSON.stringify(selectedRowData))
        if (listValues.read_users) {
            const findUsers = listValues.read_users.find(
                ({ name }) => name === user.email
            );
            if (findUsers === undefined) {
                listValues.read_users.push({ name: user.email })
                try {
                    let response = await axios({
                        url: environmentVariables.editloaddetail,
                        method: "patch",
                        data: cleanListForUpdatingWatchLoad(listValues),
                    })
                    if (response) {
                    }
                }
                catch (error) { }
            }
        }
    }


    /***************************************Secondary Pane Functionality***********************************************************/
    // Adding values to the secondary pane
    function populateSecondaryPaneValues(findResult) {
        setMapDistance(findResult);
        setLoadNumberDetail(findResult.load_number);
        setLoadPostedByUser(findResult.posted_by_user);
        setLoadPostedBy(findResult.posted_by_company);
        setLoadLoadNotes(findResult.load_notes);
        setLoadMaxWeight(findResult.max_weight);
        setLoadPickUpdate(findResult.pickupfrom_date);
        setLoadDeliverByDate(findResult.pickupto_date);
        setLoadStatus(findResult.status)
        setLoadPickUpDestination(findResult.pickup_dest);
        setLoadDeliverDestination(findResult.deliverZip);
        setLoadDeliverDestination(findResult.delivery_dest);
        if (findResult.truck_type === "") {
            setLoadLoadType("NA");
        }
        else {
            setLoadLoadType(findResult.truck_type.toString().replace(/,/g, ", "))
        }
        if (findResult.time_stamp) {
            var localTime = moment.utc(findResult.time_stamp).local().format("YYYY-MM-DD HH:mm:ss");
            setloadPostedTime(localTime);
        }
        if (findResult.total_item_count === "") {
            setLoadNoOfPieces("NA");
        }
        else {
            setLoadNoOfPieces(findResult.total_item_count);
        }
        let freightDimension = ""
        if (findResult.freight_dimension_length.toString().trim() === "" &&
            findResult.freight_dimension_width.toString().trim() === "" &&
            findResult.freight_dimension_height.toString().trim() === "") {
            setLoadFreightDimension("NA")
        }
        else {
            if (findResult.freight_dimension_length.toString().trim() === "") {
                freightDimension += "NA X ";
            }
            else {
                freightDimension += findResult.freight_dimension_length.toString().trim() + "L X ";
            }
            if (findResult.freight_dimension_width.toString().trim() === "") {
                freightDimension += "NA X ";
            }
            else {
                freightDimension += findResult.freight_dimension_width.toString().trim() + "W X ";
            }
            if (findResult.freight_dimension_height.toString().trim() === "") {
                freightDimension += "NA"
            }
            else {
                freightDimension += findResult.freight_dimension_height.toString().trim() + "H";
            }
            setLoadFreightDimension(freightDimension);
        }
        setLoadStackable(Boolean(findResult.stackable));
        setLoadHazmat(Boolean(findResult.hazmat));
        setLoadDockHigh(Boolean(findResult.dockhigh));
        setLoadTempControl(Boolean(findResult.tempcontrol));

        if (findResult.cities_distance) {
            setLoadCityDistance(findResult.cities_distance)
        }
        if (findResult.load_expiration_time) {
            setLoadExpiresIn(LoadExpirationInMiliseconds(findResult.load_expiration_time));
        }
        else {
            setLoadExpiresIn(0);
        }
        if (
            findResult.pickup_from_window === "ASAP" &&
            findResult.pickup_to_window === "ASAP"
        ) {
            setLoadPickUpWindow("ASAP");
        } else {
            setLoadPickUpWindow(
                findResult.pickup_from_window + " - " + findResult.pickup_to_window
            );
        }
        if (
            findResult.deliver_from_window.includes("Direct") &&
            findResult.deliver_to_window.includes("Direct")
        ) {
            setLoadDeliverWindow("Deliver Direct(Non-Stop)");
        } else {
            setLoadDeliverWindow(
                findResult.deliver_from_window + " - " + findResult.deliver_to_window
            );
        }

    }

    const handleGettingConfirmationForClosingSecondaryPane = () => {
        if (showing) {
            let getResponse = PostLoadFormRef.current.handleSendingResponse()
            if (getResponse) {
                if (getResponse[0].isValueChanged === true || isUpdateLoadExpirationValueChanged === true) {
                    let leavingResponse = handleLeavingEditLoadForm()
                    if (leavingResponse === true) {
                        closeSecondaryPane()
                    }
                    else {
                        if (leavingResponse === undefined) {
                            setShowing(showing);
                        }
                    }
                }
                else {
                    if (getResponse[0].isValueChanged === false) {
                        setLoadUpdatedStatus(true)
                        if (!loadUpdatedStatus) {
                            setShowing(!showing);
                            closeSecondaryPane()
                        }
                        else {
                            setShowing(!showing);
                            closeSecondaryPane()
                        }
                    }
                }

            }
            else {
                if (getResponse === undefined) {
                    if (!loadUpdatedStatus) {
                        setShowing(!showing);
                        let leavingResponse = handleLeavingEditLoadForm()
                        if (leavingResponse === true) {
                            closeSecondaryPane()
                        }
                    }
                }
            }
        }
        if (isUpdateLoadExpirationValueChanged === true) {
            let leavingResponse = handleLeavingEditLoadForm()
            if (leavingResponse === true) {
                closeSecondaryPane()
            }
        }
        else if (loadBidAmount !== "" && loadBidAmountDisable === false) {
            let leavingResponse = handleLeavingEditLoadForm()
            if (leavingResponse === true) {
                closeSecondaryPane()
            }
        }
        else {
            closeSecondaryPane()
        }
    }
    // Function to handle pane close
    const closeSecondaryPane = () => {
        window.localStorage.removeItem('LoadId');
        setIsUpdateLoadExpirationValueChanged(false)
        setSelectedRowDataValue([])
        removeBackroundColour();
        const findChatResult = chatDetails.filter(
            ({ load_id }) => load_id === selectedRow[0]
        );
        updateChatReadStatus(findChatResult);
        fetchMainMuiDatatbleValues();
        setMainGridPercent("100%");
        setIsPaneOpen(false);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = [];
        setSelectedRowDataValue([]);
        setDisplaySpecificColums(true);
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = true;
            }
        }
        setColumnDefsLoadTable(FilterColumns)
        setShowing(false);
    };


    // Function to calculate miliseconds for load expiration
    const LoadExpirationInMiliseconds = (expirationTime) => {
        let timeWithoutMinOrHr = expirationTime.split(':')
        let timeInMiliseconds = ((timeWithoutMinOrHr[0] * (60000 * 60)) + (timeWithoutMinOrHr[1] * 60000));
        return timeInMiliseconds;
    }

    const cleanListForUpdatingWatchLoad = (list) => {
        if (list.bid_amount)
            delete list.bid_amount;
        if (list.carrier_name)
            delete list.carrier_name;
        if (list.bid_id)
            delete list.bid_id;
        if (list.bid_expiration_time)
            delete list.bid_expiration_time;
        if (list.bid_time_stamp)
            delete list.bid_time_stamp;
        if (list.bid_Notes)
            delete list.bid_Notes;
        if (list.miles_Out)
            delete list.miles_Out;
        if (list.truck_dimension)
            delete list.truck_dimension;
        list.status = "new"
        return list;
    }

    // Function to handle Tab Pannel Changes
    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };

    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    async function getSingleCarrierMessage(event) {
        if (event.target.id !== null && event.target.id !== "") {
            let carrierName = event.target.id;
            let URL = ""
            if (user.email === process.env.REACT_APP_ADMIN_USER) {
                URL = environmentVariables.gettestcomment + "?load_id=" + selectedRowDataValue.load_id + "&broker_id=" + selectedRowDataValue.posted_by_user;
            }
            await axios({
                method: "GET",
                url: URL,
                headers: {
                    "content-type": "application/json"
                }
            })
                .then((response) => {
                    let data = JSON.parse(response.data)
                    data.forEach(element => {
                        if (element.carrier_id === carrierName) {
                            setChatAllMessages(element);
                        }

                    });
                })
                .catch((error) => { });
        }
    }

    //Function to createupdatestcomment
    async function updatetestcomment(secondaryArray, action) {
        try {
            let response = await axios({
                url: environmentVariables.updatetestcomment + "?action=" + action,
                method: "patch",
                data: secondaryArray,
            })
            return response
        }
        catch (error) {
            console.log("create comment", error)
        }
    }

    async function fetchAllComments(loadInformation) {
        let allChatResponse
        let URL = ""
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            URL = environmentVariables.gettestcomment + "?load_id=" + loadInformation.load_id + "&broker_id=" + loadInformation.posted_by_user;
        }
        await axios({
            method: "GET",
            url: URL,
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data)
                if (newResponse[0] !== undefined) {
                    setChatAllMessages(newResponse[0]);
                    if (user.email === process.env.REACT_APP_ADMIN_USER) {
                        setChatFirstCarrierName(newResponse[0]["broker_id"]);
                        setChatFirstCompanyName(newResponse[0]["broker_company"])
                        setChatCarrierMCNumber(`MC #${newResponse[0]["broker_mc_number"]}`)
                    }
                    setAllChat(newResponse);
                    allChatResponse = newResponse
                }

            })
            .catch((error) => {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    setAllChat([]);
                    setChatAllMessages([]);
                    setChatFirstCarrierName("");
                    setChatFirstCompanyName("")
                    setChatCarrierMCNumber("")
                }
                else {
                    let tempAllChatArray = {
                        "Items": [{
                            "broker_id": loadInformation.posted_by_user,
                            "time_stamp": moment.utc().format("YYYY-MM-DD HH:mm:ss")
                        }]
                    }
                    setAllChat(tempAllChatArray);
                    setChatFirstCarrierName(loadInformation.posted_by_user);
                    setChatFirstCompanyName(loadInformation.posted_by_company)
                    setChatCarrierMCNumber(`MC #${loadInformation.posted_by_mc_number}`)
                    setChatAllMessages([]);
                }
            });
        return allChatResponse
    }

    // Function to update chat read status after clicking on row
    async function updateChatReadStatus(selectedRowChatDetails) {
        let listValues = JSON.parse(JSON.stringify(selectedRowChatDetails))
        listValues.forEach(element => {
            if (element.read_status) {
                const findUsers = element.read_status.find(
                    ({ name }) => name === user.email
                );
                if (findUsers !== undefined) {
                    findUsers.status = true;
                }
            }
        })
        let response = await updatetestcomment(listValues, "patch")
        return response
    }

    //++++++++++++++++++++++++++++++++++++++++++To hanlde search +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // Functions to search the load
    const searchButtonClicked = () => {
        setExpandColapseTopPannel(!expandColapseTopPannel);
        // setTopTabPannelValue(1);
        setShowing(!showing);
        closeSecondaryPane();
    }

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === "") {
            const valForFilter = place.target.value;
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === "") {
                FilterColumns[13].options.filterList = [];
            }
            else {
                FilterColumns[13].options.filterList = filterList;
            }
            setColumnDefsLoadTable(FilterColumns)
        }
    }

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ", " + getState(place.address_components) + " - " + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[1].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns)
        }
    }

    // function to get all nearby pickup cities within specified miles
    async function getNearestPickUpCities(event) {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchPickUpFromCity !== "") {
                setSnackBarMessageText("Data is loading for you....");
                setOpenSnackBar(true);
            }
            setSearchPickUpMiles(event.target.value)
            // var rad = zipcodes.radius(searchPickUpFromZip, event.target.value);
            let listvalues = []
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchPickUpFromZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchPickUpFromZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + "?zipList=" +
                        listvalues + "functionality=pickupcitysearch",
                    method: "get",
                })
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = []
                    const valForFilter = searchPickUpFromCity + ", " + searchPickUpFromState + " - " + searchPickUpFromZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status) {
                            if (element.pickup_dest === valForFilter) {
                                filterList.unshift(element.pickup_dest)
                            }
                            else {
                                filterList.push(element.pickup_dest)
                            }
                        }
                    })
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsLoadTable];
                        FilterColumns[1].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns)
                    }
                    else {
                        const FilterColumns = [...columnDefsLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[1].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ", " + getFromCityState.state_abbr + " - " + event.target.value;
                const FilterColumns = [...columnDefsLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                FilterColumns[1].options.filterList = filterList;
                setColumnDefsLoadTable(FilterColumns)
            }
        }
    }

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format("MM/DD/YYYY");
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[2].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns)
        }
        else {
            const FilterColumns = [...columnDefsLoadTable];
            FilterColumns[2].options.filterList = [];
            setColumnDefsLoadTable(FilterColumns)
        }

    }

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ", " + getState(place.address_components) + " - " + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[3].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns)
        }
    }

    // function to get all nearby deliver cities within specified miles
    async function getNearestCitiesForDeliverCity(event) {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchDeliverToCity !== "") {
                setSnackBarMessageText("Data is loading for you....");
                setOpenSnackBar(true);
            }
            setSearchDeliverMiles(event.target.value)
            // var rad = zipcodes.radius(searchDeliverToZip, event.target.value);
            let listvalues = []
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchDeliverToZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchDeliverToZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + "?zipList=" +
                        listvalues + "functionality=delivercitysearch",
                    method: "get",
                })
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = []
                    const valForFilter = searchDeliverToCity + ", " + searchDeliverToState + " - " + searchDeliverToZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status) {
                            if (element.delivery_dest === valForFilter) {
                                filterList.unshift(element.delivery_dest)
                            }
                            else {
                                filterList.push(element.delivery_dest)
                            }
                        }
                    })
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsLoadTable];
                        FilterColumns[3].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns)
                    }
                    else {
                        const FilterColumns = [...columnDefsLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[3].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ", " + getFromCityState.state_abbr + " - " + event.target.value;
                const FilterColumns = [...columnDefsLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                FilterColumns[3].options.filterList = filterList;
                setColumnDefsLoadTable(FilterColumns)
            }
        }
    }

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format("MM/DD/YYYY");
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[5].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns)
        }
        else {
            const FilterColumns = [...columnDefsLoadTable];
            FilterColumns[5].options.filterList = [];
            setColumnDefsLoadTable(FilterColumns)
        }
    }

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const filterColumns = [...columnDefsLoadTable];
        if (allTruckType && allTruckType.trim() !== "") {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            setColumnDefsLoadTable(filterColumns)
        }
        else {
            filterColumns[6].options.filterList = [];
            setColumnDefsLoadTable(filterColumns)
        }
    }

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        const filterColumns = [...columnDefsLoadTable];
        if (allTruckType.trim() !== "") {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            setColumnDefsLoadTable(filterColumns)
        }
        else {
            filterColumns[6].options.filterList = [];
            setColumnDefsLoadTable(filterColumns)
        }

    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    const valForFilter = event.target.value;
                    const FilterColumns = [...columnDefsLoadTable];
                    let filterList = [];
                    filterList = [valForFilter];
                    if (event.target.value.trim() === "") {
                        FilterColumns[7].options.filterList = [];
                    }
                    else {
                        FilterColumns[7].options.filterList = filterList;
                    }
                    setColumnDefsLoadTable(FilterColumns)
                }
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPicesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    const valForFilter = event.target.value;
                    const FilterColumns = [...columnDefsLoadTable];
                    let filterList = [];
                    filterList = [valForFilter];
                    if (event.target.value.trim() === "") {
                        FilterColumns[8].options.filterList = [];
                    }
                    else {
                        FilterColumns[8].options.filterList = filterList;
                    }
                    setColumnDefsLoadTable(FilterColumns)
                }
            }
        }
    };

    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        const FilterColumns = [...columnDefsLoadTable];
        switch (index) {
            case 1:
                {
                    FilterColumns[1].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(2)
                    return;
                }
            case 2:
                {
                    FilterColumns[2].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(3)
                    return;
                }
            case 3:
                {
                    FilterColumns[3].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(4)
                    return;
                }
            case 5:
                {
                    FilterColumns[5].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(6)
                    return;
                }
            case 6:
                {
                    const FilterColumns = [...columnDefsLoadTable];
                    FilterColumns[6].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(7)
                    return;
                }
            case 7:
                {
                    FilterColumns[7].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(8)
                    return;
                }
            case 8:
                {
                    FilterColumns[8].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(9)
                    return;
                }
            case 13:
                {
                    FilterColumns[13].options.filterList = [];
                    setColumnDefsLoadTable(FilterColumns)
                    SearchFormRef.current.onSearchClear(14)
                    return;
                }
            default:
                return;
        }
    }

    //--------------------------------------------*********************--------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === "Data is loading for you...." ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <div style={{ height: 5 }} />
                <Typography component={'div'}>
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs textColor="#101e35">
                                        <Tab className={`${classes.leftAlign}`} style={{ color: "#101e35" }} label="Search Load" onClick={() => searchButtonClicked()} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <IconButton button onClick={() => setExpandColapseTopPannel(false)} hidden={!expandColapseTopPannel}  >
                                        <ExpandMoreIcon style={{ width: 45, height: 45, color: "#0d2346" }} />
                                    </IconButton>
                                    <IconButton button onClick={() => setExpandColapseTopPannel(true)} hidden={expandColapseTopPannel} >
                                        <ExpandLessIcon style={{ width: 45, height: 45, color: "#0d2346" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            {/* ***************************** Second Tab******************************* */}
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandColapseTopPannel}>
                                <SearchForm ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPicesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: mainGridPercent, paddingBottom: "35%" }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={"All Loads"}
                                    data={rowData}
                                    columns={columnDefsLoadTable}
                                    options={options}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                        {
                            isPaneOpen && (
                                <React.Fragment>
                                    <div style={{ width: "1%" }}></div>
                                    <div style={{ width: "64%" }} border={1}>
                                        <Box
                                            boxShadow={3}>
                                            <Paper square className="Square">
                                                <Grid container xs={12} justify="flex-end">
                                                    <IconButton onClick={handleGettingConfirmationForClosingSecondaryPane}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>

                                                <div style={{ height: 7 }} />
                                                <Row style={{ fontSize: 18 }}>
                                                    <Col sm>
                                                        <b>Load Details</b>
                                                    </Col>
                                                </Row>
                                                <div style={{ height: 5 }} />
                                                <LoadDetailsPannel allLoadDetails={selectedRowDataValue} />
                                            </Paper>
                                        </Box>
                                        <div style={{ height: 10 }} />
                                        <Divider />
                                        {
                                            <React.Fragment>
                                                <div style={{ height: 10 }} />
                                                <MUIDataTable
                                                    title={"Active Bids"}
                                                    data={rowDataActiveBidTable}
                                                    columns={columnDefsBidTable}
                                                    options={optionsBidTable}
                                                ></MUIDataTable>
                                                <div style={{ height: 10 }} />
                                                <MUIDataTable
                                                    title={"Closed Bids"}
                                                    data={rowDataClosedBidTable}
                                                    columns={columnDefsClosedBidTable}
                                                    options={optionsClosedBidTable}
                                                ></MUIDataTable>
                                            </React.Fragment>
                                        }
                                        <div style={{ height: 10 }} />
                                        {(user.email === process.env.REACT_APP_ADMIN_USER) &&
                                            (
                                                <React.Fragment>
                                                    <Divider />
                                                    <Grid>
                                                        <Box boxShadow={3}>
                                                            <Paper square className="Paper">
                                                                <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor="primary" textColor="primary">
                                                                    <Tab label="Comments" />
                                                                </Tabs>
                                                                <TabPanel value={tabPannerlValue} index={0}>
                                                                    <React.Fragment>
                                                                        <div className="HomePage">
                                                                            <div className="LeftSide">
                                                                                <Header />
                                                                                {/* <SearchBar changeFilter={changeFilter} /> */}
                                                                                {Array.isArray(allChat) && allChat.length !== 0 && (
                                                                                    <List className="Custom-MuiList-root" style={{ maxHeight: "calc(100vh - 109px)", overflow: "auto", objectFit: "cover", height: 415 }}>
                                                                                        {
                                                                                            allChat
                                                                                                .sort(function (b, a) {
                                                                                                    return new Date(a.time_stamp) - new Date(b.time_stamp);
                                                                                                }).map((item, i) => {
                                                                                                    return (user.email === process.env.REACT_APP_ADMIN_USER) && (
                                                                                                        <ListItem button alignItems="flex-start" id={item.carrier_id} value={item.carrier_id} className="Custom-MuiListItem-root" key={item.carrier_id} onClick={(event) => getSingleCarrierMessage(event)}>
                                                                                                            {
                                                                                                                (item.carrier_id !== undefined) && (
                                                                                                                    <div id={item.carrier_id} className="Discussion">
                                                                                                                        <div id={item.carrier_id} className="LeftDiscussion">
                                                                                                                            {
                                                                                                                                (item.carrier_id.charAt(0) !== undefined) && (
                                                                                                                                    <Avatar>{item.carrier_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div id={item.carrier_id} className="RightDiscussion">
                                                                                                                            <div id={item.carrier_id} className="RightDiscussionTop">
                                                                                                                                <div id={item.carrier_company} className="RightDiscussionTopLeft">{item.carrier_company}</div>
                                                                                                                            </div>
                                                                                                                            <div id={item.carrier_id} className="RightDiscussionBas">
                                                                                                                                {
                                                                                                                                    (item.bid_amount !== undefined && item.bid_amount !== "$") && (
                                                                                                                                        <div className="RightDiscussionTopRight">{"Bid: " + item.bid_amount || loadBidAmount}</div>
                                                                                                                                    )

                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        </ListItem>
                                                                                                    )
                                                                                                }
                                                                                                )
                                                                                        }
                                                                                    </List>
                                                                                )}
                                                                            </div>
                                                                            <div className="RightSide">
                                                                                <HeaderChat name={chatFirstCarrierName.substring(0, chatFirstCarrierName.lastIndexOf("@"))} companyName={chatFirstCompanyName} mcnumber={chatCarrierMCNumber} />
                                                                                <Chat discussion={chatAllMessages} />
                                                                                <div>
                                                                                    <form onKeyPress={(e) => {
                                                                                        if (e.key === "Enter") {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}>
                                                                                        <Paper component="form" className={classes.customPaper}>
                                                                                            <InputBase id="inputMesage" autoComplete='off' className={classes.CustomInput} style={{ width: "85%" }} placeholder="Enter your message" />
                                                                                            <IconButton disabled={disableSendButton}>
                                                                                                <SendIcon />
                                                                                            </IconButton>
                                                                                        </Paper>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                </TabPanel>
                                                            </Paper>
                                                        </Box>
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Typography>
            </main>
        </div >
    )
}
export default AllLoads;