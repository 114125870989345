import axios from 'axios'
import { environmentVariables } from "../src/environment"

export const handleLocalStorageValue = (user) => {
    // Api call to check if the user exists in DB
    if (user) {
        axios(
            {
                url: environmentVariables.getuserdetails,
                method: 'get'
            }
        ).then(response => {
            let newResponse = JSON.parse(response.data.body)
            if (newResponse !== "UserNotPresent") {
                const findUser = newResponse.find(
                    ({ email_address }) => email_address === user
                )
                window.localStorage.setItem('ifUserExist', true)
                if (findUser.login_status === "ProfileAccepted") {
                    if (findUser.user_role) {
                        window.localStorage.setItem('user_role', findUser.user_role)
                    }
                    if (findUser.company_role) {
                        window.localStorage.setItem('company_role', findUser.company_role);
                    }
                    if (findUser.company_mc_number) {
                        window.localStorage.setItem('company_mc_number', findUser.company_mc_number);
                    }
                    if (findUser.login_status) {
                        window.localStorage.setItem('login_status', findUser.login_status);
                    }
                    if (findUser.company_name) {
                        window.localStorage.setItem('userCompanyName', findUser.company_name);
                    }
                    if (findUser.mc_certified) {
                        window.localStorage.setItem('mc_certified', findUser.mc_certified);
                    }

                }
            }

        })
            .catch(error => {
            })
    }
}
