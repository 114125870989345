import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoComponent from '../LogoComponent'
import { Grid, Paper, Box } from '@material-ui/core'

const styles = {
    header: {
        backgroundImage: `url(${"https://loadeo-logo.s3.us-east-2.amazonaws.com/01-Login-truck.png"})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
}

const Verify = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    return (
        <div>
            {isLoading && <h2>Loading..</h2>}
            {!isAuthenticated &&
                <React.Fragment>
                    {
                        <div style={styles.header}>
                            <div style={styles.content}>
                                <br />
                                <LogoComponent />
                                <br />
                                <h2 style={{ display: 'flex', justifyContent: 'center', color: "white" }}>Step 1/4</h2>
                                <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100%' }}>
                                    <Grid container xs={12} sm={6} alignItems="center" direction="column" style={{ height: "60%" }}>
                                        <Paper elevation={3} style={{ width: "70%", height: "95%", display: 'flex' }}>
                                            <Grid container direction="row">
                                                <div style={{ width: "100%", height: 20 }} />
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <h2><b>Verify Your Email</b></h2>
                                                    </Box>
                                                </div>
                                                <div style={{ width: "100%", height: 0 }} />
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {/*<p>We have sent you an email to <b>{user.email}</b> </p>*/}
                                                        <p>We have sent you an email</p>
                                                    </Box>
                                                </div>
                                                <div style={{ width: "100%", height: 0 }} />
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p>You need to verify your email to continue.</p>
                                                    </Box>
                                                </div>
                                                <div style={{ width: "100%", height: 0 }} />
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                    <Box paddingRight={5} paddingLeft={7} style={{ width: "100%" }}>
                                                        <p>If you have not received the verification email, please <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>check your "Spam" or "Bulk Email" folder.</span></p>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                </React.Fragment>
            }
        </div>
    )
}



export default Verify;