import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    CssBaseline,
    Avatar,
    Paper,
    Box,
} from '@material-ui/core';
import { Row, Col } from "react-bootstrap";
import "./index.css";
import { useAuth0 } from "@auth0/auth0-react";
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import { environmentVariables } from "../../environment"
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import Header from '../header/index';
import Footer from '../Footer/index';
import MUIDrawer from "../Drawer"

import FloatingButtonForFeedBack from "../Pages/FloatingButtonForFeedBack";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginTop: 10,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        marginTop: "5%"
    }

}));

const UserProfile = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();

    // All variables
    const [user_id, setUser_id] = React.useState("");
    const [user_role, setUser_role] = React.useState("");
    const [user_name, setUser_name] = React.useState("");
    const [user_first_name, setUser_first_name] = React.useState("");
    const [user_last_name, setUser_last_name] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [mc_number, setMc_number] = React.useState("");
    const [phone_number, setPhone_number] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [companyRole, setCompanyRole] = useState("");


    // on page load event
    useEffect(() => {
        getAllResponse();
    }, []);

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    async function getAllResponse() {
        await axios({
            url: environmentVariables.getuserdetails,
            method: 'get'
        })
            .then(response => {
                let newResponse = JSON.parse(response.data.body)
                const findUser = newResponse.find(
                    ({ email_address }) => email_address === user.email
                );
                setUser_id(findUser.user_id);
                setUser_name((findUser.user_name));
                setUser_first_name(findUser.first_name);
                setUser_last_name(findUser.last_name);
                if ((findUser.login_status) === "ProfileAccepted") {
                    if (findUser.mobile_number) {
                        setPhone_number(findUser.mobile_number);
                    }
                    if (findUser.address) {
                        setAddress(findUser.address);
                    }
                    if (findUser.city) {
                        setCity(findUser.city);
                    }
                    if (findUser.state) {
                        setState(findUser.state);
                    }
                    if (findUser.country) {
                        setCountry(findUser.country);
                    }
                    if (findUser.company_name) {
                        setCompanyName(findUser.company_name);
                    }
                    if (findUser.company_mc_number) {
                        setMc_number(findUser.company_mc_number);
                    }
                    if (findUser.user_role) {
                        setUser_role(findUser.user_role);
                    }
                    if (findUser.company_role) {
                        setCompanyRole(findUser.company_role);
                    }
                }
            })
            .catch(error => {
                history.push({ pathname: '/callback' });
            })
    }

    return (
        <>
            <div className="fixed-navbar animated fadeInDown active">
                <Header />
            </div>
            <div className={classes.root}>
                <CssBaseline />
                <MUIDrawer />
                <div style={{ marginTop: "1%" }}>
                    <Paper square style={{ width: "185%", height: "72vh", marginLeft: "1%" }}>
                        <main className={classes.content}>
                            <Row style={{ marginTop: "1%" }}>
                                <h4 style={{ marginLeft: "3.5%" }}>User Profile</h4>
                                <Avatar className="MuiAvatar-square" style={{ marginLeft: "5%" }} src={user.picture}></Avatar>
                            </Row>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid container direction="row" style={{ marginTop: "3%" }}>
                                    <Grid item xs={6}>
                                        <Row style={{ width: "80%", marginLeft: "2%" }}>
                                            <Col sm><h5>Name</h5></Col>
                                        </Row>
                                        <Row style={{ width: "99%", marginLeft: "2%" }}>
                                            <Col style={{ justifyContent: 'center' }}>
                                                {user_first_name || user_last_name ?
                                                    <TextField
                                                        value={pascalCase(user_first_name) + " " + pascalCase(user_last_name)}
                                                        inputProps={{ readOnly: true, disabled: true, }} style={{ width: "100%" }} variant="outlined" />
                                                    :
                                                    <TextField value={user.email} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "100%" }} variant="outlined" />}
                                            </Col>

                                        </Row>
                                        <Row style={{ width: "80%", marginTop: "5%", marginLeft: "2%" }}>
                                            <Col sm><h5>Email ID</h5></Col>
                                        </Row>
                                        <Row style={{ width: "97%", marginLeft: "2%" }}>
                                            <Col sm>
                                                <TextField value={user.email} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "100%" }} variant="outlined" />
                                            </Col>
                                        </Row>
                                    </Grid>
                                    <Paper square className='BoxShadow'>
                                        <Grid item xs={6}>
                                            <Box border={0} padding={1} style={{ width: "100%", paddingBottom: "10%" }}>
                                                <Row style={{ marginLeft: "200%", width: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    <TextField label="Company Name" value={pascalCase(companyName)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                    <span style={{ width: "10%" }}></span>
                                                    <TextField label="Mc Number" value={mc_number} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                </Row>
                                                <Row style={{ width: "200%", marginLeft: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    <TextField label="Phone Number" value={phone_number} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                    <span style={{ width: "10%" }}></span>
                                                    <TextField label="User Role" value={user_role} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                </Row>
                                                <Row style={{ width: "200%", marginLeft: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    <TextField label="Company Role" value={pascalCase(companyRole)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                    <span style={{ width: "10%" }}></span>
                                                    <TextField label="Address" value={pascalCase(address)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                </Row>
                                                <Row style={{ width: "200%", marginLeft: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 5 }}>
                                                    <TextField label="City" value={pascalCase(city)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                    <span style={{ width: "10%" }}></span>
                                                    <TextField label="State" value={pascalCase(state)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                </Row>
                                            </Box>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </main>
                        <div style={{ marginBottom: "10%" }}>
                        </div>
                    </Paper>
                </div>
            </div>
            {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                <FloatingButtonForFeedBack />
            )}
            <div className="fixed-footer animated fadeInDown active">
                <Footer />
            </div>
        </>

    );

}

export default UserProfile;