export const environmentVariables = {

    "getapplicationdetails": "https://api.loadeo.com/api/loadeo_fetch_application_details",

    // user related endpoints 
    "updateuserprofile": "https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/update-user-profile",
    "getuserdetails": "https://api.loadeo.com/api/loadeo_get_user_information",
    "useragreement": "https://api.loadeo.com/api/loadeo_add_agreed_user",
    "updateloginname": "https://api.loadeo.com/api/loadeo_create_user_profile",
    "getcompanyinformation": "https://api.loadeo.com/api/loadeo_get_company_information",

    // Company profile related endpoints
    "updatecompanyprofile": "https://czeh0f1h7j.execute-api.us-east-1.amazonaws.com/dev/update-company-profile",
    "setcompanystatus": "https://16ejqjxjdj.execute-api.us-east-1.amazonaws.com/dev/set-company-status",

    // dashboard related endpoints
    "getnewuserrequest": "https://api.loadeo.com/api/loadeo_get_all_new_user_details",
    "getallusersforsystemadmin": "https://api.loadeo.com/api/loadeo_get_all_approved_user_details",
    "approveusernotification": "https://mp0mo0z2k8.execute-api.us-east-1.amazonaws.com/dev/send-email-to-approved-user",
    "getnewusersforcompanydashboard": "https://api.loadeo.com/api/loadeo_get_new_company_user_details",
    "getapproveduserdetailsforcompany": "https://api.loadeo.com/api/loadeo_get_approved_company_user_details",
    "updateloadeouserdetails": "https://api.loadeo.com/api/loadeo_update_user_details",

    // Preferred carrier related endpoints
    "updatepreferstatus": "https://api.loadeo.com/api/loadeo_update_preferred_or_hold_status",
    "getpreferredcompanies": "https://api.loadeo.com/api/loadeo_get_preferred_companies",
    "getholdcompanies": "https://api.loadeo.com/api/loadeo_get_hold_companies",
    "getpreferredandholdcompanies":"https://api.loadeo.com/api/loadeo_get_preferred_and_hold_companies",

    // Prospective clients endpoints
    "addprospectiveclient": "https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/add-prospective-clients",
    "addprospectiveclientsinbulk": "https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/add_prospective_clients_in_bulk",
    "getprospectiveclients": "https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/get_prospective_clients",
    "updateprospectiveemailstatus": "https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/update-prospective-email-status",
    "updatemainstatus": "https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/update-prospective-main-toggle-status",

    // MC certification endpoints
    "extractJSONdata": "https://lxvgxtuawf.execute-api.us-east-1.amazonaws.com/Dev/upload",
    "addmccertificate":"https://api.loadeo.com/api/loadeo_add_MC_certificate",
    "getmccertificate": "https://api.loadeo.com/api/loadeo_get_all_certificate",
    "deletemccertificate": "https://api.loadeo.com/api/loadeo_delete_mc_certificate",
    "updatecertificatestatus": "https://api.loadeo.com/api/loadeo_update_certificate_status",

    // load related endpoints
    "createload": "https://api.loadeo.com/api/loadeo_create_load",
    "getcarrierloadboard": "https://api.loadeo.com/api/loadeo_get_all_loads",
    "getawardedloads": "https://api.loadeo.com/api/loadeo_get_awarded_loads",
    "getwonloads": "https://api.loadeo.com/api/loadeo_get_won_loads",
    "getexpiredloads": "https://api.loadeo.com/api/loadeo_get_expired_loads",
    "editloaddetail": "https://api.loadeo.com/api/loadeo_update_load",
    "deleteloaddetail": "https://api.loadeo.com/api/loadeo_delete_load",
    "getbidhistory": "https://api.loadeo.com/api/loadeo_get_expired_bids_loads",
    "getnearbycities": "https://sbkhuk73rl.execute-api.us-east-1.amazonaws.com/development/loadeo-get-nearby-cities",

    //bid related endpoints
    "createbidemail": "https://api.loadeo.com/api/loadeo_bid_placed_email_notification",
    "createbid": "https://api.loadeo.com/api/loadeo_create_bid",
    "getbid": "https://api.loadeo.com/api/loadeo_get_bids",
    "acceptbid": "https://api.loadeo.com/api/loadeo_accept_bid",
    "deletebid": "https://api.loadeo.com/api/loadeo_delete_bid",

    // counter offer related endpoints
    "editbid": "https://api.loadeo.com/api/loadeo_create_counter_offer",

    //chat related endpoints
    "createupdatetestcomment": "https://api.loadeo.com/api/loadeo_create_comment",
    "gettestcomment": "https://api.loadeo.com/api/loadeo_get_comments",
    "updatetestcomment": "https://api.loadeo.com/api/loadeo_update_comments",
    // websocket related endpoints
    "websocketfetchallusers": "https://api.loadeo.com/api/loadeo_get_websocket_users",
    "websocketurl": "wss://mwcc5ii8je.execute-api.us-west-2.amazonaws.com/production",

    // Email related endpoints
    // dashboard emails
    "sendingnewrequestemailtoadmin": "https://jcxq2ioxkj.execute-api.us-east-1.amazonaws.com/develop/newrequest_email_to_admin",
    "sendingemailtocompanyadmin": "https://aw3kp93g97.execute-api.us-east-1.amazonaws.com/development/sendemailtocompanyadmin",
    "sendRejectedMail": "https://1yj8wfydsj.execute-api.us-east-1.amazonaws.com/dev/companyadmin",
    // load emails
    "createloadnotification": "https://api.loadeo.com/api/loadeo_posted_load_email_notification",
    // bid emails
    "createbidnotification": "https://api.loadeo.com/api/loadeo_accept_bid_email_notification",
    //counter offer emails
    "counteremail": "https://api.loadeo.com/api/loadeo_counter_offer_email_notification",
    "acceptcounteroffernotification": "https://api.loadeo.com/api/loadeo_accept_counter_offer_email_notification",
    // saved lane emails 
    "searchLaneEmail": "https://api.loadeo.com/api/loadeo_match_lane_email_notification",

    // reopen Load Email
    "createreopenloadnotification": "https://api.loadeo.com/api/loadeo_reopen_load_email_notification", 

    // Bell icon Notification related endpoints
    "addnotifications": "",
    "getnotifications": "",
    "editnotification": "",
    "deletenotification": "",

    // Saved lane related endpoints
    "savesearch": "https://api.loadeo.com/api/loadeo_create_saved_lane",
    "getMatchedLane": "https://api.loadeo.com/api/loadeo_get_saved_lanes",
    "updateDate": "https://api.loadeo.com/api/loadeo_update_saved_lanes",

    // feedback related endpoints
    "feedbackinfodetails": "https://api.loadeo.com/api/loadeo_store_feedback",
    "feedbackemailtoadmin": "https://api.loadeo.com/api/loadeo_send_feedback_email_to_admin",

    // unused endpoints
    "updatenewuserdetails": "https://bux31h7ft6.execute-api.us-east-1.amazonaws.com/development/update-user-details",
    "getuserprofile": "https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/get-user-profile",
    "getbrokercompanyname": "https://wrsrqm9bnc.execute-api.us-east-1.amazonaws.com/development/get-broker-companyname",
    "getallusers": " https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/get-user",
    "addnewuser": "https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/create-user",
    "gettestcarriercomment": "https://ng13o2ba3k.execute-api.us-east-1.amazonaws.com/development/get-carrier-comment",
    "getbrokerloadboard": "https://0itz9q4q23.execute-api.us-east-1.amazonaws.com/development/get-broker-loadbord",
    "createCommentNotification": "https://ng13o2ba3k.execute-api.us-east-1.amazonaws.com/development/create-comment-notification",
    "getcarriersearch": "https://0itz9q4q23.execute-api.us-east-1.amazonaws.com/development/get-carrier-search",
    "getallcarriersforadmin": "https://ehb20ozqle.execute-api.us-east-1.amazonaws.com/development/loadeo-get-allcarriers",
    "getholdcarriers": "https://ehb20ozqle.execute-api.us-east-1.amazonaws.com/development/get-hold-carriers",
    "getchatdetails": "https://esahrxnfp0.execute-api.us-east-1.amazonaws.com/development/loadeo-get-all-chat-details",

    "updateuserdetailscompanyadmin": "https://5lv24vk0g5.execute-api.us-east-1.amazonaws.com/dev/companyadminuser",
    "updatesystemadminuserdetails": "https://bux31h7ft6.execute-api.us-east-1.amazonaws.com/development/update-user-details",
    "editcompanyadmin": "https://3j7pidp0id.execute-api.us-east-1.amazonaws.com/dev/edit-companyadmin-user",
    "getallcompanies": "https://hwuqq8xxi3.execute-api.us-east-1.amazonaws.com/dev/get-companies",
}