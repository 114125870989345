import React, { useState, useEffect, useRef } from "react";
import {
    CssBaseline,
    Divider,
    Typography,
    Grid,
    Paper,
    Button,
    Tooltip,
    Snackbar,
    Box,
    TextField,
    Tabs,
    IconButton,
    Tab,
    List,
    ListItem,
    InputBase,
} from "@material-ui/core";
import { AcceptCounterOffer, ExpiredCounterOffer } from "../Button/Button"
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider, } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import cities from "cities";
// import zipcodes from "zipcodes";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from 'sweetalert2';
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "react-google-autocomplete";
import { toNumber } from "lodash";
import useWebSocket, { } from 'react-use-websocket';
import addNotification from 'react-push-notification';
import { animateScroll } from "react-scroll";

// Import statements for Chat Component
import Header from "../CommentsComponent/Header/Header";
import SearchBar from "../CommentsComponent/SearchBar/SearchBar";
import HeaderChat from "../CommentsComponent/HeaderChat/HeaderChat";
import Chat from "../CommentsComponent/Chat/Chat";

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

// Imports to have all the Icons
import CloseIcon from "@material-ui/icons/Close";
import { ExpandMoreIcon } from "../../../SvgIcons/ExpandMore"
import { ExpandLessIcon } from "../../../SvgIcons/ExpandLess";
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import { NotVerifiedUserIcon } from "../../../SvgIcons/NotVerifiedUserIcon";

// Import statements for CSS and environment
import { environmentVariables } from "../../../environment"
import SearchForm from "../../SearchForm"
import Navbar from "../../Navbar"
import MUIDrawer from "../../Drawer"
import LoadDetailsPannel from "../../LoadDetailsPannel";
import { handleLocalStorageValue } from "../../../LocalStorageValue";

import sortDate from '../../Sorting/sortDate'
import sortNum from '../../Sorting/sortNum'

//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

//--------------------------------------------*********************-----------------------------------------
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    leftAlign: {
        marginLeft: "25px",
        textTransform: "none",
        fontSize: 16,
    }
}));

// Function to handle MuiAlert
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const AwardedLoads = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();
    const SearchFormRef = useRef();

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState("");
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState(window.localStorage.getItem('company_role'));

    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState(window.localStorage.getItem('user_role'));

    // Constant to handle logged in Company name
    const [userCompanyName, setUserCompanyName] = useState(window.localStorage.getItem('userCompanyName'));

    /// Constant to handle expand and colapse the top pannel
    const [expandColapseTopPannel, setExpandColapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);

    // Constant to handel main grid percentage
    const [mainGridPercent, setMainGridPercent] = useState("100%");
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);

    // Constants for search component
    const [searchLoadNumber, setSearchLoadNumber] = useState("");
    const [loadNumberLength, setLoadNumberLength] = useState(0)

    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState("");
    const [searchPickUpFromState, setSearchPickUpFromState] = useState("");
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState("");
    const [searchPickUpMiles, setSearchPickUpMiles] = useState("")

    // Constant for Pickup from date
    const [searchPickUpDate, setSearchPickUpDate] = useState(null);

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState("");
    const [searchDeliverToState, setSearchDeliverToState] = useState("");
    const [searchDeliverToZip, setSearchDeliverToZip] = useState("");
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState("")
    // Constant for Pickup from date
    const [searchDeliverToDate, setSearchDeliverToDate] = useState(null);

    // Constant for Pickup from date
    const [searchMaxWeight, setSearchMaxWeight] = useState("");

    // Constant to handle truck type
    const [searchTruckTypeCheckboxes, setSearchTruckTypeCheckboxes] = useState("");

    // Constant for Pickup from date
    const [searchNumberOfPieces, setSearchNumberOfPieces] = useState("");

    //constant to handle mui data table loader
    const [loading, setLoading] = useState(false)

    //constant to handle mui bid table loader
    const [loadingBids, setLoadingBids] = useState(false)

    //constant to handle Disable Button
    const [disableSendButton, setDisableSendButton] = useState(false)

    // constant to handle drawer notification badge
    const [isNotificationSent, setIsNotificationSent] = useState(false);

    // Constant to handle second muidatatble 
    const [rowDataBidTable, setRowDataBidTable] = useState([]);

    // Constants to handle Awarded Loads
    const [rowDataForAwardedloads, setRowDataForAwardedLoads] = useState([]);
    const optionsForAwaededLoads = {
        filter: false,
        search: false,
        responsive: "vertical",
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClick(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpen) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClick(row, dataIndex)
                }
            }
            let filteredArray = chatDetails.filter(({ load_id }) => load_id === row[0])
            let rowStatus = 0
            filteredArray.forEach(element => {
                if (element.read_status) {
                    const findChatStatus = element.read_status.find(
                        ({ name }) => name === user.email
                    )
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValue.load_id !== row[0]) {
                        rowStatus = rowStatus + 1
                    }
                }
            })
            if (rowStatus > 0) {
                return {
                    style: { backgroundColor: '#DAF7A6' }
                }
            }
        },
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    `There are no awarded loads for ${pascalCase(user.nickname)}`
            },
        }
    };
    const columnDefs = [
        { label: "PostId", name: "load_id", options: { display: false } },
        {
            label: " ",
            name: "unread_chat_count",
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined && value.includes(",")) {
                        let normalChat = value.split(",")[0]
                        let bidChat = value.split(",")[1]
                        if (normalChat > 0 && selectedRowDataValueForSocketIO.load_id !== row.rowData[0]) {
                            return (
                                < MessageRoundedIcon />
                            )
                        }
                    }
                }
            }
        },
        {
            label: "Pick-up From",
            name: "pickup_dest",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true
                                }
                            }
                            if (found === true) {
                                return false
                            }
                            else {
                                return true
                            }
                        }
                    }
                },
            },
        },
        {
            label: "Pick-up Date",
            name: "pickupfrom_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Deliver To",
            name: "delivery_dest",
            options: {
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true
                                }
                            }
                            if (found === true) {
                                return false
                            }
                            else {
                                return true
                            }
                        }
                    }
                },
            },
        },
        {
            label: "Miles (Time)",
            name: "cities_distance",
            options: {
                sort: true,
                display: displaySpecificColums, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: "Delivery Date",
            name: "pickupto_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Truck Type",
            name: "truck_type",
            options: {
                customBodyRender: (value) => {
                    if (value !== "") {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== "") {
                                if (valEnteredNumber.includes(",")) {
                                    let splittingValue = valEnteredNumber.split(",");
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: "Weight",
            name: "max_weight",
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: "Number Of Pieces",
            name: "total_item_count",
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    if (value !== "") {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== "NaN" && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Carrier", name: "carrier_company", options: {
                display: displaySpecificColums,
                customBodyRender: (tableMeta, rowuserdata) => {
                    if (rowuserdata.rowData[10] !== null && rowuserdata.rowData[10] !== undefined) {
                        return (
                            rowuserdata.rowData[10].toUpperCase()
                        )
                    }
                }
            }
        },
        {
            label: "Load ID",
            name: "load_number",
            options: {
                // display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
    ];

    const getStatusForBidTables = (status) => {
        let newStatus
        if (status === "new") {
            newStatus = "Closed"
        }
        else if (status === "accepted") {
            newStatus = "Awarded"
        }
        else {
            newStatus = "Closed"
        }
        return newStatus;
    }

    const columnDefsBidTable = [
        {
            label: " ",
            name: "mc_certified",
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value !== "Verified" && loggedInuserCompanyRole === "Broker") {
                            return (
                                <div>
                                    <Tooltip title="Not Verified Company">
                                        <NotVerifiedUserIcon className="notverified" />
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                }
            }
        },
        {
            label: "Posted By",
            name: "posted_by_company",
            options: { display: false },
        },
        { label: "PostId", name: "load_id", options: { display: false } },
        { label: "BidId", name: "bid_id", options: { display: false } },
        { label: "Carrier Company", name: "carrier_company" },
        {
            label: "Bid Amount", name: "bid_amount", options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[13] === "newoffer") && rowIndex.rowData[14] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[5]}</p>}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[15]} </p>}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "Accept" && <p className="afterBidDisplay"> $ {rowIndex.rowData[15]}
                        </p>}
                    </>
                    );
                }
            },
        }, {
            label: "Status", name: "status", options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[6]);
                    return (
                        bidStatus
                    )
                }
            }
        },
        { label: "Carrier Name", name: "carrier_name", options: { display: false } },
        { label: "bid_time_stamp", name: "bid_time_stamp", options: { display: false } },
        {
            label: "Bid Notes", name: "bid_notes", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === "" || rowuserdata.rowData[8] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== "" || rowuserdata.rowData[8] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    )
                }
            }
        },
        {
            label: "Miles Out", name: "miles_out", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === "" || rowuserdata.rowData[10] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== "" || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    )
                }
            }
        },
        {
            label: "Truck Dimension", name: "truck_dimension", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === "LXWXH" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[11] !== "LXWXH" && rowuserdata.rowData[11]}
                    </>
                    )
                }
            }
        },
        { label: "bid_expiration_time", name: "bid_expiration_time", options: { display: false } },
        {
            label: "Counter Offer",
            name: "counteroffer_sent",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[15]} </p>}
                        {(rowIndex.rowData[15] === undefined || rowIndex.rowData[15] === "") && <p className="afterBidDisplay"> NA </p>}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "Accept" && rowIndex.rowData[6] === "accepted" && <AcceptCounterOffer counteroffer={rowIndex.rowData[15]} />}
                        {rowIndex.rowData[13] !== "newoffer" && rowIndex.rowData[14] === "Accept" && rowIndex.rowData[6] !== "accepted" && <ExpiredCounterOffer counteroffer={rowIndex.rowData[15]} />}
                    </>
                    );
                }
            }
        },
        {
            label: "Counter Offer Status",
            name: "counteroffer_status",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[16];
                    return (<>
                        {counterofferstatus === "Accept" && <div>{counterofferstatus} </div>}
                    </>
                    )
                }
            },

        },
        {
            label: "Counter Offer",
            name: "counter_offer",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
            },
        },
    ];

    const optionsBidTable = {
        filter: false,
        responsive: "vertical",
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    const [columnDefsAwardedLoadTable, setColumnDefsAwardedLoadTable] = useState(columnDefs);

    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // Constants to handle all Chat
    const [allChat, setAllChat] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState("");
    const [chatFirstCarrierName, setChatFirstCarrierName] = useState("");
    const [chatFirstCompanyName, setChatFirstCompanyName] = useState("");
    const [chatCarrierMCNumber, setChatCarrierMCNumber] = useState("")
    const [chatAllMessages, setChatAllMessages] = useState([]);
    const [chatDetails, setChatDetails] = useState([]);

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    //--------------------------------------------*********************--------------------------
    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    //Function to scroll bottom
    function scrollToBottom() {
        animateScroll.scrollToBottom({});
    }

    //Function to scroll On recieveing Message
    function ScrollForParticularLoad(recievedMessage) {
        if (isPaneOpen === true) {
            if (selectedRowDataValue.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                scrollToBottom()
            }
        }
    }

    // Function to recive all the socket io messages
    async function recieveMessageForSocketIo(message) {
        let recievedMessage = message.data.replace(/\"/g, "");
        // Recieve message for a new chat
        if (recievedMessage.includes("ThereIsANewMessageFromCarrier")) {
            ScrollForParticularLoad(recievedMessage)
            addNotification({
                title: 'Notification',
                subtitle: 'There is a Message',
                message: 'There is a new Message',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });

            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValue.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    fetchAllComments(
                        {
                            "load_id": recievedMessage.split("||")[1].split(":")[1],
                            "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                        }
                    );
                    let response = await getCarrierLoadboard();
                    if (response) {
                        let data = JSON.parse(response.data.body)
                        const findResult = data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
            }
        }

        // receiving message for chat read
        if (recievedMessage.includes("chatHasBeenRead")) {
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    let response = await getCarrierLoadboard();
                    if (response) {
                        const findResult = response.data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
            }
        }

        // receiving message for new notification
        if (recievedMessage.includes("ThereIsNewNotifiction")) {
            fetchMainMuiDatatableValues();
        }

        if (recievedMessage.includes("BrokerLoadHasBeenDeleted") || recievedMessage.includes("BrokerLoadHasBeenExpired") || recievedMessage.includes("newLoadHasBeenPlaced")) {
            let messageText = "";
            if (recievedMessage.includes("BrokerLoadHasBeenDeleted")) {
                messageText = 'Load has been deleted';
            }
            if (recievedMessage.includes("BrokerLoadHasBeenExpired")) {
                messageText = 'Load has been Expired';
            }
            if (recievedMessage.includes("newLoadHasBeenPlaced")) {
                messageText = 'There is a new Load';
            }
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    Swal.fire({
                        text: messageText,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                    closeSecondaryPane();
                }
            }
        }
        if (recievedMessage.includes("BrokerLoadHasBeenUpdated")) {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'Load has been Updated',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    let response = await getCarrierLoadboard();
                    if (response) {
                        const findResult = response.data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
            }
        }

        if (recievedMessage.includes("YourLoadHasBennAccepted")) {
            let notificationMessage = ""
            if (recievedMessage.split("||")[2].split(":")[1] === user.email) {
                notificationMessage = "Your bid has been accepted"
            }
            else {
                notificationMessage = "Load has been awarded"
            }
            addNotification({
                title: 'Notification',
                subtitle: notificationMessage,
                message: notificationMessage,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    Swal.fire({
                        text: notificationMessage,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                    closeSecondaryPane();
                }
            }
        }

        // Recive a message on new bid
        if (recievedMessage.includes("ThereIsANewBid") || recievedMessage.includes("ABidHasBeenRemoved")) {
            let messageText = "";
            if (recievedMessage.includes("ThereIsANewBid")) {
                messageText = 'There is a new Bid';
            }
            if (recievedMessage.includes("ABidHasBeenRemoved")) {
                messageText = 'A Bid has been Removed';
            }
            addNotification({
                title: 'Notification',
                subtitle: messageText,
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    loadAndRealoadSecondGrid(recievedMessage.split("||")[1].split(":")[1],);
                }
            }
            else {
                fetchMainMuiDatatableValues();
            }
        }

        // Recive a message on bid expires
        if (recievedMessage.includes("ABidHasExpired")) {
            if (recievedMessage.split("||")[2].split(":")[1] === user.email) {
                addNotification({
                    title: 'Notification',
                    subtitle: 'Your Bid has been Expired',
                    message: 'Your Bid has been Expired',
                    theme: 'darkblue',
                    duration: 10000,
                    native: true // when using native, your OS will handle theming.
                });

                if (isPaneOpenForSocketIO) {
                    if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                        fetchMainMuiDatatableValues();
                        let response = await getCarrierLoadboard();
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            selectedRowDataValueForSocketIO = findResult;
                            setSelectedRowDataValue(findResult);
                        }
                    }
                }
                else {
                    fetchMainMuiDatatableValues();
                }
            }
        }
        if (recievedMessage.includes("Your MC Certificate Verification is Succeeded")) {
            handleLocalStorageValue(user.email)
            fetchAllComments({
                "load_id": selectedRowDataValueForSocketIO.load_id,
                "posted_by_user": selectedRowDataValueForSocketIO.posted_by_user
            })
            loadAndRealoadSecondGrid(selectedRowDataValueForSocketIO.load_id)
        }
    }


    //function to assign values on row click in userdetails table
    async function handleRowClick(selectedRowData, rowMeta) {
        localStorage.setItem('selectedLoadId', selectedRowData[0])
        setMainGridPercent("35%");
        setIsPaneOpen(true);
        isPaneOpenForSocketIO = true;
        loadAndRealoadSecondGrid(selectedRowData[0])
        const findResult = rowDataForAwardedloads.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        let fetchAllCommentResponse = await fetchAllComments(findResult);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowData[0]
                );
                updateChatReadStatus(findChatResult);
            }
        }
        selectedRowDataValueForSocketIO = findResult;
        removeBackroundColour();
        if (!window.localStorage.getItem('LoadId')) {
            let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.dataIndex}`);
            row.setAttribute("style", "background: #9FD2F3");
        }
        setSelectedRowDataValue(findResult);
        fetchAllComments(findResult);
        setDisplaySpecificColums(false);
    };

    // Function to handle pane close
    async function closeSecondaryPane() {
        window.localStorage.removeItem('LoadId');
        let fetchAllCommentResponse = await fetchAllComments(selectedRowDataValue);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowDataValue.load_id
                );
                updateChatReadStatus(findChatResult);
            }
        }
        setMainGridPercent("100%");
        setIsPaneOpen(false);
        setDisplaySpecificColums(true);
        setSelectedRowDataValue([])
        removeBackroundColour();
        isPaneOpenForSocketIO = false;
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };
    //---------------------------- Place to handle all the functions ----------------------------
    // Function that is triggered on page load
    useEffect(() => {
        checkUserRole();
        handleLocalStorageValue(user.email)
        fetchMainMuiDatatableValues();
    }, []);

    // Function to handle send Socket IO messages
    async function sendMessageForSocketIO(message, recieverId, sendNotification, selectedRow, bidDetails) {
        try {
            let response = await axios({
                url: environmentVariables.websocketfetchallusers,
                method: 'get'
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse) {
                handleAddNotification(message, recieverId, sendNotification, selectedRow, bidDetails)
                let connectionIds = []
                newResponse.forEach(element => {
                    if (recieverId === null || recieverId === "") {
                        connectionIds.push(element.connection_id)
                    }
                    else {
                        if (recieverId === element.user_id) {
                            connectionIds.push(element.connection_id)
                        }
                    }
                });
                sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
            }
        }
        catch (error) { }
    }

    async function handleAddNotification(message, recieverId, sendNotification, selectedRow, bidDetails) {
        let loadId = ""
        let messages = ""
        if (message.includes("load_id")) {
            loadId = message.split("||")[1].split(":")[1]
            messages = message.split("||")[0]
        }
        let postedByUser = ""
        let wonUser = ""
        let bidCarrier = ""
        let postedCompany = ""
        let carrierCompany = ""
        let loadNumber = ""
        let bidCarriers = []
        if (sendNotification === true) {
            if (messages === "ThereIsANewMessageFromBroker") {
                loadNumber = selectedRow.load_number
                postedByUser = selectedRow.posted_by_user
                bidCarrier = ""
                carrierCompany = userCompanyName
            }
            let listValues = {
                'load_id': loadId,
                'receiver_id': recieverId,
                'bid_carrier': bidCarrier,
                'won_carrier': wonUser,
                'posted_by_user': postedByUser,
                'message': messages,
                'carrier_company': carrierCompany,
                'load_expired_status': false,
                'posted_company': postedCompany,
                'bid_carriers': bidCarriers,
                'won_message': true,
                'load_number': loadNumber,
                'time_stamp_notification': moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                'generated_date': moment(new Date()).format("MM/DD/YYYY"),
                'read_users_list': []
            }
            try {
                let Response = await axios({

                    url: environmentVariables.addnotifications,
                    method: "put",
                    data: listValues
                });
                if (Response) {
                }
            } catch (e) {
                console.log(e);
            }
        }
    }


    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: "none",
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        "&:nth-child(odd)": {
                            backgroundColor: "#ECECEC",
                        },
                        "&:hover": { backgroundColor: "#aab5be!important" },
                        "&:focus": { backgroundColor: "#000!important" },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to handle remove backround color from selection
    const removeBackroundColour = () => {
        for (let i = 0; i < rowDataForAwardedloads.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute("style");
                if (styleObject !== null) {
                    if (styleObject.toString().includes("9FD2F3")) {
                        domObject.removeAttribute("style", "background: #9FD2F3");
                    }
                }
            }

        }
    }

    // Function to check the user role
    async function checkUserRole() {
        if (window.localStorage.ifUserExist) {
            if (window.localStorage.getItem('login_status') === "ProfileAccepted") {
                if (window.localStorage.getItem('company_role') === "Carrier") {
                    history.push({ pathname: "/forbidden" });
                }
                if (window.localStorage.getItem('user_role') === "Carrier") {
                    history.push({ pathname: "/forbidden" });
                }
                if (window.localStorage.getItem('company_role')) {
                    setLoggedInuserCompanyRole(window.localStorage.getItem('company_role'));
                }
            }
            else {
                history.push({ pathname: '/callback' });
            }
        }
        else if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: '/callback' });
        }
    }

    const getCarrierLoadboard = async () => {
        try {
            let response = await axios({
                url: environmentVariables.getawardedloads +
                    "?carrier_name=" +
                    user.email,
                method: "get",
            })
            return response;
        } catch (error) {
            console.log("fetch load error", error)
        }

    }

    // Function to fetch all the data main MuiDatatble
    async function fetchMainMuiDatatableValues() {
        try {
            let response = await getCarrierLoadboard();
            let newResponse = JSON.parse(response.data.body)
            if (newResponse) {
                if (newResponse.length === 0) {
                    let loadIds = []
                    setLoading(true);
                    newResponse.forEach(element => {
                        if (element.load_number) {
                            loadIds.push(element.load_number)
                        }
                    })
                    setLoadNumberLength(Math.max(...loadIds).toString().length)
                }
                else {
                    setLoadNumberLength(8)
                    setLoading(true)
                }

                //To delete loads 
                // Local array to handelremoval of rows
                newResponse.sort((a, b) =>
                    b.time_stamp.localeCompare(a.time_stamp)
                );
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    // Setting Up awarded loads table
                    let awardedLoadList = newResponse.filter(function (singleVal) {
                        return singleVal['posted_by_user'] === user.email
                    });
                    setRowDataForAwardedLoads(awardedLoadList);
                }
                else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    setRowDataForAwardedLoads(newResponse);
                }
                removeBackroundColour();
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // Function to load and reload bid details
    async function loadAndRealoadSecondGrid(loadId) {
        try {
            let response = await axios({
                url: environmentVariables.getbid + "?load_id=" + loadId,
                method: "get",
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse !== "NoBidsAreAvailable") {
                newResponse.sort((a, b) =>
                    b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                );
                let findBids = []
                if (newResponse.length !== 0) {
                    setLoadingBids(true);
                }
                else {
                    setLoadingBids(false);
                }
                newResponse.forEach(element => {
                    findBids.push(element);
                });
                if (findBids.length === 0) {
                    setLoadingBids(true);
                }
                setRowDataBidTable(findBids);
            }
            else {
                setRowDataBidTable([]);
                setLoadingBids(true);
            }
        }
        catch (error) {
            setRowDataBidTable([]);
        }
    }

    const searchButtonClicked = () => {
        setExpandColapseTopPannel(!expandColapseTopPannel);
        // setTopTabPannelValue(1);
        setShowing(!showing);
        closeSecondaryPane();
    }

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === "") {
            const valForFilter = place.target.value;
            const filterColumns = [...columnDefsAwardedLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === "") {
                filterColumns[11].options.filterList = [];
            }
            else {
                filterColumns[11].options.filterList = filterList;
            }
            setColumnDefsAwardedLoadTable(filterColumns)
        }
    }

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ", " + getState(place.address_components) + " - " + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsAwardedLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[2].options.filterList = filterList;
            setColumnDefsAwardedLoadTable(filterColumns)
        }
    }

    // function to get all nearby pickup cities within specified miles
    async function getNearestPickUpCities(event) {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchPickUpFromCity !== "") {
                setSnackBarMessageText("Data is loading for you....");
                setOpenSnackBar(true);
            }
            setSearchPickUpMiles(event.target.value)
            // var rad = zipcodes.radius(searchPickUpFromZip, event.target.value);
            let listvalues = []
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchPickUpFromZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchPickUpFromZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + "?zipList=" +
                        listvalues + "functionality=pickupcitysearch",
                    method: "get",
                })
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = []
                    const valForFilter = searchPickUpFromCity + ", " + searchPickUpFromState + " - " + searchPickUpFromZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== "Active") {
                            if (element.pickup_dest === valForFilter) {
                                filterList.unshift(element.pickup_dest)
                            }
                            else {
                                filterList.push(element.pickup_dest)
                            }
                        }
                    })
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsAwardedLoadTable];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsAwardedLoadTable(FilterColumns)
                    }
                    else {
                        const FilterColumns = [...columnDefsAwardedLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsAwardedLoadTable(FilterColumns)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ", " + getFromCityState.state_abbr + " - " + event.target.value;
                const filterColumns = [...columnDefsAwardedLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[2].options.filterList = filterList;
                setColumnDefsAwardedLoadTable(filterColumns)
            }
        }
    }

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format("MM/DD/YYYY");
            const filterColumns = [...columnDefsAwardedLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[3].options.filterList = filterList;
            setColumnDefsAwardedLoadTable(filterColumns)
        }
        else {
            const filterColumns = [...columnDefsAwardedLoadTable];
            filterColumns[3].options.filterList = [];
            setColumnDefsAwardedLoadTable(filterColumns)
        }
    }

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ", " + getState(place.address_components) + " - " + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsAwardedLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[4].options.filterList = filterList;
            setColumnDefsAwardedLoadTable(filterColumns)
        }
    }

    // function to get all nearby deliver cities within specified miles
    async function getNearestCitiesForDeliverCity(event) {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchDeliverToCity !== "") {
                setSnackBarMessageText("Data is loading for you....");
                setOpenSnackBar(true);
            }
            setSearchDeliverMiles(event.target.value)
            // var rad = zipcodes.radius(searchDeliverToZip, event.target.value);
            let listvalues = []
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchDeliverToZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchDeliverToZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + "?zipList=" +
                        listvalues + "functionality=delivercitysearch",
                    method: "get",
                })
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = []
                    const valForFilter = searchDeliverToCity + ", " + searchDeliverToState + " - " + searchDeliverToZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== "Active") {
                            if (element.delivery_dest === valForFilter) {
                                filterList.unshift(element.delivery_dest)
                            }
                            else {
                                filterList.push(element.delivery_dest)
                            }
                        }
                    })
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsAwardedLoadTable];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsAwardedLoadTable(FilterColumns)
                    }
                    else {
                        const FilterColumns = [...columnDefsAwardedLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsAwardedLoadTable(FilterColumns)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ", " + getFromCityState.state_abbr + " - " + event.target.value;
                const filterColumns = [...columnDefsAwardedLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[4].options.filterList = filterList;
                setColumnDefsAwardedLoadTable(filterColumns)
            }
        }
    }

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format("MM/DD/YYYY");
            const filterColumns = [...columnDefsAwardedLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            setColumnDefsAwardedLoadTable(filterColumns)
        }
        else {
            const filterColumns = [...columnDefsAwardedLoadTable];
            filterColumns[6].options.filterList = [];
            setColumnDefsAwardedLoadTable(filterColumns)
        }
    }

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const filterColumns = [...columnDefsAwardedLoadTable];
        if (allTruckType && allTruckType.trim() !== "") {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            setColumnDefsAwardedLoadTable(filterColumns)
        }
        else {
            filterColumns[7].options.filterList = [];
            setColumnDefsAwardedLoadTable(filterColumns)
        }
    }

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        if (allTruckType.includes('Sprinter/Cargo Van') && allTruckType.includes('Small Straight') && allTruckType.includes('Large Straight')) {
            document.getElementById("allTruckSearch").checked = true
        }
        const filterColumns = [...columnDefsAwardedLoadTable];
        if (allTruckType.trim() !== "") {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            setColumnDefsAwardedLoadTable(filterColumns)
        }
        else {
            filterColumns[7].options.filterList = [];
            setColumnDefsAwardedLoadTable(filterColumns)
        }

    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    const valForFilter = event.target.value;
                    const filterColumns = [...columnDefsAwardedLoadTable];
                    let filterList = [];
                    filterList = [valForFilter];
                    if (event.target.value.trim() === "") {
                        filterColumns[8].options.filterList = [];
                    }
                    else {
                        filterColumns[8].options.filterList = filterList;
                    }
                    setColumnDefsAwardedLoadTable(filterColumns)
                }
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPiecesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    const valForFilter = event.target.value;
                    const filterColumns = [...columnDefsAwardedLoadTable];
                    let filterList = [];
                    filterList = [valForFilter];
                    if (event.target.value.trim() === "") {
                        filterColumns[9].options.filterList = [];
                    }
                    else {
                        filterColumns[9].options.filterList = filterList;
                    }
                    setColumnDefsAwardedLoadTable(filterColumns)
                }
            }
        }
    };

    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        const filterColumns = [...columnDefsAwardedLoadTable];
        switch (index) {
            case 2:
                {
                    filterColumns[2].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(2)
                    break;
                }
            case 3:
                {
                    filterColumns[3].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(3)
                    break;
                }
            case 4:
                {
                    filterColumns[4].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(4)
                    break;
                }
            case 6:
                {
                    filterColumns[6].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(6)
                    break;
                }
            case 7:
                {
                    const filterColumns = [...columnDefsAwardedLoadTable];
                    filterColumns[7].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(7)
                    break;
                }
            case 8:
                {
                    filterColumns[8].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(8)
                    break;
                }
            case 9:
                {
                    filterColumns[9].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(9)
                    break;
                }
            case 11:
                {
                    filterColumns[11].options.filterList = [];
                    setColumnDefsAwardedLoadTable(filterColumns)
                    SearchFormRef.current.onSearchClear(14)
                    break;
                }
            default:
                break;

        }
    }

    const getArea = (addressArray) => {
        let area = "";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            "sublocality_level_1" === addressArray[i].types[j] ||
                            "locality" === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    const getState = (addressArray) => {
        let state = "";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    "administrative_area_level_1" === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    // Function to handle Tab Pannel Changes
    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };

    // ^^^^^^^^^^^^^^^^^^^^^^^Function to handle All the chat^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    const Disable = (chatFirstCarrierName) => {
        rowDataBidTable.forEach(rowData => {
            if (rowData.carrier_name === chatFirstCarrierName) {
                if (rowData.status === "Closed" || rowData.status === "new") {
                    setDisableSendButton(true)
                }
                else if (rowData.status === "accepted") {
                    setDisableSendButton(false)
                }
            }
            else if (rowData.carrier_name !== chatFirstCarrierName) {
                setDisableSendButton(true)
            }
        })
    }

    async function togetCarrierName(carrierName) {
        Disable(carrierName)
        if (selectedRowDataValue.carrier_name !== carrierName) {
            setDisableSendButton(true)
        }
        else {
            setDisableSendButton(false)
        }
        let URL = ""
        if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier")) {
            URL = environmentVariables.gettestcomment + "?load_id=" + selectedRowDataValue.load_id + "&broker_id=" + selectedRowDataValue.posted_by_user;
        }
        await axios({
            method: "GET",
            url: URL,
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                let data = JSON.parse(response.data)
                data.forEach(element => {
                    if (element.carrier_id === carrierName) {
                        setChatAllMessages(element);
                        setChatFirstCarrierName(element.carrier_id);
                        setChatFirstCompanyName(element.carrier_company)
                        setChatCarrierMCNumber(`MC #${element.carrier_mc_number}`)
                    }

                });
            })
            .catch((error) => { });
    }

    async function getSingleCarrierMessage(event) {
        if (event.target.id !== null && event.target.id !== "") {
            let carrierName = event.target.id;
            togetCarrierName(carrierName)
        }
        else {
            let carrierName = event.target.parentElement.id;
            togetCarrierName(carrierName)
        }

    }

    const onEnterButtonForChat = event => {
        if (event.key === "Enter") {
            if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                event.preventDefault();
                if (disableSendButton !== true) {
                    onSendMessageClick(document.getElementById("inputMesage").value, "Normal Message")
                }
            }
            else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                event.preventDefault();
            }
        }
    }

    //Function to createupdatestcomment
    async function createupdatetestcomment(secondaryArray, action) {
        try {
            let response = await axios({
                url: environmentVariables.createupdatetestcomment + "?action=" + action,
                method: 'post',
                data: secondaryArray,
            })
            return response
        }
        catch (error) { }
    }

    //Function to updatestcomment
    async function updatetestcomment(secondaryArray, action) {
        try {
            let response = await axios({
                url: environmentVariables.updatetestcomment + "?action=" + action,
                method: "patch",
                data: secondaryArray,
            })
            return response
        }
        catch (error) {
            console.log("create comment", error)
        }
    }
    //Function to createCommentNotification
    async function createCommentNotification(data) {
        try {
            let response = await axios({
                url: environmentVariables.createCommentNotification,
                method: 'patch',
                data: data,
            })
            return response
        }
        catch (error) { }
    }

    //Function to send message 
    async function onSendMessageClick() {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            let enteredMessage = document.getElementById("inputMesage").value;
            if (enteredMessage !== null && enteredMessage !== "") {
                document.getElementById("inputMesage").value = "";
                if (chatAllMessages.length === 0) {
                    const secondaryArray = [
                        {
                            time_stamp: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                            broker_id: selectedRowDataValue.posted_by_user,
                            carrier_id: user.email,
                            load_id: selectedRowDataValue.load_id,
                            messages: [
                                {
                                    date: moment().format("hh:mm A"),
                                    messanger_id: user.email,
                                    message: enteredMessage,
                                    message_type: "Normal Message",
                                    read_status: [{ name: user.email, status: true }, { name: selectedRowDataValue.posted_by_user, status: false }],
                                    user: user.nickname,
                                },
                            ],
                        },
                    ];
                    let response = await createupdatetestcomment(secondaryArray, "put")
                    if (response) {
                        fetchAllComments(JSON.parse(response.data.body))
                        setChatAllMessages(JSON.parse(response.data.body));
                    }
                    if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Broker" || loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin"))) {
                        sendMessageForSocketIO("ThereIsANewMessageFromBroker||load_id:" + selectedRowDataValue.load_id + "||posted_by_user:" + user.email, secondaryArray.carrier_id, true, selectedRowDataValue, []);
                        sendMessageForSocketIO("ThereIsNewMessageFromBroker||load_id:" + selectedRowDataValue.load_id + "||posted_by_user:" + selectedRowDataValue.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValue, []);
                        sendMessageForSocketIO("newNotification", "", false);
                        setIsNotificationSent(true)
                    }
                    return await createCommentNotification({
                        data: {
                            load_id: selectedRowDataValue.load_id,
                            fromEmail: user.email,
                            toEmail: selectedRowDataValue.posted_by_user,
                            message: enteredMessage
                        },
                    })
                } else {
                    const secondaryArray = chatAllMessages;
                    secondaryArray.time_stamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
                    if (secondaryArray.read_status) {
                        const ownUser = secondaryArray.read_status.find(
                            ({ name }) => name === user.email
                        );
                        const otherUser = secondaryArray.read_status.find(
                            ({ name }) => name !== user.email
                        );
                        if (ownUser !== undefined && ownUser.name === user.email) {
                            ownUser.status = true;
                        }
                        if (otherUser !== undefined) {
                            otherUser.status = false;
                        }
                    }
                    secondaryArray.messages = [
                        ...secondaryArray.messages,
                        {
                            date: moment().format("hh:mm A"),
                            messanger_id: user.email,
                            message: enteredMessage,
                            user: user.nickname,
                            message_type: "Normal Message",
                            read_status: [{ name: user.email, status: true }, { name: selectedRowDataValue.posted_by_user, status: false }],
                        },
                    ];
                    let response = await updatetestcomment(secondaryArray, "patch")
                    if (response) {
                        fetchAllComments(JSON.parse(response.data.body))
                        setChatAllMessages(JSON.parse(response.data.body));
                    }
                    document.getElementById("inputMesage").value = "";
                    if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Broker" || loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin"))) {
                        sendMessageForSocketIO("ThereIsANewMessageFromBroker||load_id:" + selectedRowDataValue.load_id + "||posted_by_user:" + user.email, secondaryArray.carrier_id, true, selectedRowDataValue, []);
                        sendMessageForSocketIO("ThereIsNewMessageFromBroker||load_id:" + selectedRowDataValue.load_id + "||posted_by_user:" + selectedRowDataValue.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValue, []);
                        sendMessageForSocketIO("newNotification", "", false);
                        setIsNotificationSent(true)
                    }
                    return await createCommentNotification({
                        data: {
                            load_id: selectedRowDataValue.load_id,
                            fromEmail: user.email,
                            toEmail: selectedRowDataValue.posted_by_user,
                            message: enteredMessage
                        },
                    })
                }
            }
        }
    }

    function showChatAccordingToUser(data) {
        setChatAllMessages(data);
        if (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Broker" || loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin"))) {
            setChatFirstCarrierName(data.carrier_id);
            setChatFirstCompanyName(data.carrier_company)
            setChatCarrierMCNumber(`MC #${data.carrier_mc_number}`)
            Disable(data.carrier_id)
        }
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            setChatFirstCarrierName(data.broker_id);
            setChatFirstCompanyName(data.broker_company)
            setChatCarrierMCNumber(`MC #${data.broker_mc_number}`)
        }
    }

    async function fetchAllComments(loadInformation) {
        let allChatResponse
        let URL = ""
        if (loadInformation.posted_by_user === user.email || loadInformation.broker_id === user.email) {
            URL = environmentVariables.gettestcomment + "?load_id=" + loadInformation.load_id + "&broker_id=" + user.email;
        }
        else if (user.email === process.env.REACT_APP_ADMIN_USER) {
            URL = environmentVariables.gettestcomment + "?load_id=" + loadInformation.load_id + "&broker_id=" + loadInformation.posted_by_user;
        }
        await axios({
            method: "GET",
            url: URL,
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data)
                if (newResponse[0] !== undefined) {
                    if (loadInformation.load_id === newResponse[0].load_id) {
                        if (isPaneOpen === true) {
                            newResponse.forEach(data => {
                                if (data.carrier_id === chatFirstCarrierName) {
                                    showChatAccordingToUser(data)
                                }
                            })
                        }
                        else {
                            showChatAccordingToUser(newResponse[0])
                        }
                        setAllChat(newResponse);
                        allChatResponse = newResponse
                        if (newResponse[0]['carrier_id'] === selectedRowDataValue.carrier_name) {
                            if (newResponse[0]["carrier_id"] === chatFirstCarrierName)
                                if (loadInformation.carrier_name === undefined) {
                                    setDisableSendButton(false)
                                }
                        }
                        else if (loadInformation.carrier_name !== newResponse[0]["carrier_id"]) {
                            setDisableSendButton(true)
                        }
                        else if (loadInformation.carrier_name === newResponse[0]["carrier_id"]) {
                            setDisableSendButton(false)
                        }
                    }
                }
                return allChatResponse
            })
            .catch((error) => {
                if (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" && ((loggedInuserRole !== "Carrier" && loadInformation.posted_by_user === user.email) || (loggedInuserRole === "Broker")))) {
                    setAllChat([]);
                    setChatAllMessages([]);
                    setChatFirstCarrierName("");
                    setChatFirstCompanyName("");
                    setChatCarrierMCNumber("")
                }
            });
    }

    // Function to update chat read status on click of row
    async function updateChatReadStatus(selectedRowChatDetails) {
        let listValues = JSON.parse(JSON.stringify(selectedRowChatDetails))
        if (listValues !== undefined) {
            listValues.forEach(element => {
                if (element.messages !== undefined) {
                    element.messages.forEach(message => {
                        if (message.read_status && message.read_status !== undefined) {
                            const findUsers = message.read_status.find(
                                ({ name }) => name === user.email
                            );
                            if (findUsers.status === false) {
                                findUsers.status = true;
                            }
                        }

                    })
                }
            })
            let response = await updatetestcomment(listValues, "patch")
            if (response) {
                let data = JSON.parse(response.data.body)
                sendMessageForSocketIO("chatHasBeenRead||load_id:" + data.load_id, "", false);
                sendMessageForSocketIO("newNotification", "", false);
            }
        }
    }

    //---------------------------------------------------------------------------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === "Data is loading for you...." ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={closeSecondaryPane} />
            <MUIDrawer notificationSent={isNotificationSent} />
            <main className={classes.content}>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs textColor="#101e35">
                                        <Tab className={`${classes.leftAlign}`} style={{ color: "#101e35" }} label="Search Load" onClick={() => searchButtonClicked()} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <IconButton button onClick={() => setExpandColapseTopPannel(false)} hidden={!expandColapseTopPannel} >
                                        <ExpandMoreIcon style={{ width: 45, height: 45, color: "#0d2346" }} />
                                    </IconButton>
                                    <IconButton button onClick={() => setExpandColapseTopPannel(true)} hidden={expandColapseTopPannel} >
                                        <ExpandLessIcon style={{ width: 45, height: 45, color: "#0d2346" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandColapseTopPannel}>
                                <SearchForm ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPiecesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: mainGridPercent, paddingBottom: "15%" }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={"Awarded Loads"}
                                    data={rowDataForAwardedloads}
                                    columns={columnDefsAwardedLoadTable}
                                    options={optionsForAwaededLoads}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                        {
                            isPaneOpen && (
                                <React.Fragment>
                                    <div style={{ width: "1%" }}></div>
                                    <div style={{ width: "64%" }} border={1}>
                                        <Box
                                            boxShadow={3}>
                                            <Paper square className="Square">
                                                <Grid container xs={12} justify="flex-end">
                                                    <IconButton onClick={closeSecondaryPane}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>
                                                <div style={{ height: 7 }} />
                                                <Row style={{ fontSize: 18 }}>
                                                    <Col sm>
                                                        <b>Load Details</b>
                                                    </Col>
                                                </Row>
                                                <div style={{ height: 5 }} />
                                                <LoadDetailsPannel allLoadDetails={selectedRowDataValue} />
                                            </Paper>
                                        </Box>
                                        <div style={{ height: 10 }} />
                                        <Divider />
                                        {
                                            <React.Fragment>
                                                <div style={{ height: 10 }} />
                                                <MUIDataTable
                                                    title={"Bid Details"}
                                                    data={rowDataBidTable}
                                                    columns={columnDefsBidTable}
                                                    options={optionsBidTable}
                                                ></MUIDataTable>
                                                <div style={{ height: 10 }} />
                                            </React.Fragment>
                                        }
                                        <div style={{ height: 10 }} />
                                        {
                                            ((loggedInuserCompanyRole === "Broker" || userCompanyName === "SystemAdmin") ||
                                                (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin" || loggedInuserRole === "Broker"))
                                            ) &&
                                            (
                                                <React.Fragment>
                                                    <Divider />
                                                    <Grid>
                                                        <Box boxShadow={3}>
                                                            <Paper square className="Paper">
                                                                <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor="primary" textColor="primary">
                                                                    <Tab label="Comments" />
                                                                </Tabs>
                                                                <TabPanel value={tabPannerlValue} index={0}>
                                                                    <React.Fragment>
                                                                        <div className="HomePage">
                                                                            <div className="LeftSide">
                                                                                <Header />
                                                                                {/* <SearchBar changeFilter={changeFilter} /> */}
                                                                                {Array.isArray(allChat) && allChat.length !== 0 && (
                                                                                    <List className="Custom-MuiList-root" style={{ maxHeight: "calc(100vh - 109px)", overflow: "auto", objectFit: "cover", height: 415 }}>
                                                                                        {
                                                                                            allChat
                                                                                                .sort(function (b, a) {
                                                                                                    return new Date(a.timestamp) - new Date(b.timestamp);
                                                                                                }).map((item, i) => {
                                                                                                    return (
                                                                                                        <div>
                                                                                                            <ListItem button alignItems="flex-start" id={item.carrier_id} value={item.carrier_id} className="Custom-MuiListItem-root" key={item.carrier_id} onClick={(event) => getSingleCarrierMessage(event)}>
                                                                                                                {
                                                                                                                    (item.carrier_id !== undefined) && (
                                                                                                                        <div id={item.carrier_id} className="Discussion">
                                                                                                                            <div id={item.carrier_id} className="LeftDiscussion">
                                                                                                                                {
                                                                                                                                    (item.carrier_id.charAt(0) !== undefined) && (
                                                                                                                                        <Avatar>{item.carrier_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <div id={item.carrier_id} className="RightDiscussion">
                                                                                                                                <div id={item.carrier_id} className="RightDiscussionTop">
                                                                                                                                    <div id={item.carrier_id} className="RightDiscussionTopLeft">{item.carrier_company}</div>
                                                                                                                                </div>
                                                                                                                                <div id={item.carrier_id} className="RightDiscussionBas">
                                                                                                                                    {
                                                                                                                                        (item.bid_amount !== undefined && item.bid_amount !== "$") ?
                                                                                                                                            <span style={{ paddingTop: "10px", paddingRight: "2%" }} className="RightDiscussionTopRight">{"Bid: " + item.bid_amount}</span>
                                                                                                                                            :
                                                                                                                                            <span style={{ paddingTop: "10px", paddingRight: "2%" }} className="RightDiscussionTopRight">{"Bid: " + "$" + selectedRowDataValue.bid_amount}</span>
                                                                                                                                    }
                                                                                                                                    {(item.mc_certified !== "Verified") && (
                                                                                                                                        <span><Tooltip title="Not Verified Company">
                                                                                                                                            <NotVerifiedUserIcon />
                                                                                                                                        </Tooltip></span>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                            </ListItem>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                )
                                                                                        }
                                                                                    </List>
                                                                                )}
                                                                            </div>
                                                                            <div className="RightSide">
                                                                                {
                                                                                    (chatFirstCarrierName !== undefined) && (
                                                                                        <HeaderChat name={chatFirstCarrierName.substring(0, chatFirstCarrierName.lastIndexOf("@"))} companyName={chatFirstCompanyName} mcnumber={chatCarrierMCNumber} />
                                                                                    )
                                                                                }
                                                                                <Chat discussion={chatAllMessages} />
                                                                                <div>
                                                                                    <form onKeyPress={(e) => { onEnterButtonForChat(e) }}>
                                                                                        <Paper component="form" className={classes.customPaper}>
                                                                                            <InputBase id="inputMesage" autoComplete='off' className={classes.CustomInput} style={{ width: "85%" }} placeholder="Enter your message" />
                                                                                            <IconButton onClick={() => onSendMessageClick(document.getElementById("inputMesage").value)} disabled={disableSendButton}>
                                                                                                <SendIcon />
                                                                                            </IconButton>
                                                                                        </Paper>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                </TabPanel>
                                                            </Paper>
                                                        </Box>
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }

                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Typography>
            </main>
        </div>
    )
}

export default AwardedLoads;