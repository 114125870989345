import React from 'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb1 from '../../components/bredcrumb1'
import AllLoads from '../../components/Pages/AllLoads'
import Footer from '../../components/Footer'


const AllLoadsPage = () => {
    return (
        <div className="price-sec">
            <Navbar />
            <Breadcumb1 bdtitle={'All Loads'} />
            <AllLoads />
            <Footer />
        </div>
    )
}

export default AllLoadsPage;