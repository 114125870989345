import React from 'react';

// components
import Error403Page from '../../components/Pages/Error403Page'


const Page403Error = () => {
    return (
        <Error403Page />
    )
}

export default Page403Error;