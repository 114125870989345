export const LoadboardIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="white" />
            <path d="M1 4H12.0006V13.7064H8.11806" stroke="#333333" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M15.2356 15.0005C15.9504 15.0005 16.5298 14.4211 16.5298 13.7063C16.5298 12.9915 15.9504 12.4121 15.2356 12.4121C14.5208 12.4121 13.9414 12.9915 13.9414 13.7063C13.9414 14.4211 14.5208 15.0005 15.2356 15.0005Z" stroke="#333333" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M4.88404 15.0005C5.5988 15.0005 6.17823 14.4211 6.17823 13.7063C6.17823 12.9915 5.5988 12.4121 4.88404 12.4121C4.16927 12.4121 3.58984 12.9915 3.58984 13.7063C3.58984 14.4211 4.16927 15.0005 4.88404 15.0005Z" stroke="#333333" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M12 13.7059V6.58789H15.8826L18.471 9.82337V13.7059H16.5297" stroke="#333333" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M2.29297 6.58789H8.11683" stroke="#333333" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M1 9.17578H6.82386" stroke="#333333" strokeMiterlimit="10" strokeLinejoin="round" />
        </svg>
    )
}
