import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Grid, TextField } from "@material-ui/core";
import Countdown from "react-countdown";
import { useAuth0 } from "@auth0/auth0-react";
// import map
import Map from '../Pages/Map/index';
import { withScriptjs } from "react-google-maps";
import { toShowIcon } from "../Pages/YesOrNA";
import moment from "moment";

const LoadDetailsPannel = (props) => {
    const { user } = useAuth0();
    // Constants to handle secondary pane load details
    const [loadNumberDetail, setLoadNumberDetail] = useState("");
    // Constants for showing Loaddetails in the secondary pane
    const [loadPostedByUser, setLoadPostedByUser] = useState("");
    const [loadPostedBy, setLoadPostedBy] = useState("");
    const [loadLoadType, setLoadLoadType] = useState("");
    const [loadLoadNotes, setLoadLoadNotes] = useState("");

    const [loadPickUpdate, setLoadPickUpdate] = useState("");
    //const [loadPickUpAsap, setLoadPickUpAsap] = useState("");
    const [loadPickUpWindow, setLoadPickUpWindow] = useState("");
    const [loadPickUpDestination, setLoadPickUpDestination] = useState("");

    const [loadDeliverByDate, setLoadDeliverByDate] = useState("");
    //const [loadDeliverDirect, setLoadDeliverDirect] = useState("");
    const [loadDeliverWindow, setLoadDeliverWindow] = useState("");
    const [loadDeliverDestination, setLoadDeliverDestination] = useState("");

    const [loadMaxWeight, setLoadMaxWeight] = useState("");
    const [loadNoOfPieces, setLoadNoOfPieces] = useState("");

    const [loadStackable, setLoadStackable] = useState(false);
    const [loadHazmat, setLoadHazmat] = useState(false);
    const [loadDockHigh, setLoadDockHigh] = useState(false);
    const [loadTempControl, setLoadTempControl] = useState(false);
    // ---- Sagar Commented as the feature was not necessary at this moment---------------
    // const [loadRate, setLoadRate] = useState("");
    const [loadFreightDimension, setLoadFreightDimension] = useState("");
    const [loadCityDistance, setLoadCityDistance] = useState("");

    // Constant to handle load notes
    const [loadStatusBidHistory, setLoadStatusBidHistory] = useState("");
    const [loadPostedTimeBidHistory, setloadPostedTimeBidHistory] = useState("");

    const MapLoader = withScriptjs(Map);
    const [mapDistance, setMapDistance] = useState("");

    useEffect(() => {
        populatePropsForLoadDetails();
    }, [props]);

    function populatePropsForLoadDetails() {
        if (props.allLoadDetails.length !== 0) {
            setMapDistance(props.allLoadDetails);
            setLoadNumberDetail(props.allLoadDetails.load_number);
            setLoadPostedByUser(props.allLoadDetails.posted_by_user);
            setLoadPostedBy(props.allLoadDetails.posted_by_company);
            setLoadLoadNotes(props.allLoadDetails.load_notes);
            setLoadMaxWeight(props.allLoadDetails.max_weight);
            setLoadPickUpdate(props.allLoadDetails.pickupfrom_date);
            setLoadDeliverByDate(props.allLoadDetails.pickupto_date);
            setLoadStatusBidHistory(props.allLoadDetails.status);
            setloadPostedTimeBidHistory(moment.utc(props.allLoadDetails.time_stamp).local().format("YYYY-MM-DD HH:mm:ss"));
            if (props.allLoadDetails.truck_type === "") {
                setLoadLoadType("NA");
            }
            else {
                setLoadLoadType(props.allLoadDetails.truck_type.toString().replace(/,/g, ", "))
            }
            if (props.allLoadDetails.total_item_count === "") {
                setLoadNoOfPieces("NA");
            }
            else {
                setLoadNoOfPieces(props.allLoadDetails.total_item_count);
            }
            let freightDimension = ""
            if (props.allLoadDetails.freight_dimension_length.toString().trim() === "" &&
                props.allLoadDetails.freight_dimension_width.toString().trim() === "" &&
                props.allLoadDetails.freight_dimension_height.toString().trim() === "") {
                setLoadFreightDimension("NA")
            }
            else {
                if (props.allLoadDetails.freight_dimension_length.toString().trim() === "") {
                    freightDimension += "NA X ";
                }
                else {
                    freightDimension += props.allLoadDetails.freight_dimension_length.toString().trim() + "L X ";
                }
                if (props.allLoadDetails.freight_dimension_width.toString().trim() === "") {
                    freightDimension += "NA X ";
                }
                else {
                    freightDimension += props.allLoadDetails.freight_dimension_width.toString().trim() + "W X ";
                }
                if (props.allLoadDetails.freight_dimension_height.toString().trim() === "") {
                    freightDimension += "NA"
                }
                else {
                    freightDimension += props.allLoadDetails.freight_dimension_height.toString().trim() + "H";
                }
                setLoadFreightDimension(freightDimension);
            }
            setLoadStackable(Boolean(props.allLoadDetails.stackable));
            setLoadHazmat(Boolean(props.allLoadDetails.hazmat));
            setLoadDockHigh(Boolean(props.allLoadDetails.dockhigh));
            setLoadTempControl(Boolean(props.allLoadDetails.tempcontrol));
            if (props.allLoadDetails.cities_distance) {
                setLoadCityDistance(props.allLoadDetails.cities_distance)
            }
            if (
                props.allLoadDetails.pickup_from_window === "ASAP" &&
                props.allLoadDetails.pickup_to_window === "ASAP"
            ) {
                setLoadPickUpWindow("ASAP");
            }
            else {
                setLoadPickUpWindow(
                    props.allLoadDetails.pickup_from_window + " - " + props.allLoadDetails.pickup_to_window
                );
            }
            if (
                props.allLoadDetails.deliver_from_window.includes("Direct") &&
                props.allLoadDetails.deliver_to_window.includes("Direct")
            ) {
                setLoadDeliverWindow("Deliver Direct(Non-Stop)");
            }
            else {
                setLoadDeliverWindow(
                    props.allLoadDetails.deliver_from_window + " - " + props.allLoadDetails.deliver_to_window
                );
            }
            setLoadPickUpDestination(props.allLoadDetails.pickup_dest);
            setLoadDeliverDestination(props.allLoadDetails.deliverZip);
            setLoadDeliverDestination(props.allLoadDetails.delivery_dest);
        }
    }

    return (
        <React.Fragment>
            <Grid style={{ fontSize: 14 }} xs={12}>
                <Grid container direction="row">
                    <Grid xs={6}>
                        <Row>
                            <Col sm>Load ID:</Col>
                            <Col sm>{loadNumberDetail}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row>
                            <Col sm style={{ fontWeight: 'bold' }}>Pick-Up Destination:</Col>
                            <Col sm style={{ fontWeight: 'bold' }}>{loadPickUpDestination}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row>
                            <Col sm>Pick-Up Window:</Col>
                            <Col sm>{loadPickUpWindow}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row>
                            <Col sm>Pick-Up Date:</Col>
                            <Col sm>{loadPickUpdate}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row>
                            <Col sm style={{ fontWeight: 'bold' }}>Deliver Destination:</Col>
                            <Col sm style={{ fontWeight: 'bold' }}>{loadDeliverDestination}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row>
                            <Col sm>Delivery Window:</Col>
                            <Col sm>{loadDeliverWindow}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row>
                            <Col sm>Deliver Date:</Col>
                            <Col sm>{loadDeliverByDate}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row style={{ fontWeight: "bold" }}>
                            <Col sm>Weight:</Col>
                            <Col sm>
                                {loadMaxWeight}
                            </Col>
                        </Row>
                        <div style={{ height: 5 }} />
                        <Row>
                            <Col sm>Stackable:</Col>
                            <Col sm>
                                {toShowIcon(loadStackable)}
                            </Col>
                        </Row>
                        <div style={{ height: 5 }} />
                        <Row>
                            <Col sm>Hazmat:</Col>
                            <Col sm>
                                {toShowIcon(loadHazmat)}
                            </Col>
                        </Row>
                        <div style={{ height: 5 }} />
                        <Row>
                            <Col sm>Dock High:</Col>
                            <Col sm>
                                {toShowIcon(loadDockHigh)}
                            </Col>
                        </Row>
                        <div style={{ height: 5 }} />
                        <Row>
                            <Col sm>Temperature Control:</Col>
                            <Col sm>
                                {toShowIcon(loadTempControl)}
                            </Col>
                        </Row>
                        <div style={{ height: 4 }} />                            
                        {
                            (loadPostedByUser !== user.email) && (
                                <Row>
                                    <Col sm>Status:</Col>
                                    <Col sm >{loadStatusBidHistory}</Col>
                                </Row>
                            )
                        }
                    </Grid>
                    <Grid xs={6}>
                        <Row style={{ fontWeight: "bold" }}>
                            <Col sm>Approximate Distance {<div>(Drive Time):</div>}</Col>
                            <Col sm >{loadCityDistance}</Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <Row style={{ fontWeight: "bold" }}>
                            <Col sm>Number Of Pieces:</Col>
                            <Col sm>
                                {loadNoOfPieces}
                            </Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <Row>
                            <Col sm>Freight Dimension:</Col>
                            <Col sm >{loadFreightDimension}</Col>
                        </Row>
                        <div style={{ height: 12 }} />
                        <Row>
                            <Col sm>Truck Type:</Col>
                            <Col sm>
                                {loadLoadType}
                            </Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <TextField
                            label="Load Notes"
                            multiline
                            rows={3}
                            variant="outlined"
                            style={{
                                width: "97%",
                            }}
                            value={loadLoadNotes}
                            disabled={true}
                        />
                        {(loadPostedByUser === user.email) ?
                            <div style={{ height: 4 }} />
                            :
                            <div style={{ height: 10 }} />
                        }
                        <Row>
                            <Col sm>Posted By:</Col>
                            <Col sm>{loadPostedBy}</Col>
                        </Row>
                        {(loadPostedByUser === user.email) ?
                            <div style={{ height: 4 }} />
                            :
                            <div style={{ height: 10 }} />
                        }
                        <Row>
                            <Col sm>Posted By User:</Col>
                            <Col sm>{loadPostedByUser}</Col>
                        </Row>
                        {(loadPostedByUser === user.email) ?
                            <div style={{ height: 4 }} />
                            :
                            <div style={{ height: 10 }} />
                        }
                        <Row>
                            <Col sm>Load Posted at:</Col>
                            <Col sm >{loadPostedTimeBidHistory}</Col>
                        </Row>
                        {(loadPostedByUser === user.email) ?
                            <div style={{ height: 4 }} />
                            :
                            <div style={{ height: 10 }} />
                        }
                        <Row>
                            <Col sm>Load Expired In:</Col>
                            <Col sm >{
                                <Countdown autoStart={true} date={Date.now() + 0}>
                                </Countdown>
                            }</Col>
                        </Row>
                    </Grid>
                    {/*<div style={{ justifyContent: "center", width: "100%", display: "flex" }}>

                        <div ><div>
                            <MapLoader data={{ "fromlatitude": mapDistance.pickuplat, "fromlongitude": mapDistance.pickuplng, "tolatitude": mapDistance.deliverylat, "tolongitude": mapDistance.deliverylng }}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6w_WDy6psJ5HPX15Me1-o6CkS5jTYWnE"
                                loadingElement={<div style={{ height: `100%` }} />}
                            />
                        </div></div>
                        </div>*/}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
export default withRouter(LoadDetailsPannel);