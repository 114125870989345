import React from 'react';
// components
import Navbar from '../../components/Navbar'
import Breadcumb1 from '../../components/bredcrumb1'

const PrivacyPolicy = () => {



  return (
    <div>
      <Navbar />
      <Breadcumb1 bdtitle={'Carrier'} />
      {<React.Fragment>
        <div style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "5%" }}>

          <p>

            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>Privacy Statement</b></h2>
            <p></p>
            <h3 style={{ color: 'black', fontFamily: "Arial" }}><b>Last updated March 2022</b></h3>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b> About this Privacy Statement</b></h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}> Loadeo, LLC is committed to protecting your privacy. This Privacy Statement applies to our website, mobile applications or any other sites hosted by our service providers on our behalf (collectively the “Sites”).  This Statement describes the information that we collect, the purposes for which it is used, and your choices regarding our use of it.  By using our Sites, you consent to our data practices described in this Statement.</h2>

            <br></br>
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>Types of Information We Collect</b> </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>Loadeo is a business to business company, and therefore, does not collect personal information of consumers. Any personal information that we collect about you is incidental to your use of our Sites for commercial purposes.  To the limited extent that we collect personal information about you, it can be divided into two categories, personally identifiable information and non-personal data.  Personally identifiable information means information that specifically identifies you as an individual.  Non-personal data is information that does not identify you.</h2>
            <br></br>
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>Personally Identifiable Information</h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}> You can visit our Sites without providing us with any personally identifiable information.  In some instances, however, we may ask that you provide us with personally identifiable information so that we can provide you with a service or carry out a transaction that you have requested.  In these instances, the personally identifiable information that we collect may include:</h2>


            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}> <h1 style={{ margin: 0, display: "inline", fontWeight: 'bold' }}>.</h1> Contact details, such as your name, MC number, job title, company/organization name, website, telephone number, and email address; and</h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}> <h1 style={{ margin: 0, display: "inline", fontWeight: 'bold' }}>.</h1> Information about service or technical requests you have initiated for our products or services.</h2>


            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>Also, if you make an application for a position with Loadeo we may collect the following information from you through our online portal:</h2>
            <ul>

              <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}> <h1 style={{ margin: 0, display: "inline", fontWeight: 'bold' }}>.</h1> Resume or CV, cover letter, previous work experience, educational background, type of employment sought, date available, employee referral, desired salary, travel preferences, information about your performance and career developments; and</h2></ul>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}> <h1 style={{ margin: 0, display: "inline", fontWeight: 'bold' }}>.</h1> Identification, such as your passport number, expatriate status, government identification numbers (e.g. Social Security Number) where permitted, immigration status, nationality, and, where permitted, information concerning your heritage and ethnicity (for diversity monitoring purposes); and</h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}> <h1 style={{ margin: 0, display: "inline", fontWeight: 'bold' }}>.</h1> Any other information you submit to us, including any correspondences you may have with us.</h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>You are not required to provide any of this personally identifiable information, but if you do not, we may not be able to provide you the requested service, complete your transaction, or process your employment application.</h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>Finally, we may obtain information about you from other sources, such as where your employer supplies your contact details in connection with a service request. If you make an application for employment with us or accept employment with us, we may obtain information from third parties, including, to the extent permitted by applicable law, recruiters, employment research firms, identity verification services, the references you provide to us, websites and other publicly accessible information on the Internet.  We may also receive information you submit to us through third-party websites, including through LinkedIn, Facebook, and other publicly available sources. Where permitted by local law and with your consent, we may request background or credit checks from public authorities or financial institutions to evaluate your eligibility for employment, as well as your medical information if required to evaluate your eligibility for employment benefits.</h2>
            <br />
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>Non-Personal Data</h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We also collect non-personal data that does not identify you, directly, including “automatically collected data,” “aggregated” or “anonymized” information that we collect to inform us how others use our Sites.  Non-personal data includes, <p></p>(i) device software platform, operating system, type of Internet browser, and firmware;
              <br />(ii) mobile phone carrier;<br /> (iii) web browsing patterns, including websites visited;<br /> (iv) geographical data such as zip code, area code, and other non-precise location information; and<br /> (v) the way in which you use the Site, including frequency and duration of usage.
            </h2>
            <br />
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>Cookies and Similar Technologies</h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              A cookie is a text file that is downloaded on to your hard disk or in to your browser's memory by a webserver that allows us to monitor your interaction with our Sites. Cookies cannot be used to run programs or deliver viruses to your computer, read data from elsewhere on your computer, or misappropriate any personally identifiable information about you.  Cookies are uniquely assigned to you, and can only be read by a webserver in the domain that issued you the cookie.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              Cookies are useful because they help arrange the content and layout of our Sites and allow us to recognize those computers or other devices that have been to our Sites before. Cookies do many different jobs, such as allowing our Sites to remember your preference settings and helping us to enhance the usability and performance of our Sites and your experience using it; for example, a cookie could store your personally identifiable information that you have entered into a form on our Site.  We also use cookies that are managed by Alphabet, Inc. (fka Google, Inc.) for providing its Google Analytics service, which allows us to understand how users navigate to and interact with our Site.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of our Site.
            </h2>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>How We Use the Information We Collect</b></h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>
              We may use your personal information for the following purposes:
            </h2>
            <br />
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>
              Services and Transactions
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We may use your personal information to deliver services to you or carry out transactions you have requested, including, but not limited to, providing information on our products or services you have purchased or otherwise use, registering purchased products, processing product orders, handling warranty claims, replacing product manuals, answering customer service requests and facilitating the use of our Sites.  We may also use your personal information to send you email or postal mail, provide customer support, or arrange for deliveries.  As part of delivering services, we may share your information with our distributors.  We provide these companies with only those elements of your personal information they need to deliver those services. These companies and their employees are prohibited from using that personal information for any other purpose.

            </h2>
            <br />
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>
              Improving our Sites
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We may use your personal information to perform business analyses or for other purposes that help us to develop and improve the quality of our Sites; for example, by customizing our Sites to your particular preferences or interests.
            </h2>
            <br />
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>
              Marketing
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We may use your personal information to inform you of products or services which may be of interest to you, and to otherwise communicate with you about offerings, events and news, surveys, special offers, and related topics. You are able to opt-out from marketing communications sent via e-mail at any time, free of charge by using the “unsubscribe” link in any e-mail marketing materials you receive from us, or by contacting us using the contact information listed in this Statement. Depending on your country of residence, you may also be able to opt out of other types of marketing communications; please contact us using the contact information listed in the Statement for more information.
            </h2>
            <br />
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>
              Employment Applications
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We use your personally identifiable information for processing employment applications.  We will keep this information for future consideration unless you direct us not to do so.  If you accept a position with Loadeo we will retain this information and may refer back to it during your employment.

            </h2>
            <br />
            <h2 style={{ fontStyle: "italic", fontSize: "25px" }}>
              Compulsory Disclosure
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We may also disclose your personal information if we are required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on us; (b) protect and defend our rights or property; or (c) act under exigent circumstances to protect the personal safety of any individual.
            </h2>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>
              Security of your personal information
            </b>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              Loadeo. is committed to protecting your personal information.  We secure your personal information from unauthorized access, use or disclosure using a variety of security procedures and technology.  For example, we secure your personally identifiable information on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure.
            </h2>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>
              Your rights to your information

            </b>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              You may have certain rights with respect to personal information about you that is collected through the Sites or when you contact us. If you are a resident of the European Union, the rights described below may apply to you. For other users, the rights that apply to you will depend on the laws of your country of residence or, in some instances your state of residence. We will facilitate your exercise of the rights that apply to you in accordance with applicable law.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              You may have the right to access, review and correct personal information collected through our Sites or when you contact us. In some cases, you can do this by going to the page on which you provided the information. You can help us to maintain the accuracy of your information by notifying us of any change to your mailing address, phone number or e-mail address.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>

              You may be entitled to object to certain types of processing activities involving your personal information, including where we are relying on a legitimate interest to process your personal information or where we are processing your personal information for direct marketing.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              You may be entitled to request that we delete your personal information.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              You may be entitled to receive and reuse your personal information for your own purposes. This is known as the right to data portability and, where applicable, requires us to move, copy or transfer your personal information from our systems to you or (where technically feasible) a third party chosen by you without affecting its usability.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              If you have provided your consent to the processing of your personal information, you may have the right to withdraw your consent at any time by contacting us using the contact details at the end of this Statement or, in the case of email marketing, by using the “unsubscribe” link in any of our marketing emails. This won’t affect the lawfulness of any processing up to that point
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We will respond to any requests from you to exercise your rights within the timeframes required by law. We may charge a fee to facilitate your request where permitted by law. To exercise any of your rights, please contact us using the contact details set out at the end of this Statement. We may take steps to verify your identity before taking action on a request. If you believe our processing of your personal information does not comply with data protection law, you can make a complaint to the supervisory authority in charge of overseeing compliance with data protection law in your jurisdiction. We would however appreciate the chance to address your concerns, so please feel free to contact us regarding any complaint you may have.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              Some web browsers may transmit “do-not-track” signals to the Sites with which the user communicates. Because of differences in how web browsers incorporate and activate this feature, it is not always clear whether users intend for these signals to be transmitted, or whether they even are aware of them. We currently do not take action in response to these signals.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              For more information about your rights, please contact us using the contact details at the end of this Statement.
            </h2>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>
              Children’s information
            </b>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }} >
              We do not knowingly collect information from children and do not target or direct our Sites to children. The meaning of “children” is subject to the laws and regulations in the jurisdiction in which you are located.
            </h2>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>
              <section >
                California privacy statement
              </section>
            </b>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>


              Pursuant to Section 1798.83 of the California Civil Code, residents of California can obtain certain information about the types of personal information that companies with whom they have an established business relationship have shared with third parties for direct marketing purposes during the preceding calendar year. The law provides that companies must inform consumers about the categories of personal information that have been shared with third parties, the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. To request a copy of the information disclosure provided by Loadeo pursuant to Section 1798.83 of the California Civil Code, please contact us via the email or address stated above. Please allow 30 days for a response.
            </h2>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>
              Changes to this statement
            </b>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              We may occasionally update this Statement to reflect company and customer feedback.  When we do, we will revise the "last updated" date at the top and bottom of the Privacy Statement and take such additional steps as may be required by law. We encourage you to periodically review this Statement to be informed of how we use and protect your information.
            </h2>
            <br />
            <h2 style={{ color: '#7890ad', fontFamily: "Cambria" }}><b>
              Contact information
            </b>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              Loadeo welcomes your comments regarding this Statement. If you believe that we have not adhered to this Statement, please contact us at privacy@loadeo.com. We will use commercially reasonable efforts to promptly determine and remedy the problem.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>

              <u> Mailing address</u> <br></br>
              Loadeo, LLC<br id="california"></br>
              9720 Coit Rd Ste 220-314<br></br>
              Plano, TX 75025
            </h2>

            <h2 style={{ paddingTop: "40px", fontFamily: "Cambria", fontSize: "30px", lineHeight: 1.6, textAlign: "center", justifyContent: 'center' }}><b>LOADEO’S California Consumer Privacy Act
              Privacy Policy</b>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              This PRIVACY POLICY FOR CALIFORNIA RESIDENTS supplements the information contained in the Policy of Loadeo, LLC and applies solely to visitors, users, and others who reside in the State of California (“consumers” or “you”) when you visit the Loadeo website or use of or placing orders through our website  (collectively, our “Services”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other California privacy laws.  Any terms defined in the CCPA have the same meaning when used in this notice.
            </h2>

            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "25px", }}><b>
              <br />
              Information We Collect
            </b>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>
              We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”). In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:
            </h2>

            <table style={{
              width: "100%", border: "1px solid"
            }} >
              <tr style={{ border: "1px solid" }}>
                <th style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, border: "1px solid", width: "30%", color: "black" }}>Category</th>
                <th style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, border: "1px solid", width: "60%", color: "black" }}>Examples</th>
                <th style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, border: "1px solid", color: "black" }}>Collected</th>
              </tr>

              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, width: "30%", color: "black" }}>A. Identifiers.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>YES</td>
              </tr>

              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, width: "30%", color: "black" }}>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
                <td style={{ border: "1px solid", width: "60%", fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>YES</td>
              </tr>

              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", width: "30%" }}>C. Protected classification characteristics under California or federal law.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>NO</td>
              </tr>
              <tr style={{ border: "1px solid" }} className="text">
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "30%" }}>D. Commercial information.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, width: "30%", color: "black", border: "1px solid" }}>YES</td>
              </tr>
              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "30%" }}>E. Biometric information.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, width: "30%", color: "black", border: "1px solid" }}>NO</td>
              </tr>
              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "30%" }}>F. Internet or other similar network activity.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, width: "30%", color: "black", border: "1px solid" }}>YES</td>
              </tr>
              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", width: "30%" }}>G. Geolocation data.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Physical location or movements.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>NO</td>
              </tr>
              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", width: "30%" }}>H. Sensory data.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>NO</td>
              </tr>
              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", width: "30%" }}>I. Professional or employment-related information.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Current or past job history or performance evaluations.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>NO</td>
              </tr>
              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", width: "30%" }}>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>NO</td>
              </tr>

              <tr style={{ border: "1px solid" }}>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", width: "30%" }}>K. Inferences drawn from other personal information.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", border: "1px solid", width: "60%" }}>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
                <td style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black" }}>NO</td>
              </tr>

            </table>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", }}>
              <br />
              Personal information does not include:
            </h2>
            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify', }}>Publicly available information from government records.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify', }}>De-identified or aggregated consumer information.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify', }}>Information excluded from the CCPA's scope, like:</li>
              <ul style={{ listStyleType: "circle", paddingLeft: "30px" }}>
                <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify', }}>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
                <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify', }}>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</li>
              </ul>

            </ul>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              We obtain the categories of personal information listed above from the following categories of sources:
            </h2>

            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}>Directly from you; for example, when you use our Services to create an account.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}>Directly and indirectly from you when using our Services or visiting our website. For example, usage details collected automatically in the course of your interaction with our platform or website.</li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "25px", }}><b>
              <br />
              Use of Personal Information
            </b>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              We may use or disclose the personal information we collect for one or more of the following business purposes:
            </h2>

            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}> To fulfill or meet the reason for which the information is provided.  </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}> To provide you with information, products or services that you request from us. </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}> To carry out our obligations and enforce our rights arising from any contracts entered into between you, our customers and us. </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}> To improve our website and present its contents to you. </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}> For testing, research, analysis and product development.  </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}>As necessary or appropriate to protect the rights, property or safety of us, our clients or others.  </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.  </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}> As described to you when collecting your personal information or as otherwise set forth in the CCPA.  </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, color: "black", textAlign: 'justify' }}> To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred. </li>
            </ul>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.
            </h2>

            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "25px", }}><b>
              <br />
              Sharing Personal Information
            </b>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              We may disclose your personal information to a third party for a business purpose.  When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              Category A: &nbsp;&nbsp;      Identifiers.
              <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
                Category B:  &nbsp;&nbsp;  	California Customer Records personal information categories.
              </h2>
              <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
                Category D:  &nbsp;&nbsp; 	Commercial information.
              </h2>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              We disclose your personal information for a business purpose to the following categories of third parties:
            </h2>

            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Service providers</li>
            </ul>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              In the preceding twelve (12) months, we have not sold any personal information
            </h2>
            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "25px", }}><b>
              <br />
              Your Rights and Choices
            </b>
            </h2>
            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              <br />
              The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.
            </h2>

            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>
              <br />
              1. &nbsp;&nbsp; <u>Access to Specific Information and Data Portability Rights</u>
            </h2>

            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>
              <br />
              You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:
            </h2>

            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>The categories of personal information we collected about you.</li>
              <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>The categories of sources for the personal information we collected about you. </li>

              <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>Our business or commercial purpose for collecting or selling that personal information </li>

              <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>The categories of third parties with whom we share that personal information </li>
              <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>The specific pieces of personal information we collected about you (also called a data portability request).</li>
              <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>

              <ul style={{ listStyleType: "circle", paddingLeft: "30px" }}>
                <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center' }}>sales, identifying the personal information categories that each category of recipient purchased; and</li>
                <li style={{ color: 'black', fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', textAlign: 'justify' }}>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
              </ul>

            </ul>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              2. &nbsp;&nbsp; <u>Deletion Request Rights</u>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.

            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              We may deny your deletion request if retaining the information is necessary for us or our service providers to:
            </h2>

            <ol type="1">
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Debug products to identify and repair errors that impair existing intended functionality.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Comply with a legal obligation.</li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>

            </ol>


            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              3. &nbsp;&nbsp; <u>Exercising Access, Data Portability, and Deletion Rights</u>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us at privacy@loadeo.com.
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:
            </h2>

            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative. </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
            </ul>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  Making a verifiable consumer request does not require you to create an account with us.  We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              4. &nbsp;&nbsp; <u>Response Timing and Format</u>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              We endeavor to respond to a verifiable consumer request within 45 days of its receipt.  If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.  If you have an account with us, we will deliver our written response to that account.  If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.  Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt.  The response we provide will also explain the reasons we cannot comply with a request, if applicable.  For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              5. &nbsp;&nbsp; <u>Non-Discrimination</u>
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
            </h2>

            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Deny you goods or services. </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties. </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>Provide you a different level or quality of goods or services. </li>
              <li style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services </li>
            </ul>

            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "25px", }}><b>
              <br />
              Changes to Our Privacy Notice
            </b>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will notify you by email or through a notice on our website homepage.
            </h2>

            <h2 style={{ color: 'black', fontFamily: "Cambria", fontSize: "25px", }}><b>
              <br />
              Contact Information
            </b>
            </h2>

            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black", textAlign: 'justify' }}>
              <br />
              If you have any questions or comments about this notice, our Privacy Policy, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:
            </h2>
            <h2 style={{ fontFamily: "Cambria", fontSize: "20px", lineHeight: 1.6, justifyContent: 'center', color: "black" }}>
              <br />
              <u> Mailing ddress</u> <br />
              Loadeo, LLC<br />
              9720 Coit Rd Ste 220-314<br />
              Plano, TX 75025
            </h2>




          </p>
        </div>
      </React.Fragment>}
    </div>
  )
}

export default PrivacyPolicy;

