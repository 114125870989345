export const PreferredCarrierIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 20 20" style= {{marginRight: 25 }} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" fill="white" />
            <path d="M14.5 7.9375H11.6875V6.6875H7.9375V4.65625L5.4375 3.25L2.9375 4.65625V7.46875L3.5625 7.82062V12.9375H11.6875H16.6875V11.6875L14.5 7.9375Z" fill="white" />
            <path d="M6.0625 15.125L7 15.75L7.9375 15.125V14.1875L7 13.5625L6.0625 14.1875V15.125Z" stroke="#333333" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M12.9375 15.125L13.875 15.75L14.8125 15.125V14.1875L13.875 13.5625L12.9375 14.1875V15.125Z" stroke="#333333" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M3.5625 9.8125V12.9375H11.6875V6.6875H9.1875" stroke="#333333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.0625 14.1875H16.6875V12.9375V11.6875L14.5 7.9375H11.6875" stroke="#333333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.2294 9.1875H12.9375V11.0625H16.29" stroke="#333333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.6875 12.9375H11.6875" stroke="#333333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6875 14.1875H9.1875" stroke="#333333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.8125 14.1875H3.5625" stroke="#333333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.9375 7.46875L5.4375 8.875L2.9375 7.46875V4.65625L5.4375 3.25L7.9375 4.65625V7.46875Z" stroke="#333333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.875 6.25L4.70833 7L7 5.125" stroke="#333333" strokeWidth="0.5" />
        </svg>
    )
}
