import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { SignIn } from "../Pages/Button/Button"

import "./index.css";
const styles = {
    header: {
        backgroundImage: `url(${"https://loadeo-company-logo-images.s3.amazonaws.com/404.png"})`,
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vh',
    },
    header1: {
        backgroundImage: `url(${"https://loadeo-company-logo-images.s3.amazonaws.com/401.png"})`,
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vh',
        marginLeft: "5%"
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
}
//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let pathList = ['/expiredloads', '/awardedloads', '/admin', '/allloads', '/expiredbid',
    '/feedback', '/activeloads', '/activebids', '/loadswon', '/loadboard', '/prospectiveclients',
    '/systemadmincompanies', '/systemadmin', '/underverification', '/createprofile',
    '/useragreement', '/companysuspended', '/usersuspended', '/rejecteduser',
    '/preferredcarrier', '/companyadmin', '/companyprofile', '/profile', '/updateprofile', '/emailsettings', '/settings',
    '/callback', '/privacypolicy', '/verify', '/savedlanes', '/documents', '/mcverify']
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const Page401AndPage404 = (props) => {
    const history = useHistory();
    const [count, setCount] = useState(0)

    //Handle Constant
    const [match, setMatch] = useState(props.location.location.pathname);

    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState(window.localStorage.getItem('user_role'));

    // Constant to handle logged in Company name
    const [userCompanyName, setUserCompanyName] = useState(window.localStorage.getItem('userCompanyName'));

    useEffect(() => {
        toValidate()
    }, []);

    //function to Check Path is Valid
    async function toValidate() {
        let pathCount = 0;
        pathList.forEach(element => {
            if (element === props.location.location.pathname) {
                pathCount = pathCount + 1;
                setCount(pathCount)
            }
        });
        if (pathCount === 0) {
            setMatch("")
            setCount(pathCount)
        }
    }
    //function to Return Home Page
    function handleHome() {
        if (userCompanyName !== "SystemAdmin" && userCompanyName !== "SupportPage") {
            setMatch("/loadboard")
            history.push({ pathname: "/loadboard" })
        }
        else {
            if(userCompanyName === "SystemAdmin"){
                setMatch("/admin")
                history.push({ pathname: "/admin" })
            }
            else {
                setMatch("/mcverify")
                history.push({ pathname: "/mcverify" })
            }
        }
    }
    /******************************************************************************************************/
    return (
        <main>
            {
                (count === 0 && match === "") ?
                    <section id="head" className="d-flex align-items-center">
                        <div className="container">
                            <div className="row ">
                                <div className="col-md-6">
                                    <div className="head_content">
                                        <h1>OOPS!</h1>
                                        <h2>PAGE NOT FOUND</h2>
                                        <p>Sorry, the page you're looking for doesn't exist.</p>
                                        <div class="head_btn">
                                            {
                                                (loggedInuserRole === null && userCompanyName !== "SystemAdmin") ?
                                                    <div>
                                                        <button className="button1" onClick={() => history.push({ pathname: "/" })}>
                                                            Return Home
                                                        </button>
                                                        <SignIn />
                                                    </div>
                                                    :
                                                    <button className="button1" onClick={() => handleHome()}>
                                                        Return Home
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="head_img">
                                        <div style={styles.header}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    (match !== "" && count !== 0 && loggedInuserRole === null && props.user === undefined && userCompanyName !== "SystemAdmin" && userCompanyName !== "SupportPage") && (
                        history.push({ pathname: "/notauthenticated" })
                    )
            }
        </main>
    )
}

export default Page401AndPage404;