export const ExpiredLoadsAdminIcon = () => {
    return (
        <svg width="50%" height="30" style= {{backgroundColor: "#17b8a6", margin: "25%"}} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7486 0.0705643C11.6665 0.370617 7.8705 2.10813 4.97116 5.01096C0.725069 9.25356 -0.94964 15.3279 0.526197 21.1266C1.14375 23.5584 2.28464 25.7739 3.96633 27.8079C4.3885 28.3173 5.54335 29.4722 6.06669 29.9048C8.43222 31.8691 11.1676 33.1356 14.1262 33.6311C15.0683 33.7915 15.8289 33.8509 16.9663 33.8578L17.9746 33.8613L19.4504 31.4365C20.2599 30.1037 20.9193 29.0082 20.9158 29.0012C20.9088 28.9977 20.6646 29.0535 20.368 29.1303C19.6877 29.3082 18.948 29.4582 18.3653 29.542C17.7338 29.6292 15.951 29.6292 15.3125 29.542C11.8305 29.057 8.82648 27.2916 6.74007 24.5004C4.11984 21.0044 3.5023 16.3781 5.11071 12.3273C5.63406 11.0085 6.30046 9.89204 7.22155 8.79301C7.59487 8.34991 8.45316 7.49511 8.85788 7.16715C10.8187 5.57618 13.0272 4.63764 15.5218 4.34108C16.1429 4.26781 17.5524 4.25734 18.1386 4.32363C21.16 4.66206 23.7628 5.90065 25.8666 8.00799C27.9007 10.0421 29.2196 12.7286 29.5545 15.5198C29.5754 15.7047 29.5964 15.8617 29.5999 15.8687C29.6068 15.8756 29.7185 15.8094 29.8476 15.7256C30.5419 15.279 31.463 14.958 32.3108 14.8638C32.4992 14.8464 32.869 14.8289 33.1412 14.8289C33.4168 14.8289 33.6296 14.815 33.6296 14.7975C33.6296 14.7801 33.5808 14.4766 33.5215 14.1242C32.7469 9.51523 30.0953 5.44011 26.1807 2.83733C23.9303 1.34055 21.3694 0.419464 18.627 0.122902C18.0304 0.0566101 16.3522 0.028698 15.7486 0.0705643Z" fill="white"/>
        <path d="M16.4351 6.4436C16.1943 6.52036 16.0164 6.6669 15.9327 6.85879C15.8699 7.00533 15.8489 7.32283 15.6919 10.9618C15.5349 14.6043 15.5175 14.9114 15.4617 14.9393C15.4268 14.9567 15.2663 15.1067 15.1023 15.2742C14.7569 15.6231 14.5545 15.9894 14.4568 16.4395C14.4045 16.6803 14.3975 16.9838 14.4429 17.1129C14.4499 17.1373 13.759 17.9886 12.9042 19.0109C12.0494 20.0297 11.3202 20.9298 11.2819 21.0101C11.1772 21.2404 11.1911 21.5369 11.3272 21.8091C11.4598 22.0812 11.7738 22.4022 12.032 22.5278C12.2902 22.6534 12.6251 22.6743 12.8379 22.5801C12.9356 22.5348 13.6265 21.9486 14.7534 20.9508L16.5118 19.3947L16.6374 19.4191C16.8363 19.4645 17.3003 19.4435 17.5446 19.3807C17.6702 19.3458 17.9039 19.2551 18.0679 19.1749C18.3087 19.0597 18.4238 18.9725 18.6785 18.7143C18.9367 18.4596 19.0239 18.3445 19.139 18.1038C19.3554 17.6676 19.4077 17.4269 19.4077 16.935C19.4042 16.5791 19.3902 16.4744 19.3135 16.2372C19.1984 15.8883 19.0134 15.5743 18.7622 15.2986L18.5634 15.0823L18.2633 11.0909C17.9667 7.18676 17.9598 7.09255 17.8725 6.91113C17.7644 6.69132 17.5411 6.5064 17.2899 6.43314C17.0596 6.36336 16.6688 6.37033 16.4351 6.4436Z" fill="white"/>
        <path d="M31.9892 17.0748C30.8518 17.319 29.8086 18.0971 28.9991 19.3078C28.9224 19.4229 26.4836 23.4875 23.5807 28.3442C15.6607 41.5849 13.8465 44.6238 12.559 46.766C11.2577 48.9396 10.996 49.4281 10.7866 50.0631C10.5878 50.6597 10.5215 51.0365 10.5215 51.5634C10.5215 52.5019 10.825 53.2346 11.46 53.8277C12.0776 54.4069 12.901 54.7558 14.0558 54.9372C14.4396 54.9965 15.3153 55 32.6172 55C47.2744 55 50.8401 54.993 51.1088 54.9546C53.2475 54.6651 54.5419 53.73 54.9327 52.1914C55.0234 51.8355 55.0234 51.04 54.9327 50.6213C54.835 50.1747 54.6501 49.6723 54.4093 49.2083C54.2907 48.978 52.1066 45.3425 49.5562 41.1313C47.0092 36.9201 43.0248 30.3329 40.7011 26.495C38.381 22.6572 36.4167 19.4229 36.3399 19.3078C35.5235 18.0936 34.4175 17.2946 33.2522 17.0713C32.9312 17.0085 32.2892 17.012 31.9892 17.0748ZM33.5487 26.7567C34.0686 26.8928 34.4908 27.1475 34.9862 27.6325C35.4886 28.1279 35.7608 28.6443 35.8829 29.3525C35.9771 29.8724 35.9352 30.3678 35.6735 31.8855C34.9304 36.2084 34.7141 37.5377 34.5047 39.0903C34.421 39.6973 34.2221 41.3721 34.2221 41.4628C34.2221 41.4732 33.5104 41.4767 32.6451 41.4732L31.0646 41.4628L31.0402 41.2709C31.0262 41.1662 30.9739 40.7266 30.925 40.294C30.7471 38.7379 30.475 36.8748 30.0319 34.1882C29.3724 30.1724 29.355 30.0259 29.4562 29.513C29.7318 28.1558 31.0367 26.8928 32.373 26.6904C32.68 26.6451 33.2243 26.6765 33.5487 26.7567ZM33.5557 43.4585C34.1454 43.5736 34.5954 43.7969 35.0071 44.1737C35.7538 44.8575 36.1864 45.8484 36.1306 46.7486C36.0852 47.4847 35.8654 47.9872 35.3177 48.6152C34.6199 49.4176 33.772 49.7526 32.5858 49.7037C31.5321 49.6584 30.8413 49.3304 30.1644 48.5454C29.7458 48.0604 29.5329 47.6208 29.4422 47.0486C29.3899 46.7276 29.4143 46.1485 29.4911 45.8624C29.7597 44.8924 30.5133 43.9958 31.3612 43.6399C31.9717 43.3817 32.8265 43.3119 33.5557 43.4585Z" fill="white"/>
        </svg>
               
    )
}
