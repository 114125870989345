import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import {
    TextField,
    Grid,
    Box,
    Link,
    Checkbox,
    Button,
    Paper,
    FormControlLabel
} from "@material-ui/core";
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import moment from "moment";

import LogoComponent from '../../main-component/LogoComponent'
import { environmentVariables } from '../../environment';

const styles = {
    header: {
        backgroundImage: `url(${"https://loadeo-logo.s3.us-east-2.amazonaws.com/01-Login-truck.png"})`,
        height: '140vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
}

const UserAgreement = () => {
    // Variable declaration
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [checkedAgree, setChecked] = React.useState(false);
    const [disableCheckBox, setDisableCheckBox] = React.useState(false);
    const [name, setName] = useState("");
    const { user } = useAuth0();

    // Function that triggers on page load
    useEffect(() => {
        checkUserPresent()
    }, []);

    async function checkUserPresent() {
        await axios(
            {
                url: environmentVariables.getuserdetails,
                method: 'get'
            }
        ).then(response => {
            let newResponse = JSON.parse(response.data.body)
            if (newResponse.length !== 0) {
                const findUser = newResponse.find(({ email_address }) => email_address === user.email)
                if (findUser === undefined || findUser === null) {
                }
                else {
                    document.getElementById("checkBoxAgree").checked = true;
                    setDisableCheckBox(true);
                    setName(findUser.user_name)
                    if (findUser.login_status === "AgreementAgreed") {
                        history.push({ pathname: '/createprofile' });
                    }
                }
            }
            else {
                history.push({ pathname: '/useragreement' });
            }
        })
            .catch(error => {
            })
    }
    // Function to handle checkbox change
    const handleCheckBoxChanage = (event) => {
        setChecked(event.target.checked);
    };

    // FUnction to handle textbox change
    function handletxtBoxChange(val) {
        setName(val.target.value)
    }

    // Function to save values into db and validation
    async function handleRepostChanges() {
        if (validate()) {
            let listValues =
            {
                "user_name": name,
                "email_address": user.email,
                "time_stamp_agreement": moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                "login_status": "AgreementAgreed"
            }
            await axios({
                url: environmentVariables.useragreement,
                method: "post",
                data: listValues,
            }).then(response => {
                history.push({
                    pathname: '/createprofile',
                });
            })
                .catch(error => {
                })

        }
    }

    // Function to handle validation
    const validate = () => {
        let temp = {}
        temp.name = name ? "" : "error"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "");
    }
    return (
        <div style={styles.header}>
            <div style={styles.content}>
                <br />
                <LogoComponent />
                <br />
                <h2 style={{ display: 'flex', justifyContent: 'center', color: "white" }}>Step 2/4</h2>
                <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100%' }}>
                    <Grid container xs={12} sm={6} alignItems="center" direction="column" style={{ height: "60%" }}>
                        <Paper elevation={3} style={{ width: "100%", height: "100%", display: 'flex' }}>
                            <Grid container direction="row">
                                <div style={{ width: "100%", height: 20 }} />
                                <div style={{ display: 'flex', width: "100%" }}>
                                    <Box style={{ width: "100%", textAlign: "center" }} paddingLeft={8} paddingRight={8}>
                                        <h3><font color="black"> Subscription Agreement</font></h3>
                                    </Box>
                                </div>
                                <div style={{ width: "100%", height: 10 }} />
                                <div style={{ display: 'flex', width: "100%" }}>
                                    <Box style={{ width: "100%", height: 400, display: 'flex', overflowY: "scroll" }} paddingLeft={8} paddingRight={8}>
                                        <Grid direction="row">
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                <p><b>LOADEO</b></p>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                <p><b>SUBSCRIPTION AGREEMENT FOR USE OF THE LOADEO LOAD BOARD</b></p>
                                            </div>
                                            <div style={{ display: 'flex', width: "100%" }}>
                                                <Grid direction="row">
                                                    <p><b>Last updated March 2022</b></p>
                                                    <p style={{ textAlign: "justify" }}>This Subscription Agreement (this “Agreement”) is between you and Loadeo, LLC (“Loadeo” or “we”).
                                                        This Agreement governs your subscription to and use of the Loadeo load board,
                                                        whether through the use of Loadeo’s website,
                                                        mobile applications or any other sites hosted by our service providers on our behalf (collectively the “Loadeo Services”).
                                                    </p>
                                                    <p><b>PLEASE READ THE FOLLOWING AGREEMENT CAREFULLY BEFORE USING OUR LOADEO SERVICES.</b></p>
                                                    <p style={{ textAlign: "justify" }}>All users of our Loadeo Services agree that access to and use of our Loadeo Services is subject to This Agreement and other applicable law.
                                                        If you do not agree to these terms and conditions, please do not use this site.
                                                        You acknowledge that you have the authority on behalf of the entity for which you subscribe to and use the Loadeo Services to bind the entity to this Agreement.
                                                    </p>
                                                    <p><b>Creating an Account</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        In order to use the Loadeo Services, you must create an account.   There is a separate enrollment application for carriers and brokers.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        Carriers will need to provide the following information in order to create an  account: MC Number, Business name, Business address, Email address, and Phone number.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        Brokers will need to provide the following information in order to create an account: MC Number, Business name, Business address, Email address, and Phone number.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        Once Loadeo receives your application, it will be reviewed for approval by Loadeo.  Loadeo reserves the right to accept or reject any application for any reason or no reason.  Until your application is approved, you are not allowed to use the Loadeo Services.  Moreover, Loadeo reserves the right to rescind such approval and terminate your access to the Loadeo Services at any time and for any reason or no reason.
                                                    </p>
                                                    <p><b>Use of the Services</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        Loadeo provides the Loadeo Services as a platform to connect carriers and brokers.  When you post your availability as a carrier or an available load as a broker, you warrant that you are postings are being done in good faith. You will not post false or misleading information on the Loadeo Services.You, as a carrier or a broker, however, are solely responsible for consummating any transactions with other users of the Loadeo Services.This means that you are solely responsible for conducting due diligence on the carriers you hire or the brokers you engage. Loadeo is not in a position and cannot, even with the application process, evaluate all of the users of the Loadeo Service. Loadeo does not conduct background or credit checks on users of the Loadeo Service.
                                                    </p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        The Loadeo Services are made available to you solely to connect with carriers and brokers.  No other uses of ours Loadeo Services is permitted.  Harassment in any manner or form on our Loadeo Services, including via e-mail, chat, or by use of obscene or abusive language, is strictly forbidden. Impersonation of others, including a Loadeo or other employee, host, or representative, as well as other users on our Loadeo Services are prohibited. You may not upload to, distribute, or otherwise publish through the Loadeo Services any content which is libelous, defamatory, obscene, threatening, invasive of privacy or publicity rights, abusive, illegal, or otherwise objectionable which may constitute or encourage a criminal offense, violate the rights of any party or which may otherwise give rise to liability or violate any law. You may not upload commercial content on our Loadeo Services or use our Loadeo Services to solicit others to join or become members of any other commercial online service or other organization.
                                                    </p>
                                                    <p><b>Term</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        This Agreement is applicable to you upon your accessing our Loadeo Services. This Agreement, or any part of it, may be modified or terminated by Loadeo without notice at any time, for any reason or no reason.  Your continued use of our Loadeo Services is subject to the then current Agreement in effect. The provisions relating to Copyrights, Trademark, Disclaimer of Warranties, Limitation of Liability, Indemnification, and Miscellaneous, shall survive any termination.
                                                    </p>
                                                    <p><b>Posting Errors</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        Loadeo is not responsible for any errors or omissions on the Loadeo Services, whether by Loadeo or any of its users.  You agree to post accurate information about your availability as a carrier or an available load as a broker.  Loadeo does not warrant that descriptions or other content published on the load board or on any other Loadeo Services is accurate, complete, reliable, current, or error-free.
                                                    </p>
                                                    <p><b>Intellectual Property</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        The entire content included in or published ono our Loadeo Services, including but not limited to text, graphics or code is copyrighted as a collective work under the United States and other copyright laws, and is the property of Loadeo. The collective work includes works that are licensed to Loadeo. You may display and, subject to any expressly stated restrictions or limitations relating to specific material, download or print portions of the material from the different areas of our Loadeo Services solely for your own use, or to place an order with Loadeo. Any other use, including but not limited to the reproduction, distribution, display or transmission of the content of our Loadeo Services is strictly prohibited, unless authorized by Loadeo. You further agree not to change or delete any proprietary notices from materials downloaded from the Site. 2022 © Loadeo, LLC.  ALL RIGHTS RESERVED.All trademarks, service marks and trade names of Loadeo used on our Loadeo Services are registered or common law trademarks of Loadeo or its suppliers.
                                                    </p>
                                                    <p><b>Warranty Disclaimer</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        THE LOADEO SERVICES AND THE CONTENT ON THE LOADEO SERVICES ARE PROVIDED BY LOADEO "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. EXCEPT AS EXPRESSLY STATED HEREIN, LOADEO MAKES NO REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS OR IMPLIED, OF ANY KIND WITH RESPECT TO ANY POSTINGS OR PUBLICATIONS ON THE LOAD BOARD OR ANY OTHER LOADEO SERVICES.  LOADEO EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND WITH RESPECT TO THE LOADEO SERVICES, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. LOADEO DOES NOT REPRESENT OR WARRANT THAT THE LOAD DESCRIPTIONS OR OTHER CONTENT ON THE LOADEO SERVICES IS ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE.  LOADEO DOES NOT REPRESENT OR WARRANT THAT THE FUNCTIONS CONTAINED IN THE LOADEO SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT THE DEFECTS WILL BE CORRECTED, OR THAT THE LOADEO SERVICES OR THE SERVER THAT MAKES THE LOADEO SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. BY USING THE LOADEO SERVICES, YOU AGREE THAT IN NO EVENT SHALL LOADEO, ITS PARENTS AND SUBSIDIARIES, AND THEIR DIRECTORS, OFFICERS, EMPLOYEES OR OTHER REPRESENTATIVES BE LIABLE FOR SPECIAL, INDIRECT, CONSEQUENTIAL, OR PUNITIVE DAMAGES RELATED TO YOUR USE OF THE LOADEO SERVICES.
                                                    </p>
                                                    <p><b>Limitation of Liability</b></p>
                                                    <p style={{ textAlign: "justify" }}>IN NO EVENT SHALL LOADEO, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES ON THE LOADEO SERVICES, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE LOADEO SERVICES, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE LOADEO SERVICES, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE LOADEO SERVICES BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT ON THE LOADEO SERVICES OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT PUBLISHED, POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE LOADEO SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT LOADEO IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  LOADEO’S LIABILITY TO YOU IS LIMITED TO THE AMOUNT YOU PAID TO LOADEO FOR USE OF THE LOADEO SERVICES IN THE PRECEEDING SIX (6) MOMTHS OR ONE-DOLLAR ($1.00), WHICH EVER IS GREATER.</p>
                                                    <p><b>
                                                        Indemnification
                                                    </b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS LOADEO, ITS DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS (COLLECTIVELY THE "INDEMNIFIED PARTIES") FROM AND AGAINST ANY AND ALL CLAIMS, DAMAGES, LOSSES, LIABILITIES, COSTS OR DEBT, AND EXPENSES (INCLUDING REASONABLE ATTORNEYS’ FEES) THAT ARISE OR RESULT FROM (I) YOUR USE OF AND ACCESS TO THE LOADEO SERVICES; (II) YOUR VIOLATION OF THIS AGREEMENT, INCLUDING, BUT NOT LIMITED TO, ANY OBLIGATION, REPRESENTATION, OR WARRANTY MADE HEREIN; (III) YOUR VIOLATION OF ANY THIRD PARTY RIGHT, INCLUDING WITHOUT LIMITATION ANY INTELLECTUAL PROPERTY RIGHT, PROPERTY RIGHT, OR PRIVACY RIGHT; AND (IV) YOUR NEGLIGENCE.  THIS DEFENSE AND INDEMNIFICATION OBLIGATION WILL SURVIVE TERMINATION OF THIS AGREEMENT AND YOUR USE OF THE LOADEO SERVICES.
                                                    </p>
                                                    <p><b>
                                                        Third-Party Links
                                                    </b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        In an attempt to provide increased value to our visitors, Loadeo may link to websites operated by third parties. However, even if the third party is affiliated with Loadeo, Loadeo has no control over these linked sites, all of which have separate privacy and data collection practices, independent of Loadeo. These linked sites are only for your convenience and therefore you access them at your own risk. Nonetheless, Loadeo seeks to protect the integrity of its Loadeo Services and the links placed upon it, and therefore, requests feedback on its Loadeo Services and sites that link to the Loadeo Services as well (including if a specific link does not work).
                                                    </p>

                                                    <p><b>Notice</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        Loadeo may deliver notice to you by means of e-mail, a general notice on the Loadeo Services, or by other reliable method to the address you have provided to Loadeo.  You may contact our customer service department anytime using the contact information on the website or mobile application of the Loadeo Services.
                                                    </p>
                                                    <p><b>Miscellaneous</b></p>
                                                    <p style={{ textAlign: "justify" }}>
                                                        This Agreement comprises the entire agreement between you and Loadeo with respect to your use of the Loadeo Services and supersedes all prior agreements between the parties regarding the subject matter contained herein.  This Agreement shall be governed by, and construed and enforced in accordance with, the laws of the State of Iowa, without regard to the choice of law provisions of that State. Any claim relating to This Agreement must be brought within one year of the date on which the claim arises. Any dispute between the parties relating to This Agreement shall be resolved only in the Iowa District Court for Linn County or the United States District Court for the Northern District of Iowa (Cedar Rapids Division).  In the event that any provision of This Agreement becomes or is declared by a court of competent jurisdiction to be illegal, unenforceable or void, This Agreement shall continue in full force and effect without said provision.  You agree that money damages would not be a sufficient remedy for breach of This Agreement and Loadeo shall be entitled to specific performance for any such breach in addition to any other remedies available to it at law or in equity. No waiver of any term in This Agreement shall be deemed a further or continuing waiver of such term or any other term.
                                                    </p>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Box>
                                </div>
                                <div style={{ width: "100%", height: 25 }} />
                                <div style={{ display: 'flex', width: "100%" }}>
                                    <Box style={{ width: "100%", display: 'flex' }} paddingLeft={8} paddingRight={8}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={disableCheckBox}
                                                    checked={checkedAgree}
                                                    onChange={handleCheckBoxChanage}
                                                    name="checkBoxAgree"
                                                    color="primary"
                                                    id="checkBoxAgree"
                                                />
                                            }
                                            label={<label>I agree to the subscription agreement and privacy policy<Link href="/privacypolicy" target="_blank" variant="body2" style={{ margin: 0 }}> (Read privacy policy Here)</Link></label>}
                                        />
                                    </Box>
                                </div>
                                <div style={{ width: "100%", height: 10 }} />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                        <Grid container direction="row">
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <TextField label="Enter Your Full Name" disabled={disableCheckBox} placeholder="Full Name" onChange={handletxtBoxChange} value={name} error={errors.name} ></TextField>
                                                </Box>
                                            </div>
                                            <div style={{ width: "100%", height: 10 }} />
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                                <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button disabled={!checkedAgree} variant="contained" onClick={handleRepostChanges} type="submit" value="Submit" style={{ backgroundColor: (checkedAgree) ? "black" : "gray", color: "white", textTransform: "none", maxWidth: '170px', maxHeight: '30px', minWidth: '170px', minHeight: '30px' }}>
                                                        Agree
                                                    </Button>
                                                </Box>
                                            </div>
                                            <div style={{ width: "100%", height: 20 }} />
                                        </Grid>
                                </div>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default UserAgreement;