import React from 'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb1 from '../../components/bredcrumb1'
import CompanyProfile from '../../components/Pages/CompanyProfile';
import Footer from '../../components/Footer'


const CompanyProfilePage = () => {
    return (
        <div className="price-sec">
            <Navbar />
            <Breadcumb1 bdtitle={'Company Profile'} />
            <CompanyProfile />
            <Footer />
        </div>
    )
}

export default CompanyProfilePage;