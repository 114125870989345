import React from 'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb1 from '../../components/bredcrumb1'
import Admin from '../../components/Admin'
import Footer from '../../components/Footer'


const AdminPage = () => {
    return (
        <div className="price-sec">
            <Navbar />
            <Breadcumb1 bdtitle={'Admin'} />
            <Admin />
            <Footer />
        </div>
    )
}

export default AdminPage;