import React from 'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb1 from '../../components/bredcrumb1'
import SystemAdmin from '../../components/SystemAdmin'
import Footer from '../../components/Footer'


const CarrierPage = () => {
    return (
        <div className="price-sec">
            <Navbar />
            <Breadcumb1 bdtitle={'Carrier'} />
            <SystemAdmin />
            <Footer />
        </div>
    )
}

export default CarrierPage;