import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const PromptComponent = (message = "Changes you made may not be saved") => {
    const [showPrompt, setShowPrompt] = useState(false)

    //detecting browser closing
    useEffect(() => {
        window.onbeforeunload = showPrompt && (() => message);

        return () => {
            window.onbeforeunload = null;
        }
    }, [showPrompt, message])

    const routerPrompt = <Prompt when={showPrompt} message={message} />;
    return [routerPrompt, () => setShowPrompt(true), () => setShowPrompt(false)]
}
export default PromptComponent;
