export const MCVerifyIcon = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 720 731" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M445.618 163.5C435.218 112.3 388.618 100.166 366.618 100.5H184.618M440.618 548.5C424.218 594.9 384.451 602.166 366.618 600H98.6178C23.8177 603.6 8.45108 546.166 10.1177 517V179.5C10.9511 151 29.8177 95.2996 98.6178 100.5H184.618M82.1177 202.5H274.618M82.1177 300.5H229.118M82.1177 399H229.118M82.1177 497.5H274.618M184.618 100.5C186.784 68.4997 205.818 5.79977 264.618 10.9998H445.618C463.951 14.4998 501.518 33 505.118 79V163.5" stroke="black" stroke-width="20" />
            <path d="M643 358C643 450.123 571.828 524 485 524C398.172 524 327 450.123 327 358C327 265.877 398.172 192 485 192C571.828 192 643 265.877 643 358Z" stroke="black" stroke-width="20" />
            <path d="M615.5 501L539 546.5L597.5 647L674 602L615.5 501Z" fill="black" />
            <path d="M681.5 615L605 660.5L621.521 690.753C633.545 712.77 661.403 720.469 683.026 707.749C703.651 695.617 710.959 669.326 699.552 648.291L681.5 615Z" fill="black" />
            <path d="M459.467 432C458.199 432.002 456.943 431.754 455.771 431.269C454.6 430.784 453.536 430.072 452.641 429.175L394.703 371.317C392.947 369.498 391.977 367.064 392 364.538C392.024 362.012 393.039 359.596 394.828 357.81C396.617 356.023 399.036 355.009 401.565 354.986C404.095 354.963 406.533 355.932 408.354 357.684L459.467 408.721L568.513 299.827C569.41 298.931 570.474 298.22 571.645 297.735C572.817 297.25 574.072 297 575.34 297C576.608 297 577.864 297.249 579.036 297.733C580.208 298.217 581.272 298.927 582.169 299.822C583.066 300.717 583.778 301.78 584.264 302.95C584.749 304.12 585 305.373 585 306.64C585 307.906 584.751 309.16 584.266 310.33C583.781 311.5 583.07 312.564 582.174 313.459L466.297 429.175C465.402 430.073 464.337 430.785 463.165 431.27C461.993 431.755 460.736 432.003 459.467 432V432Z" fill="black" />
        </svg>

    )
}