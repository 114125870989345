import React from "react";
import "./HeaderChat.css";
import Avatar from '@mui/material/Avatar';

/* Function to Convert a String to PascalCase*/
function pascalCase(str) {
  return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

export default function HeaderChat(props) {
  return (
    <div className="HeaderChat">
      <div className="HeaderChatLeft">
        <Avatar src={props.avatar}>{props.name.charAt(0).toUpperCase()} </Avatar>
        {/* <div className="Title">{props.name} {(props.companyName !== undefined && props.companyName !== "") && ("(" + pascalCase(props.companyName) + ")")} */}
        <div className="Title">{props.name} {(props.companyName !== undefined && props.companyName !== "") && ("(" + props.companyName + ")")}
        </div>
      </div>
      <div className="HeaderChatRight">
        {
          (props.mcnumber !== "") && (
            <div>
            <span className="TitleMcNumber">{props.mcnumber}</span>
            </div>
          )
        }
      </div>
    </div>
  );
}
