import React from 'react';
import './style.css'

const Breadcumb1 = (props) => {

    return (
        <div className="breadcumb-area1">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcumb-wrap text-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breadcumb1;
