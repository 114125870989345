import { Button } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "./index.css";
/*****************************Book It*************************************/
export const BookItButton = () => {
    return (
        <Button variant="contained" className="ButtonColor" disabled>
            Book It
        </Button>
    )
}

/***************************Accept and Expired Counter Offer Button***************************** */
export const AcceptCounterOffer = (props) => {
    return (
        <Button variant="outlined" className="ButtonColorGreen"> $ {props.counteroffer} </Button>
    )
}

export const ExpiredCounterOffer = (props) => {
    return (
        <Button variant="outlined" className="ButtonColorRed" > $ {props.counteroffer}</Button>
    )
}
/*****************Sign in Button For 401 and 404 ************************************************/
export const SignIn = () => {
    const { loginWithRedirect } = useAuth0();

    // function to handle sign in button click
    const handleSignInUser = () => {
        loginWithRedirect()
        window.localStorage.removeItem('LoadId');
    }

    return (
        <button onClick={() => handleSignInUser()} class="button2">Sign In</button>
    )
}

/*****************************FeedBack and User Profile***********************************************/
export const Home = () => {
    const history = useHistory();
    return (
        <Button style={{ color: "#142440" }} onClick={() => { history.push({ pathname: '/callback' }) }}>
            <ArrowBackIosIcon color="#142440" /> Home
        </Button>
    )
}
