import React, { useState, useEffect, } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import MUIDataTable from "mui-datatables";
import MuiAlert from "@material-ui/lab/Alert";
import { Row, Col } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import {
    Button,
    Box,
    Grid,
    IconButton,
    Snackbar,
    CssBaseline,
    TextField,
    Typography,
    Card,
    CardContent,
    Tooltip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Paper,
} from "@material-ui/core";
import { environmentVariables } from "../../environment"
import axios from 'axios';
import { createMuiTheme, MuiThemeProvider, useTheme, makeStyles } from "@material-ui/core/styles";
import "./index.css"
import Swal from 'sweetalert2';

// import statements to have all icons

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import MUIDrawer from "../Drawer";

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}));

const CompanyAdmin = () => {

    //------------------------- Declaring all the constants ------------------------------
    //Constants to handle snackbar
    const [colorseverity, setColorseverity] = useState("");
    const [snackBarMessageText, setSnackBarMessageText] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);

    //Constants to handle MUIDatatable
    const [mainGridPercent, setMainGridPercent] = useState("100%");
    const [gridPercent, setGridPercent] = useState("100%");

    //Constants to handle Side Panes
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isFieldEdited, setIsFieldEdited] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [clickRowData, setClickRowData] = useState([]);
    const [clickNewUserRowData, setClickNewUserRowData] = useState([]);

    const classes = useStyles();

    const history = useHistory();
    //Constants to handle user details side pane textfields
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [userRole, setUserRole] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyMcNumber, setCompanyMcNumber] = useState("");
    const [changedValue, setChangedValue] = useState(false);
    const [mcNumber, setMcNumber] = useState("");

    const theme = useTheme();
    // Constants to handle new user request side pane text fields
    const [newUserAddress, setNewUserAddress] = useState("");
    const [newUserState, setNewUserState] = useState("");
    const [newUserCity, setNewUserCity] = useState("");
    const [newUserFirstName, setNewUserFirstName] = useState("");
    const [newUserLastName, setNewUserLastName] = useState("");
    const [newUserName, setNewUserName] = useState("");
    const [newUsercompanyName, setNewUserCompanyName] = useState("");
    const [newUserRole, setNewUserRole] = useState("");
    const [companyRole, setCompanyRole] = useState("");
    const [newUserMobileNumber, setNewUserMobileNumber] = useState("");
    const [newUserEmailAddress, setNewUserEmailAddress] = useState("");
    const [isComapnyAdminDeletable, setIsCompanyAdminDeletable] = useState(false);
    const [isComapnyAdminSuspendable, setIsCompanyAdminSuspendable] = useState(false);
    const [noOfAcceptedCompanyAdmins, setNoOfAcceptedCompanyAdmins] = useState(false)

    //Constant to handle Company Name
    const [userCompanyName, setUserCompanyName] = useState("");

    //Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState("");

    //constant to handle logged in company name
    const [loggedInCompanyName, setLoggedInCompanyName] = useState("");

    //constant to handle logged in company Role
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState("");

    // Constant to handle errors
    const [errors, setErrors] = useState({});
    const { user } = useAuth0();

    //Constants to handle MUIDatatable
    const [rowData, setRowData] = useState([]);
    const [rowUserData, setRowUserData] = useState([]);

    //constant to handle mui data table loader
    const [loadingNewUsers, setLoadingNewUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);

    // constant to handle disabling button first click
    const [disableApproveButton, setDisableApproveButton] = useState(false)
    const [disableRejectButton, setDisableRejectButton] = useState(false)

    const [openInviteCard, setOpenInviteCard] = useState(false);

    // Function to open form for inviting user
    const handleOpenForm = () => {
        setOpenInviteCard(true)
    }
    // Function to handle email change
    const handleEmailIdChange = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            // setEmailId(event.target.value)
        }
    }
    //function to close secondary pane of invite user
    const close = () => {
        setOpenInviteCard(false);
    };

    //options of userdetails table
    const options = {
        onRowClick: (clickRowData, rowMeta) =>
            UserDetails(clickRowData, rowMeta),
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingUsers ?
                    <CircularProgress /> :
                    `There are no users for ${pascalCase(loggedInCompanyName)}`
            },
        }
    };

    //options of newuser details table
    const options1 = {
        onRowClick: (clickNewUserRowData, rowState) =>
            EditChanges(clickNewUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: false,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingNewUsers ?
                    <CircularProgress /> :
                    `There are no new user request for ${pascalCase(loggedInCompanyName)}`
            },
        }
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: "none",
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        "&:nth-child(odd)": {
                            backgroundColor: "#ECECEC",
                        },
                        "&:hover": { backgroundColor: "#aab5be!important" },
                        "&:focus": { backgroundColor: "#000!important" },
                    },
                },
                MUIDataTableBodyCell: {},
            },
        });


    //column values for newuser table
    const columnDefs = [
        { label: "UserId", name: "user_id", options: { display: false } },
        {
            name: "user_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        pascalCase(rowuserdata.rowData[1])
                    )
                }
            }
        },
        {
            name: "email_address",
            label: "Email Id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "mobile_number",
            label: "Mobile",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "user_role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[4] === "CompanyAdmin" ? "Company Admin" : rowuserdata.rowData[4] === "Broker&Carrier" ? "Broker & Carrier" : rowuserdata.rowData[4])
                }
            }
        },
    ]

    //column values for UserDetails table
    const columns = [
        { label: "UserId", name: "user_id", options: { display: false } },
        {
            name: "user_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        pascalCase(rowuserdata.rowData[1])
                    )
                }
            }
        },
        {
            name: "email_address",
            label: "Email Id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "mobile_number",
            label: "Mobile Number",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "user_role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[4] === "CompanyAdmin" ? "Company Admin" : rowuserdata.rowData[4] === "Broker&Carrier" ? "Broker & Carrier" : rowuserdata.rowData[4])
                }
            }
        },
        {
            name: "login_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[5] === "ProfileAccepted" ? "Accepted" : rowuserdata.rowData[5] === "ProfileRejected" ? "Not Approved" : rowuserdata.rowData[5] === "ProfileSuspended" ? "Suspended" : "Activated"
                    )
                }
            }
        },
    ];

    useEffect(() => {
        checkUserRole();
    }, []);

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    // Function to getall response of both the tables
    function checkUserRole() {
        if (window.localStorage.ifUserExist) {
            if (window.localStorage.getItem('login_status') === "ProfileAccepted") {
                if (window.localStorage.getItem('user_role')) {
                    setLoggedInuserRole(window.localStorage.getItem('user_role'));
                }
                if (window.localStorage.getItem('company_role')) {
                    setLoggedInuserCompanyRole(window.localStorage.getItem('company_role'));
                }
                if (window.localStorage.getItem('userCompanyName')) {
                    setUserCompanyName(window.localStorage.getItem('userCompanyName'));
                }
                if (user.email === process.env.REACT_APP_ADMIN_USER || window.localStorage.getItem('user_role') === "CompanyAdmin") {
                    getAllResponse(window.localStorage.getItem('company_mc_number'))
                    getUserDetailsResponse(window.localStorage.getItem('company_mc_number'))
                }
                else {
                    history.push({ pathname: '/forbidden' });
                }
            }
            else {
                history.push({ pathname: '/callback' });
            }
        }
        else if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: '/callback' });
        }
    }

    // Function to get values of newuser Table
    async function getAllResponse(company_mc_number) {
        axios.get(
            environmentVariables.getnewusersforcompanydashboard + "?company_mc_number=" + company_mc_number,
        )
            .then(response => {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "UserNotPresent") {
                    if (newResponse.length === 0) {
                        setLoadingNewUsers(false)
                    }
                    else {
                        setLoadingNewUsers(true)
                    }
                    const userDetails = [];
                    newResponse.forEach(element => {
                        if (element.mc_numbers) {
                            const newUsermcNumbers = element["mc_numbers"][0]["mcNumber"]
                            userDetails.push({ ...element, "mc_number": newUsermcNumbers })
                        }
                    });
                    userDetails.sort((a, b) =>
                        b.time_stamp.localeCompare(a.time_stamp)
                    );
                    setRowData(userDetails);
                }
                else {
                    setRowData([]);
                    if (newResponse.length === 0) {
                        setLoadingNewUsers(false)
                    }
                    else {
                        setLoadingNewUsers(true)
                    }
                }
            })
            .catch((error) => {
            });
    }

    // Function to fetch data from api for User Details Company Name table
    async function getUserDetailsResponse(company_mc_number) {
        await axios({
            url:
                environmentVariables.getapproveduserdetailsforcompany +
                "?company_mc_number=" +
                company_mc_number,
            method: "get",
        })
            .then(response => {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "UserNotPresent") {
                    if (newResponse.length === 0) {
                        setLoadingUsers(false)
                    }
                    else {
                        setLoadingUsers(true)
                    }
                    const userDetails = [];
                    newResponse.forEach(element => {
                        if (element.mc_numbers) {
                            const mcNumbers = element["mc_numbers"][0]["mcNumber"]
                            userDetails.push({ ...element, "mc_number": mcNumbers })
                        }
                    });
                    userDetails.sort((a, b) =>
                        b.time_stamp.localeCompare(a.time_stamp)
                    );
                    setRowUserData(userDetails);
                }
                else {
                    setRowUserData([]);
                    if (newResponse.length === 0) {
                        setLoadingUsers(false)
                    }
                    else {
                        setLoadingUsers(true)
                    }
                }
            })
            .catch((error) => {
                console.log("fetch all user error", error)
            });
    }

    //function to handle validation for userdetail table
    const validate = () => {
        let temp = {}
        temp.state = state ? "" : "error"
        temp.city = city ? "" : "error"
        temp.userName = userName ? "" : "error"
        temp.firstName = firstName ? "" : "error"
        temp.lastName = lastName ? "" : "error"
        //temp.mobileNumber = mobileNumber ? "" : "error"
        temp.address = address ? "" : "error"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "");
    }

    //function to handle validation for newuser table
    const newUserValidate = () => {
        let temp = {}
        temp.newUserName = newUserName ? "" : "error"
        temp.newUserAddress = newUserAddress ? "" : "error"
        temp.newUserCity = newUserCity ? "" : "error"
        temp.newUserState = newUserState ? "" : "error"
        temp.newUserFirstName = newUserFirstName ? "" : "error"
        temp.newUserLastName = newUserLastName ? "" : "error"
        //temp.newUserMobileNumber = newUserMobileNumber ? "" : "error"
        temp.newUserRole = newUserRole ? "" : "error"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "");
    }

    // Function to handle pane close
    const closeSecondaryPane = () => {
        setMainGridPercent("100%");
        setIsPaneOpen(false);
    };

    // Function to handle MuiAlert
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //function to close secondary pane
    const closeSecondaryDetailPane = () => {
        setGridPercent("100%");
        setIsDetailOpen(false);
    };

    //function to close secondary pane of userdetails Table
    const closeSecondaryUserPane = () => {
        setGridPercent("100%");
        setIsDetailOpen(false);
    };

    //Function to set value for text field
    const handleChangedValue = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            setChangedValue(true);
        }
        else {
            setChangedValue(false);
        }
    }

    // Function to handle FirstName changes in NewUser Table
    const handleNewUserFirstNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserFirstName(event.target.value);
        }
    };

    // Function to handle LastName changes in NewUser Table
    const handleNewUserLastNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserLastName(event.target.value);
        }
    };

    //Function to handle new user state
    const handleNewUserState = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserState(event.target.value);
        }
    };

    //Function to handle new user city
    const handleNewUserCity = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserCity(event.target.value);
        }
    };

    //Function to handle new user address
    const handleNewUserAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserAddress(event.target.value);
        }
    };

    //Function to handle user table phone no change
    const handleNewUserPhoneNoChange = (value, data, event, formattedValue) => {
        setNewUserMobileNumber(value)
        setIsFieldEdited(true);

    }

    // Function to handle FirstName changes in UserDetails
    const handleFirstNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setFirstName(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to handle LastName changes in UserDetails
    const handleLastNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setLastName(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to show Details
    const handleCompanyName = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyName(event.target.value);
        }
    };

    //Function to handle state change
    const handleState = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setState(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to handle City Changes
    const handleCity = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCity(event.target.value);
            setIsFieldEdited(true);
        }
    };

    //Function to handle address change
    const handleAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setAddress(event.target.value);
            setIsFieldEdited(true);
        }
    };

    //Function to handle user table phone no change
    const handleUserPhoneNoChange = (value, data, event, formattedValue) => {
        setMobileNumber(value)
        setIsFieldEdited(true);
    }

    //function to update the changes on save changes of userdetails table
    async function EditUserDetails(rowValues) {
        if (validate()) {
            if ((mobileNumber.length === 14 || mobileNumber.length === 13 || mobileNumber.length === 10) && mobileNumber !== "") {
                let completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === userId)
                if ((userRole === "Carrier") || (userRole === "Broker&Carrier")) {
                    completeSelectedRowData = { ...completeSelectedRowData, 'carrier_preferred': 'Preferred' }
                }
                let listValues = completeSelectedRowData;
                listValues.user_name = userName;
                listValues.first_name = firstName;
                listValues.last_name = lastName;
                listValues.address = address;
                listValues.city = city;
                listValues.state = state;
                listValues.mobile_number = mobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
                listValues.user_role = userRole;
                listValues.new_user_status = "false";
                await axios({
                    url: environmentVariables.editcompanyadmin,
                    method: "put",
                    data: listValues
                })
                    .then((response) => {
                        let newResponse = JSON.parse(response.data.body)
                        getAllResponse(newResponse.company_mc_number);
                        getUserDetailsResponse(newResponse.company_mc_number);
                        setColorseverity("success")
                        setSnackBarMessageText(rowValues[1] + " has been Updated")
                        setOpenSnackBar(true);
                        closeSecondaryUserPane()
                    })
            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    }

    //function to trigger on click of approve in newuser table
    async function approveUserInformation(rowValues) {
        setDisableApproveButton(true)
        if (newUserValidate()) {
            if ((newUserMobileNumber.length === 14 || newUserMobileNumber.length === 13 || newUserMobileNumber.length === 10) && newUserMobileNumber !== "") {
                let completeSelectedRowData = rowData.find(({ user_id }) => user_id === userId)
                if ((userRole === "Carrier") || (userRole === "Broker&Carrier")) {
                    completeSelectedRowData = { ...completeSelectedRowData, 'carrier_preferred': 'Preferred' }
                }
                let listValues = completeSelectedRowData;
                listValues.user_name = newUserName;
                listValues.address = newUserAddress;
                listValues.city = newUserCity;
                listValues.state = newUserState;
                listValues.first_name = newUserFirstName;
                listValues.last_name = newUserLastName;
                listValues.mobile_number = newUserMobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
                listValues.user_role = newUserRole;
                listValues.new_user_status = "false";
                listValues.login_status = "ProfileAccepted";
                await axios({
                    url: environmentVariables.updateloadeouserdetails + "?page=" + "CompanyAdmin",
                    method: "patch",
                    data: listValues
                })
                    .then((response) => {
                        let newResponse = JSON.parse(response.data.body)
                        getAllResponse(newResponse.company_mc_number);
                        getUserDetailsResponse(newResponse.company_mc_number);
                        setColorseverity("success")
                        setSnackBarMessageText(rowValues[1] + " has been approved")
                        setOpenSnackBar(true);
                        closeSecondaryPane()
                        axios({
                            url: environmentVariables.approveusernotification,
                            method: "put",
                            data: {
                                email_address: completeSelectedRowData.email_address,
                            },
                        })
                    })
                    .catch((error) => {
                    });
            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
        setDisableApproveButton(false)
    }

    //function to trigger on click of reject in newuser table
    async function rejectedUser(rowValues) {
        setDisableRejectButton(true)
        let completeSelectedRowData = rowData.find(({ user_id }) => user_id === userId)
        let listValues = completeSelectedRowData;
        listValues.mobile_number = newUserMobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
        listValues.login_status = "ProfileRejected"
        listValues.new_user_status = "false"
        listValues = { ...listValues }
        listValues = { ...listValues, "rejected_by": "CompanyAdmin" };
        await axios({
            url: environmentVariables.updateloadeouserdetails + "?page=" + "CompanyAdmin",
            method: "patch",
            data: listValues
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data.body)
                getAllResponse(newResponse.company_mc_number);
                getUserDetailsResponse(newResponse.company_mc_number);
                closeSecondaryPane()
                setColorseverity("error")
                setSnackBarMessageText(rowValues[1] + " has been rejected")
                setOpenSnackBar(true);
                axios({
                    url: environmentVariables.sendRejectedMail,
                    method: "patch",
                    data: {
                        email_address: completeSelectedRowData.email_address,
                    },
                })
                    .catch((errorEmail) => {
                        console.error("Details havent Sent");
                    });
            })
        setDisableRejectButton(false)
    }

    // Function to show pop-up while Suspend
    function handleRejectConfirmClick(rowValues) {
        handleClose()
        Swal.fire({
            text: "Are you sure you want to reject " + rowValues[1] + "?",
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                rejectedUser(rowValues)
            }
        })
    }

    // Function to show Details
    function UserDetails(rowValues) {
        setClickRowData(rowValues);
        setIsEditOpen(true);
        setGridPercent("45%");
        handleClose()
        if (rowUserData !== undefined && rowUserData !== null) {
            const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setUserName(completeSelectedRowData.user_name);
                setAddress(completeSelectedRowData.address);
                if (completeSelectedRowData.first_name === undefined) {
                    setFirstName("")
                }
                else {
                    setFirstName(completeSelectedRowData.first_name)
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setLastName("")
                }
                else {
                    setLastName(completeSelectedRowData.last_name)
                }
                setCity(completeSelectedRowData.city);
                setCompanyName(completeSelectedRowData.company_name);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
                setEmailAddress(completeSelectedRowData.email_address);
                setMobileNumber(completeSelectedRowData.mobile_number);
                setState(completeSelectedRowData.state);
                setUserRole(completeSelectedRowData.user_role);
                setCompanyRole(completeSelectedRowData.company_role);
                setUserId(completeSelectedRowData.user_id);
                setMcNumber(completeSelectedRowData.mc_number);
                setIsDetailOpen(true);
                if (completeSelectedRowData.user_role === "CompanyAdmin" && completeSelectedRowData.login_status === "profileRejected") {
                    setIsCompanyAdminDeletable(true);
                }
                else if (completeSelectedRowData.user_role === "CompanyAdmin") {
                    let mcNumer = completeSelectedRowData.company_mc_number;
                    let noOfActiveCompanyAdmins = 0;
                    let noOfCompanyAdmins = 0;
                    let noOfSuspendedCompanyAdmins = 0;
                    for (let i = 0; i < rowUserData.length; i++) {
                        if ((rowUserData[i].company_mc_number === mcNumer && rowUserData[i].user_role === "CompanyAdmin" && rowUserData[i].login_status === "ProfileAccepted")) {
                            noOfActiveCompanyAdmins += 1;
                            if (noOfActiveCompanyAdmins <= 1) {
                                setIsCompanyAdminSuspendable(false);
                            }
                            else {
                                setIsCompanyAdminSuspendable(true);
                                setIsCompanyAdminDeletable(true);
                                setNoOfAcceptedCompanyAdmins(true);
                            }
                        }
                    }
                    for (let i = 0; i < rowUserData.length; i++) {
                        if (rowUserData[i].company_mc_number === mcNumer && rowUserData[i].user_role === "CompanyAdmin") {
                            noOfCompanyAdmins += 1;
                            if (noOfCompanyAdmins <= 1) {
                                setIsCompanyAdminDeletable(false);
                            }
                            else {
                                setIsCompanyAdminDeletable(true);
                            }
                        }
                    }
                    for (let i = 0; i < rowUserData.length; i++) {
                        if (rowUserData[i].company_mc_number === mcNumer && rowUserData[i].user_role === "CompanyAdmin" && rowUserData[i].login_status === "ProfileSuspended") {
                            noOfSuspendedCompanyAdmins += 1;
                            if (noOfActiveCompanyAdmins - noOfSuspendedCompanyAdmins === 1 || noOfActiveCompanyAdmins - noOfSuspendedCompanyAdmins === 0) {
                                setNoOfAcceptedCompanyAdmins(false)
                            }
                        }
                    }
                }
                else {
                    setIsCompanyAdminDeletable(true);
                    setIsCompanyAdminSuspendable(true);
                }
            }
        }
    }

    //function to trigger on click of edit in userdetail table
    const EditChanges = (selectedRowData, selectedRowState) => {
        setClickNewUserRowData(selectedRowData);
        setDisableApproveButton(false)
        setDisableRejectButton(false)
        setMainGridPercent("45%");
        if (selectedRowData !== undefined && selectedRowData !== null) {
            const completeSelectedRowData = rowData.find(({ user_id }) => user_id === selectedRowData[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setNewUserName(completeSelectedRowData.user_name);
                setNewUserAddress(completeSelectedRowData.address);
                setNewUserCity(completeSelectedRowData.city);
                setNewUserCompanyName(completeSelectedRowData.company_name);
                setCompanyRole(completeSelectedRowData.company_role)
                setNewUserEmailAddress(completeSelectedRowData.email_address);
                setNewUserMobileNumber(completeSelectedRowData.mobile_number);
                setNewUserState(completeSelectedRowData.state);
                if (completeSelectedRowData.first_name === undefined) {
                    setNewUserFirstName("")
                }
                else {
                    setNewUserFirstName(completeSelectedRowData.first_name)
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setNewUserLastName("")
                }
                else {
                    setNewUserLastName(completeSelectedRowData.last_name)
                }
                setNewUserRole(completeSelectedRowData.user_role);
                setUserId(completeSelectedRowData.user_id);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
                setIsPaneOpen(true);
            }
        }
    }

    function EditSelected(rowvalues) {
        setIsEditOpen(false)
        setGridPercent("45%");
        handleClose()
        if (rowUserData !== undefined && rowUserData !== null) {
            const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowvalues[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setUserName(completeSelectedRowData.user_name);
                setAddress(completeSelectedRowData.address);
                setCity(completeSelectedRowData.city);
                if (completeSelectedRowData.first_name === undefined) {
                    setFirstName("")
                }
                else {
                    setFirstName(completeSelectedRowData.first_name)
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setLastName("")
                }
                else {
                    setLastName(completeSelectedRowData.last_name)
                }
                setCompanyName(completeSelectedRowData.company_name);
                setEmailAddress(completeSelectedRowData.email_address);
                setMobileNumber(completeSelectedRowData.mobile_number);
                setState(completeSelectedRowData.state);
                setUserRole(completeSelectedRowData.user_role);
                setUserId(completeSelectedRowData.user_id);
                //setMcNumber(completeSelectedRowData.mc_number);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
            }
        }
    }

    // Function to show pop-up while delete
    function handleCancelButtonConfirmClick(rowValues) {
        handleClose()
        if (isFieldEdited) {
            Swal.fire({
                text: "Do you want to save the changes before cancelling",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    EditUserDetails(rowValues)
                    UserDetails(rowValues);
                }
            })
        }
        else {
            UserDetails(rowValues);
        }
    }

    // Function to suspend the user
    async function SuspendUser(rowValues) {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0])
        let listValues = completeSelectedRowData;
        listValues.login_status = "ProfileSuspended"
        listValues = { ...listValues }
        await axios({
            url: environmentVariables.updateloadeouserdetails + "?page=" + "CompanyAdmin",
            method: "patch",
            data: listValues
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data.body)
                getAllResponse(newResponse.company_mc_number);
                getUserDetailsResponse(newResponse.company_mc_number);
                setColorseverity("error")
                setSnackBarMessageText(rowValues[1] + " has been Suspended")
                setOpenSnackBar(true);
            })
        closeSecondaryUserPane()
    }

    // Function to show pop-up while Suspend
    function handleSuspendConfirmClick(rowValues) {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0])
        handleClose()
        if (user.email === completeSelectedRowData.email_address) {
            Swal.fire({
                text: "Cannot suspend own account",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            })
        }
        else if (isComapnyAdminSuspendable) {
            Swal.fire({
                text: "Are you sure you want to suspend " + rowValues[1] + "?",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    SuspendUser(rowValues)
                }
            })
        }
        else (
            Swal.fire({
                text: "Cannot suspend Company Admin",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            })
        )
    }

    // Function to activate user
    async function ActivateUser(rowValues) {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0])
        let listValues = completeSelectedRowData;
        listValues.login_status = "ProfileAccepted"
        listValues = { ...listValues }
        await axios({
            url: environmentVariables.updateloadeouserdetails + "?page=" + "CompanyAdmin",
            method: "put",
            data: listValues
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data.body)
                getUserDetailsResponse(newResponse.company_mc_number);
                setColorseverity("success")
                setSnackBarMessageText(rowValues[1] + " has been Activated")
                setOpenSnackBar(true);
            })
        closeSecondaryUserPane()
    }

    // Function to show pop-up while Activate
    function handleActivateConfirmClick(rowValues) {
        handleClose()
        Swal.fire({
            text: "Are you sure you want to activate " + rowValues[1] + " ?",
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                ActivateUser(rowValues)
            }
        })
    }

    // Function to delete the user
    async function handleSoftDelete(rowValues) {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0])
        let listValues = completeSelectedRowData;
        listValues.login_status = "ProfileDeleted"
        listValues = { ...listValues }
        await axios({
            url: environmentVariables.editcompanyadmin + "?page=" + "CompanyAdmin",
            method: "patch",
            data: listValues
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data.body)
                getAllResponse(newResponse.company_mc_number);
                getUserDetailsResponse(newResponse.company_mc_number);
                setColorseverity("error")
                setSnackBarMessageText(rowValues[1] + " has been Deleted")
                setOpenSnackBar(true);
            })
        closeSecondaryUserPane()
    }

    // Function to show pop-up while delete
    function handleSoftDeleteConfirmClick(rowValues) {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0])
        handleClose()
        if (user.email === completeSelectedRowData.email_address) {
            Swal.fire({
                text: "Cannot delete own account",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            })
        }
        else if ((isComapnyAdminDeletable && completeSelectedRowData.login_status !== "ProfileAccepted") || (isComapnyAdminDeletable && noOfAcceptedCompanyAdmins && completeSelectedRowData.login_status === "ProfileAccepted") || (completeSelectedRowData.user_role !== "CompanyAdmin")) {
            Swal.fire({
                text: "Are you sure you want to delete " + rowValues[1] + " ?",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handleSoftDelete(rowValues)
                }
            })
        }
        else (
            Swal.fire({
                text: "Cannot delete Company Admin",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            })
        )
    }

    return (
        <div className={classes.root}>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={colorseverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <div className="main" style={{ padding: "40px 30px 10px 20px", width: "100%" }} hidden={!openInviteCard}>
                    <Paper elevation={3}  >
                        <Grid container spacing={3} style={{ marginRight: 60 }}>
                            <Grid item xs={4} style={{ fontSize: 20 }} >
                                <b style={{ paddingLeft: 50 }}>Invite User</b>
                            </Grid>
                            <Grid item xs={3}  >
                            </Grid>
                            <Grid container xs={5}  >
                                <IconButton onClick={close} style={{ marginLeft: "88%" }}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Card margin={(5, 1)} height={6} style={{ maxWidth: "80%", margin: "0 auto", paddingTop: "20px", paddingBottom: "20px" }} >

                            <CardContent>
                                <Grid container direction="row">
                                    <Grid container spacing={2}>

                                        <Grid item xs style={{ paddingLeft: "20%" }}>
                                            <TextField id="email" type='email' label="Email Address" placeholder="Enter email" variant="standard" style={{ width: "60%" }} error={errors.emailId} onChange={handleEmailIdChange} required />
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl style={{ width: "40%" }}>
                                                <InputLabel id="demo-simple-select-label">User Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select" >

                                                    <MenuItem value="CompanyAdmin">Company Admin</MenuItem>
                                                    <MenuItem hidden={loggedInuserCompanyRole === "Broker"} value="Carrier">Carrier User</MenuItem>
                                                    <MenuItem hidden={loggedInuserCompanyRole === "Broker"} value="Carrier">Driver User</MenuItem>
                                                    <MenuItem hidden={loggedInuserCompanyRole === "Carrier"} value="Carrier">Broker User</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} justify="flex-end" paddingTop={35} style={{ paddingTop: 5 }}>
                                    <Tooltip >
                                        <Button type='submit' variant="contained" style={{ textTransform: "none", marginRight: "10%", }}>Invite</Button>
                                    </Tooltip>
                                </Grid>
                            </CardContent>
                        </Card><br></br>
                    </Paper>
                </div>
                {/* <Button onClick={() => handleOpenForm()} type='submit' variant='contained' color="primary"
                    style={{
                        width: "10%", marginTop: "2%",
                        marginLeft: "80%", textTransform: "none"
                    }} >
                    Invite
                </Button> */}
                <Typography component={'div'} ><br></br>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: mainGridPercent }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={"New User List"}
                                    data={rowData}
                                    columns={columnDefs}
                                    options={options1}
                                />
                            </MuiThemeProvider>
                        </div><br></br>
                        {isPaneOpen && (
                            <React.Fragment>
                                <div style={{ width: "1%" }}></div>
                                <div style={{ width: "54%" }} border={1}>
                                    <Box
                                        boxShadow={3}
                                        style={{ width: "100%", overflowY: "scroll", maxHeight: "100%", height: "100%" }}
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={3} style={{ fontSize: 18 }} justify-content="center">
                                                <b>User Details</b>
                                            </Grid>
                                            <Grid container xs={5} justify="flex-end">
                                                <IconButton onClick={closeSecondaryPane}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Row >
                                            <Col sm>
                                            </Col>
                                        </Row>
                                        <div style={{ height: 5, paddingTop: "5%" }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Company Name" style={{ width: "85%" }} value={pascalCase(newUsercompanyName)}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" style={{ width: "85%" }} value={companyMcNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" style={{ width: "85%" }} value={companyRole}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Address *" style={{ width: "85%" }} error={errors.newUserAddress} value={pascalCase(newUserAddress)} onChange={handleNewUserAddress}>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="City *" style={{ width: "85%" }} error={errors.newUserCity} value={pascalCase(newUserCity)} onChange={handleNewUserCity}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="State *" style={{ width: "85%", paddingTop: "2%" }} error={errors.newUserState} value={pascalCase(newUserState)} onChange={handleNewUserState} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Email Address" style={{ width: "85%" }} value={newUserEmailAddress} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={user.email === newUserEmailAddress} label="First Name *" style={{ width: "85%" }} error={errors.newUserFirstName} value={pascalCase(newUserFirstName)} onChange={handleNewUserFirstNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={user.email === newUserEmailAddress} label="Last Name *" style={{ width: "85%" }} error={errors.newUserLastName} value={pascalCase(newUserLastName)} onChange={handleNewUserLastNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{ paddingTop: "3%" }} />
                                                        <PhoneInput
                                                            disabled={user.email === newUserEmailAddress}
                                                            value={newUserMobileNumber}
                                                            onChange={(value, data, event, formattedValue) => { handleNewUserPhoneNoChange(value, data, event, formattedValue) }}
                                                            localization={'us'}
                                                            onlyCountries={['us']}
                                                            country={'us'}
                                                            defaultCountry={"us"}
                                                            disableDropdown={true}
                                                            disableCountryCode={true}
                                                            placeholder='(xxx) xxx-xxxx'
                                                            error={errors.mobileNumber}
                                                            containerStyle={{
                                                                width: "80%",
                                                                border: "1px solid black"
                                                            }}
                                                            //dropdownStyle={{ height: '40%' }}
                                                            inputStyle={{ width: "100%" }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="User Role " style={{ width: "85%" }} value={newUserRole} ></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <div style={{ paddingTop: "5%" }}>
                                            <Grid container justify="space-around" spacing={4}>
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Button variant="contained" color="primary" disabled={disableApproveButton} style={{
                                                            position: "absolute",
                                                            margin: "2px, auto",
                                                            textTransform: "none",
                                                        }} onClick={() => { approveUserInformation(clickNewUserRowData) }} >
                                                            Approve
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Button variant="contained" color="primary" disabled={disableRejectButton} style={{
                                                            position: "absolute",
                                                            margin: "0px, auto",
                                                            textTransform: "none",
                                                        }} onClick={() => { handleRejectConfirmClick(clickNewUserRowData) }} >
                                                            Reject
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Grid style={{ fontSize: 14 }} xs={12}>
                                            <Grid container direction="row">
                                                <div style={{ paddingTop: "5%" }}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                        <div style={{ width: gridPercent }} id="Users">
                            <div style={{ paddingTop: "8%", paddingBottom: "25%" }} >
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"User Details"}
                                        data={rowUserData}
                                        columns={columns}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                        {isDetailOpen && (
                            <React.Fragment>
                                <div style={{ width: "1%" }}></div>
                                <div style={{ width: "54%", paddingBottom: "5%" }} border={1}>
                                    <div style={{ paddingTop: "7%" }} />
                                    <Box
                                        boxShadow={3}
                                        style={{ width: "100%", maxHeight: "90%", height: "90%" }}
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={3} style={{ fontSize: 18 }} justify-content="center">
                                                <b hidden={!isEditOpen}>View Details</b>
                                                <b hidden={isEditOpen}>Edit</b>
                                            </Grid>
                                            <Grid container xs={5} justify="flex-end">
                                                <React.Fragment>
                                                    <IconButton onClick={() => EditSelected(clickRowData)} hidden={clickRowData[5] === "Suspended" || clickRowData[5] === "Not Approved"} >
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleSoftDeleteConfirmClick(clickRowData)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton onClick={closeSecondaryDetailPane}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </React.Fragment>
                                            </Grid>
                                        </Grid>
                                        <div style={{ height: 5, paddingTop: "8%" }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <TextField disabled label="Company Name" style={{ width: "85%" }} onChange={handleCompanyName} value={pascalCase(companyName)} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" style={{ width: "85%" }} value={companyMcNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" style={{ width: "85%" }} value={companyRole}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Address *" style={{ width: "85%" }} error={errors.address} value={pascalCase(address)} onChange={handleAddress}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="City *" style={{ width: "85%" }} error={errors.city} value={pascalCase(city)} onChange={handleCity}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="State *" style={{ width: "85%" }} error={errors.state} value={pascalCase(state)} onChange={handleState}></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <TextField disabled label="Email Address" style={{ width: "85%" }} value={emailAddress} >{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen || user.email === emailAddress} label="First Name *" style={{ width: "85%" }} error={errors.firstName} value={pascalCase(firstName)} onChange={handleFirstNametxt} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen || user.email === emailAddress} label="Last Name *" style={{ width: "85%" }} error={errors.lastName} value={pascalCase(lastName)} onChange={handleLastNametxt} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{ paddingTop: "3%" }} />
                                                        <PhoneInput
                                                            disabled={isEditOpen || user.email === emailAddress}
                                                            value={mobileNumber}
                                                            onChange={(value, data, event, formattedValue) => { handleUserPhoneNoChange(value, data, event, formattedValue) }}
                                                            localization={'us'}
                                                            onlyCountries={['us']}
                                                            country={'us'}
                                                            defaultCountry={"us"}
                                                            disableDropdown={true}
                                                            disableCountryCode={true}
                                                            placeholder='(xxx) xxx-xxxx'
                                                            error={errors.mobileNumber}
                                                            containerStyle={{
                                                                border: "1px solid black",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="User Role " style={{ width: "85%" }} value={userRole} ></TextField>
                                                    </Grid>
                                                </Grid>
                                                <Grid style={{ fontSize: 14 }} xs={12}>
                                                    <Grid container direction="row">
                                                        <div style={{ paddingTop: "3%" }}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={1}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs style={{ paddingLeft: "9%", paddingTop: "6%" }}>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    marginLeft: "10px",
                                                    textTransform: "none",
                                                }} onClick={() => EditUserDetails(clickRowData)} hidden={isEditOpen} >
                                                    Save Changes
                                                </Button>
                                            </Grid>
                                            <Grid item xs style={{ paddingTop: "6%" }}>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    margin: "0px, auto",
                                                    textTransform: "none",
                                                }} onClick={() => handleSuspendConfirmClick(clickRowData)} hidden={clickRowData[5] === "Suspended" || clickRowData[5] === "Not Approved" || !isEditOpen} >
                                                    Suspend
                                                </Button>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    margin: "0px, auto",
                                                    textTransform: "none",
                                                }} onClick={() => handleActivateConfirmClick(clickRowData)} hidden={(clickRowData[5] !== "Suspended" && clickRowData[5] !== "Not Approved") || !isEditOpen} >
                                                    Activate
                                                </Button>
                                            </Grid>
                                            <Grid item xs style={{ paddingTop: "6%" }}>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    margin: "0px, auto",
                                                    textTransform: "none",
                                                }} onClick={() => handleCancelButtonConfirmClick(clickRowData)} hidden={isEditOpen}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ fontSize: 14 }} xs={12}>
                                            <Grid container direction="row">
                                                <div style={{ paddingTop: "5%" }}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                    </Grid>
                </Typography>
            </main>
        </div>
    )
}

export default CompanyAdmin;