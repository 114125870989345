import React, { useState, useEffect, useRef } from "react";
import {
    CssBaseline,
    Typography,
    Grid,
    Box,
    Snackbar,
    IconButton,
    TextField,
    InputAdornment,
    Button,
    Tooltip,
    Tabs,
    Paper,
    Divider,
    InputBase,
    List,
    ListItem,
    Tab,
} from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Autocomplete as MUIAutoComplete } from "@material-ui/lab";
import { toNumber } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider, } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import PropTypes from 'prop-types';
import cities from "cities";
// import zipcodes from "zipcodes";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@material-ui/lab/Alert";
import useWebSocket, { } from 'react-use-websocket';
import Countdown from "react-countdown";
import moment from "moment";
import Swal from 'sweetalert2';
import slugid from "slugid";
import addNotification from 'react-push-notification';
import { animateScroll } from "react-scroll";

// Imports to have all the Icons
import CloseIcon from "@material-ui/icons/Close";
import { ExpandMoreIcon } from "../../../SvgIcons/ExpandMore"
import { ExpandLessIcon } from "../../../SvgIcons/ExpandLess";
import DeleteIcon from '@material-ui/icons/Delete';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import BookmarkIcon from '@material-ui/icons/Bookmark'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import Edit from "@material-ui/icons/Edit";
import { AttachMoney } from "@material-ui/icons";
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';

//import Button 
import { BookItButton, AcceptCounterOffer, ExpiredCounterOffer } from "../Button/Button"
import { handleLocalStorageValue } from "../../../LocalStorageValue";

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';
import "./index.css";
import { environmentVariables } from "../../../environment"
import Navbar from "../../Navbar"
import MUIDrawer from "../../Drawer"
import PostLoadForm from "../../PostLoadForm"
import { toShowIcon } from "../YesOrNA"
import { handleFetchLoadForLoadboard } from "../../../APICalls";
import { reduceCountOnLoadExpire, getMatchedLoad, updateCount, sendEmailForBidPlace, sendEmailForCounterOffer, sendEmailForAcceptCounterOffer } from "../SavedSearchLane/updatecount"
import { NotVerifiedUserIcon } from "../../../SvgIcons/NotVerifiedUserIcon";

// Import statements for Chat Component
import Header from "../CommentsComponent/Header/Header";
import SearchBar from "../CommentsComponent/SearchBar/SearchBar";
import HeaderChat from "../CommentsComponent/HeaderChat/HeaderChat";
import Chat from "../CommentsComponent/Chat/Chat";

//import statement to handle post load form edit
import PromptComponent from '../../Prompt'

import sortDate from '../../Sorting/sortDate'
import sortNum from '../../Sorting/sortNum'
import SearchForm from "../../SearchForm"

// import map
import Map from '../Map';
import { withScriptjs } from "react-google-maps";
//--------------------------------------------*********************-----------------------------------------
let selectedRowDataValueForFavoritesSocketIO = [];
let selectedRowDataValueForPostedLoadsSocketIO = [];
let isPaneOpenForSocketIO = false;
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    rightAlign: {
        marginLeft: "25px",
        textTransform: "none",
        fontSize: 16,
    },
    leftAlign: {
        marginLeft: "25px",
        textTransform: "none",
        fontSize: 16,
    }
}));

// Function to handle MuiAlert
function Alert(prop) {
    return <MuiAlert elevation={6} variant="filled" {...prop} />;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const ActiveLoads = () => {
    //------------------------- Place to declare all the constants -----------------------------
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();
    const SearchFormRef = useRef();

    //constants to handle Post Load form component 
    const [Prompt, setShowPrompt, setPristine] = PromptComponent("Changes you made may not be saved")
    const [loadUpdatedStatus, setLoadUpdatedStatus] = useState(false)
    const PostLoadFormRef = useRef();
    const [postLoadFormFunctionality, setPostLoadFormFunctionality] = useState("")

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState("");
    const [selectedRowforLoadsPosted, setSelectedRowforLoadsPosted] = useState([]);
    const [selectedRowforFavorites, setSelectedRowforFavorites] = useState([]);
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState(window.localStorage.getItem('company_mc_number'))
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState(window.localStorage.getItem('company_role'));
    const [loggedInuserRole, setLoggedInuserRole] = useState(window.localStorage.getItem('user_role'));

    /// Constant to handle expand and colapse the top pannel
    const [expandColapseTopPannel, setExpandColapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(1);

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);

    // Constant to handle LoadNumber
    const [loadNumber, setLoadNumber] = useState("");
    const [loadNumberDetail, setLoadNumberDetail] = useState("");

    // Constants to handle Hours and minutes
    const hourValue = ["01", "03", "06", "12", "24"];
    const minutes = ["15", "30", "45"];

    // Variable to handle length width height
    const [txtLength, setTxtLength] = useState("");
    const [txtWidth, setTxtWidth] = useState("");
    const [txtHeight, setTxtHeight] = useState("");

    // constant to handle drawer notification badge
    const [isNotificationSent, setIsNotificationSent] = useState(false);

    //---------- Hiding code as the feature is not necessary currently ------------//
    // const [txtRate, setTxtRate] = useState("");

    // Hidden code for above market rate checkbox
    // const [checkBoxAboveMarketRate, setCheckBoxAboveMarketRate] = React.useState(false);

    // Constant to handel main grid percentage
    const [mainGridPercentPostedLoads, setMainGridPercentPostedLoads] = useState("100%");
    const [mainGridPercentFavorites, setMainGridPercentFavorites] = useState("100%");
    const [isPaneOpenPostedLoads, setIsPaneOpenPostedLoads] = useState(false);
    const [isPaneOpenFavorites, setIsPaneOpenFavorites] = useState(false);
    const [displaySpecificColumns, setDisplaySpecificColumns] = useState(true);
    const [selectedRowDataValuePostedLoads, setSelectedRowDataValuePostedLoads] = useState([]);
    const [selectedRowDataValueFavorites, setSelectedRowDataValueFavorites] = useState([]);

    // Constants for search component
    const [loadNumberLength, setLoadNumberLength] = useState(0)

    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState("");
    const [searchPickUpFromState, setSearchPickUpFromState] = useState("");
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState("");

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState("");
    const [searchDeliverToState, setSearchDeliverToState] = useState("");
    const [searchDeliverToZip, setSearchDeliverToZip] = useState("");
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState("")

    const [isSearchFieldValueChanged, setIsSearchFieldValueChanged] = useState(false)

    // Constant to handle logged in Company name
    const [userCompanyName, setUserCompanyName] = useState("");

    //constant to handle mui data table loader
    const [loadingPostedLoads, setLoadingPostedLoads] = useState(false);
    const [loadingFavorites, setLoadingFavorites] = useState(false);

    //constant to handle mui bid data table loader
    const [loadingPostedActiveBids, setLoadingPostedActiveBids] = useState(false);
    const [loadingPostedClosedBids, setLoadingPostedClosedBids] = useState(false);
    const [loadingFavoritesActiveBidTable, setLoadingFavoritesActiveBidTable] = useState(false);
    const [loadingFavoritesClosedBidTable, setLoadingFavouritesClosedBidTable] = useState(false);

    // Constants to handle Awarded Loads
    const [rowDataForPostedLoads, setRowDataForPostedLoads] = useState([]);
    const [rowDataForFavorites, setRowDataForFavorites] = useState([]);

    const [rowDataActiveBidTable, setRowDataActiveBidTable] = useState([]);
    const [rowDataClosedBidTable, setRowDataClosedBidTable] = useState([]);
    const [rowDataFavouritesActiveBidTable, setRowDataFavouritesActiveBidTable] = useState([]);
    const [rowDataFavouritesClosedBidTable, setRowDataFavouritesClosedBidTable] = useState([]);

    // constants to handle disabling buttons after first click
    const [disableBidButton, setDisableBidButton] = useState(false)
    const [disablePostLoadButton, setDisablePostLoadButton] = useState(false)
    const [disableUpdateLoadButton, setDisableUpdateLoadButton] = useState(false)
    const [awardedCarrierName, setAwardedCarrierName] = useState("")

    //constants tohandle secondary panel Value
    const [loadPickUpWindow, setLoadPickUpWindow] = useState("");
    const [loadPickUpDestination, setLoadPickUpDestination] = useState("");

    const [loadDeliverByDate, setLoadDeliverByDate] = useState("");
    //const [loadDeliverDirect, setLoadDeliverDirect] = useState("");
    const [loadDeliverWindow, setLoadDeliverWindow] = useState("");
    const [loadDeliverDestination, setLoadDeliverDestination] = useState("");

    const [loadMaxWeight, setLoadMaxWeight] = useState("");
    const [loadNoOfPieces, setLoadNoOfPieces] = useState("");

    const [loadStackable, setLoadStackable] = useState(false);
    const [loadHazmat, setLoadHazmat] = useState(false);
    const [loadDockHigh, setLoadDockHigh] = useState(false);
    const [loadTempControl, setLoadTempControl] = useState(false);
    const [loadFreightDimension, setLoadFreightDimension] = useState("");
    const [loadCityDistance, setLoadCityDistance] = useState("");

    // Constant for max weight 
    const [txtMilesOut, updateTxtMilesOut] = useState("");

    // Constant to handle load notes
    const [BidNotes, updateBidNotes] = useState("");

    const [loadBidNotesDisable, setLoadBidNotesDisable] = useState(false);
    const [MilesOutDisable, setMilesOutDisable] = useState(false);
    const [lengthDisable, setLengthDisable] = useState(false);
    const [widthDisable, setWidthDisable] = useState(false);
    const [heightDisable, setHeightDisable] = useState(false);

    // variable to handle counter offer
    const [counterOffer, setCounterOffer] = useState("");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [mapDistance, setMapDistance] = useState("");

    //counter popup
    const [counteropen, setCounterOpen] = React.useState(false);
    const [counteropencarrier, setCounterOpenCarrier] = React.useState(false);
    const [disableBookit, setDisableBookit] = useState(false);
    const [disableCounterButton, setDisableCounterButton] = useState(false);
    const [disableEdit, setDisadbleEdit] = useState(false)

    //constant tohandle Label
    const [label, setLabel] = useState("Search Load")

    const MapLoader = withScriptjs(Map);

    // Function to get bid status for bid tables
    const getStatusForBidTables = (status) => {
        let newStatus
        if (status === "new") {
            newStatus = "New"
        }
        else if (status === "accepted") {
            newStatus = "Won"
        }
        else {
            newStatus = "Closed"
        }
        return newStatus;
    }

    // constants to handle loads posted active bid table
    const columnDefsActiveBidTable = [
        {
            label: " ",
            name: "mc_certified",
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value !== "Verified" && loggedInuserCompanyRole === "Broker") {
                            return (
                                <div>
                                    <Tooltip title="Not Verified Company">
                                        <NotVerifiedUserIcon className="notverified" />
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                }
            }
        },
        {
            label: "Posted By",
            name: "posted_by_company",
            options: { display: false },
        },
        { label: "PostId", name: "load_id", options: { display: false } },
        { label: "BidId", name: "bid_id", options: { display: false } },
        { label: "Carrier Company", name: "carrier_company" },
        {
            label: "Bid Amount", name: "bid_amount", options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[15] === "newoffer") && rowIndex.rowData[16] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[5]}</p>}
                        {rowIndex.rowData[15] !== "newoffer" && rowIndex.rowData[16] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[17]} </p>}
                        {rowIndex.rowData[15] !== "newoffer" && rowIndex.rowData[16] === "Accept" && <p className="afterBidDisplay"> $ {rowIndex.rowData[17]}
                        </p>}
                    </>
                    );
                }
            }
        },
        {
            label: "Status", name: "status", options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[6]);
                    return (
                        bidStatus
                    )
                }
            }
        },
        { label: "Carrier Name", name: "carrier_name", options: { display: false } },
        { label: "bid_time_stamp", name: "bid_time_stamp", options: { display: false } },
        { label: "bid_expiration_time", name: "bid_expiration_time", options: { display: false } },
        {
            label: "Bid Notes", name: "bid_notes", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[10] === "" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[10] !== "" && rowuserdata.rowData[10]}
                    </>
                    )
                }
            }
        },
        {
            label: "Miles Out", name: "miles_out", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === "" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[11] !== "" && rowuserdata.rowData[11]}
                    </>
                    )
                }
            }
        },
        {
            label: "Truck Dimension", name: "truck_dimension", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[12] === "LXWXH" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[12] !== "LXWXH" && rowuserdata.rowData[12]}
                    </>
                    )
                }
            }
        },
        {
            label: "Bid Expiration",
            name: "BidExpiration",
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[8];
                    var localTime = moment.utc(TimeStamp).local().format("YYYY-MM-DD HH:mm:ss");
                    let BidExpireTime = parseInt(rowIndex.rowData[9].replace(/\D/g, ''));
                    let time = "";
                    if (BidExpireTime === 5) {
                        time = "00:0" + BidExpireTime;
                    }
                    else {
                        time = "00:" + BidExpireTime;
                    }
                    let timeParts = time.split(":");
                    let timeInMiliseconds = ((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(timeInMiliseconds)}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: "Accept",
            name: "Accept",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === "Broker" || loggedInuserRole !== "Carrier") && selectedRowDataValuePostedLoads.posted_by_user === user.email,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(disableBookit === false) ?
                            <Button variant="contained" className="ButtonColor" id="ButtonColorBookit" style={(disableBookit === false && (rowIndex.rowData[16] === "Accept" || rowIndex.rowData[17] === "")) ? { backgroundColor: '#0d2346', fontSize: 12 } : { fontSize: 12 }}
                                disabled={disableBookit}
                                onClick={() => acceptBidData(rowIndex)}> Book It</Button>
                            :
                            <BookItButton />
                        }
                    </>
                    );
                },
            },
        },
        {
            label: "Counter Offer",
            name: "counteroffer_sent",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" && selectedRowDataValuePostedLoads.posted_by_user === user.email)),
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[15];
                    let counterofferstatus = rowIndex.rowData[16];
                    let counteroffer = rowIndex.rowData[17]

                    return (<>
                        {disableBookit === false && counteroffersent === "newoffer" && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ backgroundColor: '#0d2346', fontSize: 12 }} disabled={disableCounterButton} onClick={() => handleOpen(rowIndex)}>  Counter Offer</Button>}
                        {disableBookit === true && counteroffersent === "newoffer" && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ fontSize: 12 }} disabled={disableCounterButton}>  Counter Offer</Button>}
                        {counteroffersent === "carrier" && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ backgroundColor: '#0d2346' }} disabled={disableCounterButton} onClick={() => handleOpenCarrier(counteroffer, rowIndex)}> ${counteroffer} <Edit className="coediticon" disabled={disableEdit} />
                        </Button>}
                        {(counteroffersent === "carrier" || counteroffersent === "broker") && counterofferstatus === "Accept" && <AcceptCounterOffer counteroffer={counteroffer} />}
                        {disableBookit === false && counteroffersent === "broker" && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ backgroundColor: '#0d2346' }} disabled={disableCounterButton} onClick={() => handleOpenCarrier(counteroffer, rowIndex)}> ${counteroffer} <Edit className="coediticon" disabled={disableEdit} />
                        </Button>}
                        {disableBookit === true && counteroffersent === "broker" && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" disabled={disableCounterButton} > ${counteroffer} <Edit className="coediticon" disabled={disableEdit} /> </Button>}
                    </>
                    );
                },
            },
        },
        {
            label: "Counter Offer Status",
            name: "counteroffer_status",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[15];

                    return (<>
                        {counterofferstatus === "Accept" && <div>{counterofferstatus} </div>}
                    </>
                    )
                }
            },

        },
        {
            label: "Counter Offer",
            name: "counter_offer",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
            },
        },

    ];

    const optionsActiveBidTable = {
        filter: false,
        responsive: true,
        download: false,
        print: false,
        selectableRows: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingPostedActiveBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    // Bid datatable Row and column defination
    const columnDefsClosedBidTable = [
        {
            label: " ",
            name: "mc_certified",
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value !== "Verified" && loggedInuserCompanyRole === "Broker") {
                            return (
                                <div>
                                    <Tooltip title="Not Verified Company">
                                        <NotVerifiedUserIcon className="notverified" />
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                }
            }
        },
        {
            label: "Posted By",
            name: "posted_by_company",
            options: { display: false },
        },
        { label: "PostId", name: "load_id", options: { display: false } },
        { label: "BidId", name: "bid_id", options: { display: false } },
        { label: "Carrier Company", name: "carrier_company" },
        {
            label: "Bid Amount", name: "bid_amount", options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[15] === "newoffer") && rowIndex.rowData[16] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[5]}</p>}
                        {rowIndex.rowData[15] !== "newoffer" && rowIndex.rowData[16] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[17]} </p>}
                        {rowIndex.rowData[15] !== "newoffer" && rowIndex.rowData[16] === "Accept" && <p className="afterBidDisplay"> $ {rowIndex.rowData[17]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: "Status", name: "status", options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[6]);
                    return (
                        bidStatus
                    )
                }
            }
        },
        { label: "Carrier Name", name: "carrier_name", options: { display: false } },
        { label: "bid_time_stamp", name: "bid_time_stamp", options: { display: false } },
        { label: "bid_expiration_time", name: "bid_expiration_time", options: { display: false } },
        {
            label: "Bid Notes", name: "bid_notes", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === "" || rowuserdata.rowData[10] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== "" || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    )
                }
            }
        },
        {
            label: "Miles Out", name: "miles_out", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[11] === "" || rowuserdata.rowData[11] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[11] !== "" || rowuserdata.rowData[11] !== undefined) && rowuserdata.rowData[11]}
                    </>
                    )
                }
            }
        },
        {
            label: "Truck Dimension", name: "truck_dimension", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[12] === "LXWXH" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[12] !== "LXWXH" && rowuserdata.rowData[12]}
                    </>
                    )
                }
            }
        },
        {
            label: "Bid Expiration",
            name: "BidExpiration",
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.rowData[6] === "Closed") {
                        return (
                            <Countdown autoStart={true} date={Date.now() + 0}>
                            </Countdown>
                        )
                    }
                },
            }
        },
        {
            label: "Accept",
            name: "Accept",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === "Broker" || loggedInuserRole !== "Carrier"),
                customBodyRender: (dataIndex, rowIndex) => {
                    return (
                        <BookItButton />
                    );
                },
            },
        },
        {
            label: "Counter Offer",
            name: "counteroffer_sent",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" || selectedRowDataValuePostedLoads.posted_by_user === user.email)),
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[15];
                    let counterofferstatus = rowIndex.rowData[16];
                    let counteroffer = rowIndex.rowData[17];

                    return (<>
                        {(counteroffersent === "newoffer") && counterofferstatus === "" && <p className="afterBidDisplay"> NA </p>}
                        {(counteroffersent === "newoffer") && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ fontSize: 12 }} disabled={true}>
                            Counter Offer
                        </Button>}
                        {(counteroffersent === "carrier" || counteroffersent === "broker") && counterofferstatus === "New" && <p className="afterBidDisplay"> ${counteroffer} </p>}
                        {(counteroffersent === "carrier" || counteroffersent === "broker") && counterofferstatus === "Accept" && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                },
            },
        },
        {
            label: "Counter Offer Status",
            name: "counteroffer_status",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[16];
                    return (<>
                        {counterofferstatus === "Accept" && <div>{counterofferstatus} </div>}
                    </>
                    )
                }
            },

        },
        {
            label: "Counter Offer",
            name: "counter_offer",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
            },
        },
    ];
    const optionsClosedBidTable = {
        filter: false,
        responsive: "vertical",
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingPostedClosedBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    const columnDefsActiveBidTableForFavourites = [
        {
            label: " ",
            name: "mc_certified",
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value !== "Verified" && loggedInuserCompanyRole === "Broker") {
                            return (
                                <div>
                                    <Tooltip title="Not Verified Company">
                                        <NotVerifiedUserIcon className="notverified" />
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                }
            }
        },
        {
            label: "Posted By",
            name: "posted_by_company",
            options: { display: false },
        },
        { label: "PostId", name: "load_id", options: { display: false } },
        { label: "BidId", name: "bid_id", options: { display: false } },
        { label: "Carrier Company", name: "carrier_company" },
        {
            label: "Bid Amount", name: "bid_amount", options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[14] === "newoffer") && rowIndex.rowData[15] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[5]}</p>}
                        {rowIndex.rowData[14] !== "newoffer" && rowIndex.rowData[15] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[16]} </p>}
                        {rowIndex.rowData[14] !== "newoffer" && rowIndex.rowData[15] === "Accept" && <p className="afterBidDisplay"> $ {rowIndex.rowData[16]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: "Status", name: "status", options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[6]);
                    return (
                        bidStatus
                    )
                }
            }
        },
        { label: "Carrier Name", name: "carrier_name", options: { display: false } },
        { label: "bid_time_stamp", name: "bid_time_stamp", options: { display: false } },
        { label: "bid_expiration_time", name: "bid_expiration_time", options: { display: false } },
        {
            label: "Bid Notes", name: "bid_notes", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[10] === "" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[10] !== "" && rowuserdata.rowData[10]}
                    </>
                    )
                }
            }
        },
        {
            label: "Miles Out", name: "miles_out", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === "" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[11] !== "" && rowuserdata.rowData[11]}
                    </>
                    )
                }
            }
        },
        {
            label: "Truck Dimension", name: "truck_dimension", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[12] === "LXWXH" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[12] !== "LXWXH" && rowuserdata.rowData[12]}
                    </>
                    )
                }
            }
        },
        {
            label: "Bid Expiration",
            name: "BidExpiration",
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.rowData[6] === "Closed") {
                        return (
                            <Countdown autoStart={true} date={Date.now() + 0}>
                            </Countdown>
                        );
                    }
                    else {
                        let TimeStamp = rowIndex.rowData[8];
                        var localTime = moment.utc(TimeStamp).local().format("YYYY-MM-DD HH:mm:ss");
                        let BidExpireTime = parseInt(rowIndex.rowData[9].replace(/\D/g, ''));
                        let time = "";
                        if (BidExpireTime === 5) {
                            time = "00:0" + BidExpireTime;
                        }
                        else {
                            time = "00:" + BidExpireTime;
                        }
                        let timeParts = time.split(":");
                        let timeInMiliseconds = ((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
                        return (
                            <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(timeInMiliseconds)}>
                            </Countdown>
                        );
                    }
                },
            }
        },
        {
            label: "Counter Offer",
            name: "counteroffer_sent",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
            },
        },
        {
            label: "Counter Offer Status",
            name: "counteroffer_status",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = ""
                    if (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carruer" && selectedRowforFavorites.posted_by_user === user.email)) {
                        counterofferstatus = rowuserdata.rowData[14];
                    }
                    else if (loggedInuserCompanyRole === "Carrier" || (loggedInuserCompanyRole === "Broker&Carruer" && selectedRowforFavorites.posted_by_user !== user.email)) {
                        counterofferstatus = rowuserdata.rowData[15];
                    }
                    return (<>
                        {counterofferstatus === "Accept" && <div>{counterofferstatus} </div>}
                    </>
                    )
                }
            },

        },
        {
            label: "Counter Offer",
            name: "counter_offer",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === "Carrier" || (loggedInuserCompanyRole === "Broker&Carrier" && selectedRowDataValueFavorites.posted_by_user !== user.email)),
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[16];
                    let counterofferstatus = rowuserdata.rowData[15];

                    return (<>
                        {(rowuserdata.rowData[6] === "accepted" || rowuserdata.rowData[6] === "Closed") && <Button style={{ fontSize: 12 }} disabled={true}>
                            Counter Offer
                        </Button>}
                        {(counteroffer === "" || counteroffer === undefined) && counterofferstatus === "New" && <p className="afterBidDisplay"> NA
                        </p>}
                        {disableBookit === false && counteroffer !== "" && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor" style={{ backgroundColor: '#0d2346' }} onClick={() => handleOpenCarrier(counteroffer, rowuserdata)}> ${counteroffer} <Edit className="coediticon" disabled={disableEdit} />
                        </Button>}
                        {disableBookit === true && counteroffer !== "" && counterofferstatus === "New" && <Button variant="contained" className="ButtonColor"> ${counteroffer} <Edit className="coediticon" disabled={disableEdit} />
                        </Button>}
                        {counteroffer !== "" && counterofferstatus === "Accept" && <AcceptCounterOffer counteroffer={counteroffer} />}
                    </>
                    )
                }
            },
        },
    ];
    const optionsActiveBidTableForFavourites = {
        filter: false,
        responsive: "vertical",
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingFavoritesActiveBidTable ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    const columnDefsClosedBidTableForFavourites = [
        {
            label: " ",
            name: "mc_certified",
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value !== "Verified" && loggedInuserCompanyRole === "Broker") {
                            return (
                                <div>
                                    <Tooltip title="Not Verified Company">
                                        <NotVerifiedUserIcon className="notverified" />
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                }
            }
        },
        {
            label: "Posted By",
            name: "posted_by_company",
            options: { display: false },
        },
        { label: "PostId", name: "load_id", options: { display: false } },
        { label: "BidId", name: "bid_id", options: { display: false } },
        { label: "Carrier Company", name: "carrier_company" },
        {
            label: "Bid Amount", name: "bid_amount", options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[14] === "newoffer") && rowIndex.rowData[15] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[5]}</p>}
                        {rowIndex.rowData[14] !== "newoffer" && rowIndex.rowData[15] === "New" && <p className="afterBidDisplay"> ${rowIndex.rowData[16]} </p>}
                        {rowIndex.rowData[14] !== "newoffer" && rowIndex.rowData[15] === "Accept" && <p className="afterBidDisplay"> $ {rowIndex.rowData[16]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: "Status", name: "status", options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[6]);
                    return (
                        bidStatus
                    )
                }
            }
        },
        { label: "Carrier Name", name: "carrier_name", options: { display: false } },
        { label: "bid_time_stamp", name: "bid_time_stamp", options: { display: false } },
        { label: "bid_expiration_time", name: "bid_expiration_time", options: { display: false } },
        {
            label: "Bid Notes", name: "bid_notes", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[10] === undefined && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[10] === "" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[10] !== undefined && rowuserdata.rowData[10]}
                    </>
                    )
                }
            }
        },
        {
            label: "Miles Out", name: "miles_Out", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === undefined && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[11] === "" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[11] !== undefined && rowuserdata.rowData[11]}
                    </>
                    )
                }
            }
        },
        {
            label: "Truck Dimension", name: "truck_dimension", options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[12] === "LXWXH" && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[12] !== "LXWXH" && rowuserdata.rowData[12]}
                    </>
                    )
                }
            }
        },

        {
            label: "Bid Expiration",
            name: "BidExpiration",
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    return (
                        <Countdown autoStart={true} date={Date.now() + 0}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: "Counter Offer",
            name: "counteroffer_sent",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
            },
        },
        {
            label: "Counter Offer Status",
            name: "counteroffer_status",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[15];
                    return (<>
                        {counterofferstatus === "Accept" && <div>{counterofferstatus} </div>}
                    </>
                    )
                }
            },

        },
        {
            label: "Counter Offer",
            name: "counter_offer",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === "Carrier" || (loggedInuserCompanyRole === "Broker&Carrier" && selectedRowDataValueFavorites.posted_by_user !== user.email)),
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[16];
                    let counterofferstatus = rowuserdata.rowData[15];

                    return (<>
                        {(counteroffer === undefined || counteroffer === "") && counterofferstatus === "New" && <p className="afterBidDisplay"> NA
                        </p>}
                        {(counteroffer !== undefined && counteroffer !== "") && counterofferstatus === "New" && <p className="afterBidDisplay"> ${counteroffer}
                        </p>}
                        {(counteroffer !== undefined || counteroffer !== "") && counterofferstatus === "Accept" && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    )
                }
            },
        },
    ];
    const optionsClosedBidTableForFavourites = {
        filter: false,
        responsive: "vertical",
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingFavoritesClosedBidTable ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    const columnDefsPL = [
        { label: "PostId", name: "load_id", options: { display: false } },
        {
            label: " ",
            name: "watch_load",
            options: { display: false }
        },
        {
            label: "Pick-up From",
            name: "pickup_dest",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true
                                }
                            }
                            if (found === true) {
                                return false
                            }
                            else {
                                return true
                            }
                        }
                    }
                },
            },
        },
        {
            label: "Pick-up Date",
            name: "pickupfrom_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Deliver To",
            name: "delivery_dest",
            options: {
                display: displaySpecificColumns,
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true
                                }
                            }
                            if (found === true) {
                                return false
                            }
                            else {
                                return true
                            }
                        }
                    }
                },
            },
        },
        {
            label: "Miles (Time)",
            name: "cities_distance",
            options: {
                sort: true,
                display: displaySpecificColumns, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: "Delivery Date",
            name: "pickupto_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Truck Type",
            name: "truck_type",
            options: {
                customBodyRender: (value) => {
                    if (value !== "" && value.length !== 0) {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== "") {
                                if (valEnteredNumber.includes(",")) {
                                    let splittingValue = valEnteredNumber.split(",");
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: "Weight",
            name: "max_weight",
            options: {
                sort: true,
                display: displaySpecificColumns,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },

                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: "Number Of Pieces",
            name: "total_item_count",
            options: {
                sort: true,
                display: displaySpecificColumns,
                customBodyRender: (value) => {
                    if (value !== "") {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== isNaN && valNoOfPieces !== "NaN" && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: "TimeStamp",
            name: "time_stamp",
            options: {
                display: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = "false";
                        }
                    }
                }
            }
        },
        {
            label: "LoadExpirationTime",
            name: "load_expiration_time",
            options:
            {
                display: false,
                viewColumns: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = "false";
                        }
                    }
                }
            }
        },
        {
            label: "Load Expires In",
            name: "LoadExpiresIn",
            options: {
                display: displaySpecificColumns,
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[10];
                    var localTime = moment.utc(TimeStamp).local().format("YYYY-MM-DD HH:mm:ss");
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(LoadExpirationInMiliseconds(rowIndex.rowData[11]))}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: "Posting Company", name: "posted_by_company",
            options: {
                display: displaySpecificColumns,
            }
        },
        {
            label: "Load ID",
            name: "load_number",
            options: {
                customBodyRender: (value) => {
                    return value;
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
    ];

    const optionsForPostedLoads = {
        filter: false,
        search: false,
        responsive: "vertical",
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClickConfirmationForPostedLoads(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpenPostedLoads) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClickPostedLoads(row, dataIndex)
                }
            }
            let filteredArray = chatDetails.filter(({ load_id }) => load_id === row[0])
            let rowStatus = 0
            filteredArray.forEach(element => {
                if (element.read_status) {
                    const findChatStatus = element.read_status.find(
                        ({ name }) => name === user.email
                    )
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValuePostedLoads.load_id !== row[0]) {
                        rowStatus = rowStatus + 1
                    }
                }
            })
            if (rowStatus > 0) {
                return {
                    style: { backgroundColor: '#DAF7A6' }
                }
            }
        },
        textLabels: {
            body: {
                noMatch: !loadingPostedLoads ?
                    <CircularProgress /> :
                    `${pascalCase(user.nickname)} has not posted any loads`
            },
        }
    };

    const columnDefs = [
        { label: "PostId", name: "load_id", options: { display: false } },
        {
            label: " ",
            name: "watch_load",
            options: {
                display: true,
                customBodyRender: (value, row) => {
                    if ((row.rowData[1] !== undefined || row.rowData[1] !== null) && (row.rowData[2] !== undefined && row.rowData[2] !== null && row.rowData[2].length !== undefined)) {
                        let addedToWatch = false
                        if (row.rowData[1] !== undefined && row.rowData[1] !== null) {
                            row.rowData[1].forEach(Data => {
                                if (Data.name === user.email && Data.accStatus === true) {
                                    addedToWatch = true
                                }
                            })
                        }
                        let addedToLane = false
                        row.rowData[2].forEach(Data => {
                            if (Data.name === user.email && Data.accStatus === true) {
                                addedToLane = true
                            }
                        })
                        if (addedToWatch === true && addedToLane === true) {
                            return (
                                <div>
                                    <StarIcon style={{ fill: "#ffe007" }} />
                                    <BookmarkIcon style={{ fill: "#0BDA51" }} />
                                </div>
                            )
                        }
                        else if (addedToLane === true && addedToWatch === false) {
                            return (
                                <div>
                                    {/* <StarBorderIcon /> */}
                                    <BookmarkIcon style={{ fill: "#0BDA51" }} />
                                </div>
                            )
                        }
                        else if (addedToWatch === true && addedToLane === false) {
                            return (
                                <div>
                                    {/* <StarIcon style={{ fill: "#ffe007" }} /> */}
                                    <BookmarkBorderIcon />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div>
                                    {/* <StarBorderIcon /> */}
                                    <BookmarkBorderIcon />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <div>
                                {/* <StarBorderIcon /> */}
                                <BookmarkBorderIcon />
                            </div>
                        )
                    }
                }
            }
        },
        {
            label: " ",
            name: "saved_lane",
            options: {
                display: false,
            }
        },
        {
            label: "Pick-up From",
            name: "pickup_dest",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterList: []
            }
        },
        {
            label: "Pick-up Date",
            name: "pickupfrom_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Deliver To",
            name: "delivery_dest",
            options: {
                display: displaySpecificColumns,
                customBodyRender: (value) => {
                    return (
                        <div className="Bold">
                            {value}
                        </div>
                    )
                },
                filterList: []
            },
        },
        {
            label: "Miles (Time)",
            name: "cities_distance",
            options: {
                sort: true,
                display: displaySpecificColumns, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: "Delivery Date",
            name: "pickupto_date",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: "Truck Type",
            name: "truck_type",
            options: {
                customBodyRender: (value) => {
                    if (value !== "" && value.length !== 0) {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== "") {
                                if (valEnteredNumber.includes(",")) {
                                    let splittingValue = valEnteredNumber.split(",");
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: "Weight",
            name: "max_weight",
            options: {
                sort: true,
                display: displaySpecificColumns,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: "Number Of Pieces",
            name: "total_item_count",
            options: {
                sort: true,
                display: displaySpecificColumns,
                customBodyRender: (value) => {
                    if (value !== "") {
                        return value;
                    }
                    else {
                        return "NA"
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== "NaN" && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: "TimeStamp",
            name: "time_stamp",
            options: {
                display: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = "false";
                        }
                    }
                }
            }
        },
        {
            label: "LoadExpirationTime",
            name: "load_expiration_time",
            options:
            {
                display: false,
                viewColumns: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = "false";
                        }
                    }
                }
            }
        },
        {
            label: "Load Expires In",
            name: "LoadExpiresIn",
            options: {
                display: displaySpecificColumns,
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[11];
                    var localTime = moment.utc(TimeStamp).local().format("YYYY-MM-DD HH:mm:ss");
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(LoadExpirationInMiliseconds(rowIndex.rowData[12]))}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: "Posting Company", name: "posted_by_company", options: {
                display: displaySpecificColumns,
            }
        },
        {
            label: "Load ID",
            name: "load_number",
            options: {
                customBodyRender: (value) => {
                    return value;
                },
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
    ];

    const optionsForFavorites = {
        filter: false,
        search: false,
        responsive: "vertical",
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClickConfirmationForFavorites(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEventForFavorite(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpenFavorites) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClickFavorites(row, dataIndex)
                }
            }
            const findChatResult = chatDetails.find(
                ({ load_id }) => load_id === row[0]
            );
            if (findChatResult !== undefined) {
                if (findChatResult.read_status) {
                    const findChatStatus = findChatResult.read_status.find(
                        ({ name }) => name === user.email
                    )
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValueFavorites.load_id !== row[0]) {
                        return {
                            style: { backgroundColor: '#DAF7A6' }
                        }
                    }
                }
            }
        },
        textLabels: {
            body: {
                noMatch: !loadingFavorites ?
                    <CircularProgress /> :
                    `There are no Bids or Favorite Loads for ${pascalCase(user.nickname)}`
            },
        }
    };

    const [columnDefsForPostedLoads, setColumnDefsForPostedLoads] = useState(columnDefsPL);
    const [columnDefsForFavorites, setColumnDefsForFavorites] = useState(columnDefs);

    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // Constants to handle all Chat
    const [allChatForLoadsPosted, setAllChatForLoadsPosted] = useState([]);
    const [allChatForFavorites, setAllChatForFavorites] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState("");
    const [chatFirstCarrierNameForLoadsPosted, setChatFirstCarrierNameForLoadsPosted] = useState("");
    const [chatFirstCompanyNameForLoadsPosted, setChatFirstCompanyNameForLoadsPosted] = useState("");
    const [chatFirstCarrierNameForFavorites, setChatFirstCarrierNameForFavorites] = useState("");
    const [chatFirstCompanyNameForFavorites, setChatFirstCompanyNameForFavorites] = useState("");
    const [chatAllMessagesForLoadsPosted, setChatAllMessagesForLoadsPosted] = useState([])
    const [chatAllMessagesForFavorites, setChatAllMessagesForFavorites] = useState([]);
    const [chatDetails, setChatDetails] = useState([]);
    const [chatMCNumberForFavorites, setChatMCNumberForFavorites] = useState("")
    const [chatMCNumberForLoadsPosted, setChatMCNumberForLoadsPosted] = useState("")

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    // Constants for showing Loaddetails in the secondary pane
    const [loadPostedTimePostedLoads, setloadPostedTimePostedLoads] = useState("");
    const [loadExpiresInPostedLoads, setLoadExpiresInPostedLoads] = useState("");
    const [loadLoadNotesPostedLoads, setLoadLoadNotesPostedLoads] = useState("");
    const [loadPickUpdate, setLoadPickUpdate] = useState("");
    const [loadUpdateLoadExpirationTimeForHour, setLoadUpdateLoadExpirationTimeForHour] = useState("");
    const [loadUpdateLoadExpirationTimeForMin, setLoadUpdateLoadExpirationTimeForMin] = useState("");
    const [isUpdateLoadExpirationValueChanged, setIsUpdateLoadExpirationValueChanged] = useState(false)
    const [loadBidAmount, setLoadBidAmount] = useState("");
    const [loadPostedBy, setLoadPostedBy] = useState("");
    const [loadPostedByUser, setLoadPostedByUser] = useState("");
    const [loadLoadType, setLoadLoadType] = useState("");

    const [loadWatchLoadPostedLoads, setLoadWatchLoadPostedLoads] = useState(false);
    const [bidExpirationPostedLoads, setBidExpirationPostedLoads] = useState("");
    const [loadBidAmountDisablePostedLoads, setLoadBidAmountDisablePostedLoads] = useState(false);

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    // Constants for showing Loaddetails in the secondary pane
    const [loadStatusFavorites, setLoadStatusFavorites] = useState("");
    // Constant to handle watch_load 
    const [loadSavedLane, setLoadSavedLane] = useState(false);
    const [loadPostedTimeFavorites, setloadPostedTimeFavorites] = useState("");
    const [loadExpiresInFavorites, setLoadExpiresInFavorites] = useState("");
    const [loadLoadNotesFavorites, setLoadLoadNotesFavorites] = useState("");

    const [loadWatchLoadFavorites, setLoadWatchLoadFavorites] = useState(false);
    const [bidExpirationFavorites, setBidExpirationFavorites] = useState("");
    const [loadBidAmountDisableFavorites, setLoadBidAmountDisableFavorites] = useState(false);

    //--------------------------------------------*********************--------------------------

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    //Function to scroll bottom
    function scrollToBottom() {
        animateScroll.scrollToBottom({});
    }

    // function to handle Confirm message before leaving edit form
    const handleLeavingEditLoadForm = () => {
        if (window.confirm("Changes you made may not be saved")) {
            setLoadUpdatedStatus(true)
            return true;
        }
        else {
            return setLoadUpdatedStatus(false)
        }
    }

    const handleLeavingSearchLoadForm = () => {
        if (window.confirm("Changes you made may not be saved")) {
            setLoadUpdatedStatus(true)
            return true;
        }
    }

    const handleCloseSecondaryPaneForNotificationOpen = () => {
        closeSecondaryPanePostedLoads();
        closeSecondaryPaneFavorites();
    }

    const handleGettingConfirmationForPostedLoadsClosingSecondaryPane = () => {
        if (postLoadFormFunctionality === "Edit Load") {
            let getResponse = PostLoadFormRef.current.handleSendingResponse()
            if (getResponse) {
                if (getResponse[0].isValueChanged === true) {
                    let leavingResponse = handleLeavingEditLoadForm()
                    if (leavingResponse === true) {
                        closeSecondaryPanePostedLoads()
                    }
                    else {
                        if (leavingResponse === undefined) {
                            setShowing(showing);
                        }
                    }
                }
                else {
                    if (getResponse[0].isValueChanged === false) {
                        setLoadUpdatedStatus(true)
                        if (!loadUpdatedStatus) {
                            setShowing(!showing);
                            setSelectedRowDataValuePostedLoads([])
                            setPristine()
                            setPostLoadFormFunctionality("Post Load")
                            closeSecondaryPanePostedLoads()
                        }
                        else {
                            setShowing(!showing);
                            setSelectedRowDataValuePostedLoads([])
                            setPristine()
                            setPostLoadFormFunctionality("Post Load")
                            closeSecondaryPanePostedLoads()
                        }
                    }
                }
            }
        }
        else if (isUpdateLoadExpirationValueChanged === true) {
            let leavingResponse = handleLeavingEditLoadForm()
            if (leavingResponse === true) {
                closeSecondaryPanePostedLoads();
            }
        }
        else {
            closeSecondaryPanePostedLoads();
        }
    }

    // Function to handle pane close
    const closeSecondaryPanePostedLoads = () => {
        setLabel("Search Load")
        window.localStorage.removeItem('LoadId');
        setPristine()
        setPostLoadFormFunctionality("Post Load")
        setIsUpdateLoadExpirationValueChanged(false)
        removeBackroundColourForPostedLoads();
        setDisplaySpecificColumns(true);
        setMainGridPercentPostedLoads("100%");
        setSelectedRowDataValuePostedLoads([])
        setIsPaneOpenPostedLoads(false);
        isPaneOpenForSocketIO = false;
        const findChatResult = chatDetails.filter(
            ({ load_id }) => load_id === selectedRowforLoadsPosted[0]
        );
        updateChatReadStatusForLoadsPosted(findChatResult);
        setExpandColapseTopPannel(true)
        setPristine()
    };

    const handleGettingConfirmationForFavouritesClosingSecondaryPane = () => {
        if (loadBidAmount !== "" && loadBidAmountDisableFavorites === false) {
            let leavingResponse = handleLeavingEditLoadForm()
            if (leavingResponse === true) {
                closeSecondaryPaneFavorites()
            }
        }
        else {
            closeSecondaryPaneFavorites()
        }
    }
    const closeSecondaryPaneFavorites = () => {
        window.localStorage.removeItem('LoadId');
        setPristine()
        setPostLoadFormFunctionality("Post Load")
        setIsUpdateLoadExpirationValueChanged(false)
        setMainGridPercentFavorites("100%");
        setSelectedRowDataValueFavorites([])
        removeBackroundColourForFavorites()
        setIsPaneOpenFavorites(false);
        const findChatResult = chatDetails.find(
            ({ load_id }) => load_id === selectedRowforFavorites[0]
        );
        updateChatReadStatus(findChatResult);
        isPaneOpenForSocketIO = false;
        setDisplaySpecificColumns(true);
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    // Function to handle Tab Pannel Changes
    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };

    //---------------------------- Place to handle all the functions ----------------------------
    // Function that is triggered on page load
    useEffect(() => {
        checkUserRole();
        handleLocalStorageValue(user.email)
        fetchMainMuiDatatableValues();
        ExpireAndFetchAllBids();
        try {
            setInterval(async () => {
                ExpireAndFetchAllBids();
            }, 120000);
        } catch (e) {
            console.log(e);
        }
    },[]);

    const getCombinedFreightDimension = (rowDataValue) => {
        let loadDimension
        if (rowDataValue.freight_dimension_height !== "" || rowDataValue.freight_dimension_length !== "" || rowDataValue.freight_dimension_width !== "") {
            if (rowDataValue.freight_dimension_length === "") {
                loadDimension = "NA X ";
            }
            else {
                loadDimension = rowDataValue.freight_dimension_length.toString().trim() + "L X ";
            }
            if (rowDataValue.freight_dimension_width === "") {
                loadDimension += "NA X ";
            }
            else {
                loadDimension += rowDataValue.freight_dimension_width.toString().trim() + "W X ";
            }
            if (rowDataValue.freight_dimension_height === "") {
                loadDimension += "NA"
            }
            else {
                loadDimension += rowDataValue.freight_dimension_height.toString().trim() + "H";
            }
        }
        else {
            loadDimension = "NA"
        }
        return loadDimension
    }

    // function to accept bid
    async function acceptBidData(acceptedRow) {
        setDisableBookit(true)
        setDisableCounterButton(true)
        setDisadbleEdit(true)
        setAwardedCarrierName(acceptedRow.rowData[7])
        const findResult = rowDataForPostedLoads.find(
            ({ load_id }) => load_id === selectedRowDataValuePostedLoads.load_id
        );

        let listValue = findResult;
        listValue = { ...listValue, "posted_by_company": acceptedRow.rowData[1] };
        listValue = { ...listValue, "bid_amount": acceptedRow.rowData[5] };
        listValue = { ...listValue, "load_id": acceptedRow.rowData[2] };
        listValue = { ...listValue, "broker_id": user.email };
        listValue = { ...listValue, "carrier_id": acceptedRow.rowData[7] };
        listValue = { ...listValue, "load_dimension": loadFreightDimension };
        listValue = { ...listValue, "equipment_needed": loadLoadType };
        listValue = { ...listValue, "bid_notes": acceptedRow.rowData[10] };
        listValue = { ...listValue, "miles_out": acceptedRow.rowData[11] };
        listValue = { ...listValue, "load_notes": selectedRowDataValuePostedLoads.load_notes };
        listValue = { ...listValue, "carrier_company": acceptedRow.rowData[4] };
        listValue = { ...listValue, "truck_dimension": acceptedRow.rowData[12] };
        listValue = { ...listValue, "loadUrl": window.location.origin + '/loadboard?loadId=' + slugid.encode(acceptedRow.rowData[2]) };
        if (selectedRowDataValuePostedLoads.posted_by_user === user.email) {
            if (selectedRowDataValuePostedLoads.load_id === acceptedRow.rowData[2]) {
                if (selectedRowDataValuePostedLoads.status === "Closed") {
                    Swal.fire({
                        text: 'A bid is already accepted',
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                }
                else {
                    // Nested axios
                    await axios
                        ({
                            url: environmentVariables.acceptbid,
                            method: "patch",
                            data: {
                                load_id: acceptedRow.rowData[2],
                                bid_id: acceptedRow.rowData[3],
                                carrier_name: acceptedRow.rowData[7],
                                status: "accepted",
                                carrier_company: acceptedRow.rowData[4]
                            },
                        })
                        .then((response) => {
                            setSnackBarMessageText("Bid accepted");
                            setOpenSnackBar(true);
                            onSendMessageClick(`Bid of amount $${acceptedRow.rowData[4]} accepted `, "System Message");
                            handleDeletingExpiredLoadNotification(acceptedRow.rowData[1], "Load Accepted");
                            fetchMainMuiDatatableValues();
                            const findChatResult = chatDetails.filter(
                                ({ load_id }) => load_id === selectedRowforFavorites[0]
                            );
                            updateChatReadStatus(findChatResult);
                            sendMessageForSocketIO("BidHasBeenAccepted||load_id:" + acceptedRow.rowData[1] + "||posted_by_user:" + acceptedRow.rowData[3], process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, acceptedRow);
                            sendMessageForSocketIO("LoadHasBeenAwarded||load_id:" + acceptedRow.rowData[1] + "||posted_by_user:" + acceptedRow.rowData[3], process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, acceptedRow);
                            sendMessageForSocketIO("YourLoadHasBennAccepted||load_id:" + acceptedRow.rowData[1] + "||posted_by_user:" + acceptedRow.rowData[3], "", true, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, acceptedRow);
                            sendMessageForSocketIO("newNotification", "", false);
                            setIsNotificationSent(true)
                            return axios({
                                url: environmentVariables.acceptbidnotification,
                                method: "post",
                                data: listValue
                                // data: {
                                //     posted_by_company: acceptedRow.rowData[0],
                                //     bid_amount: acceptedRow.rowData[4],
                                //     load_id: acceptedRow.rowData[1],
                                //     broker_id: user.email,
                                //     carrier_id: acceptedRow.rowData[6],
                                //     load_dimension: loadFreightDimension,
                                //     equipment_needed: loadLoadType,
                                //     bid_Notes: acceptedRow.rowData[9],
                                //     miles_Out: acceptedRow.rowData[10],
                                //     truck_dimension: acceptedRow.rowData[11],
                                //     load_notes: loadLoadType,
                                // },
                            })
                        })
                        .then((response) => {
                            fetchMainMuiDatatableValues();
                        })
                        .catch((error) => { });
                }
            }
        }
        else {
            Swal.fire({
                text: 'Not authorized to accept the bid',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
    }

    // Axios Functions
    const editLoadDetail = async (data) => {
        try {
            let response = await axios({
                url: environmentVariables.editloaddetail,
                method: "patch",
                data: data,
            });
            return response;
        } catch (error) { }

    }

    //Accepting Counter Offer 
    async function onAcceptClickCancel(recievedMessage) {
        Swal.fire({
            text: `Counter offer of $${recievedMessage.split("||")[3].split(":")[1]} has been accepted on Load from ${recievedMessage.split("||")[4].split(":")[1]} to ${recievedMessage.split("||")[5].split(":")[1]} for the Load ID ${recievedMessage.split("||")[6].split(":")[1]} Click here "Ok" to review counter offer or "Cancel" to review later `,
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok'
        }).then(async (result) => {
            if (result.dismiss !== "cancel") {
                if (recievedMessage.split("||")[7].split(":")[1] === "Loads Posted") {
                    if (isPaneOpenPostedLoads === true) {
                        if (recievedMessage.split("||")[1].split(":")[1] === selectedRowDataValuePostedLoads.load_id) {
                            onAcceptClickOk(recievedMessage)
                        }
                        else {
                            closeSecondaryPanePostedLoads()
                            fetchAndOpen(recievedMessage)
                            onAcceptClickOk(recievedMessage)
                        }
                    }
                    else {
                        fetchAndOpen(recievedMessage)
                    }
                }
                else {
                    if (isPaneOpenFavorites === true) {
                        if (recievedMessage.split("||")[1].split(":")[1] === selectedRowDataValueFavorites.load_id) {
                            onAcceptClickOk(recievedMessage)
                        }
                        else {
                            closeSecondaryPaneFavorites()
                            fetchAndOpen(recievedMessage)
                            onAcceptClickOk(recievedMessage)
                        }
                    }
                    else {
                        fetchAndOpen(recievedMessage)
                    }
                }
            }
        })
    }
    async function onAcceptClickOk(recievedMessage) {
        await axios({
            url:
                environmentVariables.getcarrierloadboard +
                "?carrier_name=" +
                user.email,
            method: "get",
        })
            .then((response) => {
                let data = JSON.parse(response.data.body)
                const findResult = data.find(
                    ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                );
                if (loadPostedByUser === user.email) {
                    populateSecondaryPaneValuesPostedLoads(findResult);
                    setDisplaySpecificColumns(false);
                    setMainGridPercentPostedLoads("35%");
                    setIsPaneOpenPostedLoads(true);
                    isPaneOpenForSocketIO = true;
                    selectedRowDataValueForPostedLoadsSocketIO = findResult;
                    setSelectedRowDataValuePostedLoads(findResult);
                    loadAndRealoadSecondGridPostedLoads(recievedMessage.split("||")[1].split(":")[1]);
                }
                else {
                    populateSecondaryPaneValuesFavorites(findResult);
                    setDisplaySpecificColumns(false);
                    setMainGridPercentFavorites("35%");
                    setIsPaneOpenFavorites(true);
                    isPaneOpenForSocketIO = true;
                    selectedRowDataValueForFavoritesSocketIO = findResult;
                    setSelectedRowDataValueFavorites(findResult)
                    loadAndRealoadSecondGridForFavourites(recievedMessage.split("||")[1].split(":")[1]);
                }
            })
            .catch((error) => { });
    }

    //Function used Repeadtly
    function fetchAndOpen(recievedMessage) {
        fetchMainMuiDatatableValues();
        window.localStorage.setItem('LoadId', recievedMessage.split("||")[1].split(":")[1]);

    }
    //Submitting Counter Offer
    function paneOpen(recievedMessage) {
        if (recievedMessage.split("||")[7].split(":")[1] === "Loads Posted") {
            if (recievedMessage.split("||")[1].split(":")[1] === loadNumber) {
                Swal.fire({
                    text: `Counter offer of $${recievedMessage.split("||")[3].split(":")[1]} has been submitted on Load from ${recievedMessage.split("||")[4].split(":")[1]} to ${recievedMessage.split("||")[5].split(":")[1]} for the Load ID ${recievedMessage.split("||")[6].split(":")[1]} Click here "Ok" to review counter offer`,
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc',
                    confirmButtonText: 'Ok'
                })
                loadAndRealoadSecondGridPostedLoads(recievedMessage.split("||")[1].split(":")[1])
            }
            else {
                ifPaneClose(recievedMessage)
            }
        }
        else {
            if (recievedMessage.split("||")[1].split(":")[1] === loadNumber) {
                Swal.fire({
                    text: `Counter offer of $${recievedMessage.split("||")[3].split(":")[1]} has been submitted on Load from ${recievedMessage.split("||")[4].split(":")[1]} to ${recievedMessage.split("||")[5].split(":")[1]} for the Load ID ${recievedMessage.split("||")[6].split(":")[1]} Click here "Ok" to review counter offer`,
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc',
                    confirmButtonText: 'Ok'
                })
                loadAndRealoadSecondGridForFavourites(recievedMessage.split("||")[1].split(":")[1])
            }
            else {
                ifPaneClose(recievedMessage)
            }
        }

    }

    function ifPaneClose(recievedMessage) {
        Swal.fire({
            text: `Counter offer of $${recievedMessage.split("||")[3].split(":")[1]} has been submitted on Load from ${recievedMessage.split("||")[4].split(":")[1]} to ${recievedMessage.split("||")[5].split(":")[1]} for the Load ID ${recievedMessage.split("||")[6].split(":")[1]} Click here "Ok" to review counter offer or "Cancel" to review later`,
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok'
        }
        ).then((result) => {
            if (result.value === true) {
                if (recievedMessage.split("||")[7].split(":")[1] === "Loads Posted") {
                    if (isPaneOpenPostedLoads === true) {
                        if (recievedMessage.split("||")[1].split(":")[1] === selectedRowDataValuePostedLoads.load_id) {
                            loadAndRealoadSecondGridPostedLoads(recievedMessage.split("||")[1].split(":")[1])
                        }
                        else {
                            closeSecondaryPanePostedLoads()
                            fetchAndOpen(recievedMessage)
                        }
                    }
                    else {
                        fetchAndOpen(recievedMessage)
                    }
                }
                else {
                    if (isPaneOpenFavorites === true) {
                        if (recievedMessage.split("||")[1].split(":")[1] === selectedRowDataValueFavorites.load_id) {
                            loadAndRealoadSecondGridForFavourites(recievedMessage.split("||")[1].split(":")[1])
                        }
                        else {
                            closeSecondaryPaneFavorites()
                            fetchAndOpen(recievedMessage)
                        }
                    }
                    else {
                        fetchAndOpen(recievedMessage)
                    }
                }
            }
        })
    }
    const getCarrierLoadboard = async () => {
        try {
            let response = await axios({
                url: environmentVariables.getcarrierloadboard +
                    "?carrier_name=" +
                    user.email,
                method: "get",
            })
            return response;
        } catch (error) {
            console.log("fetch load error", error)
        }

    }

    // Function to recive all the socket io messages
    async function recieveMessageForSocketIo(message) {
        let recievedMessage = message.data.replace(/\"/g, "");

        // Recieveing a message from carrier for new load
        if (recievedMessage.includes("newLoadHasBeenPlaced")) {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'There is a new Load',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
        }

        if (recievedMessage.includes("ThereIsANewMessageFromCarrier") || recievedMessage.includes("ThereIsNewMessageFromCarrier")) {
            if (isPaneOpenPostedLoads === true) {
                if (selectedRowDataValuePostedLoads.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    scrollToBottom()
                }
            }
            addNotification({
                title: 'Notification',
                subtitle: 'There is a Message',
                message: 'There is a new Message',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });

            if ((isPaneOpenForSocketIO || isPaneOpenForSocketIO === false) && selectedRowDataValuePostedLoads.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                fetchAllComments(
                    {
                        "load_id": recievedMessage.split("||")[1].split(":")[1],
                        "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                    }, "Loads Posted"
                );
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    fetchAllComments(
                        {
                            "load_id": recievedMessage.split("||")[1].split(":")[1],
                            "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                        }, "Favorites"
                    );
                }
                try {
                    let response = await getCarrierLoadboard();
                    if (response) {
                        const findResult = response.data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        selectedRowDataValueForPostedLoadsSocketIO = findResult;
                    }
                }
                catch (error) { }
            }
        }
        // Recieveing a message from carrier for new message
        if (recievedMessage.includes("ThereIsANewMessageFromBroker") || recievedMessage.includes("ThereIsNewMessageFromBroker")) {
            if (isPaneOpenFavorites === true) {
                if (selectedRowDataValueFavorites.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    scrollToBottom()
                }
            }
            addNotification({
                title: 'Notification',
                subtitle: 'There is a Message',
                message: 'There is a new Message',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });

            if (loadPostedByUser !== user.email && selectedRowDataValueFavorites.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                fetchAllComments({
                    "load_id": recievedMessage.split("||")[1].split(":")[1],
                    "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                }, "Favorites"
                );
            }
            if ((user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser === user.email) && selectedRowDataValueFavorites.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                fetchAllComments({
                    "load_id": recievedMessage.split("||")[1].split(":")[1],
                    "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                }, "Loads Posted"
                );
            }
            try {
                let response = await getCarrierLoadboard();
                if (response) {
                    const findResult = response.data.find(
                        ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                    );
                    selectedRowDataValueForFavoritesSocketIO = findResult;
                }
            }
            catch (error) { }
        }
        // Receive a message on counter offer
        if ((recievedMessage.includes("ThereIsACounterOffer")) || (recievedMessage.includes("CounterOfferIsUpdated"))) {
            if ((isPaneOpenFavorites === false && loadPostedByUser !== user.email) || (isPaneOpenPostedLoads === false && loadPostedByUser === user.email)) {
                ifPaneClose(recievedMessage)
            }
            else {
                paneOpen(recievedMessage)
            }
            let messageText = "";
            if (recievedMessage.includes("ThereIsACounterOffer")) {
                messageText = 'There is a counter offer';
            }
            if (recievedMessage.includes("CounterOfferIsUpdated")) {
                messageText = 'Counter Offer is updated';
            }
            addNotification({
                title: 'Notification',
                subtitle: messageText,
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });

        }

        // Receive a message on counter offer
        if (recievedMessage.includes("CounterOfferAccepted")) {
            if ((isPaneOpenFavorites === false && loadPostedByUser !== user.email) || (isPaneOpenPostedLoads === false && loadPostedByUser === user.email)) {
                onAcceptClickCancel(recievedMessage)
            }
            else {
                if (recievedMessage.split("||")[1].split(":")[1] === selectedRowDataValueFavorites.load_id || recievedMessage.split("||")[1].split(":")[1] === selectedRowDataValuePostedLoads.load_id) {
                    Swal.fire({
                        text: `Counter offer of $${recievedMessage.split("||")[3].split(":")[1]} has been accepted on Load from ${recievedMessage.split("||")[4].split(":")[1]} to ${recievedMessage.split("||")[5].split(":")[1]} for the Load ID ${recievedMessage.split("||")[6].split(":")[1]} Click here "Ok" to review counter offer`,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc',
                        confirmButtonText: 'Ok'
                    })
                    onAcceptClickOk(recievedMessage)
                }
                else {
                    onAcceptClickCancel(recievedMessage)
                }
            }
        }
        // Recieve message for update bid
        if (recievedMessage.includes("BrokerLoadHasBeenWatchList") || recievedMessage.includes("BrokerLoadHasBeenWatchListRemoved")) {
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForFavoritesSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    try {
                        let response = await getCarrierLoadboard();
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            selectedRowDataValueForFavoritesSocketIO = findResult;
                            setSelectedRowDataValuePostedLoads(findResult);
                        }
                    }
                    catch (error) { }
                }
            }
        }

        // receiving message for chat read
        if (recievedMessage.includes("chatHasBeenRead")) {
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForPostedLoadsSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    let response = await getCarrierLoadboard();
                    if (response) {
                        const findResult = response.data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        populateSecondaryPaneValuesPostedLoads(findResult);
                        selectedRowDataValueForPostedLoadsSocketIO = findResult;
                        setSelectedRowDataValuePostedLoads(findResult);
                        setSelectedRowDataValueFavorites(findResult)
                    }
                }
            }
        }

        // receiving message for new notification
        if (recievedMessage.includes("ThereIsNewNotifiction")) {
            fetchMainMuiDatatableValues();
        }

        if (recievedMessage.includes("BrokerLoadHasBeenDeleted") || recievedMessage.includes("BrokerLoadHasBeenExpired")) {
            let messageText = "";
            if (recievedMessage.includes("BrokerLoadHasBeenDeleted")) {
                messageText = 'Load has been deleted';
            }
            if (recievedMessage.includes("BrokerLoadHasBeenExpired")) {
                messageText = 'Load has been Expired';
            }
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            handleGettingConfirmationForPostedLoadsClosingSecondaryPane();
            handleGettingConfirmationForFavouritesClosingSecondaryPane();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForPostedLoadsSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1] || selectedRowDataValueForFavoritesSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    Swal.fire({
                        text: messageText,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                }
            }
        }

        if (recievedMessage.includes("BrokerLoadHasBeenUpdated")) {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'Load has been Updated',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForPostedLoadsSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    let response = await getCarrierLoadboard();
                    if (response) {
                        const findResult = response.data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        populateSecondaryPaneValuesPostedLoads(findResult);
                        selectedRowDataValueForPostedLoadsSocketIO = findResult;
                        setSelectedRowDataValuePostedLoads(findResult);
                    }
                }
                else if (selectedRowDataValueForFavoritesSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    let response = await getCarrierLoadboard();
                    if (response) {
                        const findResult = response.data.find(
                            ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                        );
                        populateSecondaryPaneValuesPostedLoads(findResult);
                        selectedRowDataValueForFavoritesSocketIO = findResult;
                        setSelectedRowDataValuePostedLoads(findResult);
                    }
                }
            }
        }

        if (recievedMessage.includes("YourLoadHasBennAccepted")) {
            let notificationMessage = ""
            if (recievedMessage.split("||")[2].split(":")[1] === user.email) {
                notificationMessage = "Your bid has been accepted"
            }
            else {
                notificationMessage = "Load has been awarded"
            }
            addNotification({
                title: 'Notification',
                subtitle: notificationMessage,
                message: notificationMessage,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            handleGettingConfirmationForPostedLoadsClosingSecondaryPane();
            handleGettingConfirmationForFavouritesClosingSecondaryPane();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForPostedLoadsSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1] || selectedRowDataValueForFavoritesSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    Swal.fire({
                        text: notificationMessage,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                }
            }
        }

        if (recievedMessage.includes("BidHasBeenAccepted") || recievedMessage.includes("LoadHasBeenAwarded")) {
            addNotification({
                title: 'Notification',
                subtitle: "There is a Message",
                message: "There is a Message",
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    Swal.fire({
                        text: "Bid Accepted and Load Awarded",
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                }
            }
        }

        // Recive a message on new bid
        if (recievedMessage.includes("ThereIsANewBid") || recievedMessage.includes("ABidHasBeenRemoved") || recievedMessage.includes("ThereIsNewBid") || recievedMessage.includes("BidHasBeenRemoved")) {
            let messageText = "";
            if (recievedMessage.includes("ThereIsANewBid")) {
                messageText = 'There is a new Bid';
            }
            if (recievedMessage.includes("ABidHasBeenRemoved")) {
                messageText = 'A Bid has been Removed';
            }
            addNotification({
                title: 'Notification',
                subtitle: messageText,
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForPostedLoadsSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1] || selectedRowDataValueForFavoritesSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                    loadAndRealoadSecondGridPostedLoads(recievedMessage.split("||")[1].split(":")[1]);
                    if (recievedMessage.includes("ThereIsANewBid")) {
                        fetchAllComments(
                            {
                                "load_id": recievedMessage.split("||")[1].split(":")[1],
                                "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                            }, "Loads Posted"
                        )
                    }
                    if (recievedMessage.includes("ABidHasBeenRemoved")) {
                        if (loadPostedByUser === user.email) {
                            fetchAllComments(
                                {
                                    "load_id": recievedMessage.split("||")[1].split(":")[1],
                                    "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                                }, "Loads Posted"
                            )
                        }
                        else {
                            fetchAllComments(
                                {
                                    "load_id": recievedMessage.split("||")[1].split(":")[1],
                                    "posted_by_user": recievedMessage.split("||")[2].split(":")[1]
                                }, "Favorites"
                            )
                        }
                    }
                }
            }
            else {
                fetchMainMuiDatatableValues();
            }
        }

        // Recive a message on bid expires
        if (recievedMessage.includes("YourBidHasExpired")) {
            if (recievedMessage.split("||")[2].split(":")[1] === user.email) {
                addNotification({
                    title: 'Notification',
                    subtitle: 'Your Bid has been Expired',
                    message: 'Your Bid has been Expired',
                    theme: 'darkblue',
                    duration: 10000,
                    native: true // when using native, your OS will handle theming.
                });

                if (isPaneOpenForSocketIO) {
                    if (selectedRowDataValueForPostedLoadsSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                        fetchMainMuiDatatableValues();
                        let response = await getCarrierLoadboard();
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            populateSecondaryPaneValuesPostedLoads(findResult);
                            selectedRowDataValueForPostedLoadsSocketIO = findResult;
                            setSelectedRowDataValuePostedLoads(findResult);
                        }
                    }
                    else if (selectedRowDataValueForFavoritesSocketIO.load_id === recievedMessage.split("||")[1].split(":")[1]) {
                        fetchMainMuiDatatableValues();
                        let response = await getCarrierLoadboard();
                        if (response) {
                            const findResult = response.data.find(
                                ({ load_id }) => load_id === recievedMessage.split("||")[1].split(":")[1]
                            );
                            populateSecondaryPaneValuesPostedLoads(findResult);
                            selectedRowDataValueForFavoritesSocketIO = findResult;
                            setSelectedRowDataValuePostedLoads(findResult);
                        }
                    }
                }
                else {
                    fetchMainMuiDatatableValues();
                }
            }
        }
        if (recievedMessage.includes("Your MC Certificate Verification is Succeeded")) {
            handleLocalStorageValue(user.email)
            fetchAllComments({
                "load_id": selectedRowDataValueForPostedLoadsSocketIO.load_id,
                "posted_by_user": selectedRowDataValueForPostedLoadsSocketIO.posted_by_user
            })
            fetchAllComments({
                "load_id": selectedRowDataValueForFavoritesSocketIO.load_id,
                "posted_by_user": selectedRowDataValueForFavoritesSocketIO.posted_by_user
            })
            loadAndRealoadSecondGridForFavourites(selectedRowDataValueForFavoritesSocketIO.load_id)
            loadAndRealoadSecondGridPostedLoads(selectedRowDataValueForPostedLoadsSocketIO.loadId)
        }
    }


    //Function to handle send Socket IO messages
    async function sendMessageForSocketIO(message, recieverId, sendNotification, selectedRowPostedLoads, selectedRowFavotites, bidDetails) {
        try {
            let response = await axios({
                url: environmentVariables.websocketfetchallusers,
                method: 'get'
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse) {
                handleAddNotification(message, recieverId, sendNotification, selectedRowPostedLoads, selectedRowFavotites, bidDetails)
                let connectionIds = []
                newResponse.forEach(element => {
                    if (recieverId === null || recieverId === "") {
                        connectionIds.push(element.connection_id)
                    }
                    else {
                        if (recieverId === element.user_id) {
                            connectionIds.push(element.connection_id)
                        }
                    }

                });
                sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    async function handleAddNotification(message, recieverId, sendNotification, selectedRowPostedLoads, selectedRowFavotites, bidDetails) {
        let loadId = ""
        let messages = ""
        if (message.includes("load_id")) {
            loadId = message.split("||")[1].split(":")[1]
            messages = message.split("||")[0]
        }
        let postedByUser = ""
        let wonUser = ""
        let bidCarrier = ""
        let postedCompany = ""
        let carrierCompany = ""
        let bidCarriers = []
        let loadNumber = ""
        if (sendNotification === true) {
            if (messages === "BrokerLoadHasBeenUpdated") {
                let chats = allChatForLoadsPosted
                if (chats.Items !== undefined) {
                    chats.Items.forEach(element => {
                        bidCarriers.push(element.carrier_id)
                    })
                }
                loadNumber = selectedRowPostedLoads.load_number
                postedByUser = selectedRowPostedLoads.posted_by_user
                bidCarrier = ""
                postedCompany = selectedRowPostedLoads.posted_by_company
            }
            else if (messages === "ThereIsANewBid") {
                loadNumber = selectedRowFavotites.load_number
                postedByUser = selectedRowFavotites.posted_by_user
                bidCarrier = user.email
                carrierCompany = bidDetails.carrier_company
            }
            else if (messages === "ThereIsACounterOffer") {
                loadNumber = selectedRowPostedLoads.load_number
                postedByUser = selectedRowPostedLoads.posted_by_user
                bidCarrier = ""
                postedCompany = selectedRowPostedLoads.posted_by_company
            }
            else if (messages === "CounterOfferIsUpdated") {
                loadNumber = selectedRowFavotites.load_number
                postedByUser = selectedRowFavotites.posted_by_user
                bidCarrier = user.email
                carrierCompany = userCompanyName
            }

            else if (messages === "ABidHasBeenRemoved") {
                loadNumber = selectedRowFavotites.load_number
                postedByUser = selectedRowFavotites.posted_by_user
                bidCarrier = user.email
                carrierCompany = bidDetails.carrier_company
            }
            else if (messages === "YourLoadHasBennAccepted") {
                loadNumber = selectedRowPostedLoads.load_number
                postedByUser = selectedRowPostedLoads.posted_by_user
                wonUser = bidDetails.rowData[6]
                carrierCompany = bidDetails.rowData[3]
            }
            else if (messages === "ThereIsANewMessageFromBroker") {
                loadNumber = selectedRowPostedLoads.load_number
                postedByUser = selectedRowPostedLoads.posted_by_user
                bidCarrier = ""
                postedCompany = selectedRowPostedLoads.posted_by_company
            }
            else if (messages === "ThereIsANewMessageFromCarrier") {
                loadNumber = selectedRowFavotites.load_number
                postedByUser = selectedRowFavotites.posted_by_user
                bidCarrier = ""
                carrierCompany = userCompanyName
            }
            let listValues = {
                'load_id': loadId,
                'receiver_id': recieverId,
                'bid_carrier': bidCarrier,
                'won_carrier': wonUser,
                'posted_by_user': postedByUser,
                'message': messages,
                'time_stamp_notification': moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                'generated_date': moment(new Date()).format("MM/DD/YYYY"),
                'carrier_company': carrierCompany,
                'load_expired_status': false,
                'posted_company': postedCompany,
                'bid_carriers': bidCarriers,
                'load_number': loadNumber,
                'won_message': false,
                'read_users_list': []
            }
            try {
                let Response = await axios({
                    url: environmentVariables.addnotifications,
                    method: "put",
                    data: listValues
                });
                if (Response) {
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    // Function to check the user role
    async function checkUserRole() {
        if (window.localStorage.ifUserExist) {
            if (window.localStorage.getItem('login_status') === "ProfileAccepted") {
                if (window.localStorage.getItem('company_role')) {
                    setLoggedInuserCompanyRole(window.localStorage.getItem('company_role'));
                }
                if (window.localStorage.getItem('user_role')) {
                    setLoggedInuserRole(window.localStorage.getItem('user_role'));
                }
                if (window.localStorage.getItem('userCompanyName')) {
                    setUserCompanyName(window.localStorage.getItem('userCompanyName'));
                }
            }
            else if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                history.push({ pathname: '/callback' });
            }
        }
        else if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: '/callback' });
        }
    }

    // Function to fetch all the data main MuiDatatable
    async function fetchMainMuiDatatableValues() {
        try {
            let response = await getCarrierLoadboard();
            let newResponse = JSON.parse(response.data.body)
            console.log("fetch load response", newResponse)
            if (newResponse.length === 0) {
                setLoadingPostedLoads(true);
                setLoadingFavorites(true);
                setLoadNumberLength(8)
            }
            else {
                let loadIds = []
                newResponse.forEach(element => {
                    if (element.load_number) {
                        loadIds.push(element.load_number)
                    }
                })
                setLoadNumberLength(Math.max(...loadIds).toString().length)
                setLoadingPostedLoads(true);
                setLoadingFavorites(true);
            }
            //To delete loads 
            // Local array to handelremoval of rows
            newResponse.sort((a, b) =>
                b.time_stamp.localeCompare(a.time_stamp)
            );

            let loadsPostedList = newResponse.filter(singleVal => {
                return ((singleVal['load_delete_status'] !== true
                    && singleVal['load_expired_status'] !== true
                    && singleVal['status'] === "Bid Pending")
                    && singleVal['posted_by_user'] === user.email)
                    ||
                    (singleVal['load_delete_status'] !== true
                        && singleVal['load_expired_status'] !== true
                        && singleVal['status'] !== "Closed"
                        && singleVal['status'] !== "Awarded"
                        && singleVal['posted_by_user'] === user.email
                    )
            });
            setRowDataForPostedLoads(loadsPostedList);

            let favoritesList = newResponse.filter(singleVal => {
                return (singleVal['load_delete_status'] !== true
                    && singleVal['carrier_name'] === user.email
                    && singleVal['status'] === "Bid Pending")
                    ||
                    (singleVal['load_delete_status'] !== true
                        && singleVal['load_chat_initiated'] === user.email)
                // ||
                // (singleVal['load_delete_status'] !== true
                //     && singleVal['load_expired_status'] !== true
                //     && singleVal['status'] !== "Closed"
                //     && singleVal['status'] !== "Awarded"
                //     && singleVal.watch_load.find(({ name, accStatus }) => name === user.email && accStatus === true))
            });
            setRowDataForFavorites(favoritesList);

            if (user.email === process.env.REACT_APP_ADMIN_USER) {
                let loadsPostedList = newResponse.filter(singleVal => {
                    return (singleVal['load_delete_status'] !== true
                        && singleVal['load_expired_status'] !== true
                        && singleVal['status'] === "Bid Pending"
                        && singleVal['posted_by_user'] !== user.email)
                        ||
                        (singleVal['load_delete_status'] !== true
                            && singleVal['load_expired_status'] !== true
                            && singleVal['status'] !== "Closed"
                            && singleVal['status'] !== "Awarded"
                            && singleVal['posted_by_user'] !== user.email
                        )
                });
                setRowDataForPostedLoads(loadsPostedList);

                let favoritesList = newResponse.filter(singleVal => {
                    return (singleVal['load_delete_status'] !== true
                        && singleVal['load_expired_status'] !== true
                        && singleVal['status'] !== "Closed"
                        && singleVal['status'] !== "Awarded"
                        && singleVal.watch_load.find(({ name, accStatus }) => name !== user.email && accStatus === true))
                });
                setRowDataForFavorites(favoritesList);
            }
            removeBackroundColourForPostedLoads();
        }
        catch (error) {
            console.log("error", error)
        }
    }

    // Function to calculate miliseconds for load expiration
    const LoadExpirationInMiliseconds = (expirationTime) => {
        let timeWithoutMinOrHr = expirationTime.split(':');
        let timeInMiliseconds = ((+timeWithoutMinOrHr[0] * (60000 * 60)) + (+timeWithoutMinOrHr[1] * 60000));
        return timeInMiliseconds;
    }

    async function RemoveBidsWhenLoadExpires(allNewBids, loadId) {
        if (allNewBids.length !== 0) {
            allNewBids.forEach(element => {
                if (element.load_id === loadId) {
                    if (element.status !== "accepted" && element.status !== "Closed") {
                        DeleteExpiredBids(element, "loadExpired")
                    }
                }
            });
        }
    }

    //------------------------------------------- PLace to handle bid expiration------------------------------
    // Function to handle get all bids
    async function ExpireAndFetchAllBids() {
        try {
            let response = await axios({
                url: environmentVariables.getbid,
                method: "get"
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse !== "NoBidsAreAvailable") {
                ExpireBidsAfterTimeOut(newResponse)
                return newResponse;
            }
            else {
                return [];
            }
        }
        catch (error) { }
    }

    // Function to handle bid expiration
    const ExpireBidsAfterTimeOut = (allBidInformation) => {
        allBidInformation.forEach(sindleBids => {
            if (sindleBids.status !== "accepted" && sindleBids.status !== "Closed") {
                if (sindleBids.bid_expiration_time) {
                    if (CheckBidExpirationDifference(sindleBids.bid_expiration_time, sindleBids.bid_time_stamp) <= 0) {
                        DeleteExpiredBids(sindleBids, "bidExpired")
                    }
                }
            }
        });
    }

    // Function to check expiration difference
    const CheckBidExpirationDifference = (expirationTime, bidTimeStamp) => {
        const localVarExpirationTime = parseInt(expirationTime.replace(/\D/g, ''));
        var stillUtc = moment.utc(bidTimeStamp).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        let futureTime = moment(local).add(localVarExpirationTime, "minutes");
        let diference = futureTime.diff(moment(), "minutes");
        return diference;
    }

    // Function to handle delete unwanted notifications
    async function handleDeletingExpiredLoadNotification(loadId, functionality) {
        let listValues =
        {
            "load_id": loadId,
            "functionality": functionality,
        }
        try {
            let response = await axios({
                url: environmentVariables.deletenotification +
                    "?load_id=" +
                    loadId,
                method: "patch",
                data: listValues
            });
            if (response) {
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // +++++++++++++++++++++++++++++++++++++ Function to handle soft delete +++++++++++++++++++++++++++++++++++++++++++++
    async function handleSoftDeleteConfirmClick() {
        setPostLoadFormFunctionality("Delete Load")
        if (selectedRowDataValuePostedLoads.posted_by_user === user.email && (selectedRowDataValuePostedLoads.status === "new" || selectedRowDataValuePostedLoads.status === "Active")) {
            Swal.fire({
                text: "Are you sure you want to Delete this load?",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    if (PostLoadFormRef.current !== undefined) {
                        let getResponse = null
                        if (getResponse) {
                            if (getResponse[0].isValueChanged === true || isUpdateLoadExpirationValueChanged === true) {
                                let leavingResponse = handleLeavingEditLoadForm()
                                if (leavingResponse === true) {
                                    handleSoftDelete()
                                }
                            }
                            else {
                                handleSoftDelete()
                            }
                        }
                        else {
                            handleSoftDelete()
                        }
                    }
                    else {
                        handleSoftDelete()
                    }
                }
            })
        }
        else if (selectedRowDataValuePostedLoads.status === "Closed") {
            Swal.fire({
                text: 'Cannot delete load after a bid has been accepted.',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
        else {
            Swal.fire({
                text: 'Not authorized to delete this load',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
    }
    async function handleSoftDelete() {
        setLoadingPostedLoads(true);
        let listValues = selectedRowDataValuePostedLoads;
        listValues = { ...listValues, "load_Delete_Status": true };
        let response = await axios({
            url: environmentVariables.deleteloaddetail,
            method: "patch",
            data: listValues,
        });
        if (response) {
            let data = JSON.parse(response.data.body)
            setSelectedRowDataValuePostedLoads([])
            setShowing(false);
            fetchMainMuiDatatableValues();
            sendMessageForSocketIO("BrokerLoadHasBeenDeleted||load_id:" + selectedRowDataValuePostedLoads.load_id, "", false, [], []);
            sendMessageForSocketIO("newNotification", "", false);
            setLoadingPostedLoads(true);
            closeSecondaryPanePostedLoads()
            reduceCountOnLoadExpire(data, user.email)
        }
    }

    async function DeleteExpiredBids(bidData, functionality) {
        let listValues = bidData
        listValues.status = "Closed"
        try {
            let response = await axios({
                url: environmentVariables.deletebid,
                method: "patch",
                data: listValues
            })
            if (response) {
                let fetchAllCommentResponse = await fetchAllComment()
                const findResult = fetchAllCommentResponse.find(
                    ({ load_id }) => load_id === bidData.load_id
                );
                loadAndRealoadSecondGridForFavourites(bidData.load_id);
                if (bidData.posted_by_user === user.email) {
                    onSendMessageClick(`Bid Closed`, "System Message", "Favorites", findResult);
                }
                fetchAllComments(bidData)
                sendMessageForSocketIO("YourBidHasExpired||load_id:" + bidData.load_id + "||posted_by_user:" + bidData.posted_by_user, bidData.carrier_name, true, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, bidData);
                sendMessageForSocketIO("ABidHasExpired||load_id:" + bidData.load_id + "||posted_by_user:" + bidData.carrier_name, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, bidData);
                sendMessageForSocketIO("newNotification", "", false);
                setIsNotificationSent(true)
            }
        }
        catch (error) { }
    }
    //--------------------------------------------------------------------------------------------------------

    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: "none",
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        "&:nth-child(odd)": {
                            backgroundColor: "#ECECEC",
                        },
                        "&:hover": { backgroundColor: "#aab5be!important" },
                        "&:focus": { backgroundColor: "#000!important" },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to handle remove backround color from selection
    const removeBackroundColourForPostedLoads = () => {
        for (let i = 0; i < rowDataForPostedLoads.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute("style");
                if (styleObject !== null) {
                    if (styleObject.toString().includes("9FD2F3")) {
                        domObject.removeAttribute("style", "background: #9FD2F3");
                    }
                }
            }

        }
    }

    // Function to handle remove backround color for Favorite Table
    const removeBackroundColourForFavorites = () => {
        for (let i = 0; i < rowDataForFavorites.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute("style");
                if (styleObject !== null) {
                    if (styleObject.toString().includes("9FD2F3")) {
                        domObject.removeAttribute("style", "background: #9FD2F3");
                    }
                }
            }

        }
    }

    // Adding values to the secondary pane
    function populateSecondaryPaneValuesPostedLoads(findResult) {
        setMapDistance(findResult);
        setLoadNumber(findResult.load_id)
        setLoadNumberDetail(findResult.load_number);
        setLoadLoadNotesPostedLoads(findResult.load_notes);
        setLoadMaxWeight(findResult.max_weight);
        setLoadPostedBy(findResult.posted_by_company);
        setLoadPostedByUser(findResult.posted_by_user);
        setLoadPickUpdate(findResult.pickupfrom_date);
        setLoadPickUpDestination(findResult.pickup_dest);
        setLoadDeliverDestination(findResult.delivery_dest);
        setLoadDeliverByDate(findResult.pickupto_date);
        if (findResult.truck_type === "" || findResult.truck_type.length === 0) {
            setLoadLoadType("NA");
        }
        else {
            setLoadLoadType(findResult.truck_type.toString().replace(/,/g, ", "))
        }
        if (findResult.status) {
            if (findResult.status === "Closed") {
                setLoadBidAmountDisablePostedLoads(true);
            }
            else {
                if (!findResult.bid_amount) {
                    setLoadBidAmountDisablePostedLoads(false);
                }
            }
        }
        if (findResult.time_stamp) {
            var localTime = moment.utc(findResult.time_stamp).local().format("YYYY-MM-DD HH:mm:ss");
            setloadPostedTimePostedLoads(localTime);
        }
        if (findResult.load_expiration_time) {
            setLoadUpdateLoadExpirationTimeForHour(findResult.load_expiration_time.split(":")[0]);
            setLoadUpdateLoadExpirationTimeForMin(findResult.load_expiration_time.split(":")[1]);
            setLoadExpiresInPostedLoads(LoadExpirationInMiliseconds(findResult.load_expiration_time));
        }
        else {
            setLoadExpiresInPostedLoads(0);
        }

        if (findResult.bid_expiration_time) {
            setBidExpirationPostedLoads(findResult.bid_expiration_time);
            setLoadBidAmountDisablePostedLoads(true);
        }
        else {
            setBidExpirationPostedLoads("15");
            setLoadBidAmountDisablePostedLoads(false);
        }

        if (findResult.watch_load) {
            if (findResult.watch_load.length !== 0) {
                const findUserWatchLoad = findResult.watch_load.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserWatchLoad) {
                    setLoadWatchLoadPostedLoads(true);
                }
                else {
                    setLoadWatchLoadPostedLoads(false);
                }
            }
            else {
                setLoadWatchLoadPostedLoads(false);
            }
        }
        if (findResult.saved_lane) {
            if (findResult.saved_lane.length !== 0 && findResult.saved_lane.length !== undefined) {
                const findUserSavedLane = findResult.saved_lane.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserSavedLane) {
                    setLoadSavedLane(true);
                }
                else {
                    setLoadSavedLane(false);
                }
            }
            else {
                setLoadSavedLane(false);
            }
        }
        else {
            setLoadSavedLane(false);
        }
        if (findResult.total_item_count === "") {
            setLoadNoOfPieces("NA");
        }
        else {
            setLoadNoOfPieces(findResult.total_item_count);
        }
        let freightDimension = ""
        if (findResult.freight_dimension_length.toString().trim() === "" &&
            findResult.freight_dimension_width.toString().trim() === "" &&
            findResult.freight_dimension_height.toString().trim() === "") {
            setLoadFreightDimension("NA")
        }
        else {
            if (findResult.freight_dimension_length.toString().trim() === "") {
                freightDimension += "NA X ";
            }
            else {
                freightDimension += findResult.freight_dimension_length.toString().trim() + "L X ";
            }
            if (findResult.freight_dimension_width.toString().trim() === "") {
                freightDimension += "NA X ";
            }
            else {
                freightDimension += findResult.freight_dimension_width.toString().trim() + "W X ";
            }
            if (findResult.freight_dimension_height.toString().trim() === "") {
                freightDimension += "NA"
            }
            else {
                freightDimension += findResult.freight_dimension_height.toString().trim() + "H";
            }
            setLoadFreightDimension(freightDimension);
        }
        if (findResult.cities_distance) {
            setLoadCityDistance(findResult.cities_distance)
        }
        if (
            findResult.pickup_from_window === "ASAP" &&
            findResult.pickup_to_window === "ASAP"
        ) {
            setLoadPickUpWindow("ASAP");
        } else {
            setLoadPickUpWindow(
                findResult.pickup_from_window + " - " + findResult.pickup_to_window
            );
        }
        if (
            findResult.deliver_from_window.includes("Direct") &&
            findResult.deliver_to_window.includes("Direct")
        ) {
            setLoadDeliverWindow("Deliver Direct(Non-Stop)");
        } else {
            setLoadDeliverWindow(
                findResult.deliver_from_window + " - " + findResult.deliver_to_window
            );
        }
        setLoadStackable(Boolean(findResult.stackable));
        setLoadHazmat(Boolean(findResult.hazmat));
        setLoadDockHigh(Boolean(findResult.dockhigh));
        setLoadTempControl(Boolean(findResult.tempcontrol));
    }

    function populateSecondaryPaneValuesFavorites(findResult) {
        setMapDistance(findResult);
        setLoadNumber(findResult.load_id)
        setLoadNumberDetail(findResult.load_number);
        setLoadLoadNotesFavorites(findResult.load_notes);
        setLoadMaxWeight(findResult.max_weight);
        setLoadPostedBy(findResult.posted_by_company);
        setLoadPostedByUser(findResult.posted_by_user);
        setLoadPickUpdate(findResult.pickupfrom_date);
        setLoadPickUpDestination(findResult.pickup_dest);
        setLoadDeliverDestination(findResult.delivery_dest);
        setLoadDeliverByDate(findResult.pickupto_date);
        if (findResult.truck_type === "" || findResult.truck_type.length === 0) {
            setLoadLoadType("NA");
        }
        else {
            setLoadLoadType(findResult.truck_type.toString().replace(/,/g, ", "))
        }
        if (findResult.status) {
            if (findResult.status === "Closed") {
                setLoadBidAmountDisablePostedLoads(true);
            }
            else {
                if (!findResult.bid_amount) {
                    setLoadBidAmountDisablePostedLoads(false);
                }
            }
        }
        if (findResult.time_stamp) {
            var localTime = moment.utc(findResult.time_stamp).local().format("YYYY-MM-DD HH:mm:ss");
            setloadPostedTimePostedLoads(localTime);
        }
        if (findResult.load_expiration_time) {
            setLoadUpdateLoadExpirationTimeForHour(findResult.load_expiration_time.split(":")[0]);
            setLoadUpdateLoadExpirationTimeForMin(findResult.load_expiration_time.split(":")[1]);
            setLoadExpiresInPostedLoads(LoadExpirationInMiliseconds(findResult.load_expiration_time));
        }
        else {
            setLoadExpiresInPostedLoads(0);
        }

        if (findResult.bid_expiration_time) {
            setBidExpirationFavorites(findResult.bid_expiration_time);
            setLoadBidAmountDisableFavorites(true);
        }
        else {
            setBidExpirationFavorites("15");
            setLoadBidAmountDisableFavorites(false);
        }

        if (findResult.watch_load) {
            if (findResult.watch_load.length !== 0) {
                const findUserWatchLoad = findResult.watch_load.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserWatchLoad) {
                    setLoadWatchLoadPostedLoads(true);
                }
                else {
                    setLoadWatchLoadPostedLoads(false);
                }
            }
            else {
                setLoadWatchLoadPostedLoads(false);
            }
        }
        if (findResult.total_item_count === "") {
            setLoadNoOfPieces("NA");
        }
        else {
            setLoadNoOfPieces(findResult.total_item_count);
        }
        let freightDimension = ""
        if (findResult.freight_dimension_length.toString().trim() === "" &&
            findResult.freight_dimension_width.toString().trim() === "" &&
            findResult.freight_dimension_height.toString().trim() === "") {
            setLoadFreightDimension("NA")
        }
        else {
            if (findResult.freight_dimension_length.toString().trim() === "") {
                freightDimension += "NA X ";
            }
            else {
                freightDimension += findResult.freight_dimension_length.toString().trim() + "L X ";
            }
            if (findResult.freight_dimension_width.toString().trim() === "") {
                freightDimension += "NA X ";
            }
            else {
                freightDimension += findResult.freight_dimension_width.toString().trim() + "W X ";
            }
            if (findResult.freight_dimension_height.toString().trim() === "") {
                freightDimension += "NA"
            }
            else {
                freightDimension += findResult.freight_dimension_height.toString().trim() + "H";
            }
            setLoadFreightDimension(freightDimension);
        }
        if (findResult.cities_distance) {
            setLoadCityDistance(findResult.cities_distance)
        }
        if (findResult.bid_amount) {
            setLoadBidAmount(findResult.bid_amount);
            setLoadBidAmountDisableFavorites(true);
        }
        else {
            setLoadBidAmount("");
            setLoadBidAmountDisableFavorites(false);
        }
        if (findResult.bid_notes) {
            updateBidNotes(findResult.bid_notes);
            setLoadBidNotesDisable(true);
        }
        else {
            if (findResult.bid_notes === null || findResult.bid_notes === "") {
                if (findResult.bid_amount === "" || findResult.bid_amount === undefined) {
                    updateBidNotes("")
                    setLoadBidNotesDisable(false);
                }
                else {
                    setLoadBidNotesDisable(true);
                }
            }
        }

        if (findResult.miles_out) {
            updateTxtMilesOut(findResult.miles_out);
            setMilesOutDisable(true);
        }
        else {
            if (findResult.miles_out === null || findResult.miles_out === "") {
                if (findResult.bid_amount === "" || findResult.bid_amount === undefined) {
                    updateTxtMilesOut("")
                    setMilesOutDisable(false);
                }
                else {
                    setMilesOutDisable(true);
                }
            }

        }
        if (findResult.truck_dimension !== null && findResult.truck_dimension !== "{}") {
            if (findResult.truck_dimension !== "LXWXH") {
                setTxtLength(findResult.truck_dimension_length)
                setTxtHeight(findResult.truck_dimension_height)
                setTxtWidth(findResult.truck_dimension_width)
                setLengthDisable(true);
                setWidthDisable(true);
                setHeightDisable(true);
            }
            else if (findResult.truck_dimension === "LXWXH") {
                setTxtLength("")
                setTxtHeight("")
                setTxtWidth("")
                setLengthDisable(true);
                setWidthDisable(true);
                setHeightDisable(true);
            }
            else if (findResult.truck_dimension === null) {
                setTxtLength("")
                setTxtHeight("")
                setTxtWidth("")
                setLengthDisable(false);
                setWidthDisable(false);
                setHeightDisable(false);
                setMilesOutDisable(false);
            }

        }
        else if (findResult.truck_dimension === "LXWXH") {
            setTxtLength("")
            setTxtHeight("")
            setTxtWidth("")
            setLengthDisable(true);
            setWidthDisable(true);
            setHeightDisable(true);
        }
        else if (findResult.truck_dimension === null || findResult.truck_dimension === "{}") {
            setTxtLength("")
            setTxtHeight("")
            setTxtWidth("")
            setLengthDisable(false);
            setWidthDisable(false);
            setHeightDisable(false);
            setMilesOutDisable(false);
        }

        if (findResult.status) {
            setLoadStatusFavorites(findResult.status)
            if (findResult.status === "Closed") {
                setLoadBidAmountDisableFavorites(true);
            }
            else {
                if (!findResult.bid_amount) {
                    setLoadBidAmountDisableFavorites(false);
                }
            }
        }
        if (findResult.time_stamp) {
            let localTime = moment.utc(findResult.time_stamp).local().format("YYYY-MM-DD HH:mm:ss");
            setloadPostedTimeFavorites(localTime);
        }
        if (findResult.load_expiration_time) {
            setLoadExpiresInFavorites(LoadExpirationInMiliseconds(findResult.load_expiration_time));
        }
        else {
            setLoadExpiresInFavorites(0);
        }

        if (findResult.watch_load) {
            if (findResult.watch_load.length !== 0) {
                const findUserWatchLoad = findResult.watch_load.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserWatchLoad) {
                    setLoadWatchLoadFavorites(true);
                }
                else {
                    setLoadWatchLoadFavorites(false);
                }
            }
            else {
                setLoadWatchLoadFavorites(false);
            }
        }
        if (
            findResult.pickup_from_window === "ASAP" &&
            findResult.pickup_to_window === "ASAP"
        ) {
            setLoadPickUpWindow("ASAP");
        } else {
            setLoadPickUpWindow(
                findResult.pickup_from_window + " - " + findResult.pickup_to_window
            );
        }
        if (
            findResult.deliver_from_window.includes("Direct") &&
            findResult.deliver_to_window.includes("Direct")
        ) {
            setLoadDeliverWindow("Deliver Direct(Non-Stop)");
        } else {
            setLoadDeliverWindow(
                findResult.deliver_from_window + " - " + findResult.deliver_to_window
            );
        }
        setLoadStackable(Boolean(findResult.stackable));
        setLoadHazmat(Boolean(findResult.hazmat));
        setLoadDockHigh(Boolean(findResult.dockhigh));
        setLoadTempControl(Boolean(findResult.tempcontrol));
        if (findResult.saved_lane) {
            if (findResult.saved_lane.length !== 0 && findResult.saved_lane.length !== undefined) {
                const findUserSavedLane = findResult.saved_lane.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserSavedLane) {
                    setLoadSavedLane(true);
                }
                else {
                    setLoadSavedLane(false);
                }
            }
            else {
                setLoadSavedLane(false);
            }
        }
        else {
            setLoadSavedLane(false);
        }
    }

    // Function to handle row click confirmation
    const handleRowClickConfirmationForPostedLoads = (selectedRowData, rowMeta) => {
        if (loadBidAmountDisablePostedLoads === true || isUpdateLoadExpirationValueChanged === true) {
            setPristine()
        }
        if (postLoadFormFunctionality === "Edit Load") {
            let getResponse = PostLoadFormRef.current.handleSendingResponse()
            if (getResponse) {
                if (getResponse[0].isValueChanged === true) {
                    let leavingResponse = handleLeavingEditLoadForm()
                    if (leavingResponse === true) {
                        handleRowClickPostedLoads(selectedRowData, rowMeta);
                    }
                }
            }
        }
        if (isUpdateLoadExpirationValueChanged === true) {
            let leavingResponse = handleLeavingEditLoadForm()
            if (leavingResponse === true) {
                handleRowClickPostedLoads(selectedRowData, rowMeta);
            }
        }
        else {
            handleRowClickPostedLoads(selectedRowData, rowMeta);
        }
    }

    //function to assign values on row click in posted loads table
    const handleRowClickPostedLoads = (selectedRowData, rowMeta) => {
        const findChatResult = chatDetails.filter(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        setDisableBookit(false)
        setDisableCounterButton(false)
        setDisadbleEdit(false)
        updateChatReadStatusForLoadsPosted(findChatResult);
        setMainGridPercentPostedLoads("35%");
        setIsPaneOpenPostedLoads(true);
        isPaneOpenForSocketIO = true;
        setSelectedRowforLoadsPosted(selectedRowData);
        removeBackroundColourForPostedLoads();
        if (!window.localStorage.getItem('LoadId')) {
            let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.dataIndex}`);
            row.setAttribute("style", "background: #9FD2F3");
        }
        setLoadUpdatedStatus(false)
        const findResult = rowDataForPostedLoads.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        setSelectedRowDataValuePostedLoads(findResult);
        fetchAllComments(findResult, "Loads Posted");
        selectedRowDataValueForPostedLoadsSocketIO = findResult;
        populateSecondaryPaneValuesPostedLoads(findResult);
        loadAndRealoadSecondGridPostedLoads(selectedRowData[0]);
        setDisplaySpecificColumns(false);
    };

    // Function to handle row click confirmation
    const handleRowClickConfirmationForFavorites = (selectedRowData, rowMeta) => {
        if (loadBidAmountDisableFavorites === true || isUpdateLoadExpirationValueChanged === true) {
            setPristine()
            setPostLoadFormFunctionality("Post Load")
        }
        if (loadBidAmount !== "" && loadBidAmountDisableFavorites === false) {
            let leavingResponse = handleLeavingEditLoadForm()
            if (leavingResponse === true) {
                handleRowClickFavorites(selectedRowData, rowMeta)
            }
        }
        else {
            handleRowClickFavorites(selectedRowData, rowMeta)
        }
    }

    //function to assign values on row click in Favorites loads table
    const handleRowClickFavorites = (selectedRowData, rowMeta) => {
        const findChatResult = chatDetails.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        updateChatReadStatus(findChatResult);
        setMainGridPercentFavorites("35%");
        setIsPaneOpenFavorites(true);
        setSelectedRowforFavorites(selectedRowData);
        if (!window.localStorage.getItem('LoadId')) {
            let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.dataIndex}`);
            row.setAttribute("style", "background: #9FD2F3");
        }
        setSelectedRowforFavorites(selectedRowData);
        const findResult = rowDataForFavorites.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        setSelectedRowDataValueFavorites(findResult);
        selectedRowDataValueForFavoritesSocketIO = findResult;
        fetchAllComments(findResult, "Favorites")
        populateSecondaryPaneValuesFavorites(findResult);
        loadAndRealoadSecondGridForFavourites(selectedRowData[0])
        setDisplaySpecificColumns(false);
    };

    //Function to handle expand more icon click
    const handleExpandMoreIconClick = () => {
        setExpandColapseTopPannel(false)
        localStorage.setItem('panelValue', "false")
    }

    //Function to handle expand less icon click
    const handleExpandLessIconClick = () => {
        if (postLoadFormFunctionality === "Edit Load") {
            let getResponse = PostLoadFormRef.current.handleSendingResponse()
            if (getResponse) {
                if (getResponse[0].isValueChanged === true) {
                    let leavingResponse = handleLeavingEditLoadForm()
                    if (leavingResponse === true) {
                        setExpandColapseTopPannel(true)
                    }
                }
            }
        }
        else {
            setExpandColapseTopPannel(true)
            localStorage.setItem('panelValue', "true")
        }
    }

    /****************************************Save To Lane , Add To Favorite ***********************************************************************/
    async function executeIfSocketOpen(data) {
        if (isPaneOpenForSocketIO === false || isPaneOpenForSocketIO === true) {
            try {
                let LoadboardLoads = await handleFetchLoadForLoadboard(user.email)
                if (LoadboardLoads) {
                    const findResult = LoadboardLoads.find(
                        ({ load_id }) => load_id === selectedRowDataValueFavorites.load_id
                    );
                    populateSecondaryPaneValuesFavorites(findResult);
                    selectedRowDataValueForFavoritesSocketIO = findResult;
                    setSelectedRowDataValueFavorites(findResult);
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const cleanListForUpdatingWatchLoad = (list) => {
        if (list.bid_amount)
            delete list.bid_amount;
        if (list.carrier_name)
            delete list.carrier_name;
        if (list.bid_id)
            delete list.bid_id;
        if (list.bid_expiration_time)
            delete list.bid_expiration_time;
        if (list.bid_time_stamp)
            delete list.bid_time_stamp;
        if (list.bid_Notes)
            delete list.bid_Notes;
        if (list.bid_Notes)
            delete list.bid_Notes;
        if (list.miles_Out)
            delete list.miles_Out;
        if (list.truck_dimension)
            delete list.truck_dimension;
        list.status = "new"
        list.load_expired_status = false
        list.load_delete_status = false
        return list;
    }

    //Function to handle saved Lane click
    async function handleSavedLaneClick() {
        let listValue =
        {
            "pick_upfrom_date": moment(selectedRowDataValueFavorites.pickupfrom_date).format("MM/DD/YYYY"),
            "pickup_dest": selectedRowDataValueFavorites.pickup_dest,
            "delivery_dest": selectedRowDataValueFavorites.delivery_dest,
            "pickupto_date": moment(selectedRowDataValueFavorites.pickupto_date).format("MM/DD/YYYY"),
            "carrier_name": user.email,
            "max_weight": selectedRowDataValueFavorites.max_weight,
            "total_item_count": selectedRowDataValueFavorites.total_item_count,
            "truck_type": selectedRowDataValueFavorites.truck_type,
            "miles_from": "NA",
            "miles_to": "NA",
            "delete_lane": false,
            "matched_lane": "0",
            "load_id": selectedRowDataValueFavorites.load_id,
            "time_stamp": selectedRowDataValueFavorites.time_stamp
        };
        try {
            let response = await axios({
                url: environmentVariables.savesearch,
                method: "post",
                data: listValue,
            })
            if (response) {
                const username = user.email;
                const findResult = rowDataForFavorites.find(
                    ({ load_id }) => load_id === selectedRowDataValueFavorites.load_id
                );
                let newList = findResult;
                if (newList.saved_lane === null || newList.saved_lane.length === 0 || newList.saved_lane.length === undefined) {
                    newList.saved_lane = [{ name: username, accStatus: true }]
                }
                else {
                    const findUserSaveLane = newList.saved_lane.find(({ name }) => name === username);
                    if (!findUserSaveLane) {
                        newList.saved_lane.push({ name: username, accStatus: true })
                    }
                    else {
                        findUserSaveLane.accStatus = true;
                    }
                }
                try {
                    let response = await editLoadDetail(newList);
                    if (response) {
                        let data = JSON.parse(response.data.body)
                        // sendMessageForSocketIO("LoadAddedToSavedLane||load_id:" + data.load_id, "", false);
                        executeIfSocketOpen(data)
                        setSnackBarMessageText(`Load Details Added To Saved Lane`);
                        setOpenSnackBar(true);
                    }

                }
                catch (error) {
                    console.log(error)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // Function to handle remove Saved Lane click 
    async function handleRemoveSavedLaneClick() {
        let response = await getMatchedLoad(user.email)
        if (response) {
            let data = JSON.parse(response.data.body)
            data.forEach(laneDetails => {
                if (laneDetails.load_id === selectedRowDataValueFavorites.load_id) {
                    let listValues =
                    {
                        "pick_upfrom_date": laneDetails.pick_upfrom_date,
                        "pickup_dest": laneDetails.pickup_dest,
                        "delivery_dest": laneDetails.delivery_dest,
                        "pickupto_date": laneDetails.pickupto_date,
                        "carrier_name": user.email,
                        "max_weight": laneDetails.max_weight,
                        "total_item_count": laneDetails.total_item_count,
                        "truck_type": laneDetails.truck_type,
                        "miles_from": laneDetails.miles_from,
                        "miles_to": laneDetails.miles_to,
                        "lane_id": laneDetails.lane_id,
                        "delete_lane": true,
                        "matched_lane": "0",
                        "load_id": laneDetails.load_id,
                        "lane_number": laneDetails.lane_number
                    };
                    toRemoveLaneDetails(listValues)
                }
            })
        }
        async function toRemoveLaneDetails(listValues) {
            try {
                let response = await updateCount(listValues)
                if (response) {
                    const username = user.email;
                    const findResult = rowDataForFavorites.find(
                        ({ load_id }) => load_id === selectedRowDataValueFavorites.load_id
                    );
                    let newList = findResult;
                    const findUserSaveLane = newList.saved_lane.find(({ name }) => name === username);
                    if (findUserSaveLane) {
                        findUserSaveLane.accStatus = false;
                    }
                    try {
                        let response = await editLoadDetail(newList);
                        if (response) {
                            let data = JSON.parse(response.data.body)
                            // sendMessageForSocketIO("LoadRemovedFromSavedLane||load_id:" + data.load_id, "", false);
                            executeIfSocketOpen(data)
                            setSnackBarMessageText("Saved Lane is removed");
                            setOpenSnackBar(true);
                        }

                    }
                    catch (error) {
                        console.log(error)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    //Function to handle watch load click
    async function handleWatchLoadClick() {
        const username = user.email;
        const findResult = rowDataForFavorites.find(
            ({ load_id }) => load_id === selectedRowDataValueFavorites.load_id
        );
        let listValue = JSON.parse(JSON.stringify(findResult));
        if (listValue.watch_load.length === 0) {
            listValue.watch_load = [{ name: username, accStatus: true }];
        }
        else {
            const findUserWatchLoad = listValue.watch_load.find(({ name }) => name === username);
            if (!findUserWatchLoad) {
                listValue.watch_load.push({ name: username, accStatus: true })
            }
            else {
                findUserWatchLoad.accStatus = true;
            }
        }
        try {
            let response = await editLoadDetail(cleanListForUpdatingWatchLoad(listValue));
            if (response) {
                let data = JSON.parse(response.data.body)
                fetchMainMuiDatatableValues();
                setSnackBarMessageText("Load succesfully added to watch list");
                setOpenSnackBar(true);
                sendMessageForSocketIO("BrokerLoadHasBeenWatchList||load_id:" + data.load_id, "", false);
                executeIfSocketOpen(data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // Function to handle stop watching click 
    async function handleStopWatchLoadClick() {
        const username = user.email;
        const findResult = rowDataForFavorites.find(
            ({ load_id }) => load_id === selectedRowDataValueFavorites.load_id
        );
        let listValue = JSON.parse(JSON.stringify(findResult));
        const findUserWatchLoad = listValue.watch_load.find(({ name }) => name === username);
        if (findUserWatchLoad) {
            findUserWatchLoad.accStatus = false;
        }
        try {
            let response = await editLoadDetail(cleanListForUpdatingWatchLoad(listValue));
            if (response) {
                let data = JSON.parse(response.data.body)
                fetchMainMuiDatatableValues();
                setSnackBarMessageText("Load removed from watch list");
                setOpenSnackBar(true);
                sendMessageForSocketIO("BrokerLoadHasBeenWatchListRemoved||load_id:" + data.load_id, "", false);
                executeIfSocketOpen(response.data)
            }

        }
        catch (error) {
            console.log(error)
        }
    }
    //--------------------------------------------------------------------------------------------
    // Function to handle bid amount selection
    const updateLoadBidAmount = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setLoadBidAmount(event.target.value);
                    setShowPrompt()
                }
            }
        }
    };

    // Function to set and check MilesOut textfield
    const handelTxtMilesOutChange = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    updateTxtMilesOut(event.target.value);
                }
            }
        }
    };

    //Function to Show Alert On Miles Out and Truck Dimension Value Is Empty
    async function milesTruckDimension(Data) {
        setDisableBidButton(false)
        Swal.fire({
            text: `Providing ${Data} greatly increases the chances of winning the bid, Click "OK" to Continue OR Click "Cancel" to Enter Value `,
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
        })
            .then((result) => {
                if ((result.value === true) || (txtMilesOut !== "" && txtLength !== "" && txtHeight !== "" && txtWidth !== "")) {
                    disableBidDetails()
                    addBidDetails()
                }
            })
    }

    //Function to Validate Bid Now
    const bidValidate = () => {
        let bidError = {}
        if (loadBidAmount === "0" || loadBidAmount === 0 || loadBidAmount === "") {
            bidError.loadBidAmount = "error"
        }
        setErrors({
            ...bidError
        })
        return Object.values(bidError).every(x => x === "");
    }

    // Function to handle bid button
    async function bidForALoad() {
        if (bidValidate()) {
            if (loadBidAmount.toString().trim().replace(" ", "") !== "" && loadBidAmount !== "" && parseInt(loadBidAmount) !== 0) {
                setDisableBidButton(true)
                setPristine()
                if (txtMilesOut === "" && (txtLength === "" || txtHeight === "" || txtWidth === "")) {
                    milesTruckDimension("Miles out and Truck Dimensions")
                }
                else if (txtMilesOut === "") {
                    milesTruckDimension("Miles out")
                }
                else if (txtLength === "" || txtHeight === "" || txtWidth === "") {
                    milesTruckDimension("Truck Dimensions")
                }
                else {
                    disableBidDetails()
                    addBidDetails()
                }
            }
            else {
                setLoadBidAmount("");
                setDisableBidButton(false)
            }
        }
    }

    //function to StoreBidResponse
    async function addBidDetails() {
        setPristine()
        setDisableBidButton(true)
        let loadDimension = getCombinedFreightDimension(selectedRowDataValueFavorites)
        const findResult = rowDataForFavorites.find(
            ({ load_id }) => load_id === selectedRowDataValueFavorites.load_id
        );
        let listValue = findResult;
        listValue = { ...listValue, "carrier_name": user.email };
        listValue = { ...listValue, "carrier_company": userCompanyName };
        listValue = { ...listValue, "bid_amount": loadBidAmount };
        listValue = { ...listValue, "status": "new" };
        listValue = { ...listValue, "bid_expiration_time": bidExpirationFavorites + " min" };
        listValue = { ...listValue, "load_dimension": loadDimension };
        listValue = { ...listValue, "bid_Notes": BidNotes };
        listValue = { ...listValue, "miles_Out": txtMilesOut };
        listValue = { ...listValue, "Truck_dimension_length": txtLength };
        listValue = { ...listValue, "Truck_dimension_width": txtWidth };
        listValue = { ...listValue, "Truck_dimension_height": txtHeight };
        listValue = { ...listValue, "truck_dimension": txtLength + "LX" + txtWidth + "WX" + txtHeight + "H" };
        listValue = { ...listValue, "loadUrl": window.location.origin + '/loadboard?loadId=' + slugid.encode(selectedRowDataValueFavorites.load_id) };
        listValue.bid_time_stamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        listValue.counter_offer = "";
        listValue.counteroffer_sent = "newoffer";
        listValue.counteroffer_status = "New";
        sendEmailForBidPlace(listValue)
        toStoreCreateBid(listValue)
        fetchAllComments(listValue, "Favorites")
    }

    //function to send system message For Bid Details
    async function BidDetailmsg(listValue) {
        if (listValue.bid_amount !== "" && listValue.bid_Notes !== "" && listValue.miles_Out !== "" && (listValue.truck_dimension !== "" && listValue.truck_dimension !== "LXWXH")) {
            onSendMessageClick(`BidAmount: ${"$" + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes} ,
                Miles Out : ${listValue.miles_Out} ,
                Truck Dimension :${listValue.truck_dimension} `, "System Message", "Favorites");
        }
        else {
            if (listValue.bid_amount !== "" && listValue.bid_Notes !== "" && listValue.miles_Out !== "") {
                onSendMessageClick(`BidAmount: ${"$" + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes}, Miles Out : ${listValue.miles_Out} `, "System Message", "Favorites");
            }
            else if (listValue.bid_amount !== "" && listValue.miles_Out !== "" && (listValue.truck_dimension !== "" && listValue.truck_dimension !== "LXWXH")) {
                onSendMessageClick(`BidAmount: ${"$" + listValue.bid_amount}, Miles Out : ${listValue.miles_Out}, 
                Truck Dimension :${listValue.truck_dimension}`, "System Message", "Favorites");
            }
            else if (listValue.bid_amount !== "" && listValue.bid_Notes !== "" && (listValue.truck_dimension !== "" && listValue.truck_dimension !== "LXWXH")) {
                onSendMessageClick(`BidAmount: ${"$" + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes}, Truck Dimension :${listValue.truck_dimension}`, "System Message", "Favorites");
            }
            else if (listValue.bid_amount !== "" && listValue.bid_Notes !== "") {
                onSendMessageClick(`BidAmount: ${"$" + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes}`, "System Message", "Favorites");
            }
            else if (listValue.bid_amount !== "" && listValue.miles_Out !== "") {
                onSendMessageClick(`BidAmount: ${"$" + listValue.bid_amount}, Miles Out : ${listValue.miles_Out}`, "System Message", "Favorites");
            }
            else if (listValue.bid_amount !== "" && (listValue.truck_dimension !== "" && listValue.truck_dimension !== "LXWXH")) {
                onSendMessageClick(`BidAmount: ${"$" + listValue.bid_amount}, Truck Dimension :${listValue.truck_dimension}`, "System Message", "Favorites");
            }
            else if (listValue.bid_amount !== "") {
                onSendMessageClick(`Bid of $${loadBidAmount} submitted`, "System Message", "Favorites");
            }
        }
    }

    //Function used repeatedly To Diasble Bid Related Details
    function disableBidDetails() {
        setLoadBidAmountDisableFavorites(true);
        setMilesOutDisable(true)
        setLoadBidNotesDisable(true)
        setLengthDisable(true)
        setWidthDisable(true)
        setHeightDisable(true)
    }
    //Function to handle listValue For Bid
    async function toStoreCreateBid(listValue) {
        let response = await axios({
            url: environmentVariables.createbid,
            method: "post",
            data: listValue,
        })
            .then((response) => {
                BidDetailmsg(listValue)
                fetchMainMuiDatatableValues();
                loadAndRealoadSecondGridForFavourites(selectedRowDataValueFavorites.load_id)
                setSnackBarMessageText(`Bid sent to ${selectedRowDataValueFavorites.posted_by_company}`)
                setOpenSnackBar(true);
                setDisableBidButton(false)
                sendMessageForSocketIO("ThereIsANewBid||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, selectedRowDataValueFavorites.posted_by_user, true, selectedRowDataValueFavorites, [], listValue);
                sendMessageForSocketIO("ThereIsANewBid||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValueFavorites, [], listValue);
                sendMessageForSocketIO("newNotification", "", false);
                setIsNotificationSent(true)
                disableBidDetails()
                toGetBidResponse(listValue)
            })
    }

    //Function toStoreBidResponse
    async function toGetBidResponse(selectedRowDataValueFavorites) {
        await axios({
            url:
                environmentVariables.getcarrierloadboard +
                "?carrier_name=" +
                user.email,
            method: "get",
        })
            .then((response) => {
                const singleLoadId = response.data.find(
                    ({ load_id }) => load_id === selectedRowDataValueFavorites.load_id
                );
                populateSecondaryPaneValuesFavorites(singleLoadId);
                fetchAllComments(singleLoadId, "Favorites")
                selectedRowDataValueForFavoritesSocketIO = singleLoadId;
                setSelectedRowDataValueFavorites(singleLoadId);
            })
            .catch((error) => { });
    }

    // function to handle bidcancel
    async function cancelBid() {
        let listValues = selectedRowDataValueFavorites
        listValues.status = "Closed"
        listValues.bid_notes = BidNotes
        listValues.miles_out = txtMilesOut
        if (listValues.counter_offer === null) {
            listValues.counter_offer = counterOffer
        }
        listValues.load_dimension = loadFreightDimension
        listValues.status = "Closed"
        if (selectedRowDataValueFavorites.status !== "accepted") {
            Swal.fire({
                text: "Are you sure you want to close this bid?",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            })
                .then((result) => {
                    if (result.value === true) {
                        return axios({
                            url: environmentVariables.deletebid,
                            method: "patch",
                            data: listValues
                        })
                    }
                })
                .then(async (response) => {
                    if (response !== undefined) {
                        fetchAllComments(listValues, "Favorites")
                        loadAndRealoadSecondGridForFavourites(listValues.load_id)
                        populateSecondaryPaneValuesFavorites(listValues)
                        onSendMessageClick(`Bid Closed`, "System Message", "Favorites");
                        sendMessageForSocketIO("ABidHasBeenRemoved||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, selectedRowDataValueFavorites.posted_by_user, true, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, response.data);
                        sendMessageForSocketIO("ABidHasBeenRemoved||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, response.data);
                        sendMessageForSocketIO("newNotification", "", false);
                        setIsNotificationSent(true)
                        fetchMainMuiDatatableValues();
                        toGetBidResponse(selectedRowDataValueFavorites)
                    }
                })
                .catch((error) => {
                    console.log("close bid error", error)
                });
        }
        else {
            Swal.fire({
                text: 'Cannot delete Bid after a bid has been accepted.',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
    }

    // Function to handle onchange value for hour field(load details panel)
    const handleSetLoadExpirationOnchangeValueForHour = (event, newValue) => {
        if (newValue === "00") {
            setLoadUpdateLoadExpirationTimeForMin("30")
        }
        else {
            setLoadUpdateLoadExpirationTimeForHour(newValue)
        }
        setIsUpdateLoadExpirationValueChanged(true)
        setShowPrompt()
    }

    // Function to handle LoadExpirationChange update load Information Area hours    
    const loadHandleLoadExpirationChangeForHour = (event, newInputValue) => {
        if (newInputValue.length === 2) {
            if (newInputValue <= 23) {
                setLoadUpdateLoadExpirationTimeForHour(newInputValue);
            } else {
                setLoadUpdateLoadExpirationTimeForHour("23")
            }
        }
        else if (newInputValue.length > 2) {
            setLoadUpdateLoadExpirationTimeForHour("23")
        }
        else {
            setLoadUpdateLoadExpirationTimeForHour(newInputValue);
        }
        setIsUpdateLoadExpirationValueChanged(true)
        setShowPrompt()
    };

    // Function to handle Load Expiration close update load Information Area For Hours
    const loadHandleLoadExpirationCloseForHour = () => {
        if (loadUpdateLoadExpirationTimeForHour === "00" && loadUpdateLoadExpirationTimeForMin === "00") {
            setLoadUpdateLoadExpirationTimeForMin("30")
        }
        let valueOnClose = loadUpdateLoadExpirationTimeForHour
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setLoadUpdateLoadExpirationTimeForHour("0" + valueOnClose);
            }
        }
        else {
            setLoadUpdateLoadExpirationTimeForHour("00")
        }
    }

    // Function to handle onchange value for min(load details panel)
    const handleSetLoadExpirationOnchangeValueForMin = (event, newValue) => {
        if (loadUpdateLoadExpirationTimeForHour === "00") {
            if (newValue === "00") {
                setLoadUpdateLoadExpirationTimeForMin("30")
            }
        } else {
            setLoadUpdateLoadExpirationTimeForMin(newValue);
        }
        setIsUpdateLoadExpirationValueChanged(true)
        setShowPrompt()
    }

    // Function to handle Load Expiration update load Information Area minutes
    const loadHandleLoadExpirationChangeForMin = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setLoadUpdateLoadExpirationTimeForMin(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    setLoadUpdateLoadExpirationTimeForMin(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setLoadUpdateLoadExpirationTimeForMin(timeValue);
                }
            }
            else {
                setLoadUpdateLoadExpirationTimeForMin("59");
            }
        } else if (newInputValue.length > 2) {
            setLoadUpdateLoadExpirationTimeForMin("59");
        }
        else {
            setLoadUpdateLoadExpirationTimeForMin(newInputValue);
        }
        setIsUpdateLoadExpirationValueChanged(true)
        setShowPrompt()
    };


    // Function to handle Load Expiration close update load Information Area For Minutes
    const loadHandleLoadExpirationCloseForMin = () => {
        if (loadUpdateLoadExpirationTimeForHour === "00" && loadUpdateLoadExpirationTimeForMin === "00") {
            setLoadUpdateLoadExpirationTimeForMin("30")
        }
        let valueOnClose = loadUpdateLoadExpirationTimeForMin
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setLoadUpdateLoadExpirationTimeForMin("0" + valueOnClose);
            }
        }
        else {
            setLoadUpdateLoadExpirationTimeForMin("30")
        }
    }
    // Function to handle Update Load expiration Chanage
    const handleUpdateLoadExpirationUpdateChnage = async () => {
        setIsUpdateLoadExpirationValueChanged(false)
        setPristine()
        if (selectedRowDataValuePostedLoads.posted_by_user === user.email && selectedRowDataValuePostedLoads.status === "Active") {
            let listToUpdateExpirationDate = JSON.parse(JSON.stringify(selectedRowDataValuePostedLoads));
            listToUpdateExpirationDate.load_expiration_time = loadUpdateLoadExpirationTimeForHour + ":" + loadUpdateLoadExpirationTimeForMin;
            await axios({
                url: environmentVariables.editloaddetail,
                method: "patch",
                data: cleanListForUpdatingWatchLoad(listToUpdateExpirationDate),
            })
                .then((response) => {
                    let data = JSON.parse(response.data.body)
                    setSnackBarMessageText("Load Expiration Time Succesfully Updated to - " + listToUpdateExpirationDate.load_expiration_time);
                    setOpenSnackBar(true);
                    fetchMainMuiDatatableValues();
                    sendMessageForSocketIO("BrokerLoadHasBeenUpdated||load_id:" + data.load_id, "", true, selectedRowDataValuePostedLoads, [], []);
                    sendMessageForSocketIO("newNotification", "", false);
                    setIsNotificationSent(true)
                    if (isPaneOpenForSocketIO) {
                        data.status = "Active";
                        populateSecondaryPaneValuesPostedLoads(data);
                        selectedRowDataValueForPostedLoadsSocketIO = data;
                        setSelectedRowDataValuePostedLoads(data);
                    }
                })
                .catch((error) => { });
        }
        else if (selectedRowDataValuePostedLoads.status === "Closed") {
            Swal.fire({
                text: 'Cannot modify load after a bid has been accepted.',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setLoadUpdateLoadExpirationTimeForHour(selectedRowDataValuePostedLoads.load_expiration_time.split(":")[0]);
            setLoadUpdateLoadExpirationTimeForMin(selectedRowDataValuePostedLoads.load_expiration_time.split(":")[1]);
        }
        else {
            Swal.fire({
                text: 'Not authorized to edit this load',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setLoadUpdateLoadExpirationTimeForHour(selectedRowDataValuePostedLoads.load_expiration_time.split(":")[0]);
            setLoadUpdateLoadExpirationTimeForMin(selectedRowDataValuePostedLoads.load_expiration_time.split(":")[1]);
        }
    }

    async function handleLoadExpireNow() {
        Swal.fire({
            text: "Are you sure you want to Expire this load?",
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        })
            .then((result) => {
                if (result.value) {
                    let listToUpdateExpirationDate = JSON.parse(JSON.stringify(selectedRowDataValuePostedLoads));
                    listToUpdateExpirationDate.load_expiration_time = "00:00";
                    listToUpdateExpirationDate.read_users = [];
                    listToUpdateExpirationDate.watch_load = [];
                    listToUpdateExpirationDate = { ...listToUpdateExpirationDate, 'load_Expired_Status': true }
                    return axios({
                        url: environmentVariables.editloaddetail,
                        method: "patch",
                        data: listToUpdateExpirationDate,
                    })
                }
            })
            .then(async (response) => {
                let data = JSON.parse(response.data.body)
                fetchMainMuiDatatableValues();
                handleDeletingExpiredLoadNotification(data.load_id, "Load Expiration");
                handleGettingConfirmationForPostedLoadsClosingSecondaryPane();
                handleGettingConfirmationForFavouritesClosingSecondaryPane();
                const allNewBids = await ExpireAndFetchAllBids();
                RemoveBidsWhenLoadExpires(allNewBids, data.load_id);
                sendMessageForSocketIO("BrokerLoadHasBeenExpired||load_id:" + data.load_id, "", true, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, data);
                sendMessageForSocketIO("newNotification", "", false);
                setIsNotificationSent(true)
                reduceCountOnLoadExpire(data, user.email)
            })
            .catch((error) => { });
    }

    //function on clicking cancel to clear values
    async function onCancelRePostClick() {
        if (postLoadFormFunctionality === "Edit Load") {
            let getResponse = PostLoadFormRef.current.handleSendingResponse()
            if (getResponse) {
                if (getResponse[0].isValueChanged === true || getResponse === "validate failed") {
                    let leavingResponse = handleLeavingEditLoadForm()
                    if (leavingResponse === true) {
                        setLabel("Search Load")
                        setShowing(!showing);
                        setPristine()
                        setTopTabPannelValue(1)
                        setPostLoadFormFunctionality("Post Load")
                    }
                    else {
                        if (leavingResponse === undefined) {
                            setShowing(showing);
                        }
                    }
                }
                else if (getResponse[0].isValueChanged === false) {
                    setLoadUpdatedStatus(true)
                    if (loadUpdatedStatus === false) {
                        setShowing(!showing);
                        setPristine()
                        setTopTabPannelValue(1)
                        setLabel("Search Load")
                        setPostLoadFormFunctionality("Post Load")
                    }
                    else if (loadUpdatedStatus === true) {
                        setShowing(!showing);
                        setPristine()
                        setTopTabPannelValue(1)
                        setLabel("Search Load")
                        setPostLoadFormFunctionality("Post Load")
                    }
                }
            }
        }
        else {
            setShowing(!showing);
            setPristine()
            setTopTabPannelValue(1)
            setPostLoadFormFunctionality("Post Load")
        }
    }

    // FUnction to handle Repost load with updated values
    async function handleRepostChanges() {
        setDisableUpdateLoadButton(true)
        setPostLoadFormFunctionality("Edit Load")
        let getResponse = PostLoadFormRef.current.handleSendingResponse()
        if (getResponse && getResponse !== "validate failed") {
            delete getResponse[0].isValueChanged
            let response = await editLoadDetail(getResponse[0]);
            if (response) {
                let data = JSON.parse(response.data.body)
                setPristine()
                setSelectedRowDataValuePostedLoads([])
                setShowing(!showing);
                setTopTabPannelValue(1)
                fetchMainMuiDatatableValues();
                setSnackBarMessageText("Load Succesfully Updated");
                setOpenSnackBar(true);
                setLabel("Search Load")
                sendMessageForSocketIO("BrokerLoadHasBeenUpdated||load_id:" + data.load_id, "", true, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, []);
                sendMessageForSocketIO("newNotification", "", false);
                setIsNotificationSent(true)
                if (isPaneOpenForSocketIO) {
                    let data = JSON.parse(response.data.body)
                    data.status = selectedRowDataValuePostedLoads.status;
                    populateSecondaryPaneValuesPostedLoads(data);
                    selectedRowDataValueForPostedLoadsSocketIO = data;
                    setSelectedRowDataValuePostedLoads(data);
                }
                return setLoadUpdatedStatus(true)
            }
        }
        setDisableUpdateLoadButton(false)
        setLoadUpdatedStatus(false)
    }

    const searchButtonClicked = () => {
        if (postLoadFormFunctionality === "Edit Load") {
            let getResponse = PostLoadFormRef.current.handleSendingResponse()
            if (getResponse) {
                if (getResponse[0].isValueChanged === true) {
                    let leavingResponse = handleLeavingEditLoadForm()
                    if (leavingResponse === true) {
                        setTopTabPannelValue(1);
                        setExpandColapseTopPannel(false);
                        setShowing(false);
                        localStorage.setItem('panelValue', "false")
                    }
                }
            }
        }
        else if (postLoadFormFunctionality === "") {
            setExpandColapseTopPannel(!expandColapseTopPannel);
            setTopTabPannelValue(1);
        }
        else {
            setTopTabPannelValue(1);
            handleGettingConfirmationForPostedLoadsClosingSecondaryPane();
            handleGettingConfirmationForFavouritesClosingSecondaryPane();
            setExpandColapseTopPannel(true);
            setShowing(false);
            setPristine()
            setPostLoadFormFunctionality("")
        }
    }

    const handleCheckingTextFieldValueChange = () => {
        setIsSearchFieldValueChanged(true)
    }

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === "") {
            handleCheckingTextFieldValueChange()
            const valForFilter = place.target.value;
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === "") {
                filterColumns[14].options.filterList = [];
                filterColumnsF[15].options.filterList = [];
            }
            else {
                filterColumns[14].options.filterList = filterList;
                filterColumnsF[15].options.filterList = filterList;
            }
            setColumnDefsForPostedLoads(filterColumns);
            setColumnDefsForFavorites(filterColumnsF);
        }
    }


    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        handleCheckingTextFieldValueChange()
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ", " + getState(place.address_components) + " - " + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[2].options.filterList = filterList;
            filterColumnsF[3].options.filterList = filterList;
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
    }

    // function to get all nearby pickup cities within specified miles
    async function getNearestPickUpCities(event) {
        handleCheckingTextFieldValueChange()
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchPickUpFromCity !== "") {
                setSnackBarMessageText("Data is loading for you....");
                setOpenSnackBar(true);
            }
            // setSearchPickUpMiles(event.target.value)
            // var rad = zipcodes.radius(searchPickUpFromZip, event.target.value);
            let listvalues = []
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchPickUpFromZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchPickUpFromZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + "?zipList=" +
                        listvalues + "functionality=pickupcitysearch",
                    method: "get",
                })
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = []
                    const valForFilter = searchPickUpFromCity + ", " + searchPickUpFromState + " - " + searchPickUpFromZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status) {
                            if (element.pickup_dest === valForFilter) {
                                filterList.unshift(element.pickup_dest)
                            }
                            else {
                                filterList.push(element.pickup_dest)
                            }
                        }
                    })
                    if (filterList.length > 0) {
                        const filterColumns = [...columnDefsForPostedLoads];
                        const filterColumnsF = [...columnDefsForFavorites];
                        filterColumns[2].options.filterList = filterList;
                        filterColumnsF[3].options.filterList = filterList;
                        setColumnDefsForPostedLoads(filterColumns)
                        setColumnDefsForFavorites(filterColumnsF)
                    }
                    else {
                        const filterColumns = [...columnDefsForPostedLoads];
                        const filterColumnsF = [...columnDefsForFavorites];
                        let filterList = [];
                        filterList = [valForFilter];
                        filterColumns[2].options.filterList = filterList;
                        filterColumnsF[3].options.filterList = filterList;
                        setColumnDefsForPostedLoads(filterColumns)
                        setColumnDefsForFavorites(filterColumnsF)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        handleCheckingTextFieldValueChange()
        if (event.target.value.length === 5) {
            setSearchPickUpFromZip(event.target.value);
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ", " + getFromCityState.state_abbr + " - " + event.target.value;
                const filterColumns = [...columnDefsForPostedLoads];
                const filterColumnsF = [...columnDefsForFavorites];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[2].options.filterList = filterList;
                filterColumnsF[3].options.filterList = filterList;
                setColumnDefsForPostedLoads(filterColumns)
                setColumnDefsForFavorites(filterColumnsF)
            }
        }
    }

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        handleCheckingTextFieldValueChange()
        if (date) {
            const valForFilter = moment(date).format("MM/DD/YYYY");
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[3].options.filterList = filterList;
            filterColumnsF[4].options.filterList = filterList;
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
        else {
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            filterColumns[3].options.filterList = [];
            filterColumnsF[4].options.filterList = [];
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
    }

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        handleCheckingTextFieldValueChange()
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ", " + getState(place.address_components) + " - " + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[4].options.filterList = filterList;
            filterColumnsF[5].options.filterList = filterList;
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
    }

    // function to get all nearby deliver cities within specified miles
    async function getNearestCitiesForDeliverCity(event) {
        handleCheckingTextFieldValueChange()
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchDeliverToCity !== "") {
                setSnackBarMessageText("Data is loading for you....");
                setOpenSnackBar(true);
            }
            setSearchDeliverMiles(event.target.value)
            // var rad = zipcodes.radius(searchDeliverToZip, event.target.value);
            let listvalues = []
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchDeliverToZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchDeliverToZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + "?zipList=" +
                        listvalues + "functionality=delivercitysearch",
                    method: "get",
                })
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = []
                    const valForFilter = searchDeliverToCity + ", " + searchDeliverToState + " - " + searchDeliverToZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status) {
                            if (element.delivery_dest === valForFilter) {
                                filterList.unshift(element.delivery_dest)
                            }
                            else {
                                filterList.push(element.delivery_dest)
                            }
                        }
                    })
                    if (filterList.length > 0) {
                        const filterColumns = [...columnDefsForPostedLoads];
                        const filterColumnsF = [...columnDefsForFavorites];
                        filterColumns[4].options.filterList = filterList;
                        filterColumnsF[5].options.filterList = filterList;
                        setColumnDefsForPostedLoads(filterColumns)
                        setColumnDefsForFavorites(filterColumnsF)
                    }
                    else {
                        const filterColumns = [...columnDefsForPostedLoads];
                        const filterColumnsF = [...columnDefsForFavorites];
                        let filterList = [];
                        filterList = [valForFilter];
                        filterColumns[4].options.filterList = filterList;
                        filterColumnsF[5].options.filterList = filterList;
                        setColumnDefsForPostedLoads(filterColumns)
                        setColumnDefsForFavorites(filterColumnsF)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        handleCheckingTextFieldValueChange()
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ", " + getFromCityState.state_abbr + " - " + event.target.value;
                const filterColumns = [...columnDefsForPostedLoads];
                const filterColumnsF = [...columnDefsForFavorites];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[4].options.filterList = filterList;
                filterColumnsF[5].options.filterList = filterList;
                setColumnDefsForPostedLoads(filterColumns)
                setColumnDefsForFavorites(filterColumnsF)
            }
        }
    }

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        handleCheckingTextFieldValueChange()
        if (date) {
            const valForFilter = moment(date).format("MM/DD/YYYY");
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            filterColumnsF[7].options.filterList = filterList;
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
        else {
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            filterColumns[6].options.filterList = [];
            filterColumnsF[7].options.filterList = [];
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
    }

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const filterColumns = [...columnDefsForPostedLoads];
        const filterColumnsF = [...columnDefsForFavorites];
        if (allTruckType && allTruckType.trim() !== "") {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            filterColumnsF[8].options.filterList = filterList;
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
        else {
            filterColumns[7].options.filterList = [];
            filterColumnsF[8].options.filterList = [];
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
    }

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        handleCheckingTextFieldValueChange()
        const filterColumns = [...columnDefsForPostedLoads];
        const filterColumnsF = [...columnDefsForFavorites];
        if (allTruckType.trim() !== "") {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            filterColumnsF[8].options.filterList = filterList;
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
        else {
            filterColumns[7].options.filterList = [];
            filterColumnsF[8].options.filterList = [];
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }

    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        handleCheckingTextFieldValueChange()
        if (event.target.value !== "0" && (event.target.validity.valid || event.target.value === "")) {
            const valForFilter = event.target.value;
            const filterColumns = [...columnDefsForPostedLoads];
            const filterColumnsF = [...columnDefsForFavorites];
            let filterList = [];
            filterList = [valForFilter];
            if (event.target.value.trim() === "") {
                filterColumns[8].options.filterList = [];
                filterColumnsF[9].options.filterList = [];
            }
            else {
                filterColumns[8].options.filterList = filterList;
                filterColumnsF[9].options.filterList = filterList;
            }
            setColumnDefsForPostedLoads(filterColumns)
            setColumnDefsForFavorites(filterColumnsF)
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPiecesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    handleCheckingTextFieldValueChange()
                    const valForFilter = event.target.value;
                    const filterColumns = [...columnDefsForPostedLoads];
                    const filterColumnsF = [...columnDefsForFavorites];
                    let filterList = [];
                    filterList = [valForFilter];
                    if (event.target.value.trim() === "") {
                        filterColumns[9].options.filterList = [];
                        filterColumnsF[10].options.filterList = [];
                    }
                    else {
                        filterColumns[9].options.filterList = filterList;
                        filterColumnsF[10].options.filterList = filterList;
                    }
                    setColumnDefsForPostedLoads(filterColumns)
                    setColumnDefsForFavorites(filterColumnsF)
                }
            }
        }
    };

    // Function to handle Filter Chip Close event
    const onFilterChipCloseEventForFavorite = (index, removedFilter, filterList) => {
        const filterColumnsF = [...columnDefsForFavorites];
        switch (index) {
            case 1:
                {
                    break;
                }
            case 3:
                {
                    filterColumnsF[3].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(2)
                    break;
                }
            case 4:
                {
                    filterColumnsF[4].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(3)
                    break;
                }
            case 5:
                {
                    filterColumnsF[5].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(4)
                    break;
                }
            case 7:
                {
                    filterColumnsF[7].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(6)
                    break;
                }
            case 8:
                {
                    const filterColumnsF = [...columnDefsForFavorites];
                    filterColumnsF[8].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(7)

                    break;
                }
            case 9:
                {
                    filterColumnsF[9].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(8)
                    break;
                }
            case 10:
                {
                    filterColumnsF[10].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(9)
                    break;
                }
            case 15:
                {
                    filterColumnsF[15].options.filterList = [];
                    setColumnDefsForFavorites(filterColumnsF);
                    SearchFormRef.current.onSearchClear(14)
                    break;
                }
            default:
                break;

        }
    }
    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        const filterColumns = [...columnDefsForPostedLoads];
        switch (index) {
            case 1:
                {
                    break;
                }
            case 2:
                {
                    filterColumns[2].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns);
                    SearchFormRef.current.onSearchClear(2)
                    break;
                }
            case 3:
                {
                    filterColumns[3].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns)
                    SearchFormRef.current.onSearchClear(3)
                    break;
                }
            case 4:
                {
                    filterColumns[4].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns)
                    SearchFormRef.current.onSearchClear(4)
                    break;
                }
            case 6:
                {
                    filterColumns[6].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns)
                    SearchFormRef.current.onSearchClear(6)
                    break;
                }
            case 7:
                {
                    const filterColumns = [...columnDefsForPostedLoads];
                    filterColumns[7].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns)
                    SearchFormRef.current.onSearchClear(7)
                    break;
                }
            case 8:
                {
                    filterColumns[8].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns)
                    SearchFormRef.current.onSearchClear(8)
                    break;
                }
            case 9:
                {
                    filterColumns[9].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns)
                    SearchFormRef.current.onSearchClear(9)
                    break;
                }
            case 14:
                {
                    filterColumns[14].options.filterList = [];
                    setColumnDefsForPostedLoads(filterColumns)
                    SearchFormRef.current.onSearchClear(14)
                    break;
                }
            default:
                break;

        }
    }

    const getArea = (addressArray) => {
        let area = "";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            "sublocality_level_1" === addressArray[i].types[j] ||
                            "locality" === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    const getState = (addressArray) => {
        let state = "";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    "administrative_area_level_1" === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };
    async function handleGetBidApiCall(loadId) {
        try {
            let response = await axios({
                url: environmentVariables.getbid + "?load_id=" + loadId,
                method: "get"
            })
            return response
        }
        catch (error) {
            console.log("getbid error", error)
        }
    }

    async function loadAndRealoadSecondGridPostedLoads(loadId) {
        try {
            let response = await handleGetBidApiCall(loadId);
            let newResponse = JSON.parse(response.data.body)
            if (newResponse) {
                if (newResponse !== "NoBidsAreAvailable") {
                    newResponse.sort((a, b) =>
                        b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                    );
                    let findBids = []
                    let closedBids = []
                    if (newResponse.length !== 0) {
                        setLoadingPostedActiveBids(true)
                        setLoadingPostedClosedBids(true)
                    }
                    else {
                        setLoadingPostedActiveBids(false)
                        setLoadingPostedClosedBids(false)
                    }
                    newResponse.forEach(element => {
                        if (element.posted_by_user === user.email) {
                            if (element.status === "new") {
                                findBids.push(element);
                                setLoadBidAmount(element.bid_amount)
                            }
                            else {
                                closedBids.push(element);
                            }
                        }
                        else if (element.posted_by_user !== user.email) {
                            if (element.status === "new") {
                                findBids.push(element);
                            }
                            else {
                                closedBids.push(element);
                            }
                        }
                    });
                    if (findBids.length === 0) {
                        setLoadingPostedActiveBids(true)
                    }
                    if (closedBids.length === 0) {
                        setLoadingPostedClosedBids(true)
                    }
                    setRowDataActiveBidTable(findBids);
                    setRowDataClosedBidTable(closedBids);
                }
                else {
                    setRowDataActiveBidTable([]);
                    setRowDataClosedBidTable([])
                    setLoadingPostedActiveBids(true)
                    setLoadingPostedClosedBids(true)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    async function loadAndRealoadSecondGridForFavourites(loadId) {
        try {
            let response = await handleGetBidApiCall(loadId);
            let newResponse = JSON.parse(response.data.body)
            if (response) {
                if (newResponse !== "NoBidsAreAvailable") {
                    newResponse.sort((a, b) =>
                        b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                    );
                    let activeBids = []
                    let closedBids = []
                    if (newResponse.length !== 0) {
                        setLoadingFavoritesActiveBidTable(true);
                        setLoadingFavouritesClosedBidTable(true)
                    }
                    else {
                        setLoadingFavoritesActiveBidTable(false);
                        setLoadingFavouritesClosedBidTable(false)
                    }
                    newResponse.forEach(element => {
                        if (element.carrier_name === user.email) {
                            if (element.status === "new") {
                                activeBids.push(element);
                                setLoadBidAmount(element.bid_amount)
                            }
                            else {
                                closedBids.push(element);
                            }

                        }
                        else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                            if (element.carrier_name !== user.email) {
                                if (element.status === "new") {
                                    activeBids.push(element);
                                }
                                else {
                                    closedBids.push(element);
                                }
                            }
                        }
                    });
                    if (activeBids.length === 0) {
                        setLoadingFavoritesActiveBidTable(true);
                    }
                    if (closedBids.length === 0) {
                        setLoadingFavouritesClosedBidTable(true)
                    }
                    setRowDataFavouritesActiveBidTable(activeBids)
                    setRowDataFavouritesClosedBidTable(closedBids)
                }
                else {
                    setRowDataFavouritesActiveBidTable([]);
                    setRowDataFavouritesClosedBidTable([])
                    setLoadingFavoritesActiveBidTable(true);
                    setLoadingFavouritesClosedBidTable(true)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // Function to handle txtlength
    const handletxtLength = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setTxtLength(event.target.value);
                }
            }
        }
    };
    const handletxtWidth = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setTxtWidth(event.target.value);
                }
            }
        }
    };
    const handletxtHeight = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setTxtHeight(event.target.value);
                }
            }
        }
    };

    async function handleNewOrderClick() {
        setDisablePostLoadButton(true)
        let getResponse = PostLoadFormRef.current.handleSendingResponse()
        delete getResponse[0].isValueChanged
        if (getResponse) {
            let response = await axios({
                url: environmentVariables.createloadboard,
                method: "put",
                data: getResponse[0],
            })
            if (response) {
                fetchMainMuiDatatableValues();
                setSelectedRowDataValuePostedLoads([])
                setSnackBarMessageText("Load Succesfully Posted");
                populateSecondaryPaneValuesPostedLoads(getResponse[0])
                setOpenSnackBar(true);
                sendMessageForSocketIO("newLoadHasBeenPlaced", "");
                sendMessageForSocketIO("newNotification", "", false);
                setIsNotificationSent(true)
                //call api to send email
                await axios({
                    url: environmentVariables.createloadnotification,
                    method: "put",
                    data: {
                        posted_by_company: response.data.posted_by_company,
                        load_id: response.data.load_id,
                        pickupfrom_date: response.data.pickupfrom_date,
                        pickupto_date: response.data.pickupto_date,
                        max_weight: response.data.max_weight,
                        pickup_dest: response.data.pickup_dest,
                        delivery_dest: response.data.delivery_dest,
                        posted_by_user: user.email,
                        posted_comp_mc_number: loggedInCompanyMCNumber,
                        loadUrl: window.location.origin + '/loadboard?loadId=' + slugid.encode(response.data.load_id),
                        equipment_needed: response.data.truck_type,
                        load_number: response.data.load_number,
                        load_notes: response.data.load_notes
                    },
                })
            }
        }
        setDisablePostLoadButton(false)
    }

    async function handleEditButtonClick(rowValues) {
        setLabel("Edit Load")
        if (isSearchFieldValueChanged === true) {
            let formLeaveStatus = handleLeavingSearchLoadForm();
            if (formLeaveStatus === true) {
                let findLoad = rowDataForPostedLoads.find(
                    ({ load_id }) => load_id === rowValues[0]
                );
                setPostLoadFormFunctionality("Edit Load")
                setShowing(true);
                setExpandColapseTopPannel(false)
                setTopTabPannelValue(0)
                setSelectedRowDataValuePostedLoads(findLoad)
            }
        }
        else {
            let findLoad = rowDataForPostedLoads.find(
                ({ load_id }) => load_id === rowValues[0]
            );
            setPostLoadFormFunctionality("Edit Load")
            setShowing(true);
            setExpandColapseTopPannel(false)
            setTopTabPannelValue(0)
            setSelectedRowDataValuePostedLoads(findLoad)
        }
    }

    // ^^^^^^^^^^^^^^^^^^^^^^^Function to handle All the chat^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    async function getSingleCarrierMessage(event, tableName) {
        if (event.target.id !== null && event.target.id !== "") {
            if (loadPostedByUser !== user.email) {
                toSendOffer(event.target.id, "Favorites")
            }
            else if (loadPostedByUser === user.email) {
                toSendOffer(event.target.id, "Loads Posted")
            }
        }
        else {
            if (loadPostedByUser !== user.email) {
                toSendOffer(event.target.parentElement.id, "Favorites")
            }
            else if (loadPostedByUser === user.email) {
                toSendOffer(event.target.parentElement.id, "Loads Posted")
            }
        }
    }

    const onEnterButtonForChat = (event, tableName) => {
        if (event.key === "Enter") {
            if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                event.preventDefault();
                if (tableName === "Loads Posted") {
                    onSendMessageClick(document.getElementById("inputMesageLoadsPosted").value, "Normal Message", tableName)
                }
                else {
                    onSendMessageClick(document.getElementById("inputMesageFavorites").value, "Normal Message", tableName)
                }
            }
            else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                event.preventDefault();
            }
        }
    }

    // function to handle send message click button
    const handleOnSendMessageClick = (tableName) => {
        if (tableName === "Loads Posted") {
            onSendMessageClick(document.getElementById("inputMesageLoadsPosted").value, "Normal Message", tableName)
        }
        else {
            onSendMessageClick(document.getElementById("inputMesageFavorites").value, "Normal Message", tableName)
        }
    }

    //Function to createupdatestcomment
    async function createupdatetestcomment(secondaryArray, action) {
        let data = JSON.stringify(secondaryArray)
        try {
            let response = await axios({
                url: environmentVariables.createupdatetestcomment + "?action=" + action,
                method: 'patch',
                data: data,
            })
            return response
        }
        catch (error) { }
    }

    async function updatetestcomment(secondaryArray, action) {
        if (secondaryArray.carrier_company === null) {
            if (selectedRowDataValueFavorites.posted_by_user !== user.email || selectedRowDataValuePostedLoads.posted_by_user !== user.email) {
                secondaryArray.carrier_company = userCompanyName
            }
        }
        try {
            let response = await axios({
                url: environmentVariables.updatetestcomment + "?action=" + action,
                method: "patch",
                data: secondaryArray,
            })
            return response
        }
        catch (error) {
            console.log("create comment", error)
        }
    }

    //Function to createCommentNotification
    async function createCommentNotification(data) {
        try {
            let response = await axios({
                url: environmentVariables.createCommentNotification,
                method: 'patch',
                data: data,
            })
            return response
        }
        catch (error) { }
    }

    //Function to send message 
    async function onSendMessageClick(enteredMessage, messageType, tableName, loadChatMessages) {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            if (loadChatMessages === undefined || loadChatMessages.length === 0) {
                if (tableName === "Loads Posted") {
                    loadChatMessages = chatAllMessagesForLoadsPosted
                }
                else {
                    loadChatMessages = chatAllMessagesForFavorites
                }
            }
            if (enteredMessage !== null && enteredMessage !== "") {
                if (loadChatMessages && loadChatMessages.length === 0 && (selectedRowDataValuePostedLoads.posted_by_user === user.email && tableName === "Loads Posted")) {
                    Swal.fire({
                        text: 'Broker Is not Authorized to Initiate Chat',
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    }
                    );
                    if (tableName === "Loads Posted") {
                        document.getElementById("inputMesageLoadsPosted").value = "";
                    }
                    else {
                        document.getElementById("inputMesageFavorites").value = "";
                    }
                }
                else {
                    let Bid = ""
                    if (messageType === "System Message") {
                        if (enteredMessage === "Bid Closed") {
                            Bid = "Bid Closed"
                        }
                        else if (counterOffer !== "" && counterOffer !== undefined) {
                            Bid = counterOffer
                        }
                        else {
                            Bid = loadBidAmount
                        }
                    }
                    if (tableName === "Loads Posted") {
                        document.getElementById("inputMesageLoadsPosted").value = "";
                    }
                    else {
                        document.getElementById("inputMesageFavorites").value = "";
                    }
                    if (loadChatMessages && loadChatMessages.length === 0 && tableName === "Favorites") {
                        const secondaryArray = [
                            {
                                time_stamp: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                                broker_id: selectedRowDataValueFavorites.posted_by_user,
                                broker_company: selectedRowDataValueFavorites.posted_by_company,
                                broker_mc_number: selectedRowDataValueFavorites.posted_by_mc_number,
                                carrier_company: userCompanyName,
                                carrier_mc_number: loggedInCompanyMCNumber,
                                carrier_id: user.email,
                                read_status: [{ name: user.email, status: true }, { name: selectedRowDataValueFavorites.posted_by_user, status: false }],
                                load_id: selectedRowDataValueFavorites.load_id,
                                bid_amount: "$" + Bid,
                                messages: [
                                    {
                                        date: moment().format("hh:mm A"),
                                        messanger_id: user.email,
                                        message: enteredMessage,
                                        user: user.nickname,
                                        message_type: messageType,
                                    },
                                ],
                            },
                        ];
                        let response = await createupdatetestcomment(secondaryArray[0], "put")
                        if (response) {
                            if (tableName === "Loads Posted") {
                                fetchAllComments(response.data, "Loads Posted")
                                setChatAllMessagesForLoadsPosted(JSON.parse(response.data.body));
                            }
                            else {
                                fetchAllComments(response.data, "Favorites")
                                setChatAllMessagesForFavorites(JSON.parse(response.data.body));
                            }
                        }
                        if (loggedInuserCompanyRole === "Broker" || loadPostedByUser === user.email) {
                            sendMessageForSocketIO("ThereIsANewMessageFromBroker||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user, secondaryArray.carrier_id, true, selectedRowDataValuePostedLoads, selectedRowDataValuePostedLoads, []);
                            sendMessageForSocketIO("ThereIsANewMessageFromBroker||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValuePostedLoads, []);
                            sendMessageForSocketIO("newNotification", "", false);
                            setIsNotificationSent(true)
                        }
                        else {
                            sendMessageForSocketIO("ThereIsANewMessageFromCarrier||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, selectedRowDataValueFavorites.posted_by_user, true, selectedRowDataValueFavorites, selectedRowDataValueFavorites, []);
                            sendMessageForSocketIO("ThereIsANewMessageFromCarrier||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValueFavorites, selectedRowDataValueFavorites, []);
                            sendMessageForSocketIO("newNotification", "", false);
                            setIsNotificationSent(true)
                        }
                        return await createCommentNotification({
                            data: {
                                load_id: selectedRowDataValuePostedLoads.load_id || selectedRowDataValueFavorites.load_id,
                                fromEmail: user.email,
                                toEmail: selectedRowDataValuePostedLoads.posted_by_user || selectedRowDataValueFavorites.posted_by_user,
                                message: enteredMessage
                            },
                        })
                    } else {
                        const secondaryArray = loadChatMessages;
                        secondaryArray.time_stamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
                        if (messageType === "System Message") {
                            if (enteredMessage === "Bid Closed") {
                                secondaryArray.bid_amount = "Bid Closed"
                            }
                            else if (counterOffer !== "" && counterOffer !== undefined) {
                                secondaryArray.bid_amount = "$" + counterOffer
                            }
                            else {
                                secondaryArray.bid_amount = "$" + loadBidAmount
                            }
                        }
                        if (secondaryArray.read_status) {
                            const ownUser = secondaryArray.read_status.find(
                                ({ name }) => name === user.email
                            );
                            const otherUser = secondaryArray.read_status.find(
                                ({ name }) => name !== user.email
                            );
                            if (ownUser !== undefined && ownUser.name === user.email) {
                                ownUser.status = true;
                            }
                            if (otherUser !== undefined) {
                                otherUser.status = false;
                            }
                        }
                        secondaryArray.messages = [
                            ...secondaryArray.messages,
                            {
                                date: moment().format("hh:mm A"),
                                messanger_id: user.email,
                                message: enteredMessage,
                                user: user.nickname,
                                message_type: messageType,
                            },
                        ];
                        let response = await updatetestcomment(secondaryArray, "patch")
                        if (response) {
                            if (tableName === "Loads Posted") {
                                setChatAllMessagesForLoadsPosted(JSON.parse(response.data.body));
                            }
                            else {
                                setChatAllMessagesForFavorites(JSON.parse(response.data.body));
                            }
                        }
                        if (tableName === "Loads Posted") {
                            document.getElementById("inputMesageLoadsPosted").value = "";
                        }
                        else {
                            document.getElementById("inputMesageFavorites").value = "";
                        }
                        if (user.email === selectedRowDataValuePostedLoads.posted_by_user && tableName === "Loads Posted" || loadPostedByUser === user.email) {
                            sendMessageForSocketIO("ThereIsANewMessageFromBroker||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user, secondaryArray.carrier_id, true, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, []);
                            sendMessageForSocketIO("ThereIsANewMessageFromBroker||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, []);
                            sendMessageForSocketIO("newNotification", "", false);
                            setIsNotificationSent(true)
                            fetchAllComments(
                                {
                                    "load_id": selectedRowDataValuePostedLoads.load_id,
                                    "posted_by_user": selectedRowDataValuePostedLoads.posted_by_user,
                                },
                                tableName
                            )
                        }
                        else {
                            fetchAllComments(
                                {
                                    "load_id": selectedRowDataValueFavorites.load_id,
                                    "posted_by_user": selectedRowDataValueFavorites.posted_by_user,
                                },
                                tableName
                            )
                            sendMessageForSocketIO("ThereIsANewMessageFromCarrier||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, selectedRowDataValueFavorites.posted_by_user, true, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, []);
                            sendMessageForSocketIO("ThereIsANewMessageFromCarrier||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValueFavorites, []);
                            sendMessageForSocketIO("newNotification", "", false);
                            setIsNotificationSent(true)
                        }
                        return await createCommentNotification({
                            data: {
                                load_id: selectedRowDataValuePostedLoads.load_id || selectedRowDataValueFavorites.load_id,
                                fromEmail: user.email,
                                toEmail: selectedRowDataValuePostedLoads.posted_by_user || selectedRowDataValueFavorites.posted_by_user,
                                message: enteredMessage
                            },
                        })
                    }
                }
            }
        }
    }
    function toShowChatAccordingToUser(tableName, data) {
        if (tableName === "Loads Posted") {
            setChatAllMessagesForLoadsPosted(data);
            setChatFirstCarrierNameForLoadsPosted(data.carrier_id);
            setChatFirstCompanyNameForLoadsPosted(data.carrier_company)
            setChatMCNumberForLoadsPosted(`MC #${data.carrier_mc_number}`)
        }
        else {
            setChatAllMessagesForFavorites(data)
            setChatFirstCarrierNameForFavorites(data.broker_id);
            setChatFirstCompanyNameForFavorites(data.broker_company)
            setChatMCNumberForFavorites(`MC #${data.broker_mc_number}`)
        }
    }

    async function fetchAllComments(loadInformation, tableName) {
        let URL = ""
        if (user.email === process.env.REACT_APP_ADMIN_USER || loadInformation.posted_by_user === user.email || loadPostedByUser === user.email) {
            URL = environmentVariables.gettestcomment + "?load_id=" + loadInformation.load_id + "&broker_id=" + loadInformation.posted_by_user;
        }
        if (loadInformation.carrier_id !== undefined) {
            if (user.email === process.env.REACT_APP_ADMIN_USER || loadInformation.carrier_id === user.email || loadPostedByUser !== user.email) {
                URL = environmentVariables.gettestcomment + "?load_id=" + loadInformation.load_id + "&carrier_id=" + loadInformation.carrier_id;
            }
        }
        else if (loadPostedByUser !== user.email) {
            if ((loggedInuserCompanyRole !== "Broker" && user.email !== process.env.REACT_APP_ADMIN_USER) || loggedInuserCompanyRole === "Carrier" || loggedInuserCompanyRole === "Broker&Carrier") {
                URL = environmentVariables.gettestcomment + "?load_id=" + loadInformation.load_id + "&carrier_id=" + user.email;
            }
        }
        await axios({
            method: "get",
            url: URL,
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data)
                if (newResponse[0] !== undefined) {
                    if (isPaneOpenPostedLoads === true && tableName === "Loads Posted") {
                        if (loadInformation.posted_by_user === user.email) {
                            newResponse.forEach(data => {
                                if (data.carrier_id === chatFirstCarrierNameForLoadsPosted) {
                                    toShowChatAccordingToUser(tableName, data)
                                    setAllChatForLoadsPosted(newResponse);
                                }
                            })
                        }
                    }
                    else if (isPaneOpenFavorites === true && tableName === "Favorites") {
                        newResponse.forEach(data => {
                            if (data.broker_id === chatFirstCarrierNameForFavorites) {
                                setAllChatForLoadsPosted(newResponse);
                                toShowChatAccordingToUser(tableName, data)
                            }
                        })
                    }
                    else {
                        if (loadInformation.posted_by_user === user.email) {
                            setAllChatForLoadsPosted(newResponse);
                            toShowChatAccordingToUser(tableName, newResponse[0])
                        }
                        else {
                            setAllChatForFavorites(newResponse);
                            toShowChatAccordingToUser(tableName, newResponse[0])
                        }
                    }

                }
            })
            .catch((error) => {
                if (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" && ((loggedInuserRole !== "Carrier" && loadInformation.posted_by_user === user.email) || (loggedInuserRole === "Broker")))) {
                    setAllChatForLoadsPosted([]);
                    setAllChatForFavorites([]);
                    setChatAllMessagesForLoadsPosted([]);
                    setChatAllMessagesForFavorites([])
                    setChatFirstCarrierNameForLoadsPosted("");
                    setChatFirstCarrierNameForFavorites("")
                    setChatMCNumberForFavorites("")
                    setChatMCNumberForLoadsPosted("")
                    setChatFirstCompanyNameForLoadsPosted("")
                    setChatFirstCompanyNameForFavorites("")
                }
                else {
                    let tempAllChatArray = {
                        "Items": [{
                            "broker_id": loadInformation.posted_by_user,
                            "time_stamp": moment.utc().format("YYYY-MM-DD HH:mm:ss")
                        }]
                    }
                    if (tableName === "Loads Posted") {
                        setAllChatForLoadsPosted(tempAllChatArray);
                        setChatFirstCarrierNameForLoadsPosted(loadInformation.posted_by_user);
                        setChatFirstCompanyNameForLoadsPosted(loadInformation.posted_by_company)
                        setChatMCNumberForLoadsPosted(`MC #${loadInformation.posted_by_mc_number}`)
                        setChatAllMessagesForLoadsPosted([]);
                    }
                    else {
                        setAllChatForFavorites(tempAllChatArray);
                        setChatFirstCarrierNameForFavorites(loadInformation.posted_by_user);
                        setChatFirstCompanyNameForFavorites(loadInformation.posted_by_company)
                        setChatMCNumberForFavorites(`MC #${loadInformation.posted_by_mc_number}`)
                        setChatAllMessagesForFavorites([]);
                    }
                }
            });
    }

    //Function to update chat read status on click of row
    async function updateChatReadStatusForLoadsPosted(selectedRowChatDetails) {
        // let listValues = JSON.parse(JSON.stringify(selectedRowChatDetails))
        // selectedRowChatDetails.forEach(element => {
        //     if (element.read_status) {
        //         const findUsers = element.read_status.find(
        //             ({ name }) => name === user.email
        //         );
        //         if (findUsers !== undefined) {
        //             findUsers.status = true;
        //         }
        //     }
        // })
        // let response = await updatetestcomment(listValues, "patch")
        // if (response) {
        //     sendMessageForSocketIO("chatHasBeenRead||load_id:" + response.data.load_id, "", false);
        // }
    }

    //Function to update chat read status on click of row
    async function updateChatReadStatus(selectedRowChatDetails) {
        // let listValues = JSON.parse(JSON.stringify(selectedRowChatDetails))
        // if (listValues.read_status) {
        //     const findUsers = listValues.read_status.find(
        //         ({ name }) => name === user.email
        //     );
        //     if (findUsers !== undefined) {
        //         findUsers.status = true;
        //     }
        // }
        // let response = await createupdatetestcomment(listValues, "put")
        // if (response) {
        //     sendMessageForSocketIO("chatHasBeenRead||load_id:" + response.data.load_id, "", false);
        // }
    }

    // Function to get all chat for logged in user
    async function fetchAllComment() {
        try {
            let response = await axios({
                url: environmentVariables.getchatdetails + "?user_id=" + user.email,
                method: 'get'
            });
            if (response) {
                if (response.data.Items !== undefined) {
                    setChatDetails(response.data.Items);
                    fetchMainMuiDatatableValues();
                }
            }
            return response.data.Items
        }
        catch (error) {
            console.log(error)
        }
    }
    //----------------------------------------------Counter Offer------------------------------------------------------------
    //----------------------------------------OpenForm and CloseForm----------------------------------------------------
    function toOpen(acceptedRow) {
        let findResult;
        if (loadPostedByUser === user.email) {
            findResult = rowDataActiveBidTable.find(
                ({ bid_id }) => bid_id === acceptedRow.rowData[3]
            );
            setSelectedRowDataValuePostedLoads(findResult)
        }
        else {
            findResult = rowDataFavouritesActiveBidTable.find(
                ({ bid_id }) => bid_id === acceptedRow.rowData[3]
            );
            setSelectedRowDataValueFavorites(findResult)
        }
    }

    const handleOpen = (acceptedRow) => {
        setCounterOpen(true);
        toOpen(acceptedRow)
        if (loadPostedByUser === user.email) {
            toSendOffer(acceptedRow.rowData[7], "Loads Posted")
        }
        else {
            toSendOffer(acceptedRow.rowData[7], "Favorites")
        }
        setCounterOffer("")
    }

    const handleOpenCarrier = (data, acceptedRow) => {
        toOpen(acceptedRow)
        if (loadPostedByUser === user.email) {
            toSendOffer(acceptedRow.rowData[7], "Loads Posted")
        }
        else {
            toSendOffer(acceptedRow.rowData[7], "Favorites")
        }
        setCounterOpenCarrier(true)
        setCounterOffer(data)
    }

    const handleClose = () => {
        setCounterOpen(false)
        setCounterOpenCarrier(false)
    }

    async function toSendOffer(nameCarrier, tableName) {
        let carrierName = nameCarrier;
        let URL = ""
        if (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier")) {
            URL = environmentVariables.gettestcomment + "?load_id=" + selectedRowDataValuePostedLoads.load_id + "&broker_id=" + user.email;
        }
        else if (user.email === process.env.REACT_APP_ADMIN_USER) {
            if (tableName === "Favorites") {
                URL = environmentVariables.gettestcomment + "?load_id=" + selectedRowDataValueFavorites.load_id + "&carrier_id=" + carrierName;
            }
            else {
                if (tableName === "Loads Posted") {
                    URL = environmentVariables.gettestcomment + "?load_id=" + selectedRowDataValuePostedLoads.load_id + "&broker_id=" + selectedRowDataValuePostedLoads.posted_by_user;
                }
            }
        }
        else {
            URL = environmentVariables.gettestcomment + "?load_id=" + selectedRowDataValueFavorites.load_id + "&carrier_id=" + user.email;
        }
        await axios({
            method: "GET",
            url: URL,
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                let data = JSON.parse(response.data)
                data.forEach(element => {
                    if (element.carrier_id === carrierName) {
                        if (tableName === "Loads Posted") {
                            setChatAllMessagesForLoadsPosted(element);
                            setChatFirstCarrierNameForLoadsPosted(element.carrier_id);
                            setChatFirstCompanyNameForLoadsPosted(element.carrier_company)
                        }
                        else {
                            setChatAllMessagesForFavorites(element);
                            setChatFirstCarrierNameForFavorites(element.broker_id);
                            setChatFirstCompanyNameForFavorites(element.broker_company)
                            setChatMCNumberForFavorites(`MC #${element.broker_mc_number}`)
                        }
                    }

                });
            })
            .catch((error) => { });
    }
    //---------------------------------ToSubmitOffer---------------------------------------------------------------------//
    function toSubmitOffer() {
        if (counterOffer !== "" && counterOffer !== "0" && counterOffer !== "00") {
            let regExp = /^0+$/g
            let found = regExp.test(counterOffer)
            let result = found ? true : false;
            if (result === false) {
                if (loadPostedByUser === user.email) {
                    toStoreCounterOffer(selectedRowDataValuePostedLoads);
                }
                else {
                    toStoreCounterOffer(selectedRowDataValueFavorites);
                }
            }
        }
        else {
            toShowAlert()
        }
    }

    function toShowAlert() {
        if (counterOffer === "") {
            Swal.fire({
                text: `Counter Offer Value Is Empty`,
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setCounterOpen(false)
            setCounterOpenCarrier(false)
        }
    }

    const SubmitCounterOffer = () => {
        toSubmitOffer()
        setCounterOpen(false)
        setCounterOpenCarrier(false)
    }
    const CancelCounterOffer = () => {
        setCounterOpen(false)
        setCounterOffer("");
    }

    const CloseForm = () => {
        setCounterOpenCarrier(false)
    }

    //-----------------------------------------Handle Changes------------------------------------------------------------------------//
    function handleKeyPress(event) {
        if (event.key !== 'Enter') {
            handleCounterOffer(event)
        }
        else {
            event.preventDefault();
            SubmitCounterOffer()
        }
    }

    const handleCounterOffer = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setCounterOffer(event.target.value);
                }
            }
        }
    }
    //-----------------------------------Function to Store , Submit, Update and Accept-------------------------------------------------------------------//
    async function StoreOffer(listValue) {
        let response = await axios({
            url: environmentVariables.editbid,
            method: "patch",
            data: listValue,
        })
        return response
    }

    function toGetData() {
        fetchMainMuiDatatableValues();
        if (loadPostedByUser === user.email) {
            loadAndRealoadSecondGridPostedLoads(selectedRowDataValuePostedLoads.load_id)
        }
        else {
            loadAndRealoadSecondGridForFavourites(selectedRowDataValueFavorites.load_id)
        }
    }

    async function toStoreCounterOffer(selectedRowDataValue) {
        let response = await axios({
            url: environmentVariables.editbid,
            method: "patch",
            data: {
                load_id: selectedRowDataValue.load_id,
                bid_id: selectedRowDataValue.bid_id,
                carrier_name: selectedRowDataValue.carrier_name,
                carrier_company: selectedRowDataValue.carrier_company,
                bid_amount: counterOffer,
                bid_time_stamp: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                counter_offer: counterOffer,
                counteroffer_sent: (loadPostedByUser === user.email) ? "broker" : "carrier",
                counteroffer_status: "New"
            },
        })
        if (response) {
            setSnackBarMessageText(`Counter Offer is Submitted by ${userCompanyName}`);
            setOpenSnackBar(true);
            if (loadPostedByUser !== user.email) {
                onSendMessageClick(`Counter Offer of $${counterOffer} submitted by ${userCompanyName} `, "System Message", "Favorites");
            }
            else {
                onSendMessageClick(`Counter Offer of $${counterOffer} submitted by ${userCompanyName} `, "System Message", "Loads Posted");
            }
            toGetData()
            if (loggedInuserCompanyRole === "Carrier" || loadPostedByUser !== user.email) {
                sendMessageForSocketIO("CounterOfferIsUpdated||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user + "||amount:" + counterOffer + "||from:" + selectedRowDataValueFavorites.pickup_dest + "||to:" + selectedRowDataValueFavorites.delivery_dest + "||load_number:" + selectedRowDataValueFavorites.load_number + "||table_name:" + "Loads Posted", selectedRowDataValueFavorites.posted_by_user, true, selectedRowDataValueFavorites, selectedRowDataValueFavorites);
                sendMessageForSocketIO("CounterOfferUpdated||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValueFavorites, selectedRowDataValueFavorites);
            }
            else if (loggedInuserCompanyRole === "Broker" || loggedInuserCompanyRole === "Broker&Carrier") {
                sendMessageForSocketIO("ThereIsACounterOffer||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user + "||amount:" + counterOffer + "||from:" + selectedRowDataValuePostedLoads.pickup_dest + "||to:" + selectedRowDataValuePostedLoads.delivery_dest + "||load_number:" + selectedRowDataValuePostedLoads.load_number + "||table_name:" + "Favorites", selectedRowDataValuePostedLoads.carrier_name, true, selectedRowDataValuePostedLoads, selectedRowDataValuePostedLoads);
                sendMessageForSocketIO("ThereIsANewCounterOffer||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValuePostedLoads);
            }
            sendMessageForSocketIO("newNotification", "", false);
            setIsNotificationSent(true)
            try {
                let res = await sendEmailForCounterOffer(selectedRowDataValue, loadFreightDimension, counterOffer, loggedInuserCompanyRole, loggedInuserRole, loadPostedByUser, user)
                if (res) {
                    setSnackBarMessageText(`Counter Offer is Submitted by ${userCompanyName} and Email Sent`);
                    setOpenSnackBar(true);
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    const AcceptCounterOfferCarrier = async () => {
        if (counterOffer !== "" && counterOffer !== "0" && counterOffer !== "00") {
            setCounterOpenCarrier(false)
            setCounterOffer("");
            let listValue
            if (loadPostedByUser === user.email) {
                listValue = selectedRowDataValuePostedLoads;
            }
            else {
                listValue = selectedRowDataValueFavorites;
            }
            listValue.status = "new";
            listValue.bid_time_stamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            listValue.counter_offer = counterOffer;
            listValue.counteroffer_status = "newoffer" ? "broker" : "carrier";
            listValue.counteroffer_status = "Accept"
            let response = await StoreOffer(listValue)
            if (response) {
                setSnackBarMessageText(`${userCompanyName} Accepted Counter Offer and Email Sent`);
                setOpenSnackBar(true);
                let loadDimension = getCombinedFreightDimension(listValue);
                toGetData()
                if (loggedInuserCompanyRole === "Carrier" || loadPostedByUser !== user.email) {
                    onSendMessageClick(` ${userCompanyName} Accepted  Counter Offer  $${counterOffer}`, "System Message", "Favorites");
                    sendMessageForSocketIO("CounterOfferAccepted||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user + "||amount:" + counterOffer + "||from:" + selectedRowDataValueFavorites.pickup_dest + "||to:" + selectedRowDataValueFavorites.delivery_dest + "||load_number:" + selectedRowDataValueFavorites.load_number + "||table_name:" + "Loads Posted", selectedRowDataValueFavorites.posted_by_user, false, selectedRowDataValueFavorites, selectedRowDataValueFavorites);
                    sendMessageForSocketIO("CounterOfferAccepted||load_id:" + selectedRowDataValueFavorites.load_id + "||posted_by_user:" + selectedRowDataValueFavorites.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValueFavorites, selectedRowDataValueFavorites);
                }
                else if (loadPostedByUser === user.email) {
                    onSendMessageClick(` ${userCompanyName} Accepted  Counter Offer  $${counterOffer}`, "System Message", "Loads Posted");
                    sendMessageForSocketIO("CounterOfferAccepted||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user + "||amount:" + counterOffer + "||from:" + selectedRowDataValuePostedLoads.pickup_dest + "||to:" + selectedRowDataValuePostedLoads.delivery_dest + "||load_number:" + selectedRowDataValuePostedLoads.load_number + "||table_name:" + "Favorites", selectedRowDataValuePostedLoads.carrier_name, false, selectedRowDataValuePostedLoads, selectedRowDataValuePostedLoads);
                    sendMessageForSocketIO("CounterOfferAccepted||load_id:" + selectedRowDataValuePostedLoads.load_id + "||posted_by_user:" + selectedRowDataValuePostedLoads.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValuePostedLoads, selectedRowDataValuePostedLoads);
                }
                try {
                    let res = await sendEmailForAcceptCounterOffer(listValue, loadFreightDimension, counterOffer, loggedInuserCompanyRole, loggedInuserRole, loadPostedByUser, user)
                    if (res) {
                        setSnackBarMessageText(`${userCompanyName} Accepted Counter Offer and Email Sent`);
                        setOpenSnackBar(true);
                    }
                }
                catch (err) {
                    console.log(err)
                }
            }
        }
        else {
            Swal.fire({
                text: `Counter Offer Cannot be Zero Or Empty Value`,
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setCounterOpenCarrier(false)
        }
    }

    /******************************************Setting Bid Time ************************************************/
    // Function to handle Bid Expiration  Minutes On Input
    const loadHandleBidExpirationChangeForMin = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setBidExpirationFavorites(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    setBidExpirationFavorites(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setBidExpirationFavorites(timeValue);
                }
            }
            else {
                setBidExpirationFavorites("59");
            }
        } else if (newInputValue.length > 2) {
            setBidExpirationFavorites("59");
        }
        else {
            setBidExpirationFavorites(newInputValue);
        }
        if (newInputValue === "00") {
            setBidExpirationFavorites("15")
        }
        setShowPrompt()
    };

    //Function To handle Bid Expiration On Change From autocomplete
    const handleSetBidExpirationOnchangeValueForMin = (event, newValue) => {
        if (newValue === "00") {
            setBidExpirationFavorites("15")
        }
        else {
            setBidExpirationFavorites(newValue);
        }
        setShowPrompt()
    }

    //Function to handle On Close Of Bid Expiration time Field
    const loadHandleBidExpirationCloseForMin = () => {
        if (bidExpirationFavorites === "00") {
            setBidExpirationFavorites("15")
        }
        let valueOnClose = bidExpirationFavorites
        if (valueOnClose === "1" || valueOnClose === "2" || valueOnClose === "3" || valueOnClose === "4") {
            setBidExpirationFavorites("05")
        }
        else if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setBidExpirationFavorites("0" + valueOnClose);
            }
        }
        else {
            setBidExpirationFavorites("15")
        }
    }

    //---------------------------------------------------------------------------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === "Data is loading for you...." ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={handleCloseSecondaryPaneForNotificationOpen} />
            <MUIDrawer notificationSent={isNotificationSent} />
            <main className={classes.content}>
                <div style={{ height: 20 }} />
                {Prompt}
                <Typography component={'div'} >
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs textColor="#101e35">
                                        <Tab className={`${classes.leftAlign}`} style={{ color: "#101e35" }} label={label} onClick={() => searchButtonClicked()} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <IconButton button onClick={() => handleExpandMoreIconClick()} hidden={!expandColapseTopPannel} >
                                        <ExpandMoreIcon style={{ width: 45, height: 45, color: "#0d2346" }} />
                                    </IconButton>
                                    <IconButton button onClick={() => handleExpandLessIconClick()} hidden={expandColapseTopPannel} >
                                        <ExpandLessIcon style={{ width: 45, height: 45, color: "#0d2346" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandColapseTopPannel}>
                                <div style={{ height: 5 }} />
                                <div color="black" border={0} padding={2} margin={1} hidden={expandColapseTopPannel} onKeyUp={(event) => {
                                    if (event.ctrlKey && event.key === 'Enter') {
                                        if (!showing) {
                                            handleNewOrderClick();
                                        }

                                    }
                                }}>
                                    <PostLoadForm selectedRowData={selectedRowDataValuePostedLoads} functionality={postLoadFormFunctionality} loadNumber={loadNumber} ref={PostLoadFormRef} updatedUser={user.email} />
                                    <div style={{ height: 10 }} />
                                    <Grid item container xs={12} justify="flex-end">
                                        <Tooltip arrow title="Ctrl+Enter">
                                            <Button onClick={handleNewOrderClick} variant="contained" hidden={showing} disabled={disablePostLoadButton} style={{ marginRight: 20, textTransform: "none" }}>Post New Load</Button>
                                        </Tooltip>
                                        <Button onClick={onCancelRePostClick} variant="contained" hidden={!showing} style={{ marginRight: 20, textTransform: "none" }}>Cancel</Button>
                                        <Button onClick={handleRepostChanges} variant="contained" hidden={!showing} diabled={disableUpdateLoadButton} style={{ marginRight: 20, textTransform: "none" }}>Update Load</Button>
                                    </Grid>
                                </div>
                            </TabPanel>
                            <TabPanel value={topTabPannelValue} index={1} hidden={expandColapseTopPannel}>
                                <SearchForm ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={(loggedInuserCompanyRole === "Carrier") ? onFilterChipCloseEventForFavorite : onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPiecesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={counteropen}
                    onClose={handleClose}
                >
                    <DialogTitle>Counter Offer ($)</DialogTitle>
                    <DialogContent>

                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <div style={{ padding: "2% 18%" }}>
                                <TextField
                                    placeholder="Counter Amount"
                                    value={counterOffer}
                                    inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                                    size="large"
                                    autoComplete='off'
                                    onChange={handleKeyPress}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoney fontSize="inherit"
                                                    style={{ fontSize: "20px", marginBottom: 15, marginTop: 15, display: "inline" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: "flex", marginTop: "6%", justifyContent: "end", marginRight: "5%" }}>
                            <button className="counteroffersubmit-btn" onClick={SubmitCounterOffer}>
                                Submit
                            </button>
                            <button className="counteroffercancel-btn" onClick={CancelCounterOffer}>
                                Cancel
                            </button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={counteropencarrier}
                    onClose={handleClose}
                >
                    <Grid container item xs={12} justify="flex-end">
                        <IconButton onClick={CloseForm}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <DialogTitle style={{ paddingTop: 0 }}>Counter Offer ($)</DialogTitle>
                    <DialogContent>

                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <div style={{ padding: "2% 18%" }}>
                                <TextField
                                    placeholder="Counter Amount"
                                    value={counterOffer}
                                    inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                                    size="large"
                                    autoComplete='off'
                                    onChange={handleKeyPress}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoney fontSize="inherit"
                                                    style={{ fontSize: "20px", marginBottom: 15, marginTop: 15, display: "inline" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: "flex", marginTop: "6%", justifyContent: "end", marginRight: "5%" }}>
                            {((loadPostedByUser === user.email && counterOffer !== selectedRowDataValuePostedLoads.counter_offer) || (loadPostedByUser !== user.email && counterOffer !== selectedRowDataValueFavorites.counter_offer)) ?
                                <button className="counteroffersubmit-btn" onClick={SubmitCounterOffer}>
                                    Update
                                </button>
                                :
                                <Button style={{ textTransform: "capitalize", borderRadius: 5, width: maxWidth, color: "#7d7d7d", background: "#ccc", borderColor: "#7c7c7c" }} variant="outlined" disabled>Update</Button>
                            }
                            {
                                (loadPostedByUser === user.email) ?
                                    (counterOffer === selectedRowDataValuePostedLoads.counter_offer && selectedRowDataValuePostedLoads.counteroffer_sent === "carrier") ?
                                        <button className="counterofferaccept-btn" onClick={AcceptCounterOfferCarrier} >
                                            Accept
                                        </button>
                                        :
                                        <Button style={{ textTransform: "capitalize", borderRadius: 5, width: maxWidth, color: "#7d7d7d", background: "#ccc", borderColor: "#7c7c7c" }} variant="outlined" disabled>Accept</Button>
                                    :
                                    (counterOffer === selectedRowDataValueFavorites.counter_offer && selectedRowDataValueFavorites.counteroffer_sent === "broker") ?
                                        <button className="counterofferaccept-btn" onClick={AcceptCounterOfferCarrier} >
                                            Accept
                                        </button>
                                        :
                                        <Button style={{ textTransform: "capitalize", borderRadius: 5, width: maxWidth, color: "#7d7d7d", background: "#ccc", borderColor: "#7c7c7c" }} variant="outlined" disabled>Accept</Button>
                            }
                        </div>
                    </DialogActions>
                </Dialog>
                {
                    (loggedInuserCompanyRole === "Broker&Carrier" && loggedInuserRole !== "Carrier") || (loggedInuserCompanyRole === "Broker" || user.email === process.env.REACT_APP_ADMIN_USER) ?
                        <>
                            <div style={{ height: 20 }} />
                            <Typography component={'div'}>
                                <Grid container direction="row" margin={2}>
                                    <div style={{ width: mainGridPercentPostedLoads }}>
                                        <MuiThemeProvider theme={getMuiTheme()}>
                                            <MUIDataTable
                                                title={"Loads Posted"}
                                                data={rowDataForPostedLoads}
                                                columns={columnDefsForPostedLoads}
                                                options={optionsForPostedLoads}
                                            ></MUIDataTable>
                                        </MuiThemeProvider>
                                    </div>
                                    {
                                        isPaneOpenPostedLoads && (
                                            <React.Fragment>
                                                <div style={{ width: "1%" }}></div>
                                                <div style={{ width: "64%" }} border={1}>
                                                    <Box
                                                        boxShadow={3}>
                                                        <Paper square className="Square">
                                                            <Grid item container xs={12} justify="flex-end">
                                                                <Tooltip title="Delete Load">
                                                                    <IconButton onClick={handleSoftDeleteConfirmClick}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Edit Load">
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handleEditButtonClick(selectedRowforLoadsPosted)
                                                                        }}
                                                                    >
                                                                        <Edit />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <IconButton onClick={handleGettingConfirmationForPostedLoadsClosingSecondaryPane}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item style={{ fontSize: 14 }} xs={12}>
                                                                <Grid container direction="row">
                                                                    <Grid item xs={6}>
                                                                        <div style={{ height: 7 }} />
                                                                        <Row style={{ fontSize: 18 }}>
                                                                            <Col sm><b>Load Details</b></Col>
                                                                            <Col style={{ marginRight: 15 }} sm></Col>
                                                                        </Row>
                                                                        <div style={{ height: 7 }} />
                                                                        <Row>
                                                                            <Col sm>Load ID:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadNumberDetail}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: 'bold' }}>Pick-Up Destination:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadPickUpDestination}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Pick-Up Window:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadPickUpWindow}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Pick-Up Date:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadPickUpdate}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: 'bold' }}>Deliver Destination:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadDeliverDestination}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Delivery Window:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadDeliverWindow}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Delivery Date:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadDeliverByDate}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: "bold" }}>Weight:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: "bold" }} sm>
                                                                                {loadMaxWeight}
                                                                            </Col>
                                                                        </Row>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <div style={{ height: 33 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: "bold" }}>Approximate Distance <div>(Drive Time):</div></Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: "bold" }} sm >{loadCityDistance}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 5 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: "bold" }}>Number Of Pieces:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: "bold" }} sm>
                                                                                {loadNoOfPieces}
                                                                            </Col>
                                                                        </Row>
                                                                        <div style={{ height: 5 }} />
                                                                        <Row>
                                                                            <Col sm>Freight Dimension:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm >{loadFreightDimension}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Truck Type:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>
                                                                                {loadLoadType}
                                                                            </Col>
                                                                        </Row>
                                                                        {/* <Row>
                                                            <Col sm>Rate:</Col>
                                                            <Col style={{ marginRight: 15 }} sm>{loadRate}</Col>
                                                        </Row> */}

                                                                        <div style={{ height: 5 }} />
                                                                        <TextField
                                                                            label="Load Notes"
                                                                            multiline
                                                                            autoComplete='off'
                                                                            rows={6}
                                                                            paddingTop={3}
                                                                            variant="outlined"
                                                                            style={{
                                                                                width: "97%",
                                                                            }}
                                                                            value={loadLoadNotesPostedLoads}
                                                                            disabled={true}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {
                                                                            (loadPostedByUser === user.email) &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div style={{ height: 20 }} />
                                                                                    <Grid style={{ paddingTop: "2%", paddingBottom: "2%" }}>
                                                                                        <Box className="heightfix" border={0} padding={2} paddingTop={"3%"} >
                                                                                            <div style={{ display: 'flex' }}>
                                                                                                <h6><b>Load Expiration Time</b></h6>
                                                                                            </div>
                                                                                            <Row>
                                                                                                <Col xs={4} className="setCloseButtonSm3">
                                                                                                    <MUIAutoComplete
                                                                                                        onClose={() => loadHandleLoadExpirationCloseForHour()}
                                                                                                        //freeSolo
                                                                                                        disableClearable
                                                                                                        value={loadUpdateLoadExpirationTimeForHour}
                                                                                                        onChange={(event, newValue) => {
                                                                                                            handleSetLoadExpirationOnchangeValueForHour(event, newValue)
                                                                                                        }}
                                                                                                        inputValue={loadUpdateLoadExpirationTimeForHour}
                                                                                                        onInputChange={(event, newInputValue) =>
                                                                                                            loadHandleLoadExpirationChangeForHour(event, newInputValue)
                                                                                                        }
                                                                                                        options={hourValue}
                                                                                                        style={{ marginTop: 10, width: "90%", marginLeft: 20 }}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField {...params} variant="outlined" label="Select Hour" />
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col xs={4} className="ExpirationButton">
                                                                                                    <MUIAutoComplete
                                                                                                        onClose={() => loadHandleLoadExpirationCloseForMin()}
                                                                                                        //freeSolo
                                                                                                        disableClearable
                                                                                                        value={loadUpdateLoadExpirationTimeForMin}
                                                                                                        onChange={(event, newValue) => {
                                                                                                            handleSetLoadExpirationOnchangeValueForMin(event, newValue)
                                                                                                        }}
                                                                                                        inputValue={loadUpdateLoadExpirationTimeForMin}
                                                                                                        onInputChange={(event, newInputValue) =>
                                                                                                            loadHandleLoadExpirationChangeForMin(event, newInputValue)
                                                                                                        }
                                                                                                        options={minutes}
                                                                                                        style={{ width: "90%", marginTop: 10, marginLeft: 20 }}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField {...params} variant="outlined" label="Select Minute" />
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <div>
                                                                                                    <Button style={{ marginTop: 20, marginLeft: 12, width: "20%", textTransform: "none", backgroundColor: "#101e35", color: "white" }} variant="contained" size="medium" onClick={handleUpdateLoadExpirationUpdateChnage}>
                                                                                                        Set
                                                                                                    </Button>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <Button style={{ marginTop: 20, marginLeft: 12, width: "20%", textTransform: "none", backgroundColor: "#101e35", color: "white" }} variant="contained" size="medium" onClick={handleLoadExpireNow}>
                                                                                                        Close
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Row>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid container direction="row">
                                                                        <Grid item xs={6}>
                                                                            <div style={{ height: 5 }} />
                                                                            <Row>
                                                                                <Col sm>Stackable:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>
                                                                                    {toShowIcon(loadStackable)}
                                                                                </Col>
                                                                            </Row>
                                                                            <div style={{ height: 15 }} />
                                                                            <Row>
                                                                                <Col sm>Hazmat:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>
                                                                                    {toShowIcon(loadHazmat)}
                                                                                </Col>
                                                                            </Row>
                                                                            <div style={{ height: 15 }} />
                                                                            <Row>
                                                                                <Col sm>Dock High:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>
                                                                                    {toShowIcon(loadDockHigh)}
                                                                                </Col>
                                                                            </Row>
                                                                            <div style={{ height: 15 }} />
                                                                            <Row>
                                                                                <Col sm>Temperature Control:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>
                                                                                    {toShowIcon(loadTempControl)}
                                                                                </Col>
                                                                            </Row>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <div style={{ height: 5 }} />

                                                                            <Row>
                                                                                <Col sm>Posted By:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>{loadPostedBy}</Col>
                                                                            </Row>
                                                                            <div style={{ height: 18 }} />
                                                                            <Row>
                                                                                <Col sm>Posted By User:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>{loadPostedByUser}</Col>
                                                                            </Row>
                                                                            <div style={{ height: 18 }} />
                                                                            <Row>
                                                                                <Col sm>Load Posted at:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm >{loadPostedTimePostedLoads}</Col>
                                                                            </Row>
                                                                            <div style={{ height: 18 }} />
                                                                            <Row>
                                                                                <Col sm>Load Expires In:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm >{
                                                                                    <Countdown autoStart={true} date={Date.parse(loadPostedTimePostedLoads) + parseInt(loadExpiresInPostedLoads)}>
                                                                                    </Countdown>
                                                                                }</Col>
                                                                            </Row>
                                                                        </Grid >
                                                                    </Grid>
                                                                    {/*<div style={{ justifyContent: "center", width: "100%", display: "flex" }}>

                                                                <div ><div>
                                                                    <MapLoader data={{ "fromlatitude": mapDistance.pickuplat, "fromlongitude": mapDistance.pickuplng, "tolatitude": mapDistance.deliverylat, "tolongitude": mapDistance.deliverylng }}
                                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6w_WDy6psJ5HPX15Me1-o6CkS5jTYWnE"
                                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                                    />
                                                                </div></div>
                                                            </div>*/}
                                                                </Grid >
                                                            </Grid >
                                                        </Paper >
                                                    </Box >
                                                    <div style={{ height: 10 }} />
                                                    <Divider />
                                                    {
                                                        (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserRole !== "Carrier" || loggedInuserCompanyRole === "Broker") && (
                                                            <React.Fragment>
                                                                <div style={{ height: 10 }} />
                                                                <MUIDataTable
                                                                    title={"Active Bids"}
                                                                    data={rowDataActiveBidTable}
                                                                    columns={columnDefsActiveBidTable}
                                                                    options={optionsActiveBidTable}
                                                                ></MUIDataTable>
                                                                <div style={{ height: 10 }} />
                                                                <MUIDataTable
                                                                    title={"Closed Bids"}
                                                                    data={rowDataClosedBidTable}
                                                                    columns={columnDefsClosedBidTable}
                                                                    options={optionsClosedBidTable}
                                                                ></MUIDataTable>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    <div style={{ height: 10 }} />
                                                    <Divider />
                                                    {
                                                        (user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser === user.email || (loggedInuserCompanyRole === "Broker" || loadPostedByUser === user.email) || (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin" || loggedInuserRole === "Carrier" || loggedInuserRole === "Broker"))) &&
                                                        (
                                                            <React.Fragment>
                                                                <Divider />
                                                                <Grid>
                                                                    <Box style={{ paddingBottom: "6%", height: 597 }} boxShadow={3}>
                                                                        <Paper square className="Paper">
                                                                            <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor="primary" textColor="primary">
                                                                                <Tab label="Comments" />
                                                                            </Tabs>
                                                                            <TabPanel value={tabPannerlValue} index={0}>
                                                                                <React.Fragment>
                                                                                    <div className="Home">
                                                                                        <div className="LeftSide">
                                                                                            <Header />
                                                                                            <SearchBar changeFilter={changeFilter} />
                                                                                            {Array.isArray(allChatForLoadsPosted) && allChatForLoadsPosted.length !== 0 && (
                                                                                                <List className="Custom-MuiList-root" style={{ maxHeight: "calc(100vh - 109px)", overflow: "auto", objectFit: "cover" }}>
                                                                                                    {
                                                                                                        allChatForLoadsPosted
                                                                                                            .sort(function (b, a) {
                                                                                                                return new Date(a.time_stamp) - new Date(b.time_stamp);
                                                                                                            }).map((item, i) => {
                                                                                                                return (loggedInuserCompanyRole === "Broker" || loggedInuserCompanyRole === "Broker&Carrier" || user.email === process.env.REACT_APP_ADMIN_USER) && (
                                                                                                                    <ListItem button alignItems="flex-start" id={item.carrier_id} value={item.carrier_id} className="Custom-MuiListItem-root" key={item.carrier_id} onClick={(event) => getSingleCarrierMessage(event, "Loads Posted")}>
                                                                                                                        {
                                                                                                                            (item.carrier_id !== undefined) && (
                                                                                                                                <div id={item.carrier_id} className="Discussion">
                                                                                                                                    <div id={item.carrier_id} className="LeftDiscussion">
                                                                                                                                        {
                                                                                                                                            (item.carrier_id.charAt(0) !== undefined) && (
                                                                                                                                                <Avatar>{item.carrier_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </div>

                                                                                                                                    <div id={item.carrier_id} className="RightDiscussion">
                                                                                                                                        <div id={item.carrier_id} className="RightDiscussionTop">
                                                                                                                                            <div id={item.carrier_id} className="RightDiscussionTopLeft">{item.carrier_company}</div>
                                                                                                                                        </div>
                                                                                                                                        <div id={item.carrier_id} className="RightDiscussionBas">
                                                                                                                                            {
                                                                                                                                                (item.bid_amount !== undefined && item.bid_amount !== "$") && (
                                                                                                                                                    <div className="RightDiscussionTopRight">{"Bid: " + item.bid_amount || loadBidAmount}</div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </ListItem>
                                                                                                                )
                                                                                                            }
                                                                                                            )
                                                                                                    }
                                                                                                </List>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="RightSide">
                                                                                            {
                                                                                                (chatFirstCarrierNameForLoadsPosted !== undefined) && (
                                                                                                    <HeaderChat name={chatFirstCarrierNameForLoadsPosted.substring(0, chatFirstCarrierNameForLoadsPosted.lastIndexOf("@"))} companyName={chatFirstCompanyNameForLoadsPosted} mcnumber={chatMCNumberForLoadsPosted} />
                                                                                                )}
                                                                                            <Chat discussion={chatAllMessagesForLoadsPosted} />
                                                                                            <div>
                                                                                                <form onKeyPress={(e) => { onEnterButtonForChat(e, "Loads Posted") }}>
                                                                                                    <Paper component="form" className={classes.customPaper}>
                                                                                                        <InputBase id="inputMesageLoadsPosted" autoComplete='off' className={classes.CustomInput} style={{ width: "85%" }} placeholder="Enter your message" />
                                                                                                        <IconButton onClick={() => handleOnSendMessageClick("Loads Posted")}>
                                                                                                            <SendIcon />
                                                                                                        </IconButton>
                                                                                                    </Paper>
                                                                                                </form>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </TabPanel>
                                                                        </Paper>
                                                                    </Box>
                                                                    {
                                                                        ((loggedInuserRole !== "Broker&Carrier" && (loggedInuserCompanyRole !== "Broker&Carrier" && loggedInuserRole !== "Company Admin")) && (
                                                                            <div style={{ height: 65 }} />
                                                                        ))

                                                                    }
                                                                </Grid>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div >
                                            </React.Fragment >
                                        )
                                    }
                                </Grid >
                                {
                                    ((loggedInuserRole !== "Broker&Carrier" && (loggedInuserCompanyRole !== "Broker&Carrier" && loggedInuserRole !== "Company Admin")) && (
                                        <div style={{ paddingBottom: "5%" }} />
                                    ))
                                }
                            </Typography >
                        </>
                        : null
                }
                {
                    loggedInuserRole !== "Broker" && (loggedInuserCompanyRole !== "Broker" || user.email === process.env.REACT_APP_ADMIN_USER) ?
                        <>
                            <div style={{ height: 20 }} />
                            <Typography component={'div'}>
                                <Grid container direction="row" margin={2}>
                                    <div style={{ width: mainGridPercentFavorites, paddingBottom: "10%" }}>
                                        <MuiThemeProvider theme={getMuiTheme()}>
                                            <MUIDataTable
                                                title={"Your Bids & Favorites"}
                                                data={rowDataForFavorites}
                                                columns={columnDefsForFavorites}
                                                options={optionsForFavorites}
                                            ></MUIDataTable>
                                        </MuiThemeProvider>
                                    </div>
                                    {
                                        isPaneOpenFavorites && (
                                            <React.Fragment>
                                                <div style={{ width: "1%" }}></div>
                                                <div style={{ width: "64%" }} border={1}>
                                                    <Box boxShadow={3}>
                                                        <Paper square className="Square">
                                                            <Grid item container xs={12} justify="flex-end">
                                                                {
                                                                    (loggedInuserCompanyRole !== "Broker" && loggedInuserRole !== "Broker") &&
                                                                    (
                                                                        <React.Fragment>
                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <IconButton hidden={loadSavedLane} onClick={handleSavedLaneClick}>
                                                                                    <Tooltip title="Save Lane">
                                                                                        <BookmarkBorderIcon />
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                                <IconButton hidden={!loadSavedLane} onClick={handleRemoveSavedLaneClick}>
                                                                                    <Tooltip title="Remove SavedLane">
                                                                                        <BookmarkIcon style={{ fill: "#0BDA51" }} />
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                                {/* <IconButton hidden={loadWatchLoadFavorites} onClick={handleWatchLoadClick}>
                                                                                    <Tooltip title="Watch Load">
                                                                                        <StarBorderIcon />
                                                                                    </Tooltip>
                                                                                </IconButton> */}
                                                                                <IconButton hidden={!loadWatchLoadFavorites} onClick={handleStopWatchLoadClick}>
                                                                                    <Tooltip title="Stop Watching">
                                                                                        <StarIcon style={{ fill: "#ffe007" }} />
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                <IconButton onClick={handleGettingConfirmationForFavouritesClosingSecondaryPane}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item style={{ fontSize: 14 }} xs={12}>
                                                                <Grid container direction="row">
                                                                    <Grid item xs={6}>
                                                                        <div style={{ height: 7 }} />
                                                                        <Row style={{ fontSize: 18 }}>
                                                                            <Col sm><b>Load Details</b></Col>
                                                                            <Col style={{ marginRight: 15 }} sm></Col>
                                                                        </Row>
                                                                        <div style={{ height: 7 }} />
                                                                        <Row>
                                                                            <Col sm>Load ID:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadNumberDetail}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: 'bold' }}>Pick-Up Destination:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadPickUpDestination}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Pick-Up Window:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadPickUpWindow}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Pick-Up Date:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadPickUpdate}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 5 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: 'bold' }}>Deliver Destination:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadDeliverDestination}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Delivery Window:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadDeliverWindow}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Delivery Date:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadDeliverByDate}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: "bold" }}>Weight:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: "bold" }} sm>
                                                                                {loadMaxWeight}
                                                                            </Col>
                                                                        </Row>
                                                                        {
                                                                            (loggedInuserRole !== "Broker" || loggedInuserCompanyRole !== "Broker") && (
                                                                                <React.Fragment>
                                                                                    <div style={{ height: 15 }} />
                                                                                    <Row>
                                                                                        <Col sm>Status:</Col>
                                                                                        <Col style={{ marginRight: 15 }} sm >{loadStatusFavorites}</Col>
                                                                                    </Row>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <div style={{ height: 33 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: "bold" }}>Approximate Distance<div>(Drive Time):</div></Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: "bold" }} sm >{loadCityDistance}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 5 }} />
                                                                        <Row>
                                                                            <Col sm style={{ fontWeight: "bold" }}>Number Of Pieces:</Col>
                                                                            <Col style={{ marginRight: 15, fontWeight: "bold" }} sm>
                                                                                {loadNoOfPieces}
                                                                            </Col>
                                                                        </Row>
                                                                        <div style={{ height: 5 }} />
                                                                        <Row>
                                                                            <Col sm>Freight Dimension:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm >{loadFreightDimension}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Truck Type:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>
                                                                                {loadLoadType}
                                                                            </Col>
                                                                        </Row>
                                                                        {/* <Row>
                                                            <Col sm>Rate:</Col>
                                                            <Col style={{ marginRight: 15 }} sm>{loadRate}</Col>
                                                        </Row> */}
                                                                        <div style={{ height: 15 }} />
                                                                        <TextField
                                                                            label="Load Notes"
                                                                            multiline
                                                                            autoComplete='off'
                                                                            rows={6}
                                                                            paddingTop={3}
                                                                            variant="outlined"
                                                                            style={{
                                                                                width: "97%",
                                                                            }}
                                                                            value={loadLoadNotesFavorites}
                                                                            disabled={true}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {
                                                                            ((((loggedInuserRole === "CompanyAdmin" && loggedInuserCompanyRole !== "Broker") || (loggedInuserRole === "Carrier" && loggedInuserCompanyRole !== "Broker") || (loggedInuserRole === "Broker&Carrier")) && loadPostedByUser !== user.email) || (user.email !== process.env.REACT_APP_ADMIN_USER && loadPostedByUser !== user.email)) && (
                                                                                <React.Fragment>
                                                                                    <div style={{ height: 7 }} />
                                                                                    <Grid item style={{ marginBottom: 10 }} xs={12}>
                                                                                        <Box className="Color" boxShadow={3}
                                                                                            style={{ width: "99%", height: "350%", backgroundColor: "#ececec" }}
                                                                                            color="#101e35" border={0} padding={2} paddingTop={"2%"}>
                                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 1 }}>
                                                                                                <h6><b>Bid Now</b></h6>
                                                                                            </div>
                                                                                            <Grid item xs={12}>
                                                                                                <Row style={{ marginLeft: "3%" }}>
                                                                                                    <Col>
                                                                                                        <Row>
                                                                                                            <TextField
                                                                                                                id="txtFieldBidAmount"
                                                                                                                value={loadBidAmount}
                                                                                                                autoComplete='off'
                                                                                                                inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                                                                                                                onChange={(val) => updateLoadBidAmount(val)}
                                                                                                                disabled={loadBidAmountDisableFavorites}
                                                                                                                style={{ width: "29%", marginRight: "2%" }}
                                                                                                                label="Amount * ($)"
                                                                                                                error={(errors.loadBidAmount && loadBidAmount === "") || loadBidAmount === "0"}
                                                                                                            />
                                                                                                            <MUIAutoComplete
                                                                                                                onClose={() => loadHandleBidExpirationCloseForMin()}
                                                                                                                //freeSolo
                                                                                                                disableClearable
                                                                                                                disabled={loadBidAmountDisableFavorites}
                                                                                                                value={bidExpirationFavorites}
                                                                                                                onChange={(event, newValue) => {
                                                                                                                    handleSetBidExpirationOnchangeValueForMin(event, newValue)
                                                                                                                }}
                                                                                                                inputValue={bidExpirationFavorites}
                                                                                                                onInputChange={(event, newInputValue) =>
                                                                                                                    loadHandleBidExpirationChangeForMin(event, newInputValue)
                                                                                                                }
                                                                                                                options={minutes}
                                                                                                                style={{ width: "29%", marginRight: "2%" }}
                                                                                                                renderInput={(params) => (
                                                                                                                    <TextField {...params} variant="outlined" label="Select Minute" style={{ height: 50 }} />
                                                                                                                )}
                                                                                                            />
                                                                                                            {

                                                                                                                <TextField
                                                                                                                    label="Miles Out"
                                                                                                                    variant="outlined"
                                                                                                                    placeholder="miles"
                                                                                                                    autoComplete='off'
                                                                                                                    value={txtMilesOut}
                                                                                                                    error={txtMilesOut < 0}
                                                                                                                    helperText={txtMilesOut < 0 ? "Not Valid Input" : ""}
                                                                                                                    inputProps={{ pattern: "[0-9]*", maxLength: 3 }}
                                                                                                                    onChange={handelTxtMilesOutChange}
                                                                                                                    style={{ width: "30%" }}
                                                                                                                    disabled={MilesOutDisable}
                                                                                                                />
                                                                                                            }
                                                                                                        </Row>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <TextField
                                                                                                            label="Bid Notes"
                                                                                                            multiline
                                                                                                            autoComplete='off'
                                                                                                            rows={1}
                                                                                                            variant="outlined"
                                                                                                            style={{ width: "91%", margin: 10, backgroundColor: "#ffffff", marginLeft: "3%" }}
                                                                                                            value={BidNotes}
                                                                                                            onChange={(val) => updateBidNotes(val.target.value)}
                                                                                                            disabled={loadBidNotesDisable}
                                                                                                        />
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Row style={{ marginLeft: "3%" }}>
                                                                                                    <Col style={{ paddingLeft: 0, paddingRight: 0 }} sm>
                                                                                                        <p style={{ fontSize: 13, marginBottom: 0 }}> Truck Dimension (Inch) </p>
                                                                                                        <TextField
                                                                                                            label="Length"
                                                                                                            variant="outlined"
                                                                                                            autoComplete='off'
                                                                                                            value={txtLength}
                                                                                                            style={{ width: "23%" }}
                                                                                                            inputProps={{ pattern: "[0-9]*", maxLength: 3 }}
                                                                                                            onChange={handletxtLength}
                                                                                                            disabled={lengthDisable}
                                                                                                        />
                                                                                                        <IconButton style={{ padding: "0%", width: "5%", top: 8 }} disabled={true}>
                                                                                                            <CloseIcon className="CloseIcon"
                                                                                                                fontSize="small"
                                                                                                                style={{ marginBottom: 10, padding: 0 }}
                                                                                                            />
                                                                                                        </IconButton>
                                                                                                        <TextField
                                                                                                            label="Width"
                                                                                                            variant="outlined"
                                                                                                            autoComplete='off'
                                                                                                            value={txtWidth}
                                                                                                            style={{ width: "22%" }}
                                                                                                            inputProps={{ pattern: "[0-9]*", maxLength: 3 }}
                                                                                                            onChange={handletxtWidth}
                                                                                                            disabled={widthDisable}
                                                                                                        />
                                                                                                        <IconButton style={{ padding: "0%", width: "5%", top: 8 }} disabled={true}>
                                                                                                            <CloseIcon className="CloseIcon"
                                                                                                                fontSize="small"
                                                                                                                style={{ marginBottom: 10, padding: 0 }}
                                                                                                            />
                                                                                                        </IconButton>
                                                                                                        <TextField
                                                                                                            label="Height"
                                                                                                            variant="outlined"
                                                                                                            autoComplete='off'
                                                                                                            value={txtHeight}
                                                                                                            style={{ width: "22%" }}
                                                                                                            inputProps={{ pattern: "[0-9]*", maxLength: 3 }}
                                                                                                            onChange={handletxtHeight}
                                                                                                            disabled={heightDisable}
                                                                                                        />

                                                                                                        <Button className="ButtonColor" style={(loadBidAmount.toString().trim().replace(" ", "") !== "" && loadBidAmount !== "" && parseInt(loadBidAmount) !== 0) ? { marginLeft: "2%", height: 55, textTransform: "none", backgroundColor: "#101e35" } : { textTransform: "none", marginLeft: "2%", height: 55, backgroundColor: "#9e9e9ec2" }} variant="contained" size="medium" hidden={loadBidAmountDisableFavorites} disabled={disableBidButton} onClick={bidForALoad}>
                                                                                                            Bid Now
                                                                                                        </Button>
                                                                                                        <Button style={{ marginLeft: "2%", width: "12%", color: "white", height: 55, textTransform: "none", backgroundColor: "#101e35" }} variant="contained" size="medium" color="primary" hidden={!loadBidAmountDisableFavorites} onClick={cancelBid}>
                                                                                                            Close Bid
                                                                                                        </Button>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                        <Grid container direction="row">
                                                                            <Grid item xs={6}>
                                                                                <div style={{ height: 15 }} />
                                                                                <Row>
                                                                                    <Col sm>Stackable:</Col>
                                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                                        {toShowIcon(loadStackable)}
                                                                                    </Col>
                                                                                </Row>
                                                                                <div style={{ height: 15 }} />
                                                                                <Row>
                                                                                    <Col sm>Hazmat:</Col>
                                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                                        {toShowIcon(loadHazmat)}
                                                                                    </Col>
                                                                                </Row>
                                                                                <div style={{ height: 15 }} />
                                                                                <Row>
                                                                                    <Col sm>Dock High:</Col>
                                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                                        {toShowIcon(loadDockHigh)}
                                                                                    </Col>
                                                                                </Row>
                                                                                <div style={{ height: 15 }} />
                                                                                <Row>
                                                                                    <Col sm>Temperature Control:</Col>
                                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                                        {toShowIcon(loadTempControl)}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                {
                                                                                    <div>
                                                                                        <div style={{ height: 18 }} />
                                                                                        <Row>
                                                                                            <Col sm>Posted By:</Col>
                                                                                            <Col style={{ marginRight: 15 }} sm>{loadPostedBy}</Col>
                                                                                        </Row>
                                                                                        <div style={{ height: 18 }} />
                                                                                        <Row>
                                                                                            <Col sm>Posted By User:</Col>
                                                                                            <Col style={{ marginRight: 15 }} sm>{loadPostedByUser}</Col>
                                                                                        </Row>
                                                                                        <div style={{ height: 18 }} />
                                                                                        <Row>
                                                                                            <Col sm>Load Posted at:</Col>
                                                                                            <Col style={{ marginRight: 15 }} sm >{loadPostedTimeFavorites}</Col>
                                                                                        </Row>
                                                                                        <div style={{ height: 18 }} />
                                                                                        <Row>
                                                                                            <Col sm>Load Expires In:</Col>
                                                                                            <Col style={{ marginRight: 15 }} sm >{
                                                                                                <Countdown autoStart={true} date={Date.parse(loadPostedTimeFavorites) + parseInt(loadExpiresInFavorites)}>
                                                                                                </Countdown>
                                                                                            }</Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                        {/*<div style={{ justifyContent: "center", width: "100%", display: "flex" }}>

                                                                <div ><div>
                                                                    <MapLoader data={{ "fromlatitude": mapDistance.pickuplat, "fromlongitude": mapDistance.pickuplng, "tolatitude": mapDistance.deliverylat, "tolongitude": mapDistance.deliverylng }}
                                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6w_WDy6psJ5HPX15Me1-o6CkS5jTYWnE"
                                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                                    />
                                                                </div></div>
                                                            </div>*/}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Box>
                                                    <div style={{ height: 10 }} />
                                                    <Divider />
                                                    {
                                                        (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === "Carrier" || loggedInuserCompanyRole === "Broker&Carrier") && (
                                                            <React.Fragment>
                                                                <div style={{ height: 10 }} />
                                                                <MUIDataTable
                                                                    title={"Active Bids"}
                                                                    data={rowDataFavouritesActiveBidTable}
                                                                    columns={columnDefsActiveBidTableForFavourites}
                                                                    options={optionsActiveBidTableForFavourites}
                                                                ></MUIDataTable>
                                                                <div style={{ height: 10 }} />
                                                                <MUIDataTable
                                                                    title={"Closed Bids"}
                                                                    data={rowDataFavouritesClosedBidTable}
                                                                    columns={columnDefsClosedBidTableForFavourites}
                                                                    options={optionsClosedBidTableForFavourites}
                                                                ></MUIDataTable>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    <div style={{ height: 10 }} />
                                                    {(user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser === user.email || loggedInuserCompanyRole === "Carrier" || loggedInuserCompanyRole === "Broker" || loggedInuserCompanyRole === "Broker&Carrier") &&
                                                        (
                                                            <React.Fragment>
                                                                <Divider />
                                                                <Grid>
                                                                    <Box style={{ paddingBottom: "9%" }} boxShadow={3}>
                                                                        <Paper square className="Paper">
                                                                            <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor="primary" textColor="primary">
                                                                                <Tab label="Comments" />
                                                                            </Tabs>
                                                                            <TabPanel value={tabPannerlValue} index={0}>
                                                                                <React.Fragment>
                                                                                    <div className="Home">
                                                                                        <div className="LeftSide">
                                                                                            <Header />
                                                                                            <SearchBar changeFilter={changeFilter} />
                                                                                            {Array.isArray(allChatForFavorites) && allChatForFavorites.length !== 0 && (
                                                                                                <List className="Custom-MuiList-root" style={{ maxHeight: "calc(100vh - 109px)", overflow: "auto", objectFit: "cover", height: 415 }}>
                                                                                                    {
                                                                                                        allChatForFavorites
                                                                                                            .sort(function (b, a) {
                                                                                                                return new Date(a.time_stamp) - new Date(b.time_stamp);
                                                                                                            }).map((item, i) => {
                                                                                                                return (loggedInuserCompanyRole === "Broker" || user.email === process.env.REACT_APP_ADMIN_USER) ?
                                                                                                                    <ListItem button alignItems="flex-start" id={item.carrier_id} value={item.carrier_id} className="Custom-MuiListItem-root" key={item.carrier_id} onClick={(event) => getSingleCarrierMessage(event, "Favorites")}>
                                                                                                                        {
                                                                                                                            (item.carrier_id !== undefined) && (
                                                                                                                                <div id={item.carrier_id} className="Discussion">
                                                                                                                                    <div id={item.carrier_id} className="LeftDiscussion">
                                                                                                                                        {
                                                                                                                                            (item.carrier_id.charAt(0) !== undefined) && (
                                                                                                                                                <Avatar>{item.carrier_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div id={item.carrier_id} className="RightDiscussion">
                                                                                                                                        <div id={item.carrier_id} className="RightDiscussionTop">
                                                                                                                                            <div id={item.carrier_id} className="RightDiscussionTopLeft">{item.carrier_company}</div>
                                                                                                                                        </div>
                                                                                                                                        <div id={item.carrier_id} className="RightDiscussionBas">
                                                                                                                                            {
                                                                                                                                                (item.bid_amount !== undefined && item.bid_amount !== "$") && (
                                                                                                                                                    <div className="RightDiscussionTopRight">{"Bid: " + item.bid_amount || loadBidAmount}</div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </ListItem>
                                                                                                                    :
                                                                                                                    <ListItem button alignItems="flex-start" id={item.broker_id} value={item.broker_id} className="Custom-MuiListItem-root" key={item.broker_id} onClick={(event) => getSingleCarrierMessage(event, "Favorites")}>
                                                                                                                        {
                                                                                                                            (item.broker_id !== undefined) ?
                                                                                                                                <div id={item.broker_id} className="Discussion">
                                                                                                                                    <div id={item.broker_id} className="LeftDiscussion">
                                                                                                                                        {
                                                                                                                                            (item.broker_id.charAt(0) !== undefined) && (
                                                                                                                                                <Avatar>{item.broker_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div id={item.broker_id} className="RightDiscussion">
                                                                                                                                        <div id={item.broker_id} className="RightDiscussionTop">
                                                                                                                                            <div id={item.broker_id} className="RightDiscussionTopLeft">{item.broker_company || selectedRowDataValueFavorites.posted_by_company}</div>
                                                                                                                                        </div>
                                                                                                                                        <div id={item.broker_id} className="RightDiscussionBas">
                                                                                                                                            {
                                                                                                                                                (item.bid_amount !== undefined && item.bid_amount !== "$") && (
                                                                                                                                                    <span style={{ paddingTop: "10px", paddingRight: "2%" }} className="RightDiscussionTopRight">{"Bid: " + item.bid_amount || loadBidAmount}</span>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            {(item.mc_certified !== "Verified") && (
                                                                                                                                                <span><Tooltip title="Not Verified Company">
                                                                                                                                                    <NotVerifiedUserIcon />
                                                                                                                                                </Tooltip></span>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <div id={selectedRowDataValueFavorites.posted_by_user} className="Discussion">
                                                                                                                                    <div id={selectedRowDataValueFavorites.postedByUser} className="LeftDiscussion">
                                                                                                                                        {
                                                                                                                                            (selectedRowDataValueFavorites.posted_by_user.charAt(0) !== undefined) && (
                                                                                                                                                <Avatar>{selectedRowDataValueFavorites.posted_by_user.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div id={selectedRowDataValueFavorites.posted_by_user} className="RightDiscussion">
                                                                                                                                        <div id={selectedRowDataValueFavorites.posted_by_user} className="RightDiscussionTop">
                                                                                                                                            <div id={selectedRowDataValueFavorites.posted_by_user} className="RightDiscussionTopLeft">{selectedRowDataValueFavorites.posted_by_company}</div>
                                                                                                                                        </div>
                                                                                                                                        <div id={selectedRowDataValueFavorites.posted_by_user} className="RightDiscussionBas">
                                                                                                                                            {
                                                                                                                                                (selectedRowDataValueFavorites.bid_amount !== undefined && selectedRowDataValueFavorites.bid_amount !== "$") && (
                                                                                                                                                    <div className="RightDiscussionTopRight">{"Bid: " + selectedRowDataValueFavorites.bid_amount || loadBidAmount}</div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                        }
                                                                                                                    </ListItem>
                                                                                                            }
                                                                                                            )
                                                                                                    }
                                                                                                </List>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="RightSide">
                                                                                            {
                                                                                                (chatFirstCarrierNameForFavorites !== undefined) ?
                                                                                                    <HeaderChat name={chatFirstCarrierNameForFavorites.substring(0, chatFirstCarrierNameForFavorites.lastIndexOf("@"))} companyName={chatFirstCompanyNameForFavorites} mcnumber={chatMCNumberForFavorites} />
                                                                                                    :
                                                                                                    <HeaderChat name={selectedRowDataValueFavorites.posted_by_user.substring(0, selectedRowDataValueFavorites.posted_by_user.lastIndexOf("@"))} companyName={selectedRowDataValueFavorites.posted_by_company} mcnumber={selectedRowDataValueFavorites.posted_by_mc_numbe} />
                                                                                            }
                                                                                            <Chat discussion={chatAllMessagesForFavorites} />
                                                                                            <div>
                                                                                                <form onKeyPress={(e) => { onEnterButtonForChat(e, "Favorites") }}>
                                                                                                    <Paper component="form" className={classes.customPaper}>
                                                                                                        <InputBase id="inputMesageFavorites" autoComplete='off' className={classes.CustomInput} style={{ width: "85%" }} placeholder="Enter your message" />
                                                                                                        <IconButton onClick={() => handleOnSendMessageClick("Favorites")}>
                                                                                                            <SendIcon />
                                                                                                        </IconButton>
                                                                                                    </Paper>
                                                                                                </form>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </TabPanel>
                                                                        </Paper>
                                                                    </Box>
                                                                </Grid>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </Grid>
                            </Typography>
                        </>
                        : null
                }
            </main >
        </div >
    )
}

export default ActiveLoads;