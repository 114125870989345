import React, { useState, useEffect, } from 'react';
import { useHistory } from "react-router";
import {
    Tabs,
    Tab,
    Box,
    Button,
    Grid,
    IconButton,
    CssBaseline,
    Typography,
    Tooltip
} from "@material-ui/core";
import { MuiThemeProvider, makeStyles, useTheme, } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";

//drawer
import { environmentVariables } from "../../environment"
import axios from 'axios';
import CloseIcon from "@material-ui/icons/Close";
import Swal from 'sweetalert2';
import { Row, Col } from "react-bootstrap";
import MUIDrawer from "../Drawer";

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: "none",
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    nestedItems: {
        paddingLeft: theme.spacing(6),
    },
    Tabs: {
        textTransform: "none",
        maxWidth: "30%",
        width: "30%",
    },
    Tab3: {
        maxWidth: "30%",
        width: "30%",
        textTransform: "none",
    }

}));
const PreferredCarrier = () => {

    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [mainGridPercent, setMainGridPercent] = useState("100%");
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);
    const [tabPanelIndex, setTabPanelIndex] = useState(0);

    const [mcNumber, setMCNumber] = useState([]);
    const [compName, setCompName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [role, setRole] = useState("");
    const [carrierStatus, setCarrierStatus] = useState("");
    const [getRowData, setGetRowData] = useState([]);
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState(window.localStorage.getItem('company_mc_number'));

    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState(window.localStorage.getItem('user_role'));

    // Constant to handle logged in company role
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState(window.localStorage.getItem('company_role'));

    //constant to handle mui data table loader
    const [loading, setLoading] = useState(true);

    const [companyType, setCompanyType] = useState("active companies");

    const handleRowClick = (rowData, rowMeta) => {
        setGetRowData(rowData);
    };

    const options = {
        toolbar: false,
        headerStyle: { fontWeight: 600 },
        titleStyle: { fontWeight: 600 },
        filter: false,
        responsive: "vertical",
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        search: true,
        Add: false,
        onRowClick: (rowData, rowState) => handleRowClick(rowData, rowState),
        textLabels: {
            body: {
                noMatch: loading ?
                    <CircularProgress /> :
                    `There are no companies added to ${pascalCase(companyType)}`
            },
        }
    };

    useEffect(() => {
        if (window.localStorage.ifUserExist) {
            if (window.localStorage.getItem('login_status') === "ProfileAccepted") {
                if ((loggedInuserRole === "CompanyAdmin" && loggedInuserCompanyRole === "Broker") || (loggedInuserRole === "CompanyAdmin" && loggedInuserCompanyRole === "Broker&Carrier")) {
                    history.push({ pathname: '/preferredcarrier' });
                }
                else {
                    history.push({ pathname: '/forbidden' });
                }
                if (window.localStorage.getItem('company_mc_number')) {
                    setLoggedInCompanyMCNumber(window.localStorage.getItem('company_mc_number'))
                    getPreferredCarrierResponse(window.localStorage.getItem('company_mc_number'))
                    setTabPanelIndex(0)
                }
            }
        }
    }, [history, loggedInuserCompanyRole, loggedInuserRole]);


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography component={'div'}>{children}</Typography>
                    </Box>

                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const handleTopTabPannelChange = (event, newValue) => {
        if (newValue === 0) {
            setLoading(true)
            getPreferredCarrierResponse(loggedInCompanyMCNumber);
            setTabPanelIndex(newValue)
            setRowData([])
            setCompanyType("Active Companies")

        }
        else if (newValue === 1) {
            setLoading(true)
            getHoldCarrierResponse();
            setTabPanelIndex(newValue)
            setRowData([])
            setCompanyType("Disabled Companies")

        }
        else if (newValue === 2) {
            setLoading(true)
            getAllResponse();
            setTabPanelIndex(newValue)
            setRowData([])
            setCompanyType("Companies")

        }
        setTopTabPannelValue(newValue);
        setMainGridPercent("100%");
        setIsPaneOpen(false);

    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: "none",
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        "&:nth-child(odd)": {
                            backgroundColor: "#ECECEC",
                        },
                        "&:hover": { backgroundColor: "#aab5be!important" },
                        "&:focus": { backgroundColor: "#000!important" },
                    },
                },
                MUIDataTableBodyCell: {},
            },
        });

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    // Function to fetch data from api for User Company Name table
    async function getAllResponse() {
        try {
            let response = await axios({
                url: environmentVariables.getpreferredandholdcompanies +
                "?company_mc_number=" +
                loggedInCompanyMCNumber,
                method: "get",
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse.length === 0) {
                setLoading(false)
            }
            else {
                setLoading(true)
            }
            setRowData(newResponse);
        }
        catch (error) {
         }
    }

    // Function to get Preferred carrier details
    async function getPreferredCarrierResponse(loggedInCompanyMCNumber) {
        try {
            let response = await axios({
                url: environmentVariables.getpreferredcompanies+
                "?company_mc_number=" +
                loggedInCompanyMCNumber,
                method: "get",
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse.length === 0) {
                setLoading(false)
                setRowData([])
            }
            else {
                setLoading(true)
            }
            setRowData(newResponse);
        }
        catch (error) { 
            console.log("get preferred carrier error", error)
        }
    }

    // Function to get Hold carrier details
    async function getHoldCarrierResponse() {
        try {
            let response = await axios({
                url: environmentVariables.getholdcompanies +
                "?company_mc_number=" +
                loggedInCompanyMCNumber,
                method: "get",
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse.length === 0) {
                setLoading(false)
                setRowData([])
            }
            else {
                setLoading(true)
            }
            setRowData(newResponse)
        }
        catch (error) {
            console.log ("fetch hold companies error", error)
        }
    }

    const columnDefs = [
        { label: "CompanyId", name: "company_id", options: { display: false } },
        {
            label: "Company Name",
            name: 'company_name',
            options: {
                display: displaySpecificColums,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[1].toUpperCase()
                    )
                }
            }
        },
        {
            label: "MC Number",
            name: 'company_mc_number',
            options: { display: displaySpecificColums }
        },
        {
            label: "Mobile",
            name: 'mobile_number',
            options: { display: displaySpecificColums },
        },
        { label: "Company Role", name: 'company_role' },
        { label: "Status", name: "disabled_status", options: { display: false } },
        {
            label: "Actions",
            name: 'internal actions',
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    return (
                        <div>
                            <Button onClick={() => handleDetails(rowIndex.rowData)} style={{
                                backgroundColor: "black",
                                fontSize: "12px",
                                color: "white",
                                margin: "2px",
                                textTransform: "none",
                            }} variant="contained" >
                                Details
                            </Button>
                            <Button onClick={() => handlePreferredButtonConfirmClick(rowIndex.rowData)} style={{
                                backgroundColor: "#00b100",
                                fontSize: "12px",
                                color: "white",
                                margin: "2px",
                                textTransform: "none",
                            }} variant="contained" hidden={tabPanelIndex === 0 || rowIndex.rowData[5] === "false"} >
                                Activate
                            </Button>
                            <Button onClick={() => handleDisableButtonConfirmClick(rowIndex.rowData)} style={{
                                backgroundColor: "#FF5C4D",
                                fontSize: "12px",
                                color: "white",
                                textTransform: "none",
                            }} variant="contained" hidden={tabPanelIndex === 1 || rowIndex.rowData[5] === "true"} >
                                Disable
                            </Button>
                        </div>
                    )
                },
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div>
                            <span style={{ fontWeight: 500, fontSize: 14 }}>Actions  </span>
                            <Tooltip title={
                                <>
                                    <Typography component={'div'} style={{ fontSize: 12 }} color="inherit">Disable - Companies will not get load details for the loads you posted.</Typography>
                                    <Typography component={'div'} style={{ fontSize: 12 }} color="inherit">Activate - Companies will get load details for the loads you posted.</Typography>
                                </>
                            } placement="top">
                                < HelpOutlineIcon style={{ fontSize: 17, textShadow: 2 }} ></HelpOutlineIcon>
                            </Tooltip>
                        </div >
                    )
                }
            }
        }
    ];
    const [rowData, setRowData] = useState([]);

    const handleCarrierDatailsPaneClose = () => {
        setIsPaneOpen(false);
        setMainGridPercent("100%");
    }

    // Function to handle Details button of Preferred Carriers
    const handleDetails = (selectedRowState) => {
        if (selectedRowState !== undefined && selectedRowState !== null) {
            let completeSelectedRowData = rowData.find(({ company_id }) => company_id === selectedRowState[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setMCNumber(completeSelectedRowData.company_mc_number);
                setPhoneNo(completeSelectedRowData.mobile_number);
                setCompName(completeSelectedRowData.company_name);
                setRole(completeSelectedRowData.company_role);
                setCarrierStatus(completeSelectedRowData.status);
                setMainGridPercent("68%");
                setIsPaneOpen(true);
            }
        }
    }

    function handlePreferredButtonConfirmClick(rowValues) {
        if (rowValues !== undefined || rowValues !== null) {
            Swal.fire({
                text: `Are you sure you want to activate ${pascalCase(rowValues[1])
                    } ?`,
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handlePrefferButtonClick(rowValues)
                }
            })
        }

    }

    // Function to Handle Activate Button
    async function handlePrefferButtonClick(selectedRowData) {
        if (selectedRowData !== undefined && selectedRowData !== null) {
            let completeSelectedRowData = rowData.find(({ company_id }) => company_id === selectedRowData[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null && completeSelectedRowData.user_id !== "") {
                if (completeSelectedRowData['carrier_preferred']) {
                    completeSelectedRowData['carrier_preferred'] = "Preferred";
                }
                if (completeSelectedRowData.disabled_companies) {
                    completeSelectedRowData.disabled_companies = JSON.parse(completeSelectedRowData.disabled_companies)
                    const findStatus = completeSelectedRowData.disabled_companies.find(
                        ({ mc_number }) => mc_number === loggedInCompanyMCNumber
                    )
                    if (findStatus !== undefined) {
                        findStatus.status = "Preferred";
                    }
                }
                try {
                    let response = await axios({
                        url: environmentVariables.updatepreferstatus,
                        method: "patch",
                        data: completeSelectedRowData,
                    })
                    if (response) {
                        if (tabPanelIndex === 1) {
                            getHoldCarrierResponse();
                        }
                        else if (tabPanelIndex === 2)
                            getAllResponse();
                    }
                }
                catch (error) {
                    console.log("update preferred status error", error)
                 }
            }
        }
    }

    function handleDisableButtonConfirmClick(rowValues) {
        if (rowValues !== undefined || rowValues !== null) {
            Swal.fire({
                text: `Are you sure you want to disable ${pascalCase(rowValues[1])} ?`,
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handleDisableButtonClick(rowValues)
                }
            })
        }
    }


    // Function to handle Disable Button
    async function handleDisableButtonClick(selectedRowState) {
        if (selectedRowState !== undefined && selectedRowState !== null) {
            let completeSelectedRowData = rowData.find(({ company_id }) => company_id === selectedRowState[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null && completeSelectedRowData.user_id !== "") {
                if (completeSelectedRowData['carrier_preferred']) {
                    completeSelectedRowData['carrier_preferred'] = "Hold";
                    setCarrierStatus("Hold")
                }
                if (completeSelectedRowData.disabled_companies) {
                    completeSelectedRowData.disabled_companies = JSON.parse(completeSelectedRowData.disabled_companies)
                    if(completeSelectedRowData.disabled_companies.length !== undefined){
                        const findStatus = completeSelectedRowData.disabled_companies.find(
                            ({ mc_number }) => mc_number === loggedInCompanyMCNumber
                        )
                        if (findStatus === undefined) {
                            completeSelectedRowData.disabled_companies.push({ mc_number: loggedInCompanyMCNumber, status: "Hold" })
                        }
                        else {
                            findStatus.status = "Hold";
                        }
                    }
                    else{
                        completeSelectedRowData.disabled_companies = [{ mc_number: loggedInCompanyMCNumber, status: "Hold" }]
                    }
                }
                try {
                    let response = await axios({
                        url: environmentVariables.updatepreferstatus,
                        method: "patch",
                        data: completeSelectedRowData,
                    })
                    if (response) {
                        if (tabPanelIndex === 0) {
                            getPreferredCarrierResponse(loggedInCompanyMCNumber);
                        }
                        else if (tabPanelIndex === 2)
                            getAllResponse();
                    }
                }
                catch (error) {
                    console.log("hold status update error", error)
                 }
            }
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <Typography component={'div'} style={{ paddingBottom: "15%" }}>
                    <Box boxShadow={3} color="black" border={0} padding={1} >
                        <Grid container direction="row">
                            <Row>
                                <Col>
                                    <p style={{ fontSize: 20 }}>
                                        <b>Company List</b>
                                    </p>
                                </Col>
                                <Col>
                                    <Tabs value={topTabPannelValue} onChange={handleTopTabPannelChange} indicatorColor="primary" textColor="primary">
                                        <Tab className={classes.Tabs} label="Active Companies" />
                                        <Tab className={classes.Tabs} label="Disabled Companies" style={{ padding: 3 }} />
                                        <Tab className={classes.Tab3} label="View All" />
                                    </Tabs>
                                </Col>
                            </Row>
                            <Grid xs={12} sm={14}>
                                <Typography component={'div'}>
                                    <Grid container direction="row" margin={2}>
                                        <div style={{ width: mainGridPercent }} >
                                            {topTabPannelValue === tabPanelIndex &&
                                                <MuiThemeProvider theme={getMuiTheme()}>
                                                    <MUIDataTable
                                                        data={rowData}
                                                        columns={columnDefs}
                                                        options={options}
                                                    ></MUIDataTable>
                                                </MuiThemeProvider>
                                            }
                                        </div>
                                        {isPaneOpen && (
                                            <React.Fragment>
                                                <div style={{ width: "1%" }}></div>
                                                <div style={{ width: "30%" }} border={1}>
                                                    <Box
                                                        boxShadow={3}
                                                        style={{ width: "100%", overflowY: "scroll", maxHeight: "80vh", height: "100%" }}
                                                        color="black"
                                                        border={0}
                                                        padding={2}
                                                    >
                                                        <Grid container xs={12} justify="flex-end">
                                                            <IconButton onClick={handleCarrierDatailsPaneClose}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Grid>
                                                        <Row style={{ fontSize: 20 }}>
                                                            <Col sm>
                                                                <b>Company Details</b>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ height: 10 }} />
                                                        <Grid style={{ fontSize: 14 }} xs={12}>
                                                            <Grid >
                                                                <div style={{ height: 8 }} />
                                                                <Row>
                                                                    <Col sm>Company Name:</Col>
                                                                    <Col sm >{compName}</Col>
                                                                </Row>
                                                                <div style={{ height: 8 }} />
                                                                <Row>
                                                                    <Col sm>MC Number:</Col>
                                                                    <Col sm >{mcNumber}</Col>
                                                                </Row>
                                                                <div style={{ height: 8 }} />
                                                                <Row>
                                                                    <Col sm>Phone Number:</Col>
                                                                    <Col sm>{phoneNo}</Col>
                                                                </Row>
                                                                <div style={{ height: 8 }} />
                                                                <Row>
                                                                    <Col sm> Company Role:</Col>
                                                                    <Col sm>{role}</Col>
                                                                </Row>
                                                                <div style={{ height: 8 }} />
                                                                <Row>
                                                                    <Col sm>Company Status:</Col>
                                                                    <Col sm>{carrierStatus}</Col>
                                                                </Row>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Typography>
            </main >
        </div >
    )
}
export default PreferredCarrier;