import React, { useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Avatar,
    Box,
    Snackbar
} from '@material-ui/core';
import { Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import axios from 'axios'
import { environmentVariables } from "../../environment"
import { useHistory } from "react-router";
import MuiAlert from "@material-ui/lab/Alert";

const styles = {
    header: {
        backgroundImage: `url(${"https://loadeo-logo.s3.us-east-2.amazonaws.com/Loadeo_pricing_1920x550.jpg"})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },

    content: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
}

const UpdateProfile = () => {
    const history = useHistory();
    const { user } = useAuth0();
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    // All variable 
    const [user_id, setUser_id] = React.useState("");
    const [user_role, setUser_role] = React.useState("");
    const [user_name, setUser_name] = React.useState("");
    const [user_first_name, setUser_first_name] = React.useState("");
    const [user_last_name, setUser_last_name] = React.useState("");
    const [company_name, setCompany_name] = React.useState("");
    const [mc_number, setMc_number] = React.useState("");
    const [phone_number, setPhone_number] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [country, setCountry] = React.useState("");

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };
    // on page load event
    useEffect(() => {
        getAllResponse();
    }, []);

    async function getAllResponse() {
        await axios({
            url: environmentVariables.getuserdetails,
            method: 'get'
        })
            .then(response => {
                let newResponse = JSON.parse(response.data.body)
                const findUser = newResponse.find(
                    ({ email_address }) => email_address === user.email
                );
                setUser_id(findUser.user_id);
                setUser_name((findUser.user_name));
                setUser_first_name(findUser.first_name);
                setUser_last_name(findUser.last_name);

                if ((findUser.login_status) === "ProfileAccepted") {
                    if (findUser.mobile_number) {
                        setPhone_number(findUser.mobile_number);
                    }
                    if (findUser.address) {
                        setAddress(findUser.address);
                    }
                    if (findUser.city) {
                        setCity(findUser.city);
                    }
                    if (findUser.state) {
                        setState(findUser.state);
                    }
                    if (findUser.country) {
                        setCountry(findUser.country);
                    }
                    if (findUser.companyName) {
                        setCompany_name(findUser.companyName);
                    }
                    if (findUser.company_mc_number) {
                        setMc_number(findUser.company_mc_number);
                    }
                    if (findUser.user_role) {
                        setUser_role(findUser.user_role);
                    }
                }
            })
            .catch(error => {
                history.push({ pathname: '/callback' });
            })
    }
    // function to update the profile
    async function OnUpdateClick() {
        let list = {
            "user_id": user_id,
            "email_address": user.email,
            "user_name": user_name,
            "user_role": user_role,
            "user_last_name": user_last_name,
            "company_name": company_name,
            "mc_number": mc_number,
            "phone_number": phone_number,
            "address": address,
            "city": city,
            "country": country
        }
        await axios({
            url: environmentVariables.updateuserprofile,
            method: "patch",
            data: list,
        })
            .then((response) => {
                setOpenSnackBar(true);
                history.push({ pathname: '/callback' });
            })
            .catch((error) => { });
    }
    return (
        <div style={styles.header}>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Profile Succesfully Updated
                </Alert>
            </Snackbar>
            <div style={styles.content}>
                <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                    <Grid container xs={12} sm={6} alignItems="center" direction="column" style={{ height: "90vh" }}>
                        <Paper elevation={3} style={{ width: "80%", height: "100%", display: 'flex', justifyContent: 'center' }}>
                            <Grid container direction="row">
                                <Grid container direction="column" style={{ width: "100%", height: "10%" }}>
                                    <div style={{ width: "100%", background: "#d1c4bf", padding: 8 }}>
                                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Col xs lg="2">
                                                <Avatar style={{ background: "#eb7c50", width: "7vh", height: "7vh" }}>H</Avatar>
                                            </Col>
                                            <Col xs lg="6">
                                                <h2 style={{ paddingTop: 10 }}><font color="#f8f8f8">Update Profile</font></h2>
                                            </Col>
                                            <Col xs lg="1">
                                            </Col>
                                        </Row>
                                    </div>
                                </Grid>
                                <Grid container style={{ width: "100%", height: "87%", overflowY: "scroll" }}>
                                    <Box border={0} padding={2} style={{ width: "100%" }}>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="Email" value={user.email} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="Role" value={user_role} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="First Name" value={user_first_name} onChange={(value) => setUser_first_name(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="Last Name" value={user_last_name} onChange={(value) => setUser_last_name(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="Company Name" value={company_name} onChange={(value) => setCompany_name(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="MC Number" value={mc_number} onChange={(value) => setMc_number(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "100%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <PhoneInput
                                                country={'us'}
                                                value={phone_number}
                                                onChange={(phone) => setPhone_number(phone)}
                                                disableDropdown={true}
                                                placeholder='+1 (xxx) xxx-xxxx'
                                                containerStyle={{ width: "60%" }}
                                                dropdownStyle={{ height: '90%' }}
                                            />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="Address" value={address} onChange={(value) => setAddress(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="City" value={city} onChange={(value) => setCity(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="State" value={state} onChange={(value) => setState(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <TextField label="Country" value={country} onChange={(value) => setCountry(value.target.value)} style={{ width: "59%" }} variant="outlined" size="small" />
                                        </Row>
                                        <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <Button onClick={OnUpdateClick} variant="contained" color="primary" style={{ width: "60%", marginTop: 10 }}> Update</Button>
                                        </Row>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

}

export default UpdateProfile;