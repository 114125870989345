import React, { useState, useEffect, } from 'react';
import { useHistory } from "react-router";
import axios from 'axios';
import {
    Button,
    Box,
    Grid,
    IconButton,
    Snackbar,
    CssBaseline,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import MuiAlert from "@material-ui/lab/Alert";
import {
    MuiThemeProvider,
    makeStyles,
    createMuiTheme
} from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import useWebSocket, { } from 'react-use-websocket';
import { environmentVariables } from "../../environment"
//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';
// import statements to have all icons
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MUIDrawer from "../Drawer";
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    hide: {
        display: "none",
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    compRoleFormControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },

}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#b8cae9"
                },
                colorSecondary: {
                    "&$checked": {
                        // Controls checked color for the thumb
                        color: "#142440"
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.8,
                    backgroundColor: "#b8cae9",
                    "$checked$checked + &": {
                        // Controls checked color for the track
                        opacity: 0.8,
                        backgroundColor: "#142440"
                    }
                }
            },
            MUIDataTable: {
                root: {},
                paper: {
                    boxShadow: "none",
                },
            },
            MUIDataTableBodyRow: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#ECECEC",
                    },
                    "&:hover": { backgroundColor: "#aab5be!important" },
                    "&:focus": { backgroundColor: "#000!important" },
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    justifyContent: 'center',
                }
            },
            MUIDataTableHeadCell: {},
        },
    });

const Support = () => {
    //-------------------------Declaring all the constants ------------------------------
    const history = useHistory();
    const classes = useStyles();
    const { user } = useAuth0();

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [colorseverity, setColorseverity] = useState("");
    const [snackBarMessageText, setSnackBarMessageText] = useState("");
    const [isFieldEdited, setIsFieldEdited] = useState(false);


    const [isEditOpen, setIsEditOpen] = useState(false);

    // Constants to set rowdata onclick of row
    const [clickRowData, setClickRowData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [comment, setComment] = useState("");
    const [disableComment, setDisableComment] = useState(false)

    // Constant to handle main MuiDatatable
    const [gridPercent, setGridPercent] = useState("100%");
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [userTableRowData, setUserTableRowData] = useState([]);

    //constant to handle mui data table loader
    const [certificateStatus, setCertificateStatus] = useState("Verified");
    const [companyId, setCompanyId] = useState("")
    const [certificateUpdate, setCertificateUpdate] = useState(true)
    const [loadingCertificate, setLoadingCertificate] = useState(false);

    // options for user details table
    const options1 = {
        onRowClick: (clickAllUserRowData, rowState) =>
            handleRowClick(clickAllUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: true,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingCertificate ?
                    <CircularProgress /> :
                    'There are no certificate'
            },
        }
    };

    //Column definition for certificate table
    const userTableColumnDefs = [
        { label: "certificateId", name: "certificate_id", options: { display: false } },
        {
            name: "time_stamp",
            label: "Uploaded Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "company_mc_number",
            label: "MC Number",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "company_name",
            label: "Company Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "service_start_date",
            label: "Cerificate Issue Date",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "certificate_link",
            label: "Certificate Link",
            options: {
                display: false,
            }
        },
        {
            name: "verification_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, rowData) => {
                    if (value === "Verified") {
                        return (
                            <span style={{ color: "#32CD32", fontWeight: "bold" }}>{value}</span>
                        )
                    }
                    else if (value === "Not Verified" | value === "Error") {
                        return (
                            <span style={{ color: "Red", fontWeight: "bold" }}>{value}</span>
                        )
                    }
                    else if (value === "Document not matching") {
                        return (
                            <span style={{ color: "#FAD02C", fontWeight: "bold" }}>{value}</span>
                        )
                    }
                    else {
                        return (
                            <span>{value}</span>
                        )
                    }
                }
            }
        },
        {
            name: "comment_reason",
            label: "Status",
            options: { display: false, }
        },
    ];


    useEffect(() => {
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            getAllCertificate();
        }
        else {
            history.push({ pathname: '/forbidden' });
        }
    }, [history, user.email]);

    //Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => { },
            shouldReconnect: (closeEvent) => true,
        });

    // Function to handle send Socket IO messages
    async function sendMessageForSocketIO(message, recieverId) {
        try {
            let response = await axios({
                url: environmentVariables.websocketfetchallusers,
                method: 'get'
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse) {
                // handleAddNotification(message, recieverId, sendNotification, selectedRow, bidDetails)
            }
            let connectionIds = []
            // for (let i = 0; i < userResponse.length; i++) {
            newResponse.forEach(element => {
                if (recieverId === null || recieverId === "") {
                    connectionIds.push(element.connection_id)
                }
                else {
                    if (recieverId === element.user_id) {
                        connectionIds.push(element.connection_id)
                    }
                }
            });
            sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
        }
        catch (e) {
            console.log("send message error", e);
        }
    }



    // Function to handle MuiAlert
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //function to close secondary pane
    const closeSecondaryDetailPane = () => {
        setGridPercent("100%");
        setIsDetailOpen(false);
    };


    // Function to fetch data from api for certificate
    async function getAllCertificate() {
        await axios({
            url: environmentVariables.getmccertificate,
            method: 'get'
        })
            .then((response) => {
                setLoadingCertificate(true)
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "NoCertificateAreAvailable") {
                    setUserTableRowData(newResponse);
                }
                else {
                    setUserTableRowData([])
                }
            })
            .catch((error) => {
                setLoadingCertificate(false)
                console.error(error)
            });
    }

    //function to update certificate status
    async function updateUserDetails(rowValues) {
        setDisableComment(true)
        try {
            let response = await axios({
                url: environmentVariables.updatecertificatestatus,
                method: 'patch',
                data: {
                    comment_reason: comment,
                    verification_status: certificateStatus,
                    company_mc_bumber: clickRowData[2]
                }
            })
            if (response) {
                closeSecondaryDetailPane()
                setColorseverity("success")
                setSnackBarMessageText("Status has been Updated")
                setOpenSnackBar(true);
                getAllCertificate();
                setCertificateUpdate(false)
                sendMessageForSocketIO("Your MC Certificate Verification is Succeeded", "");
                setTimeout(() => {
                    setCertificateUpdate(true)
                }, 0);
            }
        }
        catch (error) { }
    }

    async function handleGetCompanyDetails() {
        try {
            let response = await axios({
                url: environmentVariables.getcompanyinformation + "?mc_number=" + clickRowData[2],
                method: 'get'
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                setCompanyId(newResponse[0].company_id)
            }
        }
        catch (error) {
            console.log("get company error", error)
        }
    }

    // Function to show Details
    function handleRowClick(rowValues) {
        setIsDetailOpen(true);
        setDisableComment(false)
        setClickRowData(rowValues);
        setComment(rowValues[7])
        setCertificateStatus(rowValues[6].props.children)
        setGridPercent("59%");
        handleClose()
        handleGetCompanyDetails()
    }

    // Function to show pop-up while delete
    function handleCancelButtonConfirmClick(rowValues) {
        handleClose()
        if (isFieldEdited) {
            Swal.fire({
                text: `Changes you made will not be saved, Click "OK" to Save OR Click "Cancel" to Continue `,
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value === true) {
                    updateUserDetails(rowValues)
                    handleRowClick(rowValues);
                }
            })
        }
        else {
            handleRowClick(rowValues);
        }

    }

    function statuschange(e) {
        setIsFieldEdited(true)
        setCertificateStatus(e.target.value)
    }

    function handleCommentChange(val) {
        setIsFieldEdited(true)
        setComment(val.target.value)
    }
    return (
        <div className={classes.root}>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={colorseverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <br></br>
                        <div style={{ width: gridPercent }} id="Users">
                            <div>
                                {certificateUpdate && <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"Posted Certificate"}
                                        data={userTableRowData}
                                        columns={userTableColumnDefs}
                                        options={options1}
                                    />
                                </MuiThemeProvider>}
                            </div>
                        </div>
                        {isDetailOpen && (
                            <React.Fragment>
                                <div style={{ width: "1%" }}></div>
                                <div style={{ width: "40%" }} border={1}>
                                    <Box boxShadow={3}>
                                        <Paper square className="Square" style={{ height: 700 }}>
                                            <IconButton style={{ marginLeft: "84%" }} onClick={closeSecondaryDetailPane}>
                                                <CloseIcon />
                                            </IconButton>
                                            <Grid container spacing={3} style={{ textAlign: "center" }}>
                                                <Grid style={{ fontSize: 14, marginLeft: "30%" }} xs={12}>
                                                    <Grid container direction="row">
                                                        <Row style={{ fontSize: 18 }}>
                                                            <div> <b>Certificate Details</b> </div>
                                                        </Row>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <div style={{ height: 5, paddingTop: "8%" }} />
                                            <Grid container spacing={2}>
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item>
                                                            <Row>
                                                                <Col sm>MC Number</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{clickRowData[2]}</Col>
                                                            </Row>
                                                        </Grid>
                                                        <Grid item>
                                                            <Row>
                                                                <Col sm>Uploaded Date</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{clickRowData[1]}</Col>
                                                            </Row>
                                                        </Grid>
                                                        <Grid item>
                                                            <Row>
                                                                <Col sm>Service Start Date</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{clickRowData[4]}</Col>
                                                            </Row>
                                                        </Grid>
                                                        <Grid item>
                                                            <Row>
                                                                <Col sm>Company Name</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{clickRowData[3]}</Col>
                                                            </Row>
                                                        </Grid>
                                                        <Grid item>
                                                            <Row>
                                                                <Col sm>Certificate Link</Col>
                                                                <Col style={{ marginRight: 15 }} sm><PictureAsPdfIcon onClick={() => window.open(clickRowData[5], '_blank')} /></Col>
                                                            </Row>
                                                        </Grid>
                                                        <Grid item disabled={certificateStatus === "Verified"}>
                                                            <Row>
                                                                <Col sm>Verification Status</Col>
                                                                {
                                                                    (clickRowData[6].props.children === "Verified") ? <Col style={{ marginRight: 15 }} sm>{certificateStatus}</Col> :
                                                                        <Col style={{ marginRight: 15 }} sm>

                                                                            <select value={certificateStatus} onChange={statuschange} >
                                                                                <option value="New">New</option>
                                                                                <option value="Verified">Verified</option>
                                                                                <option value="Document not matching">Document not matching</option>
                                                                                <option value="Corrupted Document">Corrupted Document </option>
                                                                            </select>
                                                                        </Col>
                                                                }

                                                            </Row>
                                                        </Grid>
                                                        <Grid item>
                                                            <Row>
                                                                <TextField
                                                                    label="Comments"
                                                                    autoComplete='off'
                                                                    multiline
                                                                    rows={4}
                                                                    variant="outlined"
                                                                    style={{ width: "95%", backgroundColor: "#ffffff" }}
                                                                    value={comment}
                                                                    onChange={(val) => handleCommentChange(val)}
                                                                    disabled={disableComment}
                                                                />
                                                            </Row>
                                                        </Grid>
                                                        <div style={{height:15}}/>
                                                        <Grid item>
                                                            <Row>
                                                                <Col>
                                                                    <Button variant="contained" color="primary" style={{
                                                                        position: "absolute",
                                                                        marginLeft: "7%",
                                                                        textTransform: "none",
                                                                        color: "white",
                                                                        backgroundColor: "#101e35"
                                                                    }} onClick={() => updateUserDetails(clickRowData)} hidden={isEditOpen} >
                                                                        Save
                                                                    </Button>
                                                                </Col>
                                                                <Col>
                                                                    <Button variant="contained" color="primary" style={{
                                                                        position: "absolute",
                                                                        margin: "0px, auto",
                                                                        textTransform: "none",
                                                                        color: "white",
                                                                        backgroundColor: "#101e35"
                                                                    }} onClick={() => handleCancelButtonConfirmClick(clickRowData)} hidden={isEditOpen}>
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Box>
                                    <div style={{ height: 5, paddingTop: "8%" }} />
                                    <div style={{ height: 5, paddingTop: "8%" }} />
                                </div>
                            </React.Fragment>
                        )}
                    </Grid>
                </Typography>
            </main >
        </div >
    );
};

export default Support;

