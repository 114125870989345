import React from  'react';
import { useAuth0 } from '@auth0/auth0-react';

// components
import Error401Page from "../../components/Pages/Error401Page"


const NotAuthenticatePage = (location) => {
    const { user} = useAuth0();

    return(
       <div className="price-sec">
           <Error401Page location={location} user={user}/>
       </div>
    )
}

export default NotAuthenticatePage;