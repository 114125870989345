import React from "react";
import "./Message.css";
import { useAuth0 } from "@auth0/auth0-react";

export default function Message(props) {
  const { user } = useAuth0();
  return (
    <div className={props.me === "true" ? props.type === "System Message" ? "MessageMeBid" : "MessageMe" : props.type === "System Message" ? "MessageYouBid" : "MessageYou"}>
      {(props.user === user.nickname) ?
        <div className="OwnUser">{props.user}</div>
        :
        <div className="User">{props.user}</div>
      }
      {props.message}
      <div className="Date">{props.date}</div>
    </div>
  );
}
