import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// components
import Page401AndPage404 from '../../components/Page401AndPage404';


const NotAuthorizedOrNoPath = (location) => {
    const { user } = useAuth0();

    return (
        <div className="price-sec">
            <Page401AndPage404 location={location} user={user} />
        </div>
    )
}

export default NotAuthorizedOrNoPath;