import React, { useState, useEffect } from 'react';
import {
    Grid, Paper, TextField, Typography,
    Box, Button, MenuItem, Select, Tooltip
} from '@material-ui/core';
import {
    makeStyles,
    useTheme,
} from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { Col } from "react-bootstrap";
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import LogoComponent from '../../main-component/LogoComponent'
import { environmentVariables } from '../../environment';
import moment from "moment";
import PhoneInput from 'react-phone-input-2'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import "./style.css"
import Swal from 'sweetalert2';
import { Row } from "react-bootstrap";
import useWebSocket, { } from 'react-use-websocket';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 160,
    },
}));

const styles = {
    header: {
        backgroundImage: `url(${"https://loadeo-logo.s3.us-east-2.amazonaws.com/01-Login-truck.png"})`,
        height: '140vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
}
const UserCreateProfile = () => {
    // Variable declaration
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [mobileNumber, setMobileNumber] = useState("");
    const [address, setAddress] = useState("");
    const [mcNumber1, setMcNumber1] = useState("");
    const [city, setCity] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [state, setState] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("")
    const [secondaryMcNumber, setSecondaryMcNumber] = useState("");
    const [secondaryMcNumbers, setSecondaryMcNumbers] = useState([])
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [companyRole, setCompanyRole] = useState("");
    const [changedValue, setChangedValue] = useState(false);
    const [disableCheckBox, setDisableCheckBox] = useState(false);
    const { user } = useAuth0();
    const [currentSelectedUser, setCurrentSelectedUser] = useState([])

    // Variable to handle company status
    const [disableIfNotNewCompany, setDisableIfNotNewCompany] = React.useState(false);

    // Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            shouldReconnect: (closeEvent) => true,
        });

    // Function to handle send Socket IO messages
    async function sendMessageForSocketIO(message, recieverId) {
        try {
            let response = await axios({
                url: environmentVariables.websocketfetchallusers,
                method: 'get'
            });
            let newResponse = JSON.parse(response.data.body)
            if (newResponse) {
                let connectionIds = []
                newResponse.forEach(element => {
                    if (recieverId === null || recieverId === "") {
                        connectionIds.push(element.connection_id)
                    }
                    else {
                        if (recieverId === element.user_id) {
                            connectionIds.push(element.connection_id)
                        }
                    }
                });
                sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    // Function that is triggered on page load
    useEffect(() => {
        axios(
            {
                url: environmentVariables.getuserdetails,
                method: 'get'
            }
        ).then(response => {
            let newResponse = JSON.parse(response.data.body)
            if (newResponse.length !== 0) {
                const findUser = newResponse.find(({ email_address }) => email_address === user.email)
                setEmail(findUser.email_address);
                setFullName(findUser.user_name);
                setCurrentSelectedUser(findUser);
                if (findUser.login_status === "ProfileCreated") {
                    if (findUser.mobile_number) {
                        setMobileNumber(findUser.mobile_number);
                    }
                    if (findUser.address) {
                        setAddress(findUser.address)
                    }
                    if (findUser.city) {
                        setCity(findUser.city)
                    }

                    if (findUser.company_name) {
                        setCompanyName(findUser.company_name)
                    }
                    if (findUser.state) {
                        setState(findUser.state)
                    }
                    if (findUser.user_name) {
                        setFullName(findUser.user_name)
                    }
                    if (findUser.firstName) {
                        setFirstName(findUser.firstName)
                    }
                    if (findUser.lastName) {
                        setLastName(findUser.lastName)
                    }
                    if (findUser.user_role) {
                        {/* Hidden Broker user role temperorily */}
                        // if (findUser.user_role === "Broker") {
                        //     document.getElementById("Broker").checked = true;
                        // }
                        if (findUser.user_role === "Carrier") {
                            document.getElementById("Carrier").checked = true;
                        }
                        /* commented Broker&Carrier role functionality temporerily */
                        // if (findUser.user_role === "Broker&Carrier") {
                        //     document.getElementById("Broker").checked = true;
                        //     document.getElementById("Carrier").checked = true;
                        // }
                        if (findUser.user_role === "CompanyAdmin") {
                            {/* Hidden Broker user role temperorily */}
                            // document.getElementById("Broker").checked = true;
                            document.getElementById("Carrier").checked = true;
                            document.getElementById("NewCompany").checked = true;
                        }
                    }
                    if (findUser.mc_numbers) {
                        if (findUser.mc_numbers.length === 1) {
                            setMcNumber1(findUser.mc_numbers[0]["mcNumber"])
                        }
                        for (let i = 0; i < findUser.mc_numbers.length; i++) {
                            if (i === 0) {
                                setMcNumber1(findUser.mc_numbers[i]["mcNumber"])
                            }
                            else {
                                if (!disableIfNotNewCompany) {
                                    setSecondaryMcNumbers([...secondaryMcNumbers, ""])
                                }
                                let tempArray = secondaryMcNumbers;
                                tempArray[i - 1] = findUser.mc_numbers[i]["mcNumber"]
                                setSecondaryMcNumbers(tempArray)
                            }
                        }
                    }
                    history.push({ pathname: '/underverification' });
                }
            }
        })
            .catch(error => {
            })
    }, [history, user.email, secondaryMcNumbers, disableIfNotNewCompany]);

    const handleChangedValue = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            setChangedValue(true);
        }
        else {
            setChangedValue(false);
        }
    }

    //function to handle textaddress Change
    const handelTxtaddressChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setAddress(event.target.value);
        }
    };
    //function to handle textCity Change
    const handelTxtcityChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCity(event.target.value);
        }
    };

    //function to handle CompanyName Change
    const handelTxtcompanyNameChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyName(event.target.value);
        }
    };

    //function to handle State Change
    const handelTxtStateChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setState(event.target.value);
        }
    };

    function GenerateMcNumbers() {
        if (!disableIfNotNewCompany) {
            setSecondaryMcNumbers([...secondaryMcNumbers, ""])
        }
    }

    //function to handle Name Change
    const handelNameChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setFullName(event.target.value);
        }
    };

    //function to handle MC NUMBER Change
    const handelTxtmcNumber1Change = (event) => {
        handleChangedValue(event);
        if (event.target.validity.valid || event.target.value === "") {
            setMcNumber1(event.target.value);
            if (event.target.value.length >= 6) {
                // Check company presentInfo
                axios(
                    {
                        url: environmentVariables.getcompanyinformation + "?mc_number=" + event.target.value,
                        method: 'get'
                    }
                ).then(response => {
                    let newResponse = JSON.parse(response.data.body)
                    if (newResponse !== "McNumberNotFound") {
                        setDisableIfNotNewCompany(true)
                        setSecondaryMcNumbers([])
                        setCompanyName(newResponse[0].company_name)
                        setCompanyRole(newResponse[0].company_role)
                        setAddress(newResponse[0].address)
                        setCity(newResponse[0].city)
                        setState(newResponse[0].state)
                        setStatus(newResponse[0].status)
                        if (newResponse[0].company_role === "Broker" || newResponse[0].company_role === "Carrier") {
                            setDisableCheckBox(true);
                        }
                        if (document.getElementById("NewCompany")){
                            document.getElementById("NewCompany").checked = false;
                        }
                        if (newResponse[0].company_role === "Broker") {
                            {/* Hidden Broker user role temperorily */}
                            // document.getElementById("Broker").checked = true;
                        }
                        if (newResponse[0].company_role === "Carrier") {
                            document.getElementById("Carrier").checked = true;
                        }
                        /* commented Broker&Carrier role functionality temporerily */
                        // if (newResponse[0].company_role === "Broker&Carrier") {
                        //     document.getElementById("Broker").checked = true;
                        //     document.getElementById("Carrier").checked = true;
                        // }
                    }
                    else {
                        if (document.getElementById("NewCompany")){
                            document.getElementById("NewCompany").checked = true;
                        }
                        setDisableIfNotNewCompany(false)
                        setCompanyName("")
                        setCompanyRole("")
                        setAddress("")
                        setCity("")
                        setState("")
                        document.getElementById("NewCompany").checked = true;
                        {/* Hidden Broker user role temperorily */}
                        // document.getElementById("Broker").checked = false;
                        document.getElementById("Carrier").checked = false;
                    }
                })
                    .catch(error => {
                    })
            }
            else {
                clearingAllFields();
            }
        }
    };

    const clearingAllFields = () => {
        setAddress("");
        setCompanyName("");
        setCity("");
        setCompanyRole("");
        setState("");
        {/* Hidden Broker user role temperorily */}
        // document.getElementById("Broker").checked = false;
        setDisableIfNotNewCompany(false)
        document.getElementById("Carrier").checked = false;
        if(document.getElementById("NewCompany")){
            document.getElementById("NewCompany").checked = false;
        }
        setDisableIfNotNewCompany(false);
    }

    //function to generate MCNumbers
    function handleGenerateMCNumbersChange(e, mcnumber) {
        if (e.target.validity.valid || e.target.value === "") {
            let tempArray = secondaryMcNumbers;
            tempArray[mcnumber] = e.target.value
            setSecondaryMcNumber(e.target.value)
            setSecondaryMcNumbers(tempArray)
        }
    }

    // Function to handle role changes in UserDetails Table
    const handleCompanyRoleChange = (event) => {
        setCompanyRole(event.target.value);
        if (event.target.value === "Broker") {
            {/* Hidden Broker user role temperorily */}
            // document.getElementById("Broker").checked = true;
            setDisableCheckBox(true);
        }
        else {
            {/* Hidden Broker user role temperorily */}
            // document.getElementById("Broker").checked = false;
        }
        if (event.target.value === "Carrier") {
            document.getElementById("Carrier").checked = true;
            setDisableCheckBox(true);
        }
        else {
            document.getElementById("Carrier").checked = false;
        }
        
        /* commented Broker&Carrier role functionality temporerily */
        // if (event.target.value === "Broker&Carrier") {
        //     document.getElementById("Broker").checked = true;
        //     document.getElementById("Carrier").checked = true;
        //     setDisableCheckBox(false);
        // }
    };

    //function to validate the fields
    const validate = () => {
        let temp = {}
        if (mcNumber1.length < 6 && mcNumber1.length > 10) {
            temp.mcNumber1Length = "error"
        }
        else {
            temp.mcNumber1Length = ""
        }
        if (secondaryMcNumber !== "" &&  secondaryMcNumber.length < 6 && secondaryMcNumber.length > 10) {
            temp.secMCNumberLength = "error"
        }
        else {
            temp.secMCNumberLength = ""
        }
        temp.email = email ? "" : "error"
        temp.companyName = companyName ? "" : "error"
        temp.mcNumber1 = mcNumber1 ? "" : "error"
        temp.companyRole = companyRole ? "" : "error"
        temp.address  = address ? "" : "error"
        temp.city = city ? "" : "error"
        temp.state = state ? "" : "error"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "");
    }
    function checkFirstUserStatus() {
        if ((status !== "ProfileAccepted" && disableIfNotNewCompany)) {
            Swal.fire({
                text: "Your company is not yet approved by admin",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handlepushData();
                }
            })
        }
        else {
            handlepushData();
        }
    }

    //function to post userDetails into DB
    async function handlepushData() {
        emailAddress(email)
        if (validate()) {
            if (mobileNumber.length === 10 && mobileNumber !== "") {
                let mcNumbersArray = []
                let mcNumbers = []
                let userRole = "";
                mcNumbers.push(mcNumber1)
                for (let i = 0; i < secondaryMcNumbers.length; i++) {
                    if (secondaryMcNumbers[i] !== "" && secondaryMcNumbers[i].length > 6) {
                        mcNumbers.push.apply(mcNumbers, secondaryMcNumbers);
                    }
                }
                mcNumbers.map((mcNumber) => {
                    let mcNumberobject = { mcNumber: mcNumber, role: "" }
                    mcNumbersArray.push(mcNumberobject)
                    getUserDetailsResponse(mcNumber)
                    return mcNumber
                })
                {/* Hidden Broker user role temperorily */}
                // if (document.getElementById("Broker").checked) {
                //     userRole = "Broker";
                // }
                if (document.getElementById("Carrier").checked) {
                    userRole = "Carrier";
                }
                /* commented Broker&Carrier role functionality temporerily */
                // if (document.getElementById("Broker").checked && document.getElementById("Carrier").checked) {
                //     userRole = "Broker&Carrier";
                // } 

                if (document.getElementById("NewCompany") && document.getElementById("NewCompany").checked) { 
                    userRole = "CompanyAdmin";
                }
                if (disableIfNotNewCompany === false) {
                    userRole = "CompanyAdmin";
                }
                let listValues = currentSelectedUser;
                listValues.user_name = fullName;
                listValues.address = address;
                listValues.mc_numbers = mcNumbersArray;
                listValues.first_name = fullName.split(" ")[0];
                listValues.last_name = fullName.split(" ")[1] ? fullName.split(" ")[1] : "";
                listValues.city = city;
                listValues.companyName = companyName.toUpperCase();
                listValues.state = state;
                listValues.mobile_number = mobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
                listValues.user_role = userRole;
                listValues.company_role = companyRole;
                listValues.login_status = "ProfileCreated"
                listValues.status = "ProfileCreated"
                listValues = { ...listValues, "time_stamp": moment.utc().format("YYYY-MM-DD HH:mm:ss") }
                listValues = { ...listValues, "new_user_status": "true" }
                if (disableIfNotNewCompany === false) {
                    listValues = { ...listValues, "create_new_company": "true" }
                }
                listValues = { ...listValues, "company_mc_number": mcNumber1 }
                try {
                    let response = await axios({
                        url: environmentVariables.updateloginname,
                        method: "patch",
                        data: listValues,
                    })
                    if (response) {
                        history.push({ pathname: '/underverification' })
                    }
                }
                catch (error) {
                    console.log("create profile error", error)
                 }
            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    }
    // Function to send email to admin
    async function emailAddress(email) {
        try {
            let approveMailResponse = await axios({
                url: environmentVariables.sendingnewrequestemailtoadmin,
                method: "put",
                data: {
                    email_address: email,
                },
            })
            if (approveMailResponse) {
                sendMessageForSocketIO("NewUserUnderVerification", process.env.REACT_APP_ADMIN_USER);
            }
        }
        catch (error) { }
    }

    //function to trigger on click of approve in newuser table
    async function getUserDetailsResponse(company_mc_number) {
        try {
            let response = await axios({
                url: environmentVariables.getapprovedcompanyuserdetails + "?company_mc_number=" + company_mc_number,
                method: "get",
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "UserNotPresent") {
                    try {
                        let approveMailResponse = await axios({
                            url: environmentVariables.sendingemailtocompanyadmin + "?email_address=" + newResponse[0].email_address,
                            method: "put",
                            data: {
                                email_address: email,
                            },
                        })
                        if (approveMailResponse) {
                        }
                    }
                    catch (error) { }
                }
            }
        }
        catch (error) { }
    }

    return (
        <div style={styles.header}>
            <div style={styles.content}>
                <br />
                <LogoComponent />
                <br />
                <h2 style={{ display: 'flex', justifyContent: 'center', color: "white" }}>Step 3/4</h2>
                <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100%' }}>
                    <Grid container xs={12} sm={6} alignItems="center" direction="column" style={{ height: "92vh" }}>
                        <Paper elevation={3} style={{ width: "90%", height: "100%", display: 'flex', overflowY: "scroll" }}>
                            <Grid container direction="row">
                                <Grid item xs={5}>
                                </Grid>
                                <div style={{ width: "100%", height: 20 }} />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <h2><b>Update Profile</b></h2>
                                    </Box>
                                </div>
                                <div style={{ width: "100%", height: 20 }} />
                                <Grid container direction="row">
                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid container>
                                                        <TextField label="MC Number*" style={{ width: "80%", }} value={mcNumber1} onChange={handelTxtmcNumber1Change} error={errors.mcNumber1 || errors.mcNumber1Length} inputProps={{ pattern: "[0-9]*", maxLength: 10 }}></TextField>
                                                    </Grid>
                                                    <div style={{ width: "100%", height: 10 }} />
                                                    <Grid container>
                                                        <TextField label="Company Name*" style={{ width: "80%" }}
                                                            disabled={disableIfNotNewCompany}
                                                            value={companyName}
                                                            onChange={handelTxtcompanyNameChange}
                                                            error={errors.companyName}>
                                                        </TextField>
                                                    </Grid>
                                                    <div style={{ width: "100%", height: 10 }} />
                                                    <Grid container>
                                                        <FormControl className={classes.formControl} hidden={mcNumber1.length < 6 || disableIfNotNewCompany}>
                                                            <InputLabel style={{ width: "100%" }} id="demo-controlled-open-select-label" >Company Role</InputLabel>
                                                            <Select
                                                                labelId="demo-controlled-open-select-label"
                                                                id="demo-controlled-open-select"
                                                                value={companyRole}
                                                                error={errors.companyRole}
                                                                style={{ width: "123%", marginTop: (disableIfNotNewCompany || mcNumber1 === "") === false ? "12%" : "1%" }}
                                                                onChange={handleCompanyRoleChange}
                                                            >
                                                                {/* Hidden Broker company role temperorily */}
                                                                {/* <MenuItem value="Broker">Broker</MenuItem> */}
                                                                <MenuItem value="Carrier">Carrier</MenuItem>
                                                                {/* commented Broker&Carrier role functionality temporerily */}
                                                                {/* <MenuItem value="Broker&Carrier">Broker & Carrier</MenuItem> */}

                                                            </Select>
                                                        </FormControl>
                                                        <TextField disabled={disableIfNotNewCompany} label="Address " required style={{ marginTop: (disableIfNotNewCompany || mcNumber1 === "") === false ? "3%" : "1%", width: "80%" }} value={address} error={errors.address && address === ""} onChange={handelTxtaddressChange}>mahesh</TextField>
                                                    </Grid>
                                                    <div style={{ width: "100%", height: 10 }} />
                                                    <Grid container>
                                                        <TextField disabled={disableIfNotNewCompany} label="City" required style={{ width: "80%" }}  error={errors.state && city === ""} value={city} onChange={handelTxtcityChange}></TextField>
                                                    </Grid>
                                                    {/* <div style={{ width: "100%", height: 20 }} />
                                                    <Grid container>
                                                        <Grid container>
                                                            <AddCircleOutlinedIcon onClick={GenerateMcNumbers} />
                                                            <Typography variant='caption' style={{ fontSize: "11px", lineHeight: "2.40" }}> Add one more MC Number</Typography>
                                                        </Grid>
                                                        <Grid container>
                                                            {
                                                                secondaryMcNumbers.map((country, mcnumber) => {
                                                                    return (
                                                                        <div key={mcnumber}>
                                                                            <TextField label="MC Number" style={{ width: "80%" }} onChange={(e) => handleGenerateMCNumbersChange(e, mcnumber)} value={secondaryMcNumber} error={errors.secMCNumberLength} inputProps={{ pattern: "[0-9]*", maxLength: 10 }} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid container>
                                                        <Tooltip title={email}>
                                                            <TextField disabled label="Email Address" style={{ width: "85%" }} value={email} error={errors.email}></TextField>
                                                        </Tooltip>
                                                    </Grid>
                                                    <div style={{ width: "100%", height: 10 }} />
                                                    <Grid container>
                                                        <TextField disabled label="Full Name" style={{ width: "85%" }} onChange={handelNameChange} value={fullName}></TextField>
                                                    </Grid>
                                                    <div style={{ width: "100%", height: 10 }} />
                                                    <Grid container>
                                                        <Grid container style={{ paddingTop: "4%" }}>
                                                            <PhoneInput
                                                                value={mobileNumber}
                                                                onChange={(phone) => setMobileNumber(phone)}
                                                                localization={'us'}
                                                                onlyCountries={['us']}
                                                                country={'us'}
                                                                defaultCountry={"us"}
                                                                disableDropdown={true}
                                                                disableCountryCode={true}
                                                                placeholder='(xxx) xxx-xxxx'
                                                                error={errors.mobileNumber}
                                                                containerStyle={{
                                                                    border: "1px solid black",
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ width: "100%", height: 10 }} />
                                                    <Grid container>
                                                        <TextField disabled={disableIfNotNewCompany} label="State" style={{ width: "85%" }} value={state} required error={errors.state && state === ""} onChange={handelTxtStateChange} ></TextField>
                                                    </Grid>
                                                    <div style={{ width: "100%", height: 15 }} />
                                                    <Grid container xs={9} >
                                                        {(!disableIfNotNewCompany) && (
                                                        <Row>
                                                            <Col sm>{<input type="checkbox" style={{ width: 17, height: 17, margin: 9, marginLeft: 0 }} value="1" id="NewCompany" color="primary" hidden={disableIfNotNewCompany} readOnly={!disableIfNotNewCompany} />}New Company</Col>
                                                        </Row>
                                                        )}
                                                       {/* Hidden Broker user role temperorily */}
                                                        {/* <Row>
                                                            <Col sm={12} style={{ marginRight: 60 }}>{<input type="checkbox" style={{ width: 17, height: 17, margin: 9, marginLeft: 0, marginRight: 10 }} value="2" id="Broker" color="primary" readOnly={(disableCheckBox && companyRole === "Carrier")} />}Broker</Col>
                                                        </Row> */}
                                                        <Row>
                                                            <Col sm={12} style={{ marginRight: 60 }}>{<input type="checkbox" style={{ width: 17, height: 17, margin: 9, marginLeft: 0, marginRight: 10 }} value="3" id="Carrier" color="primary" readOnly={(disableCheckBox && companyRole === "Broker")} />}Carrier</Col>
                                                        </Row>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <div style={{ width: "100%", height: 20 }} />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Button variant="contained" onClick={checkFirstUserStatus} backgroundcolor="555555" style={{ textTransform: "none" }}>Submit</Button>
                                    </Box>
                                </div>
                                <div style={{ width: "100%", height: 20 }} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div >
    )
}

export default UserCreateProfile;
