export const MCVerifyAdminIcon = () => {
    return (
        <svg width="90%" height="90%" viewBox="0 0 1107 1107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1107" height="1107" rx="66" fill="#FA8072" />
            <path d="M641.618 354.805C631.218 303.605 584.618 291.471 562.618 291.805H380.618M636.618 739.805C620.218 786.205 580.451 793.471 562.618 791.305H294.618C219.818 794.905 204.451 737.471 206.118 708.305V370.805C206.951 342.305 225.818 286.604 294.618 291.805H380.618M278.118 393.805H470.618M278.118 491.805H425.118M278.118 590.305H425.118M278.118 688.805H470.618M380.618 291.805C382.784 259.804 401.818 197.104 460.618 202.304H641.618C659.951 205.804 697.518 224.305 701.118 270.305V354.805" stroke="white" stroke-width="20" />
            <path d="M839 549.305C839 641.428 767.828 715.305 681 715.305C594.172 715.305 523 641.428 523 549.305C523 457.182 594.172 383.305 681 383.305C767.828 383.305 839 457.182 839 549.305Z" stroke="white" stroke-width="20" />
            <path d="M811.5 692.305L735 737.805L793.5 838.305L870 793.305L811.5 692.305Z" fill="white" />
            <path d="M877.5 806.305L801 851.805L817.521 882.058C829.545 904.075 857.403 911.774 879.026 899.054C899.651 886.922 906.959 860.631 895.552 839.596L877.5 806.305Z" fill="white" />
            <path d="M655.467 623.305C654.199 623.307 652.943 623.059 651.771 622.574C650.6 622.089 649.536 621.377 648.641 620.48L590.703 562.622C588.947 560.803 587.977 558.369 588 555.843C588.024 553.317 589.039 550.901 590.828 549.115C592.617 547.328 595.036 546.314 597.565 546.291C600.095 546.268 602.533 547.237 604.354 548.989L655.467 600.026L764.513 491.132C765.41 490.236 766.474 489.525 767.645 489.04C768.817 488.555 770.072 488.305 771.34 488.305C772.608 488.305 773.864 488.554 775.036 489.038C776.208 489.522 777.272 490.232 778.169 491.127C779.066 492.022 779.778 493.085 780.264 494.255C780.749 495.425 781 496.678 781 497.945C781 499.211 780.751 500.465 780.266 501.635C779.781 502.805 779.07 503.869 778.174 504.764L662.297 620.48C661.402 621.378 660.337 622.09 659.165 622.575C657.993 623.06 656.736 623.308 655.467 623.305Z" fill="white" />
        </svg>
    )
}