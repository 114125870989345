import React from 'react';
import Navbar from '../../components/Navbar'
import Breadcumb1 from '../../components/bredcrumb1'
import Support from '../../components/Support'
import Footer from '../../components/Footer'
const SupportPage = () => {
    return (
        <div className="price-sec">
            <Navbar />
            <Breadcumb1 bdtitle={'Support'} />
            <Support />
            <Footer />
        </div>
    )
}
export default SupportPage;