import React from 'react';

// components
import Breadcumb1 from '../../components/bredcrumb1'
import AwardedLoads from '../../components/Pages/AwardedLoads'
import Footer from '../../components/Footer'
import { useAuth0 } from "@auth0/auth0-react";

// import feedback
import FloatingButtonForFeedBack from "../../components/Pages/FloatingButtonForFeedBack"

const AwardedLandingPage = () => {
    const { user } = useAuth0();
    return (
        <div className="price-sec">
            <Breadcumb1 bdtitle={'Awarded Loads'} />
            <AwardedLoads />
            {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                <FloatingButtonForFeedBack />
            )}
            <Footer />
        </div>
    )
}

export default AwardedLandingPage;