import axios from 'axios'
import { environmentVariables } from '../environment';

export const handleFetchLoadForLoadboard = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getcarrierloadboard +
            "?carrier_name=" +
            user,
            method: "get",
        })
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error)
    }
}